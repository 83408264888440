.vNINWrapper {
    display: flex;
    gap: 5px;

    > p {
        color: #011a3c;
        font-family: 'SF-Pro-Display';
        font-size: 10px;
        font-style: normal;
        line-height: 14px;
        max-width: 309px;
    }
}

.UploadImageCTA {
    background: #e2eeff;
    border: 0.5px dashed rgba(0, 74, 173, 0.79);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 19px;
    width: 100%;
    height: 66px;

    &:hover,
    :active,
    :focus {
        box-shadow: 0px 0.5px 1px 0px rgba(0, 74, 173, 0.59);
        -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 74, 173, 0.59);
        -moz-box-shadow: 0px 0px 1px 0px rgba(0, 74, 173, 0.59);
    }

    div {
        background: #ffffff;
        border: 0.5px solid rgba(0, 74, 173, 0.79);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 50px;
        height: 46px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #004aad;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.IdentityModalWrapper {
    padding: 41px 50px;
    display: flex;
    justify-content: center;
    min-height: 374px;

    @media screen and (max-width: 576px) {
        padding: 20px 16px;
    }
}

.ImageOptionsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    > button {
        background: #fff;
        border: 0.5px solid #e5e5e5;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 19px;
        width: 100%;
        height: 66px;

        &:hover {
            border: 0.5px solid rgba(0, 74, 173, 0.79);
        }

        > div:first-child {
            background: #ffffff;
            border: 0.5px solid #e5e5e5;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            width: 50px;
            height: 46px;
        }

        h2 {
            color: #233862;
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            font-style: normal;
            line-height: 24px;
            text-align: left;
            margin: 0;
        }

        p {
            color: rgba(35, 56, 98, 0.7);
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            font-style: normal;
            line-height: normal;
            margin-top: 5px;
        }
    }

    .active {
        border: 0.5px solid rgba(0, 74, 173, 0.79) !important;
        background: #e2eeff !important;

        > div:first-child {
            background: #004aad !important;
            border: none !important;
        }
    }
}

.DraggerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-upload-list-item {
        margin-top: 0;
        .ant-upload-list-item-progress {
            height: 0;
        }
    }

    .ant-upload-list-item-name {
        white-space: break-spaces;
        margin-top: 6px;
        word-break: break-all;
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0;
    }
}

.Dragger {
    background: white !important;
    border: none !important;
}

.SelfieWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    row-gap: 20px;
}

.SelfieDescription {
    color: var(--shades-and-tints-gray-scale-4-d-4-d-4-d, #4d4d4d);
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    margin: 18px 0 0 0;
    width: 254px;
}

.SelfieInstructions {
    border-radius: 12px;
    padding: 11px;
    background-color: #e2eeff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    div {
        display: flex;
        align-items: center;
        gap: 5px;
        p {
            color: #4d4d4d;
            font-size: 12px;
            font-family: 'SF-Pro-Display';
            line-height: 20px;
        }
    }
}

.UploadLoadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > p {
        color: rgba(35, 56, 98, 0.78);
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
    }
}

.ConfirmedImgWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 14px;
    background: #011a3c;
    width: 100%;
    height: 201px;
    padding: 16px 20px 20px 20px;

    > img {
        height: 105px;
        object-fit: cover;
        border-radius: 14px;
    }

    > button {
        display: flex;
        padding: 11px 0px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 46px;
        border-radius: 10px;
        border: 0.5px dashed var(--disabled-btn, rgba(0, 74, 173, 0.79));
        background: var(--accent-blue-light, #e2eeff);
        width: 100%;
        cursor: pointer;

        > p {
            color: var(--accent-blue-primary, #004aad);
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.VerifyIdentityModalWrapper {
    padding: 24px 0;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 576px) {
        padding: 20px 16px;
    }
}

.imageEditorStyleWrapper {
    position: relative;
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;

    > canvas {
        border-radius: 12px;
        height: 260px !important;
        width: 375px !important;
        object-fit: cover;

        @media screen and (max-width: 576px) {
            height: 260px;
            width: 100%;
        }
    }
}
.ZoomImageWrapperStyle {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 1%;
    bottom: 10%;
    @media screen and (max-width: 576px) {
        right: 0%;
        bottom: 40%;
    }
}

.imageModalContainer {
    padding-top: 45px;
    display: flex;
    justify-content: center;

    img {
        object-fit: cover;
        height: 300px;
        border-radius: 8px;
        max-width: 100%;
        max-height: 100%;
    }
}

.customerSelfieTitle {
    color: #011a3c;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 16px;
}
.customerSelfie {
    height: 200px;
    padding: 16px 21px;
    background-color: #001837;
    border-radius: 14px;
    cursor: pointer;
    img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.imageMetaDataContainer {
    margin-top: 16px;
    background-color: #ffffff;
    border-radius: 14px;
    padding: 16px 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid #e2eeff;

    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        width: fit-content;
        h3 {
            color: #233862;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            line-height: 24px;
            /* 200% */
            letter-spacing: 0.78px;
            text-transform: uppercase;
            margin: auto;
        }
    }

    .identityMetaData {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .face-check {
            display: flex;
            align-items: center;
            gap: 3px;
            img {
                height: 24px;
            }
        }

        div {
            h4 {
                color: #3d5075;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                font-style: normal;
                line-height: 14px;
            }
            p {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }
    }
}
.check_failure {
    display: flex;
    align-items: center;
    gap: 8px;

    div {
        color: #da1e28;
        padding: 0px 8px;
        background-color: #fff1f1;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        border-radius: 3px;
        margin: auto 0;
    }
}

.check_not_available {
    display: flex;
    align-items: center;
    gap: 8px;

    div {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #e4aa0f;
        padding: 0px 8px;
        background-color: #fffaec;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        // line-height: 20px;
        letter-spacing: 1.02px;
        border-radius: 3px;
        margin: auto 0;

        .icon {
            display: flex;
        }
    }
}

.idCardMetaDataContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 14px;
    padding: 16px 14px;
    gap: 15px;

    .tooltipOverlay {
        background-color: #004aad;

        .ant-tooltip-content {
            margin-left: -18px !important;
            margin-top: -5px !important;
        }

        > .ant-tooltip-inner {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 150%;
            padding: 9px 8px !important;
            display: none;
            color: #ffffff;
            max-width: 174px;
        }
    }

    .titleSection {
        gap: 17px;
        display: flex;
        align-items: center;

        .override_btn {
            padding: 8px 12px;
            background-color: #004aad;
            border-radius: 30px;
            font-size: 12px;
            font-family: 'SF-Pro-Display-Medium';
            margin-left: auto;
            color: #fff;
            border: none;
            cursor: pointer;
        }

        .title {
            display: flex;
            align-items: center;
            gap: 8px;

            h3 {
                color: #233862;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                letter-spacing: 0.78px;
                text-transform: uppercase;
                margin: auto;
            }
        }

        .tag {
            padding: 0px 8px;
            display: flex;
            justify-items: center;
            align-items: center;

            font-family: 'SF-Pro-Display-Semibold';
            font-size: 12px;
            border-radius: 3px;
        }

        .tag.success {
            color: #32cc79;
            background-color: #32cc7926;
        }

        .tag.failure {
            color: #da1e28;
            background-color: #fff1f1;
        }
    }

    .identityMetaData {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        div {
            h4 {
                color: #3d5075;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                font-style: normal;
                line-height: 14px;
            }
            p {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
            .imageAnalyzed {
                cursor: pointer;
                display: flex;
                gap: 10px;
                align-items: center;
                p {
                    text-align: center;
                    font-family: 'SF-Pro-Display-Semibold';
                    font-size: 12px;
                    line-height: normal;
                    color: #004aad;
                }
            }
        }
    }
}

.verificationInProgressStyle {
    color: #233862;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding-left: 20px;
}
