.solutionModalContent {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 100px;
        padding-right: 100px;
        gap: 10px;
        margin-top: 45px;
        border-bottom: 1px solid rgba(229, 229, 229, 0.7);

        @media only screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }

        h2 {
            padding-bottom: 21px;
            margin-bottom: 0px;
            color: rgba(1, 26, 60, 0.8);
            border-bottom: 3px solid transparent;
            font-style: normal;
            line-height: normal;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 14px;

            cursor: pointer;
            transition: 0.3s all ease-in-out;
            -webkit-transition: 0.3s all ease-in-out;
            -moz-transition: 0.3s all ease-in-out;
            -ms-transition: 0.3s all ease-in-out;
            -o-transition: 0.3s all ease-in-out;
        }
    }

    &__body {
        padding: 20px 60px;

        @media only screen and (max-width: 768px) {
            padding: 20px 30px;
        }

        @media only screen and (max-width: 768px) {
            padding: 20px 0px;
            padding-bottom: 0px;
        }
    }
}

.active {
    border-bottom: 3px solid #004aad !important;
    color: #004aad !important;
}

.energyRecommendation {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__header {
        small {
            color: #61708e;
            font-size: 9px;
            font-style: normal;
            line-height: normal;
            font-family: 'SF-Pro-Display-Medium';
            letter-spacing: 0.9px;
        }

        h2 {
            color: #011a3c;
            font-size: 20px;
            font-style: normal;
            line-height: normal;
            font-family: 'SF-Pro-Display-Medium';
        }
    }

    &__body {
        color: rgba(1, 26, 60, 0.85);
        font-style: normal;
        h4 {
            letter-spacing: 0.66px;
            font-size: 12px;
            line-height: normal;
            font-family: 'SF-Pro-Display-Bold';
        }

        p {
            font-size: 15px;
            font-family: 'SF-Pro-Display';
            line-height: 25px;
        }
    }

    &__compHead {
        font-family: 'SF-Pro-Display-Semibold';
        font-style: normal;
        line-height: normal;
        font-size: 14px;
        margin-top: 10px;
    }

    &__table {
        padding-left: 0px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        color: #011a3c;

        max-height: 42vh;
        overflow-y: auto;
        padding-right: 5px;
        margin-bottom: 0;

        li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;

            padding-bottom: 20px;
            border-bottom: 0.5px solid #e5e5e5;

            p {
                width: 180px;
                font-size: 14px;
                font-style: normal;
                line-height: normal;
                font-family: 'SF-Pro-Display-Medium';
            }

            small {
                color: rgba(1, 26, 60, 0.8);
                font-size: 13px;
                font-style: normal;
                line-height: normal;
                font-family: 'SF-Pro-Display';
            }

            .type {
                color: rgba(1, 26, 60, 0.8);
                font-size: 13px;
                font-family: 'SF-Pro-Display';
                font-style: normal;
                line-height: normal;
                width: 100px;
            }

            .qty {
                display: flex;
                justify-content: flex-end;
                min-width: 30px;
            }
        }
    }

    &__footer {
        display: inline-block;
        text-align: center;
    }
}

.paymentPlans {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__header {
        margin-top: 10px;
        line-height: normal;
        font-style: normal;

        small {
            color: rgba(35, 56, 98, 0.7);
            display: inline-block;
            text-align: center;
            font-size: 14px;
            font-family: 'SF-Pro-Display';
            width: 100%;
            margin-bottom: 10px;
        }

        h2 {
            color: #011a3c;
            text-align: center;
            font-size: 20px;
            font-family: 'SF-Pro-Display-Bold';
        }
    }

    &__body {
        padding-left: 0px;
        margin-top: 20px;

        h4 {
            color: #011a3c;
            font-family: 'SF-Pro-Display-Semibold';
            font-style: normal;
            line-height: normal;
            font-size: 14px;
            margin-bottom: 15px;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            max-height: 40vh;
            overflow-y: auto;
            padding-right: 5px;

            li {
                list-style: none;
                display: flex;
                flex-direction: column;
                padding: 15px 20px;

                border: 1px solid #e5e5e5;
                border-radius: 12px;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                -ms-border-radius: 12px;
                -o-border-radius: 12px;

                @media only screen and (max-width: 500px) {
                    padding: 15px 10px;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }

    &__footer {
        display: inline-block;
        text-align: center;
        padding-bottom: 15px;
    }
}

.listTop {
    line-height: normal;
    font-style: normal;
    color: #011a3c;

    div {
        display: flex;
        gap:10px;
        align-items: center;
        p {
            font-size: 13px;
            font-family: 'SF-Pro-Display';
        }
    }

    small {
        text-align: right;
        font-size: 10px;
        font-family: 'SF-Pro-Display-Medium';
        letter-spacing: 0.55px;
    }
}

.listBottom {
    color: #011a3c;

    p {
        font-size: 20px;
        font-family: 'SF-Pro-Display-Bold';

        @media only screen and (max-width: 500px) {
            font-size: 14px;
        }

        span {
            font-size: 12px;
            font-family: 'SF-Pro-Display';

            @media only screen and (max-width: 500px) {
                font-size: 10px;
            }
        }
    }

    small {
        font-size: 13px;
        line-height: 25px;
        font-family: 'SF-Pro-Display-Semibold';
    }
}

.margin {
    color: #32cc79 !important;
}

.currency {
    white-space: nowrap !important;
}
