.ImageListingWrapper {
  background: var(--white);
  width: 541px;
  max-height: 510px;
  padding: 13px;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 500px) {
    box-shadow: none;
    width: 100%;
    padding: 0px;
    max-height: 200px;
  }
}

.ImageListingBtns {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background: white;
  position: relative;
  top: -65px;
  height: 60px;
  padding: 15px 0px;
  // width: 400px;
  // left: -10px;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.SingleImage {
  border-radius: 10px;

  @media screen and (max-width: 500px) {
    border-radius: 10px;
    margin-bottom: 5px;
    width: 159px;
  }
}

.DesktopImageSamples {
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 30px;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.MobileImageSamples {
  display: none;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
    max-height: 160px;
    overflow: scroll;
  }
}

.SearchingStateImageBox {
  line-height: 26px;
  color: #233862;
  font-family: 'SF-Pro-Display-Light';
  padding: 20px 15px;
  text-align: center;
  width: 100%;
  position: relative;
  top: 10px;

  @media screen and (max-width: 500px) {
    position: relative;
    top: 0px;
  }
}

.EmptyStateImageSearch {
  margin-top: 0px;
  width: 100px !important;
  z-index: 10000;
  text-align: center;
  display: block;
  position: relative;
  left: 40%;

  @media screen and (max-width: 500px) {
    position: relative;
    left: 0%;
  }
}

.MobileListingSelectButton {
  display: none;

  @media screen and (max-width: 500px) {
    display: none;
    position: relative;
    top: -220px;
  }

}