.quill {
    background-color: #fff !important;
    border-radius: 10px;
    color: #233862 !important;
    font-family: 'SF-Pro-Display' !important;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    padding: 0px 10px;

    @media screen and (max-width: 1040px) {
        width: 410px !important;
    }

    @media screen and (max-width: 500px) {
        width: 100% !important;
    }
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
    border: none;
}

.ql-container.ql-snow {
    position: relative !important;
    top: -40px;
}

.ql-toolbar {
    position: relative !important;
    bottom: 0 !important;
    left: 22px;
    width: fit-content !important;
    top: 92px;
    text-align: right;
    margin-left: auto;
    z-index: 10;

    @media screen and (max-width: 768px) {
        width: 100% !important;
    }
}

.ql-editor.ql-blank::before {
    font-style: normal;
}

.quill > .ql-container > .ql-editor.ql-blank::before {
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #233862;
    font-family: 'SF-Pro-Display-Light';
}

.RQEditorWrapper {
    height: fit-content;
    position: relative;
}

.RQEditorCharacterLimit {
    position: absolute;
    bottom: 16px;
    width: 30px !important;
    height: 16px;
    font-size: 10px;
    font-family: 'SF-Pro-Display';
    border-radius: 16px;
    display: grid;
    place-items: center;
}
