.FloatBox {
  position: fixed;
  bottom: 8%;
  right: 9%;
  z-index: 1;

}

.FloatContainer {
  background: #004AAD;
  box-shadow: 0px 8px 28px -6px rgba(1, 26, 60, 0.12), 0px 18px 88px -4px rgba(1, 26, 60, 0.14);
  border-radius: 40px;
  padding: 20px;
  width: 59px;
  height: 59px;
  text-align: center;
  cursor: pointer;

  img {
    position: relative;
    top: -4px !important;
    left: -1px;
  }
}
