.ReviewProfilePage {
    max-width: 85%;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 425px) {
        max-width: 100%;
    }
}

.ReviewProfileContainer {
    display: grid;
    grid-template-columns: minmax(420px, 450px) 1fr;
    gap: 73px;
    margin-top: 45px;

    .DetailsHeader {
        display: none;
        color: #011a3c;
        font-size: 17px;
        font-family: 'SF-Pro-Display-SemiBold';
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    @media screen and (max-width: 1192px) {
        display: block;
        margin-top: 40px;
    }
}

.ProfileDetails {
    display: flex;
    flex-direction: column;
    row-gap: 25px;

    @media screen and (max-width: 768px) {
        row-gap: 35px;

        .SummaryWrapper {
            margin-top: 0;
            height: auto;
        }

        .SectionTwoWrapper {
            flex-direction: column;
            gap: 27px;
        }

        .TotalAddedStyle {
            order: 2;
        }
    }
}

.ProfileActions {
    display: flex;
    justify-content: space-between;
    margin-top: 73px;

    .ProfileTitle {
        font-size: 20px;
        color: #011a3c;
        font-family: 'SF-Pro-Display-Bold';
    }

    .Actions {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: -30px;

        @media screen and (max-width: 768px) {
            position: absolute;
            bottom: -110px;
            left: 0;
            right: 0;
            min-height: 120px;
        }
    }

    @media screen and (max-width: 768px) {
        margin-top: 53px;
    }
}

.InfoContainer {
    display: flex;
    column-gap: 89px;
    
    >div:first-of-type {
        flex: 1;
    }

    >div:last-of-type {
        flex: 1.5;
    }

    @media screen and (max-width: 768px) {
        column-gap: 79px;
    }
}

.InfoHead {
    text-transform: uppercase;
    color: #011a3cd9;
    letter-spacing: 0.55px;
    font-size: 10px;
    font-family: 'SF-Pro-Display-Medium';
    margin-bottom: 6px;

    @media screen and (max-width: 768px) {
        margin-bottom: 2px;
        letter-spacing: 0.66px;
    }
}

.Info-Img {
    width: 182px;
    height: 123px;
    border-radius: 10px;
    object-fit: cover;
}

.Info {
    color: #011a3c;
    font-family: 'SF-Pro-Display-SemiBold';
}

.InfoDescription {
    .InfoHead {
        @media screen and (max-width: 768px) {
            font-family: 'SF-Pro-Display-SemiBold';
            margin-bottom: 15px;
        }
    }
}

.ProfileDescription {
    line-height: 25px;
    color: #011a3cbf;
    font-size: 15px;
    word-break: break-all;
}

.PackageList {
    max-width: 450px;
    width: 100%;
    justify-self: end;

    .InfoHead {
        font-family: 'SF-Pro-Display-SemiBold';
        margin-bottom: 20px;
    }

    .Cards {
        display: none;

        >div {
            h4 {
                font-size: 10px;
            }

            p {
                font-size: 14px;
            }

            button {
                background-color: transparent !important;
            }
        }

        @media only screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }

    @media screen and (max-width: 1192px) {
        margin-bottom: 30px;
        margin-top: 35px;
        max-width: none;
    }
}

.PackageTable {
    margin-top: -8px;

    @media only screen and (max-width: 600px) {
        display: none;
    }
}