.wrapper {
    padding: 34px;

    .top_title_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 40px;
        h3 {
            color: #011a3c;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 23px;
            line-height: normal;
            margin: auto 0;
        }
    }
    .bottom_title_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;
        button {
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            border: none;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 14px;
            color: #004aad;
            padding: 16px 24px;
        }
        button:hover {
            background-color: #f9fcff;
            border-radius: 30px;
        }
        h4 {
            margin: auto 0;
            color: #011a3c;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 15px;
            line-height: normal;
            span {
                color: #011a3c;
                font-family: 'SF-Pro-Display';
                font-size: 15px;
                font-style: normal;
                line-height: normal;
                margin-left: 4px;
            }
        }
    }

    .recommendations_wrapper {
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 900px) and (max-width: 1300px) {
            width: 100%;
            flex-wrap: nowrap;
            gap: 23px;
        }
    }
}

.CardWrapper {
    width: 490px;
    height: 206px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 6px;
    padding: 20px 28px;
    margin-bottom: 24px;
    box-shadow: 0px 0px 9px 0px #0000000f;

    @media screen and (min-width: 1300px) {
        width: 47%;
        margin-right: 15px;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 20px;
        width: 100%;
    }

    @media screen and (min-width: 900px) and (max-width: 1300px) {
        width: 100%;
    }
}

.FirstWrapper {
    display: flex;
    justify-content: space-between;

    button {
        border: 0.3px solid #004aad !important;
    }
}

.FirstWrapper_txt {
    display: flex;
    flex-direction: column;
    gap: 5px;

    h2 {
        font: 15px/18px 'SF-Pro-Display-Semibold';
        color: #011a3c;
        margin-right: 4px;
        max-width: 220px;
    }
}

.RecommendationSolution {
    font: 9px/11px 'SF-Pro-Display-Medium';
    color: #61708e;
    text-transform: uppercase;
}

.RecommenadationParagraph {
    font: 14px/21px 'SF-Pro-Display';
    color: #011a3cbf;
}

.SecondWrapper {
    display: flex;
    justify-content: space-between;
}

.RecommendationTitle {
    font: 9px/11px 'SF-Pro-Display-Medium';
    color: #011a3cd9;
    letter-spacing: 0.055em;
}
.RecommendationFigure {
    font: 12px/25px 'SF-Pro-Display-Semibold';
    line-height: 25px;
    color: #011a3c;
}

.sar_actionheader {
    font: 18px/24px 'SF-Pro-Display-Bold';
    color: #011a3c;
}
