.RepWorkspace_FilterDrawer {
    .ant-drawer-body {
        padding: 0;
        position: relative;
    }
}

.RepWorkspace_FilterDrawer_Content {
    padding: 32px;
    max-height: 80vh;
    overflow: auto;
}

.RepWorkspace_FilterDrawer_Heading {
    font: 18px/24px var(--font-bold);
    color: #011a3c;
    margin-bottom: 31px;
}

.RepWorkspace_FilterDrawer_SectionHeading {
    font: 14px/21px var(--font-regular);
    color: #011a3c;
    margin-bottom: 16px;
}

.RepWorkspace_FilterDrawer_Buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    position: absolute;
    bottom: 0;
    margin: 0 0 48px 32px;
}

.RepWorkspace_FilterDrawer_Checkboxes {
    display: flex;
    flex-direction: column;
    gap: 17.5px;

    .radioLabel {
        margin-bottom: 16px;
    }
}
