.ABtnWrapper {
    position: absolute;
    bottom: 30px;
    left: 35%;

    @media screen and (max-width: 500px) {
        position: relative;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-top: 200px;
    }
}

.AWCloseIconWrapper {
    position: absolute;
    right: -13px;
    cursor: pointer;
}

.DeclinePlanWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
