.wrapper {
    padding: 48px 79px 0 79px;
}

.previewWrapper {
    padding: 48px 73px 0 73px;
}

.tableTitles,
.variableHeading {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 45% 30% 20% 5%;
}

.tableTitles {
    grid-template-rows: 20px;
    margin-bottom: 7px;

    h5 {
        font: 12px/14.32px var(--font-regular);
        color: #011a3ccc;
        margin: 0;
    }

    h5:first-child {
        padding-left: 30px;
    }

    h5:nth-child(2) {
        margin-left: 3px;
    }

    h5:last-child {
        margin-left: -17px;
    }
}

.editVariableSections {
    height: 441px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.previewContentWrapper {
    background-color: #f6f6f6;
    padding: 26px 42px;
    border-radius: 9.31px;

    .editVariableSections {
        height: 409px !important;
        -ms-overflow-style: none !important;
        scrollbar-width: none !important;
    }

    &::-webkit-scrollbar {
        display: none !important;
    }

    .tableTitles,
    .variableHeading {
        grid-template-columns: 40% 30% 20% 10%;
    }

    .tableTitles {
        h5:first-child {
            padding-left: 19px;
        }

        h5:last-child {
            margin-left: -6px;
        }
    }

    .variableHeading {
        padding: 8px 19px;
    }
}

.editVariablesValuesGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    row-gap: 12px;
    justify-content: space-between;
}

.variableSection {
    width: 100%;
    background: #ffffff;
    border-radius: 3.89px;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    &.variableSectionScrollable {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.variableHeading {
    padding: 8px 30px;
    margin: 0;

    h4,
    h5,
    p {
        font: 12px/14.32px var(--font-medium);
        color: #011a3c;
        margin: 0;
    }

    h4 {
        color: #011a3cd9;
    }
}

.btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.detailsGrid {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    width: 100%;
    row-gap: 12px;
    column-gap: 12px;
    padding: 10px 19px;

    > div {
        h5 {
            font: 10px/11.93px var(--font-medium);
            color: #011a3cd9;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        p {
            font: 13px/25px var(--font-semibold);
            color: #011a3c;
            margin: 0;
        }
    }
}
