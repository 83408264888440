.ABtnWrapper {
    position: absolute;
    bottom: 30px;
    left: 35%;

    @media screen and (max-width: 500px) {
        position: relative;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin-top: 200px;
    }
}

.AButton {
    font-family: 'SF-Pro-Display-Semibold';
    width: 160px;
    height: 56px;
    background: #004aad;
    border: 0px;
    border-radius: 30px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
}

.AWrapper {
    padding: 50px;

    @media screen and (max-width: 500px) {
        padding: 20px;
    }
}

.SelectStyle {
    margin: 40px 15px;

    @media screen and (max-width: 500px) {
        margin: 100px 0;
        width: 100%;
        height: 100%;
        align-items: center;
    }
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 70px;
        justify-content: flex-start;
    }

    h1 {
        font-family: 'SF-Pro-Display-Semibold';
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #011a3c;
        text-align: center;
        margin-bottom: 6px;

        @media screen and (max-width: 500px) {
            text-align: left;
        }
    }

    p {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color: rgba(35, 56, 98, 0.7);

        @media screen and (max-width: 500px) {
            text-align: left;
        }
    }
}

.AWCloseIconWrapper {
    position: absolute;
    right: 0;
    cursor: pointer;

    @media screen and (max-width: 500px) {
        bottom: 40px;
    }
}
