.paymentPlanContainer {
    padding: 32px;
    width: 100%;

    @media only screen and (max-width: 900px) {
        padding: 15px;
    }

    @media only screen and (max-width: 500px) {
        padding: 0px;
    }

    &__content {
        display: flex;
        gap: 20px;
        flex-wrap: nowrap;

        @media only screen and (max-width: 1175px) {
            flex-wrap: wrap;
        }

        &__divider {
            flex: 0 0 0.5px;
            background: #e2eeff;

            @media only screen and (max-width: 1175px) {
                display: none;
            }
        }

        section {
            flex: 1 1 0px;
            @media only screen and (max-width: 1175px) {
                width: 100%;
            }
        }

        aside {
            min-width: 352px;
            @media only screen and (max-width: 1220px) {
                width: 100%;
                min-width: 100%;
            }
        }
    }
}

.basicInfoLoader {
    display: flex;
    gap: 5px;
}

.asideLoader {
    display: flex;
    flex-direction: column;
    gap: 50px;
    min-width: 352px;

    &__body {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}
