.SimulationContainer {
  padding: 0 46px;
  font-family: 'SF-Pro-Display';

  @media screen and (max-width: 1024px) {
    padding: 0 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 500px) {
    padding: 0;
  }
}

.SimulationTitle {
  font-family: "SF-Pro-Display-bold";
  font-size: 20px;
  line-height: 27px;
  color: #011a3c;
  margin-bottom: 50px;
  margin-top: -6px;
}

.SimulationStepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.SimulationStepsChildContainer>p {
  position: absolute;
  font-family: 'SF-Pro-Display-Light';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
}

.SimulationStepsButton {
  font-family: 'SF-Pro-Display';
  width: 36px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.SimulationActiveStepsButton {
  color: #ffffff;
  background: #011a3c;
  border: none !important;
  pointer-events: none
}

.SimulationPointerStyle {
  pointer-events: none;
}

.SimulationStepsLine {
  width: 60%;
  border-top: 1px solid #e5e5e5;
  margin: 0 25px;
  height: 1px;
}

.SimulationActiveStepsButton {
  color: #ffffff;
  background: #011a3c;
  border: none !important;
  pointer-events: none
}

.SimulationTopContent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 55px;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    margin-top: 35px;

    >div>p:first-child {
      margin-top: 40px;
    }
  }
}

.SimulationFirstText {
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 14px;
  color: #011A3C;
  margin-bottom: 11px;
}

.SimulationSecondText {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  line-height: 19px;
  color: #011A3C;
}

.SimulationEnergySolutionContainer {
  padding: 0 25px;

  @media screen and (max-width: 500px) {
    padding: 0px;
  }
}

.SimulationEnergySolutionSelectContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 19px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}

.SimulationEnergySolutionSelectChildContainer {
  height: 100px;
  padding: 15px 16px 16px 29px;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.SelectedPaymentWrapper {
  display: flex;
  margin: 0 0 24px 0;
  justify-content: center;

  @media screen and (max-width: 800px) {
    justify-content: flex-start;
  }
}

.SelectedPaymentPlans {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 11px 7px 27px;
  width: 543px;
  min-height: 50px;
  background: #ffffff;
  box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  top: 87%;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 18px;
  color: #011A3C;

  @media screen and (max-width: 500px) {
    width: 300px;
  }
}

.SimulationPagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.EnergySolutionSelectContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 19px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}

.EnergySolutionSelectChildContainer {
  height: 67px;
  padding: 15px 25px 15px 29px;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  &.StepOne {
    height: 100px;

  }
}

.SimulatedSolutionName {
  width: 30%;
}

.SimulatedSolutionAmount {
  text-align: right;
  color: #011A3C;
}

.SimulatedSolutionSelectBtn {
  text-align: right;
}

.StepOneSelectedComponents {
  display: flex;
  align-items: center;
  color: #011a3c;
  border-bottom: 0.5px solid #e5e5e5;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 58px;

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
}

.ERComponentsFirstTD {
@media only screen and (max-width: 500px) {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
}

.ERComponentsFirstTDDiv {
  margin-right: 5px;
  .ERComponentsFirstTDSelectedName {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    color: #011A3C;
    margin-right: 0;
  }

  @media only screen and (max-width: 500px) {
    margin-right: 0;

    .ERComponentsFirstTDSelectedName {
      margin-right: 5px;
    }
  }
}

.ERComponentAmountMobile {
  font-size: 13px;
  color: #011A3CCC;
  margin-bottom: 18px;
 @media only screen and (min-width: 501px){
   display: none;

 }
}

.SimulationPackageName {
  width: 100%;
  display: flex;
  align-items: center;

  p {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 16.71px;
    color: #011A3C;
    width: 150px;
  }

  span {
    margin: 0 3px;
  }
  
  @media only screen and (min-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;

    p {
      width: 262px;
    }

    span {
      margin: 0;
    }
  }

  @media only screen and (max-width: 350px){

    p {
      width: 120px;
    }

  }
}

.SimulationPackageAmountAndButton {
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 15px;
}