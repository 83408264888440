.contentContainer {
    padding: 39px 32px;

    @media screen and (min-width: 600px) {
        padding: 39px 45px;
    }
}

.heading,
.subHeading {
    color: #011a3c;
    font-size: 12px;
    line-height: 18px;
}

.subHeading {
    color: #4d4d4d;
    margin: 3px 0 16px 0;
}

.detailsContainer {
    display: flex;
    padding: 15px 16px;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    gap: 10px;
    align-self: stretch;
    background-color: #f9fcff;

    & > p {
        color: #004aad;
        font-size: 12px;
        line-height: 12px;
    }

    div {
        p:first-of-type {
            font-size: 10px;
            color: #3d5075;
            line-height: 15px;
            margin-bottom: 2px;
            text-align: center;
        }

        p:last-of-type {
            color: #233862;
            font-size: 14px;
            line-height: 21px;
        }

        .accountNumber {
            font-family: var(--font-bold);
            display: flex;
            align-items: center;

            svg {
                margin: 0 0 0 4px;
            }
        }
    }
}

.info {
    display: flex;
    gap: 3px;
    margin-top: 16px;

    svg {
        margin-top: 2px;
    }

    p {
        font-size: 10px;
        color: #000000;
        line-height: 15px;
    }
}
