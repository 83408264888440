.title {
    font: 12px/24px 'SF-Pro-Display-Medium';
    color: #001837;
    margin: 0 0 16px 0;
    letter-spacing: 0.78px;
}

.statusList {
    width: 100%;
    border: 1px solid #e2eeff;
    border-radius: 4px;
    background-color: #f9fcff;
    position: relative;
    padding: 12px 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
    max-height: 1000px;
}

.statusList.truncList {
    max-height: 390px;
}

.statusList.fullList {
    max-height: 1000px;
    overflow: visible;

    .dashed {
        height: calc(100% - 92px);
    }
}

.dashed {
    position: absolute;
    height: calc(100% - 32px); // remove padding
    border-left: 0.5px dashed #bbc9de;
    left: 22px;
    z-index: 0;
}

.allStatusOptions {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.currentStatus {
    padding: 10px 12px;
    width: 100%;
    border-top: 1px solid #e2eeff;
    border-bottom: 1px solid #e2eeff;
    box-shadow: 0px 0px 10px 0px #a4bad833;
    z-index: 3;
    background-color: #f9fcff;
}

.status {
    background-color: #f9fcff;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    padding: 0 12px;

    > span {
        font: 12px/20px 'SF-Pro-Display';
        color: #4d4d4d;
        margin: 0;
    }
}

.checkedIcon {
    fill: #f9fcff;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
}

.uncheckedIcon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #e2eeff;
}

.toggleBtnWrapper {
    z-index: 3;
    background-color: #f9fcff;
    width: 100%;

    &.lessBtnWrapper {
        margin-top: 16px;
        display: block;
    }

    &.moreBtnWrapper {
        position: absolute;
        bottom: 0;
    }
}

.toggleBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 7px 12px 7px 12px;
    cursor: pointer;
    border: 0.3px solid #004aad;
    border-radius: 30px;
    font: 12px/20px 'SF-Pro-Display-Medium';
    margin: 0 0 0 13px;

    &.lessBtn {
        background-color: #004aad;
        color: #ffffff;

        svg {
            stroke: #ffffff;
            transform: rotate(180deg);
        }
    }

    &.moreBtn {
        background-color: #f9fcff;
        color: #004aad;
        margin: 7px 14px;

        svg {
            stroke: #004aad;
        }
    }
}
