.AddressBlock {
  display: flex;
  margin-bottom: 30px;

  div {
    width: 100% !important;
  }

  span {
    display: inline-block;
    width: 12%
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    }
}

.FormBox {
  max-height: 450px;
  width: 545px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    max-height: 100%;
    height: auto;
    width: 100%;
    }
}

.InputBox {
  margin-bottom: 20px;
}
.SelectBox {
  :global {
    .ant-select-selection-item{
     padding-left: 13px !important;
   }
 }
}