.BuildYourSolarWrapper {
    background-image: url('https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/partner-cta-background.png');
    display: flex;
    justify-content: center;
    // height: 526px;
    padding: 0px 20px;

    >div {
        display: flex;
        max-width: 1244px;
        justify-content: space-between;
        width: 100%;
        padding-top: 50px;

        @media screen and (max-width: 950px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        @media screen and (max-width: 500px) {
            // flex-direction: column;
            align-items: flex-start;
            height: auto;
        }

        >img {
            max-width: 720px;
            max-height: 720px;
            width: 100%;
            overflow: hidden;
            min-width: 300px;
            object-fit: contain;

            @media screen and (min-width: 1280px) {
                position: relative;
                left: 49px;
            }

            @media screen and (max-width: 500px) {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 500px) {
        max-width: 100%;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 0px 20px;

        margin-top: 100px;
    }
}

.BuildYourSolarLeftWrapper {
    // padding-top: 120px;
    align-self: center;
    // min-width: 300px;
    padding-bottom: 20px;

    @media screen and (max-width: 500px) {
        padding: 0;
        max-width: 100%;
    }
}

.BuildYourSolarHeader {
    color: #fff;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 56px;
    line-height: 60px;
    width: 476px;
    margin-bottom: 10px;

    @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 38px;
        line-height: 42px;
    }
}

.BuildYourSolarText {
    color: #fff;
    font-family: 'SF-Pro-Display';
    font-size: 18px;
    line-height: 24px;
    width: 476px;


    @media only screen and (max-width: 998px) {
        width: 100%;
        max-width: 542px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        font-size: 16px;
        line-height: 20px;
    }
}

.BuildYourSolarBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div {
            max-width: 160px;
            justify-content: space-between;
            margin-top: 15px;
        }
    }
}