@mixin reset {
  margin: 0;
  padding: 0;
}

.AdminProviderUserTableDesktop {
  display: block;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.AdminProviderUserTableMainHead {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 20% 30%;
  padding: 0 30px 0 28px;
  margin-bottom: 15px;

  p {
    font-size: 13px;
    color: #011A3CCC;
  }
}

.AdminProviderUserTable {
  height: 425px;
  overflow-x: auto;
}

.AdminProviderUserTableItem {
  display: grid;
  align-items: center;
  grid-template-columns: 50% 20% 30%;
  height: 69px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 30px 0 28px;
}

.AdminProviderUserTableUserDetails {
  display: flex;
  align-items: center;
  gap: 5px;


  h4 {
    @include reset;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 0px 10px;
    border-radius: 3px;

    p {
      @include reset;
      font-family: 'SF-Pro-Display-Bold';
      font-size: 10px;
      letter-spacing: 0.5px;
    }
  }

  &.Mobile {
    gap: 2px;

    h4 {
      max-width: 100%;
    }

  }

  p {
    @include reset;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011A3C;
    text-align: left;
  }
}

.AdminProviderUserTableButtons {
  display: flex;
  align-items: center;
  gap: 5px;
  
  @media screen and (min-widtH: 1500px) {
    margin-left: 51px;
    justify-content: center;
  }
}

.AdminProviderUserTableHead {
  display: grid;
  align-items: center;
  grid-template-columns: 39% 25% 36%;
  margin-bottom: 10px;
  padding: 0 30px 0 28px;
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.8);
}

.AdminProviderUserTableItemTwo {
  display: grid;
  align-items: center;
  grid-template-columns: 39% 25% 36%;
  height: 69px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 30px 0 28px;
}

.AdminProviderUserTableMobile {
  display: block;
  height: 425px;
  overflow-x: auto;

  @media screen and (min-width: 801px) {
    display: none;
  }
}

.AdminProviderUserMobileCard {
  height: 188px;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  padding: 28px 16px;
  background-color: #FFFFFF;
  gap: 15px;
}

.AdminProviderUserCardSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.AdminProviderUserCardName {
  display: flex;
  align-items: center;
  gap: 3px;
}

.AdminProviderUserCardRoleDetails {
  h4 {
    @include reset;
    font-size: 13px;
    color: #011A3CCC;
  }

  p {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    color: #011A3C;
  }
}

.AdminProviderUserCardButtons {
  display: flex;
  align-items: center;
  gap: 3px;
}

.AdminProviderUserStatusFlag {
  @media screen and (max-width: 501px) {
    width: fit-content;
    padding: 8px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    align-self: flex-end;
  }
}