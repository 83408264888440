.CustomRecommendationEnergySolutionBottomOpen {
    opacity: 1;
    visibility: visible;
    transition: ease 0.3s;
    margin: 7px 30px 16px 30px;
}

.BottomTextWrapper {
    display: flex;
    justify-content: flex-start;
    gap: 80px;
    align-items: center;
    margin-bottom: 24px;

    &.ALTRecommendations {
        gap: 0;
        justify-content: space-between;
    }

    div {
        h5 {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 10px;
            color: #011a3cd9;
            margin: 0;
        }

        p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 16px;
            color: #011a3c;
            margin: 0;
        }
    }

    div:has(svg) {
        gap: 9px;
        align-items: center;

        p {
            font: 14px/24px 'SF-Pro-Display';
            color: #233862;
            text-wrap: nowrap;
            text-align: right;
        }

        svg {
            flex-shrink: 0;
        }

        @media only screen and (max-width: 500px) {
            p {
                text-wrap: wrap;
            }
        }
    }
}

.CustomRecommendationEnergySolutionWrapper {
    background: #ffffff;
    max-width: 417px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
}

.CustomRecommendationEnergySolutionTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px;

    h4 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        line-height: 16.71px;
        color: #011a3c;
        margin: 0 25px 0 0;
        flex-basis: 50%;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        color: #011a3c;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h4 {
            margin-right: 10px;
        }
    }
}

.AmountAndFlag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-basis: 40%;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;
        width: 150px;
    }
}

.TopSelectedFlag {
    width: 64px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2eeff;
    border-radius: 3px;

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 9px;
        letter-spacing: 0.055em;
        color: #004aad;
        margin: 0;
    }
}

.TopArrowWrapper {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.TopArrowOpen {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.TopArrowClosed {
    transition: transform 0.3s ease;
}

.CustomRecommendationEnergySolutionBottomClosed {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.3s;
    margin: 0;
}

.PackageListIemButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}
