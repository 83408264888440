.LabelBox {
    width: 57px;
    height: 23px;
    background: #e2eeff;
    border-radius: 3px;
    padding: 8px 10px;
    margin-left: 10px;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 9px;
    line-height: 0;
    letter-spacing: 0.055em;
    color: #004aad;
    cursor: pointer;
    display: grid;
    place-items: center;

    @media screen and (max-width: 500px) {
        margin-left: 0px;
    }
}
