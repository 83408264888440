.desktopTable {
    @media only screen and (max-width: 800px) {
        display: none;
    }
}

.cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    width: 100%;
    @media only screen and (min-width: 800px) {
        display: none;
    }
}

.pageWrapper {
    width: 100%;
    padding: 24px 100px;
    position: relative;
    color: #011a3c;
    font-family: 'SF-Pro-Display';

    @media screen and (min-width: 1400px) {
        padding-left: 96px;
    }

    @media screen and (max-width: 1188px) {
        padding: 10px;
    }
}
