$cardImageBoxHeight: 187px;
$cardImageBoxHeightMobile: 152px;

.Card {
    position: relative;
    width: 100%;
    min-height: 492px;
    background: #fff;
    border-radius: 16px;
    font-family: 'SF-Pro-Display';
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        min-height: 449px;
    }
}

.CardImageWrapper {
    position: relative;
    width: 100%;
    height: $cardImageBoxHeight;
    background-color: #004aad;
    overflow: hidden;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
        height: $cardImageBoxHeightMobile;
    }
}

.CardImage {
    width: 100%;
}

.CardImageOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: linear-gradient(
        180deg,
        rgba(18, 18, 18, 0) 14.17%,
        rgba(0, 0, 0, 0.88) 93.67%
    );
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h4 {
        font-family: 'SF-Pro-Display-Bold';
        color: #fff;
        font-size: 18px;
        line-height: 24px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 21px;
        opacity: 80%;
    }

    @media screen and (max-width: 768px) {
        h4 {
            font-size: 18px;
            line-height: 24px;
        }

        p {
            line-height: 23px;
        }
    }
}

.CardBody {
    width: 100%;
    height: calc(100% - $cardImageBoxHeight);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 20px 0px;

    h5,
    p {
        font-size: 14px;
        line-height: 21px;
        color: #001837;
        opacity: 80%;
    }

    @media screen and (max-width: 768px) {
        height: calc(100% - $cardImageBoxHeightMobile);
        padding: 20px;
        gap: 15px;

        button {
            margin-bottom: 20px;
        }
    }
}

.CardBodySectionWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.CardBodyHeadingBox {
    display: flex;
    gap: 8px;
    align-items: center;

    h4 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 0;
        letter-spacing: 0.065em;
        color: #233862;
        text-transform: uppercase;
        opacity: 80%;
    }
}

.CardBodyHeadingIconBox {
    margin-top: -3px;
}

.PoweringEssentialsBox {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 10px;

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(3, minmax(60px, 1fr));
    }
}

.PoweringEssentialsItem {
    display: flex;
    gap: 4px;
    align-items: center;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
        color: #060606;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.CardButtonWrapper {
    width: 100%;
    margin-top: auto;
    margin-bottom: 35px;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
}
