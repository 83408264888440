.CSIInputWrapper {
    display: flex;
    align-items: center;
    width: 389px;
    gap: 9px;
    height: 60px;
    padding: 0 0 0 16px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 1900px) {
        width: 33%;
    }

    @media screen and (max-width: 500px) {
        width: 100% !important;
    }

    input:focus {
        outline: none;
        border: 1px solid var(--blue);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    .CountryCodeBox svg {
        display: none;
    }

    .CountryCodeBox p {
        margin-left: 0px;
        font-family: 'SF-Pro-Display-Semibold';
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        padding: 0px;
        padding-left: 0px !important;
        color: #233862;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
    }

    .ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
        left: 457px;
        top: 296px !important;
        border-radius: 23px;
    }

    .ant-select.SelectCountry.undefined.ant-select-single.ant-select-show-arrow.ant-select-disabled {
        @media screen and (max-width: 500px) {
            width: 80px !important;
        }
    }

    .ant-select-arrow {
        top: 54% !important;
        right: 0px;

        .anticon:not(.ant-select-suffix) {
            pointer-events: none;
            color: black;
        }
    }
}

.SelectCountry {
    border: 0px;

    @media screen and (max-width: 500px) {
        .ant-select-selection-search {
            left: 0 !important;
            top: 0 !important;
            right: 0 !important;
        }

        .ant-select-arrow {
            right: 0 !important;
            top: 60% !important;
        }
    }
}

.CSIDropdownInput {
    position: relative;
    border: 0px !important;
    margin-left: 0px;
    padding-top: 1px;
    padding-right: 16px;
    width: 100%;
    margin-left: -2px;
    background: var(--white);
    display: flex;
    right: 0;
    top: 2.5px;
    color: #233862 !important;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Light';

    @media screen and (max-width: 600px) {
        min-width: 220px;
        margin-left: 9px;
    }

    @media screen and (max-width: 500px) {
        min-width: 68%;
    }

    @media screen and (max-width: 380px) {
        min-width: 65%;
    }
}

.CSIDropdownInput:focus {
    outline: none;
    border: 0px;
}

.FloatingInput {
    position: absolute;
    pointer-events: none;
    left: 100px;
    top: 18px;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);

    @media screen and (max-width: 501px) {
        left: 71px;
    }
}

.sseLabel {
    left: 120px !important;

    @media screen and (max-width: 501px) {
        left: 91px !important;
    }
}

.CountryCode {
    font-family: 'SF-Pro-Display-Semibold';
    color: #233862;
    position: relative;
    top: 1px;
    left: -7px;
}

.CountryCodeBox {
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 320px) {
        padding-left: 7px;
    }

    @media screen and (max-width: 501px) {
        width: 70px;
        text-align: center;
        justify-content: center;
        display: flex;
        margin: 8px 6px;
    }
}

.ErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-left: 20px;
}

input:focus ~ .FloatingInput,
input:disabled ~ .FloatingInput,
input:not(:focus):valid ~ .FloatingInput {
    top: 8px;
    margin-bottom: 10px;
    font-size: 11px;
    opacity: 1;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 13px;
    color: var(--blue);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 55px;
    border: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
    outline: none;
    border: 0px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    cursor: not-allowed;
}

.ant-select-dropdown.countrySelect.ant-select-dropdown-placement-bottomLeft {
    .rc-virtual-list-holder-inner {
        gap: 12px;
    }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    @media screen and (max-width: 500px) {
        padding: 0px;
    }
}
