.Wrapper {
    margin-top: 50px;
    font-family: 'SF-Pro-Display';

    @media screen and (min-width: 2500px) {
        width: 40vw;
    }

    @media screen and (min-width: 1300px) and (max-width: 1400px) {
        margin-left: -5%;
    }
}

.Row {
    display: flex;
    gap: 65px;
    margin-top: 35px;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        margin-top: 20px;
        gap: 20px;
    }
}

.PhoneNumberWrapper {
    display: flex;
    gap: 65px;
    margin-top: 35px;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
    }
}

.LeftWrapper {
    width: 228px;

    @media screen and (max-width: 800px) {
        width: 100%;
    }

    @media screen and (min-width: 801px) and (max-width: 1200px) {
        width: 180px;
    }
}

.BasicInfo {
    display: flex;
    flex-direction: column;
    width: 34%;

    @media screen and (max-width: 699px) {
        width: 100%;
        margin-left: 0;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
        width: 62%;
        margin-left: 0;
    }

    @media screen and (min-width: 1900px) {
        width: 40%;
    }

    @media screen and (min-width: 1300px) and (max-width: 1680px) {
        width: 51%;
    }
}

.States {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;

    @media screen and (max-width: 600px) {
        flex-direction: column;
    }
}

.Header {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
    margin-bottom: 8px;
    padding-top: 10px;
}

.SubHeader {
    font-size: 14px;
    line-height: 19px;
    color: rgba(35, 56, 98, 0.8);
}

.SaveBtn {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;
    width: 657px;

    @media screen and (max-width: 800px) {
        width: 100%;
        justify-content: center;
        margin-top: 60px;
    }

    @media screen and (min-width: 1900px) {
        width: 617px;
    }
}

.inputFieldWrapperStyle {
    margin-bottom: -19px;
}
