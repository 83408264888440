.Wrapper {
  margin: 20px 0px;
}

.PartnerWrapper {
  display: flex;
  width: 100%;
  max-width: 420px;
  height: 86px;
  background: var(--white);
  border: 0.5px solid var(--brown-grey);
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 20px;
  cursor: pointer;

  @media screen and (min-width: 2500px) {
      justify-content: space-between;
    }
  @media screen and (min-width: 1500px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 13px;
  }
}

.PartnerWrapper:hover {
  background: #ebf3ff;
  border: 0.5px solid #004aad;
}

.PartnerWrapper:hover .ImageBox{
  background-color: #004aad;;
}

.PartnerWrapper:hover .PartnerTypeIcon {
  filter: brightness(0) invert(1);
}

.ImageBox {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 0.5px solid var(--brown-grey);
  border-radius: 10px;

  @media screen and (min-width: 1900px) {
    margin-left: 5%;
  }
}

.PartnerTypeIcon {
  margin: 12px;
}

.PartnerTitleWrapper {
  margin-left: 15px;
  flex:1;

  @media screen and (min-width: 1900px) {
    margin-left: 25px;
  }
}

.PartnerTitle {
  width: 100%;
  height: 26px;
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 26px;
  color: var(--blue-3);
  margin-bottom: 2px;
}

.PartnerSubTitle {
  width: 100%;
  height: 14px;
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 14px;
  color: rgba(35, 56, 98, 0.7);
}

.ArrowIcon {
  display: flex;
  align-items: center;
  margin-left: 25px;

  @media screen and (min-width: 1900px) {
    margin-left: unset;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.LinkWrapper {
  max-width: 208px;
  height: 26px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  margin: 0px auto;
  margin-top: 40px;
}
