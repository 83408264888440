.form {
    width: 505px;
    padding: 24px;
    background-color: #f9fcff;
    border-radius: 14px;
    height: fit-content;

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}
