.SubmitKycContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 32px 42px;
    min-height: 374px;

    > .Option {
        width: 100%;
        min-height: 86px;
        padding: 18px;
        border: 0.5px solid #e5e5e5;
        border-radius: 18px;
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        transition: all 200ms linear;

        > .LeftBox {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            border: 0.5px solid #e5e5e5;
            display: flex;
            align-items: center;
            justify-content: center;

            path {
                transition: all 200ms linear;
            }
        }

        > .RightBox {
            > h4 {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                line-height: 21px;
                text-align: left;
                color: #233862;
            }

            > p {
                font-family: 'SF-Pro-Display';
                font-size: 12px;
                line-height: 14.32px;
                text-align: left;
                color: #233862b2;
            }
        }

        &:hover,
        &.Selected {
            background: #e2eeff;
            border: 0.5px solid #004aad;

            > .LeftBox {
                background: #004aad;

                path {
                    stroke: #fff;
                }
            }
        }
    }
}
