@media (max-width: 500px) {
    .ant-tabs-tab {
        width: 200px;
    }
}


.DetailsPageStatusTag {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px 6px;
    border-radius: 3px;
    background-color: #e2eeff;

    p {
        font-family: 'SF-Pro-Display-Semibold';
        color: #004aad;
        text-align: center;
        font-size: 10px;
        letter-spacing: 0.5px;
    }

    @media screen and (max-width: 500px) {
        margin-top: 6px;
    }
}

.DetailsPageStatusSecTag {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px 6px;
    border-radius: 3px;
    background: var(--Accent-Yellow-Light, #FFFAEC);

    p {
        font-family: 'SF-Pro-Display-Semibold';
        color: #E4AA0F;
        text-align: center;
        font-size: 10px;
        letter-spacing: 0.5px;
    }

    @media screen and (max-width: 500px) {
        margin-top: 6px;
    }
}