.ActionContainer {
    font-family: 'SF-Pro-Display';
    text-align: center;
    min-width: 350px;
    padding: 0 50px;

    @media screen and (max-width: 500px) {
        margin-top: 70px;
        width: 100%;
        padding: 0px;
        min-width: auto;
    }
}

.mobileActionModalStyle {
    @media screen and (max-width: 500px) {
        height: 100vh;
        overflow-y: hidden;
    }
}

.ActionHeader {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin: 20px auto;
    text-align: center;
}

.ActionText {
    font-style: normal;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(35, 56, 98, 0.8);
    margin: 10px auto 0 auto;
    max-width: 339px;

    @media only screen and (max-width: 375px) {
        width: 100%;
    }
}

.ActionBox {
    display: flex;
    margin-top: 10px;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 50px;
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
    }
}

.MobileBackLink {
    display: none;

    @media screen and (max-width: 500px) {
        display: block;
    }
}

.BackLinkIcon {
    color: var(--blue);
    margin-right: 10px;
}

.ActionCheckboxWrapper {
    margin-top: 20px;

    p {
        line-height: 24px;
        text-align: left;
        margin-left: 2px;
        white-space: nowrap;
        color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
    }

    svg {
        width: 50px;
        margin-right: 0px;
        margin-top: 5px;

        @media screen and (max-width: 500px) {
            width: 50px;
        }
    }
}

.closeIconStyles {
    width: 14.32px !important;
    height: 14.32px !important;
}
