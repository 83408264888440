.alternateRecomm {
    @media screen and (min-width: 500px) {
        margin: 0 24px;
    }

    @media screen and (min-width: 1200px) {
        margin: 0 32px;
    }

    header {
        h1 {
            font: 22px/35px 'SF-Pro-Display-Bold';
            color: #001837;
            margin-top: -42px;

            @media screen and (max-width: 576px) {
                margin-top: 0px;
            }
        }
    }

    &__filter {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 95px;

        @media screen and (max-width: 576px) {
            margin-top: 32px;
        }

        &_btn {
            width: 130px;
            height: 50px;
            border: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            border-radius: 7px;
            cursor: pointer;
        }

        &_search {
            width: 361px;
            height: 50px;
            border: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 7px;
            position: relative;

            &:focus-within {
                border-color: #004aad;
            }

            &:focus {
                border-color: blue($color: #000000);
            }

            input {
                flex: 1;
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                background: none;
                text-indent: 16px;
                position: relative;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    border: none;
                }

                :focus {
                    border: none;
                    outline: none;
                    appearance: none;
                }

                ::placeholder {
                    font: 14px/24px 'SF-Pro-Display';
                    color: #b0b0b0;
                }

                @media (max-width: 500px) {
                    ::placeholder {
                        width: 100px;
                        color: red;
                    }
                }
            }

            svg {
                margin-right: 14px;
                cursor: pointer;
            }
        }
    }

    main {
        .filterStatus {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
            z-index: 1;
            height: fit-content;

            width: 100%;
            margin-top: 42px;

            > span {
                font: 14px/24px 'SF-Pro-Display';
                color: #4d4d4d;
                margin: 0;
            }

            @media (max-width: 500px) {
                .MultiSelect {
                    width: auto !important;
                }
            }

            > div {
                margin-bottom: 0;
                width: fit-content;
            }
        }
    }

    &__pagination {
        width: 100%;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.planTable {
    display: none;

    @media (min-width: 1000px) {
        display: block;
    }

    & { 
        margin-top: 20px;
    }

    thead {
        border: none;
        margin-bottom: 0;
    }

    th {
        font: 13px/20px 'SF-Pro-Display';
        color: #011a3ccc;
        padding-left: 0;
    }

    th:first-child {
        padding-left: 61px;
    }

    &__row {
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;

        td {
            font: 14px/24px 'SF-Pro-Display';
            color: #011a3c;
            padding: 22px 0;
            border: 1px solid #e2eeff;
            border-left: none;
            border-right: none;
            background: #fff;
        }

        td:first-child {
            font: 14px/24px 'SF-Pro-Display-Bold';
            color: #011a3c;
            padding-left: 61px;
            border-left: 1px solid #e2eeff;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        td:last-child {
            border-right: 1px solid #e2eeff;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

// Tablet & Mobile
.alternate__mobile {
    margin-top: 30px;
    display: grid;
    gap: 20px;

    @media (min-width: 1000px) {
        display: none;
    }

    &__card {
        width: 100%;
        height: 178px;
        border-radius: 7px;
        background: #fff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.06);
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            column-gap: 20px;
            text-wrap: pretty;

            h4 {
                color: rgba(1, 26, 60, 0.85);
                font-family: 'SF-Pro-Display-Medium';
                font-size: 8px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.44px;
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 15px;
                font-style: normal;
                line-height: normal;

                @media (max-width: 500px) {
                    word-break: break-all;
                    max-width: 150px;
                }
            }
        }

        > div:last-child {
            align-items: start;
        }
    }
}

.solution_provided,
.awaiting_solution {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 1.02px;
    text-transform: uppercase;

    width: fit-content;
    padding: 0.5px 10px;

    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.solution_provided {
    background: #e2eeff;
    color: #004aad;
}

.awaiting_solution {
    color: rgba(35, 56, 98, 0.7);
    background: rgba(229, 229, 229, 0.5);
}
