.ComponentWrapper {
  margin: 60px 100px;
  margin-right: 0px;

  @media screen and (max-width: 500px) {
    margin: 0px;
    padding-top: 40px;
  }
}

.AddComponentRow {
  display: flex;

  @media screen and (max-width: 1200px) {
    display: block;
  }
}

.LeftComponent {
  margin-right: 50px;


  @media screen and (min-width: 1900px) {
    .LeftComponent {
      margin-right: 150px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }

}

.ComponentBtnWrapper {
  display: flex;
  margin-top: 50px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.MobileComponentBtnWrapper {
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
}

.ComponentLink {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: var(--blue);
  width: 388px;
  cursor: pointer;
  margin-top: 15px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

}

.SelectedComponentsWrapper {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  line-height: 17px;
  margin-top: 40px;
  width: 407px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.SelectedComponents {
  display: flex;
  align-items: center;
  color: #011a3c;
  border-bottom: 0.5px solid #e5e5e5;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 58px;

  @media screen and (max-width: 500px) {
    width: 100%;
    display: table-header-group;
  }
}

// .SelectedComponents :hover {
//   padding-top: 0px;
//   padding-bottom: 0px;
// }

.SelectedName {
  font-family: 'SF-Pro-Display-Semibold';
  color: #011a3c;
  max-width: 114px;
  margin-right: -5px;
}

.SelectedCapacity {
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.8);
}

.Scroll {
  display: flex;
  flex-direction: column;
}

.Arrow {
  width: 9px;
}

.QuantityImageWrapper {
  height: 11px;
  background: #ebebeb;
  width: 27px;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px;
  position: relative;
  left: -9px;
  cursor: pointer;
}

.StepOneSelectedComponents:hover .QuantityImageWrapper {
  display: flex;
}

.SelectedComponents:hover .QuantityImageWrapper {
  display: flex;
}

.Quantity {
  color: #011a3c;
  position: relative;
  margin: 2px 0px;
  width: 27px;
  display: flex;
}


.Count {
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  line-height: 16px;
  color: #011a3c;
}

.SubtotalWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 357px;
  border-bottom: 0.5px solid #e5e5e5;
  padding-left: 37px;

  @media screen and (max-width: 1200px) {
    width: 407px;
    padding-left: 15px;
  }

  @media screen and (max-width: 500px) {
    width: 100% !important;
    margin-top: 30px;
    padding-bottom: 18px;
  }
}

.firstSubTotalWrapper {
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 54px !important;
  }
}

.Subtotal {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  line-height: 17px;
  color: #011a3c;

}

.SubtotalButtonWrapper {
  text-align: right;
  // width: 357px;
  margin-top: 20px;

  @media screen and (max-width: 1200px) {
    width: 407px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {}

.HideMobile {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.HideDesktop {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.AddComponentTd {
  @media screen and (max-width: 500px) {
    width: 40% !important;
  }
}

.MobileAmount {
  @media screen and (max-width: 500px) {
    width: 130px !important;
  }
}

.MobileQty {
  @media screen and (max-width: 500px) {
    width: 10% !important;
  }
}

.MobileTotalWrapper {
  @media screen and (max-width: 500px) {
    margin-top: 100px;
  }
}