.ConsumerWorkspaceWrapper {
    margin: 0;

    .ant-carousel {
        height: 176px;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        border: none;
        height: 1px;
        background-color: #e5e5e5cc;
    }

    @media only screen and (min-width: 500px) {
        margin: 64px;
    }
    @media only screen and (min-width: 1440px) {
        margin: 62px 250px;
    }
}

.ConsumerWorkspaceHeading {
    font: 20px/24px 'SF-Pro-Display-Light';
    color: #011a3c;
    margin-bottom: 0;
    span {
        font-family: 'SF-Pro-Display-Bold';
    }

    @media only screen and (max-width: 500px) {
        margin-bottom: 15px;
    }
}

.ConsumerWorkspaceSummary {
    display: flex;
    height: fit-content;
    padding: 34px 38px;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #e2eeff;
    background: #fff;

    &.RepDashboard {
        position: relative;
        background-color: #011a3c;
        padding: 34px 38px 36px 38px;
        background-image: url(../../../assets/images/dashboard-metrics-bg.svg);

        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}

.RepDashboardMobile {
    background-color: #011a3c;
    border-radius: 10px;
    min-height: 150px;
    background-image: url(../../../assets/images/dashboard-metrics-bg.svg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% 70%;

    .ConsumerWorkspaceSummary_Card {
        display: flex !important;
        margin-top: 25px;

        &.DynamicCard_Idx0 {
            align-items: center !important;
        }

        &:last-child {
            text-align: center;
            align-items: center;
        }
    }

    .ConsumerWorkspaceSummary_Card_Icon {
        margin-bottom: 0px;
    }

    .slick-dots {
        bottom: -2px;

        li.slick-active {
            width: 30px;
        }

        li {
            width: 15px;
            margin: 0 3.5px;
        }

        li button:before {
            content: '__';
            color: white;
            opacity: 1;
            font-size: 20px;
            line-height: 16px;
            font-family: 'SF-Pro-Display-Bold';
        }

        li.slick-active button:before {
            opacity: 1;
            color: #004aad;
            content: '____‎';
        }

        @media (min-width: 500px) and (max-width: 767px) {
            bottom: -10px;
        }
    }

    @media screen and (min-width: 768px) {
        display: none;
    }

    @media screen and (min-width: 440px) {
        background-size: 100% 98% !important;
    }

    @media screen and (min-width: 395px) {
        background-size: 100% 80%;
    }
}

.ConsumerWorkspaceSummary_Card {
    width: 100%;
    border-right: 1px solid #e2eeff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
        align-items: flex-start;
    }
    &:last-child {
        align-items: flex-end;
        border-right: none;
        text-align: left;
    }
    h4 {
        font: 9px/20px 'SF-Pro-Display';
        color: #011a3c;
        letter-spacing: 1.229px;
        margin-bottom: 3px;
    }

    p {
        font: 16px/24px 'SF-Pro-Display-Bold';
        color: #011a3c;
    }

    &.RepDashboard {
        z-index: 1;

        h4,
        p {
            color: #ffffff !important;
        }

        h4 {
            text-transform: uppercase;
        }
    }

    &.DynamicCard_Idx0 {
        align-items: flex-start !important;
    }
}

.ConsumerWorkspaceSummary_Card_Icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2eeff;
    border-radius: 50%;
    margin-bottom: 22px;
}

.ConsumerWorkspace_Tabs {
    margin-top: 61px;

    @media only screen and (max-width: 500px) {
        margin-top: 80px;
    }
}

.ConsumerWorkspace_Table {
    th {
        font: 13px/20px 'SF-Pro-Display';
        color: #011a3ccc;
        padding-left: 0;
    }
    th:first-child {
        padding-left: 46px;
    }
}

.ConsumerWorkspace_Table_Rows {
    margin-bottom: 10px;
    td {
        font: 16px/24px 'SF-Pro-Display';
        color: #011a3c;
        padding: 20px 0;
        border: 0.5px solid #e2eeff;
        border-left: none;
        border-right: none;
    }
    td:first-child {
        font: 14px/24px 'SF-Pro-Display-Semibold';
        padding-left: 46px;
        border-left: 0.5px solid #e2eeff;
    }

    td:last-child {
        border-right: 0.5px solid #e2eeff;
    }
}

.ConsumerWorkspace_Table_IconBtn {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: fit-content;
    color: #004aad;
    gap: 6px;

    svg {
        stroke: #004aad !important;
    }

    &:hover {
        color: #ffffff;
        svg {
            stroke: #ffffff !important;
        }
    }
}

.ConsumerWorkspaceSummary_Mobile {
    height: 100%;

    .slick-dots.slick-dots-bottom {
        bottom: 0;
    }

    .ConsumerWorkspaceSummary_Card {
        display: flex;
        align-items: center;
        text-align: center;

        .ConsumerWorkspaceSummary_Card_Icon {
            margin: 41px auto 21px auto;
        }
    }
}

.ConsumerWorkspace_Table_Menu {
    width: 294px;
    margin-right: 60px;

    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
        font: 14px/16px 'SF-Pro-Display';
        color: #233862;
    }
}

// skeletal view styles
.SkeletonLoadingWrapper {
    margin: 30px auto;
}

.LoadingHeader {
    width: 221px;
    height: 25px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 6px;
}

.LoadingTabsWrapper {
    margin: 40px auto 20px;
    display: flex;
    justify-content: space-between;
}

.LoadingTabs {
    width: 174px;
    height: 16px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
}

.SkeletonLoadingHR {
    width: 100%;
    height: 0px;
    border: 1px solid rgba(229, 229, 229, 0.8);
}

.SkeletonLoadingCard {
    width: 100%;
    height: 206px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 40px auto;
    padding: 30px;
}

.SkeletonLoadingCardWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.LeftSideWrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 10px 5px;

    @media screen and (max-width: 640px) {
        display: none;
    }
}

.LoadingCardTopLeft {
    width: 155px !important;
    height: 16px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
    margin-bottom: 10px;
}

.LoadingCardBottomLeft {
    width: 193px !important;
    height: 12px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
}

.LoadingCardTopRight {
    width: 114px !important;
    height: 16px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
    margin-bottom: 10px;

    @media screen and (max-width: 640px) {
        display: none;
    }
}

.LoadingCardBottomRight {
    width: 129px !important;
    height: 23px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 3px;
}

.LongSkeletonLoader {
    width: 299px !important;
    height: 16px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
    margin-left: 5px;

    @media screen and (max-width: 900px) {
        width: 180px !important;
    }
}

.LongerSkeletonLoader {
    width: 547px;
    height: 14px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
    margin-bottom: 5px;
}

.SmallSkeletonCard {
    width: 94%;
    height: 78px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    margin-left: 50px;
    margin-bottom: 35px;
}

.SmallSkeletonTop {
    width: 157px;
    height: 16px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
    margin-bottom: 10px;
}

.SmallSkeletonBottom {
    width: 103px;
    height: 12px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 4px;
}

.BigSkeletonLoader {
    width: 139px;
    height: 42px !important;
    background: linear-gradient(
        90deg,
        #f1f1f1 0%,
        #f3f3f3 51.7%,
        #f3f3f3 100%,
        #f1f1f1 100%
    );
    border-radius: 3px;
}

.VerticalLine {
    width: 1px;
    height: 100px;
    border-right: 1px solid #f7f7f7;
    margin: 0px 20px;
}

.ant-carousel .slick-dots li {
    background-color: #fff;
    border-radius: 2px;
}

.pages .ant-pagination-next .ant-pagination-item-link {
    background-color: #fff;
}

.UpdateSkeletalBox {
    max-width: 60% !important;

    @media screen and (max-width: 900px) {
        max-width: 100% !important;
    }
}

.slick-slider {
    @media screen and (max-width: 500px) {
        height: 13vh;
    }

    @media screen and (max-height: 700px) and (max-width: 500px) {
        height: 19vh;
    }
}

.SpaceBetween {
    display: flex;
    justify-content: space-between;
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    .ant-carousel .slick-dots-bottom {
        bottom: 20px;
    }
}

@media screen and (max-width: 500px) {
    .ant-carousel .slick-dots-bottom {
        bottom: -60px;
    }
}

.ConsumerWorkspaceHeadingWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ConsumerWorkspace_TableStatus {
    padding: 1.5px 8px;
    border-radius: 3px;
    font: 12px/20px var(--font-semibold);
    letter-spacing: 1px;
    text-transform: uppercase;
    display: grid;
    place-items: center;
    width: fit-content;
}
