.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 48px 24px;

    >h2 {
        font-family: SF-Pro-Display-Bold;
        font-size: 20px;
        line-height: 23.87px;
    }

    >p {
        font-family: SF-Pro-Display;
        font-size: 15px;
        line-height: 17.9px;
    }

    >div:first-of-type {
        width: 100%;
        max-width: 464px;
        display: flex;
        flex-direction: column;
        gap: 36px;
        margin: 36px 0 36px 0;



        >div {
            margin-bottom: 0;

            &>div,
            span {
                margin-bottom: 0;
            }
        }
    }

}