$table-row-padding-left-full-desktop: 49px;

.wrapper {
    margin: 0 136px 0 112px;

    @media only screen and (max-width: 1300px) {
        margin: 0 52px;
    }

    @media only screen and (max-width: 1024px) {
        margin: 0 20px;
    }

    @media only screen and (max-width: 500px) {
        margin: 0;
    }
}

.titleAndMobileBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
        margin: 24px 0 52px 0;
    }
}

.title {
    font: 23px/27.45px 'SF-Pro-Display-Bold';
    margin: -42px 0 68px 0;

    @media only screen and (max-width: 500px) {
        margin: 0;
        line-height: 20px;
    }
}

.searchAndBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-bottom: 37px;
}

.search {
    width: 361px;
    height: 50px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    position: relative;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background: none;
        text-indent: 16px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        :focus {
            border: none;
            outline: none;
            appearance: none;
        }

        ::placeholder {
            font: 14px/24px 'SF-Pro-Display';
            color: #b0b0b0;
        }

        @media (max-width: 500px) {
            ::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        margin-right: 14px;
        cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}

.createBtnWrapper {
    display: flex;
    gap: 8px;

    button {
        display: block;
    }

    @media only screen and (max-width: 500px) {
        display: none;
    }
}

.dropdown {
    display: block;

    @media only screen and (min-width: 501px) {
        display: none;
    }
}

.table {
    border-collapse: separate;
    border-spacing: 0 16px;
    th {
        font: 13px/15.51px 'SF-Pro-Display';
        color: #011a3ccc;
        padding: 24px 0 2px 0;

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
        }
    }

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.tableRow {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px #0000000f;
    height: 67px;

    >div {
        height: inherit;
        display: flex;
        align-items: center;
        gap: 16px;
    }

    td {
        font: 14px/16.71px 'SF-Pro-Display';

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
            font-family: 'SF-Pro-Display-Semibold';
        }
        &:nth-child(2) {
            font-family: 'SF-Pro-Display-Semibold';
        }
    }
}

.cards {
    display: none;

    > div {
        h4 {
            font-size: 10px;
        }

        p {
            font-size: 14px;
        }
        button {
            background-color: #004aad !important;
            color: #ffffff !important;
        }
    }

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}


.profile_creation_modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 48px 24px;

    >div:first-of-type {

        h2 {
            font-family: SF-Pro-Display-Bold;
            font-size: 20px;
            line-height: normal;
            text-align: center;
            margin: 0;
        }

        h3 {
            color: rgba(35, 56, 98, 0.80);
            text-align: center;
            font-family: "SF-Pro-Display";
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
            margin: 8px 0 22px 0;
        }
    }

    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 38px;

        h3 {
            color: #233862;
            font-family: "SF-Pro-Display-Medium";
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
        }

        p {
            color: rgba(35, 56, 98, 0.70);
            font-family: "SF-Pro-Display";
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }

        input {
            display: none;
        }

        label {
            display: grid;
            grid-template-columns: auto 1fr;
            cursor: pointer;
            padding: 18px;
            gap: 17px;
            height: 86px;
            align-self: stretch;
            border-radius: 10px;
            border: 0.5px solid #E5E5E5;
            background: #FFF;

            div:first-of-type {
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                border-radius: 10px;
                border: 0.5px solid #E5E5E5;
                background: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        label:hover,
        input[type="radio"]:checked+label {
            border-radius: 10px;
            border: 0.5px solid var(--Accent-Blue-Primary, #004AAD);
            background: var(--Accent-Blue-Light, #E2EEFF);

            div:first-of-type {
                border-radius: 10px;
                background: var(--Accent-Blue-Primary, #004AAD);

                path {
                    stroke: #FFFFFF;
                }
            }
        }
    }

}