.inputTitle {
    font: 14px/16.71px var(--font-medium);
    color: rgba(1, 26, 60, 1);
    margin-bottom: 8px;
}

.inputSubTitle {
    font: 12px/14.32px var(--font-regular);
    color: rgba(35, 56, 98, 0.7);
    margin-bottom: 0px;
}

.pills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    overflow-y: auto;
}

.pill {
    font: 14px/18px var(--font-regular);
    color: white;
    background-color: rgba(35, 56, 98, 1);
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 21px;
    padding: 10px 16px;

    svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        cursor: pointer;
    }
}

.btns {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    button {
        flex-shrink: 0;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}

.errorMsg {
    font: 12px/16px var(--font-regular);
    color: #da1e28;
    margin: 0;
}
