.consumer_info_wrapper {
    padding: 24px;
    border: 1px solid #e2eeff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.consumer_info_row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
        font: 14px/24px 'SF-Pro-Display-Semibold';
        color: #3d5075;
    }
    h4 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 20px;
        color: #3d5075;
    }
    p {
        color: #233862;
        text-align: right;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
    }
}

.consumer_note {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 24px 48px 24px 43px;

    h4 {
        font: 14px/24px 'SF-Pro-Display-Medium';
        color: #3d5075;
        margin-bottom: 4px;
    }

    p {
        font: 15px/25px 'SF-Pro-Display';
        color: #011a3cbf;
        margin: 0;
    }
}
