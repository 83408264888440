.page {
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1;
    display: flex;
    justify-content: center;

    @media (min-width: 501px) and (max-width: 1000px) {
        padding: 24px;
    }
}

.mainContent {
    max-width: 954px;
    width: 100%;
}

.uploadInfo {
    color: #233862;
    text-align: center;
    font-size: 12px;
    line-height: 18px;
}

.supportedFormat {
    color: #233862b2;
    text-align: center;
    font-size: 13px;
    line-height: normal;
}
