.RowWrapper {
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column-reverse;
  }
}

.RowWrapper2 {
  margin: auto 50px;

  @media (max-width: 500px) {
    margin: 20px;
  }

  @media screen and (min-width: 1920px) {
    margin-bottom: 0px;
  }
}
