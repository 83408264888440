.Wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
}

.Image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

p {
    margin: 0
}