.ClusterModalWrapper {
  display: flex;
  justify-content: space-between;
}

.ClusterModalDiv {
  padding: 70px 50px 50px;
  width: 652px;

  @media screen and (max-width: 600px) {
      padding: 70px 20px;
    }
  h3 {
    color: #233862;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 22px;
    line-height: 35px;
    margin-top: 30px;
@media screen and (max-width: 600px) {
    width: 100%;
  }
  }

  p {
    color: #5F5B5B;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
    width: 540px;
@media screen and (max-width: 600px) {
    width: 100%;
  }
  }
}

.ClusterModalImagesWrapper {
  background-color: #004AAD;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  height: 434px;
}

.ClusterModalCloseIcon {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 20px;
}
.clusterImageStyle {
  max-height: 60px;
}