.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 25px;
    gap: 25px;

    .Title {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        line-height: 16.71px;
        color: #011a3c;
        text-align: center;
    }

    .Content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }
}
