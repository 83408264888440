.Estimation_stepthree--container {
    padding: 0 80px;

    @media (max-width: 1040px) {
        padding: 0 40px;
    }

    @media (max-width: 800px) {
        padding: 0;
    }
}

// estimatioStepOnen classes
.PrevButton {
    position: relative;
    bottom: 55px;
    color: #004AAD;
    stroke: #004AAD;
    background: none;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Medium';
    line-height: 17px;
    border: none;
    cursor: pointer;
}

.PrevButton img {
    margin-right: 13.35px;
}

.EstimationStepOneH1 {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011A3C;
}

.EstimationStepOneContainer {
    margin-top: 62px;
}

.StepsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StepsChildContainer>p {
    position: absolute;
    font-family: 'SF-Pro-Display-Light';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-top: 10px;
}

.StepsButton {
    font-family: 'SF-Pro-Display';
    width: 36px;
    height: 36px;
    border-radius: 50px;
    border: 1px solid #e5e5e5;
    cursor: pointer;
}

.EstimationStepOneH2 {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #011A3C;
    text-align: left;
}

.EstimationStepOneSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #011A3C;
    text-align: left;
    margin-bottom: 11px;
}

.PaymentPlansBtnPrimary {
    font-family: 'SF-Pro-Display';
    background: #e2eeff;
    border-radius: 30px;
    height: 36px;
    border: 1px solid #e2eeff;
    color: #004aad;
    padding: 0 8px;
    cursor: pointer;
    width: 100px;
}

.SelectedPaymentPlans {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 11px 7px 27px;
    width: 543px;
    min-height: 50px;
    background: #ffffff;
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    top: 87%;

    @media (max-width: 600px) {
        background: #ffffff;
        width: 100%;
    }
}

.SelectedPaymentPlans p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    color: #011A3C;
    margin: 0;
}

.ChangeEnergySolutionBtn {
    background: none;
    border: none;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #004AAD;
    margin-bottom: 10px;
    cursor: pointer;

    @media (max-width: 600px) {
        display: none;
    }
}

.PaymentPlansSelectContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 20px;

    @media (max-width: 978px) {
        grid-template-columns: 100%;
    }
}

.PaymentPlansSelectChildContainer {
    height: 193px;
    padding: 24px 37px 32px 36px;
    background: #f7f7f7;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    margin-top: 19px;

    @media (max-width: 600px) {
        background: #ffffff;
    }
}

.PaymentPlansSelectLabel {
    display: block;
}

.PaymentPlansSelectLabel p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    margin: 0;
}

.PaymentPlansSelectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 36px;
    padding: 12px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 11px;
    line-height: 13px;
    background: #004aad;
    border-radius: 25px;
    cursor: pointer;
    color: #ffffff;
}

.PaymentPlansSelectChildContainer input[type="checkbox"]:checked+label.PaymentPlansSelectButton {
    background: #004aad;
    color: #ffffff;
}

.PaymentPlansSelectChildRowTwoHeader {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    color: rgba(1, 26, 60, 0.85);
}

.PaymentPlansSelectChildRowTwoContent {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 25px;
}

.SelectedPaymentPlansLayout {
    display: flex;
    justify-content: left;
    margin: 73px 0 0 20px;

    @media (max-width: 600px) {
        margin: 73px 0 0 0;
    }
}

.PaymentPlansWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.PlanStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PlanAmount {
  font-size: 20px;
  line-height: 24px;
  font-family: 'SF-Pro-Display-Bold';
}

.PlanMonth {
  font-size: 12px;
  line-height: 14px;
  color: rgba(1, 26, 60, 0.72);
}

.RepaymentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
   margin-top: 42px;
}

.SkeletonTop {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.SkeletonBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 42px;
}

.SelectedPaymentWrapper {
  display: flex;
  margin: 80px 0 24px 0;
  justify-content: center;
}