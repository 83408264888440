.InvestmentContentWrapper {
    display: flex;
    justify-content: space-between;
}
.InvestmentInterest {
    font-family: 'SF-Pro-Display';
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #61708e;
}
.InvestmentMonth {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-top: 3px;
}
.InvestmentMetricsText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin: 42px 0;
}
.InvestmentTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    margin-bottom: 10px;
}
.InvestmentSubtitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 25px;
    color: #011a3c;
    margin-bottom: 40px;
}
.InvestmentBtnWrapper {
    display: flex;
    justify-content: center;
}
.InvestmentScheduleLink {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #004aad;
}
