@import 'globals';

:root {
    --font-light: 'SF-Pro-Display-Light';
    --font-regular: 'SF-Pro-Display';
    --font-medium: 'SF-Pro-Display-Medium';
    --font-semibold: 'SF-Pro-Display-Semibold';
    --font-bold: 'SF-Pro-Display-Bold';
}

body {
    margin: 0;
    padding: 0;
    font-family: 'SF-Pro-Display';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none !important;
}

code {
    font-family: 'SF-Pro-Display', 'San Serif', monospace;
}

@font-face {
    font-family: 'SF-Pro-Display-Thin';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Thin.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display-Light';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Light.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Regular.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display-Regular';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Regular.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display-Medium';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Medium.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display-Semibold';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Semibold.woff')
            format('truetype');
}

@font-face {
    font-family: 'SF-Pro-Display-Bold';
    src: local('SF-Pro-Display'),
        url('https://res.cloudinary.com/dsglv8tjy/raw/upload/v1719562240/SF-Pro-Display-Bold.woff')
            format('truetype');
}

.ant-steps-item-icon {
    border-radius: 100%;
    width: 70px !important;
    height: 70px !important;
    border: none;
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
    background-color: white !important;
    font-size: 5px !important;
    z-index: -10000;
    box-shadow: 0px 5px 0px 13px var(--dark-blue);
}

.ant-steps-item-container {
    margin: 25px 0px 0px 0px;
    min-height: 150px;
}

.ant-steps-icon {
    color: var(--primary-blue-two) !important;
    font-style: normal;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 14px !important;
}

.ant-steps-item-title {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 15px;
    margin-top: 10px;
    line-height: 35px;
    color: var(--white) !important;
    margin-left: 10px;
}

.ant-steps-item-description {
    font-family: 'SF-Pro-Display-Regular';
    font-size: 13px;
    color: rgba(255, 255, 255, 0.88) !important;
    width: 255px;
    line-height: 24px;
    margin-left: 10px;

    @media (max-width: 489px) {
        width: 238px;
    }
}

.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    left: 32.5px;
    top: 105px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%20' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%20' height='100%30' fill='none' stroke='rgba(255, 255, 255, 0.6)' stroke-width='1' stroke-dasharray='6%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: transparent;
}

.ant-pagination-item {
    border-radius: 25px;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 8px;
    height: 40px;
    width: 40px;
    font-family: 'SF-Pro-Display';
    padding: 8px 0;
    background: transparent;
}

.ant-pagination-item-active {
    background: var(--blue);
    border-color: var(--blue);
    color: var(--white);
}

.ant-pagination-item a {
    color: var(--black-3);
    margin-top: 2px;
}

.ant-pagination-item-active a {
    color: var(--white);
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    border: none;
    background-color: transparent;
}

.anticon {
    color: var(--black);
}

.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    height: 40px;
    width: 40px;
    margin-top: -3px;
    margin-left: 8px;
}

.ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
.ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    margin-top: 11px;
    color: var(--black);
}

span.anticon.anticon-double-right.ant-pagination-item-link-icon {
    display: none;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
    pointer-events: none;
}

.ant-pagination-item-ellipsis {
    color: red;
}

.badge {
    margin-bottom: 30px;

    .ant-badge-status-text {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 18px;
        line-height: 35px;
        color: rgba(1, 26, 60, 0.7);
    }

    .ant-badge-status-dot {
        height: 9px;
        width: 9px;
        margin-bottom: 8px;
    }
}

.partnerdropdown.ant-dropdown-menu {
    border-radius: 25px;
    top: -55px;
    padding: 14px;
}

.ant-layout {
    background: var(--white);
}

img:-moz-loading {
    color: transparent;
}

//Modify App Scroll Bar
::-webkit-scrollbar {
    width: 4px !important;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff !important;
    border-radius: 20px !important;
    height: 0.5px !important;
}

::-webkit-scrollbar-thumb {
    background: #dadada !important;
    border-radius: 14px !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #dadada !important;
}

::-webkit-scrollbar-track-piece:end {
    background: transparent !important;
}

::-webkit-scrollbar-track-piece:start {
    background: transparent !important;
}

//pass in class noBodyScrollbar to a component to remove scrollbar from body
body:has(.noBodyScrollbar)::-webkit-scrollbar {
    display: none !important;
}

body:has(.noBodyScrollbar) {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    // transform: rotate(90deg);
    // height: 80px;
    // opacity: 0;
    position: relative;
    top: -7px;
}

@media screen and (max-width: 900px) {
    .ant-modal-wrap {
        overflow-x: hidden;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
        display: block;
    }

    .ant-upload.ant-upload-drag {
        width: 100% !important;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
        display: none;
        align-self: stretch;
    }
}

.DraggerBox {
    background: #ffffff !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 15px !important;
    cursor: inherit !important;
    height: 154px !important;
}

.DraggerBoxError {
    background: #ffffff !important;
    border: 1px solid #da1e28 !important;
    border-radius: 15px !important;
    cursor: inherit !important;
    height: 154px !important;
}

// layout classnames
.space-between {
    display: flex;
    justify-content: space-between;
}

.align-items-center {
    display: flex;
    align-items: center;
}

.justify-center {
    display: flex;
    justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
