.ARSTHead {
    width: 100%;
    display: grid;
    grid-template-columns: 35% 25% 25% 15%;
    padding: 10px 20px;

    p {
        color: #011a3ccc;
        font-size: 13px;
    }
}

.ARSTGridItem {
    width: 100%;
    display: grid;
    grid-template-columns: 35% 25% 22% 15%;
    padding: 15px 28px 15px 20px;
    background-color: #ffffff;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 16px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 500px) {
        height: 178px;
        padding: 21px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        margin-bottom: 15px;
        display: block;
    }
}

.ARSTGridItemStatusText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #011a3c;
}

.ARSTGridItemStatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 500px) {
        justify-content: space-between;
    }
}

.ARSTGridItemStatus_TitleAndTag {
    display: flex;
    align-items: center;
    gap: 8px;
}

.ARSTGridItemStatusText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #011a3c;
}

.ARSTStatusTag {
    height: 23px;
    padding: 5.5px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 10px;
    }
}

.ARSTGridItemStatusDate {
    font-family: 'SF-Pro-Display';

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.ARSTableWrapper {
    font-family: 'SF-Pro-Display';
    height: 425px;
    margin-top: 12px;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.ARSDesktopTable {
    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ARSMobileTable {
    @media screen and (min-width: 500px) {
        display: none;
    }
}
.ARSMobileButton {
    @media screen and (min-width: 500px) {
        display: none;
    }
}
.ARSDesktopBtn {
    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ARSTContent {
    display: none;
    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        margin-top: 37px;
    }
}
.ARSTAmount {
    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ARSTGridItemStatusDateMobile {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    color: #011a3c;
}
.MobileMonthText {
    margin-bottom: -7px;
    @media screen and (min-width: 500px) {
        display: none;
    }
}

.ARSTPLink {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #004aad;
    margin-top: 15px;
    cursor: pointer;
}
