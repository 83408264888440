.UploadModalWrapper {
  .ant-upload-list-item.ant-upload-list-item-uploading.ant-upload-list-item-list-type-text {
    display: none !important;
  }
}

.UploadModalDragger {
  height: 374px !important;
  padding: 0;
  border: none !important;
  background: #FFFFFF !important;
}

.UploadModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font: 14px/26px 'SF-Pro-Display';
    color: #233862C7;

    span {
      font-family: 'SF-Pro-Display-SemiBold';
      color: #004AAD;
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
     .fileSize{
        color:#233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
    .fileFormat{
        color:#233862B2;
         font-family: 'SF-Pro-Display';
        font-size: 13px;
    }
}

.UMC__AdditionalLoadingCopy {
font-family: 'SF-Pro-Display';
font-size: 14px;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
margin-top: 16px !important;
}