.loadingContent {
    width: 100%;
    height: 374px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        display: block;
        width: 100%;
    }
}

.packageContent {
    margin: 51px 62px 0 60px;

    @media only screen and (max-width: 500px) {
        margin: 51px 20px 0 20px;
    }
}

.details {
    display: flex;
    align-items: center;
    gap: 14px;
}

.detailsText {
    h4 {
        span {
            &:first-child {
                font: 14px/21px 'SF-Pro-Display';
                color: #070c18;
            }
            &:nth-child(2) {
                font: 14px/21px 'SF-Pro-Display-Bold';
                color: #000f23;
                margin-left: 8px;
            }
        }
    }
}

.detailsTags {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    div {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: #fafafa;
        border-radius: 3px;
        width: fit-content;
        padding: 1.5px 8px;

        p {
            font: 12px/20px 'SF-Pro-Display-Semibold';
            color: #4d4d4d;
        }
    }
}

.packageImg {
    width: 61px;
    height: 60.43px;
    border-radius: 6.62px;
    border: 0.41px solid #e2eeff;
    display: grid;
    place-items: center;

    img {
        width: 46.36px;
        height: 44.7px;
        object-fit: cover;
    }
}

.linkEl {
    height: 51px;
    border: 1px solid #e2eeff;
    background-color: #f9fcff;
    border-radius: 15px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    padding-left: 24px;

    p {
        font: 16px/26px 'SF-Pro-Display';
        color: #004aad;
    }

    > div {
        display: block;
        width: 100%;
    }
}
