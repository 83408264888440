.CardDetailsWrapper {
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 0px;
  }
}

.CardStyle {
  width: 464px;
  height: 86px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px auto;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    width: 300px
  }
}

.CardDetailsStyle {
  display: flex;
  align-items: center;
}

.CardIconWrapper {
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CardNumberStyle {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 26px;
  color: #233862;
}

.CTATextStyle {
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 14px;
  color: rgba(35, 56, 98, 0.7);
  margin-top: 5px;
}

.PlusIconStyle {
  margin-right: 10px;
}

.PlusTextStyle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  line-height: 14px;
  color: #004AAD;
}

.AddButtonStyle {
  align-self: flex-end;
  margin-top: 20px;
}

.AddButtonStyleV2 {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  margin-top: 20px;
}

.BtnWrapper {
  position: absolute;
  bottom: 40px;
}