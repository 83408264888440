.viewPackage {
    padding-left: 100px;
    position: relative;
    top: -40px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
    }
}

.link {
    a {
        font-family: 'SF-Pro-Display-Medium';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
    }
}

.MainTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    margin: -20px 0px !important;
}

.titleWrapper {
    margin: 50px 0;
    display: flex;

    @media screen and (max-width: 800px) {
        display: none;
    }
}

.MobiletitleWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0;
    margin: 60px 0 52px 0;

    @media screen and (min-width: 800px) {
        display: none;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
        margin: 0;
    }

    .viewMobileMainTitle {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 23px;
        line-height: 27px;
        color: #011a3c;
        max-width: 180px;
        margin: 0;
    }
}

.label {
    // width: 75px;
    height: 23px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #004aad;
    background: #e2eeff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -18px 0px -20px 20px;
    padding: 0px 15px;

    @media screen and (max-width: 500px) {
        margin-left: -18px;
        margin-top: 0;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
        margin: -2px 0px -20px 20px !important;
    }
}

.DescriptionTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    margin-bottom: 17px;
}

.DescriptionText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    color: rgba(1, 26, 60, 0.75);
}

.ImagesAddedWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;

    .ImagesAddedGallery {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .ImagesAddedViewAllButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 70px;
        border-radius: 10px;
        border: 0.5px solid #e2eeff;
        background-color: transparent;
        font-family: 'SF-Pro-Display';
        font-size: 13px;
        line-height: 15.51px;
        color: #004aad;
        cursor: pointer;

        &:hover {
            background-color: #e2eeff;
            border: 0.5px solid #0458c9;
        }
    }
}

.rightSection {
    width: 38%;
    border-left: 0.5px solid #e5e5e5;
    padding-left: 25px;

    @media screen and (max-width: 800px) {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
        width: auto;
    }
}

.leftSection {
    padding-right: 45px;

    @media screen and (max-width: 800px) {
        margin-top: 50px;
        width: 100% !important;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0px;
    }
}

.detailsTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 17px;
    line-height: 20px;
    color: #011a3c;
    margin: 50px 0;

    @media screen and (max-width: 500px) {
        margin: 30px 0;
    }
}

.subTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
}

.details {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    color: #011a3c;
}

.btnWrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &.ViewPage {
        justify-content: flex-start;
        gap: 16px;

        @media only screen and (max-width: 500px) {
            gap: 3px;
        }
    }
}

.viewMobileBackLink {
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.ViewWrapper {
    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.viewButtonWrapper {
    @media screen and (max-width: 799px) {
        display: none;
    }
}

.ViewMobileButton {
    @media screen and (min-width: 799px) {
        display: none;
    }
}

.btnWrapper {
    @media screen and (max-width: 360px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        justify-content: space-between;
        margin-right: 3.5vw;
    }
}

.viewButton {
    @media screen and (max-width: 400px) {
        margin-left: 0px !important;
    }
}

.floatBox {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.ViewSkeleton {
    width: 170px;
    border-radius: 7px;
    height: 30px;
    margin-right: 20px;
}

.DescriptionSkeleton {
    width: 100%;
    border-radius: 7px;
    margin: 2px 0px;
}

.componentsSkeleton {
    height: 67px;
    background: #ffffff;
    border-radius: 7px;
    margin: 15px 0px;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
}

.RightSectionSkeleton {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.noAttributesTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    margin: 38px 0 7px 0;
}

.noAttributesSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}
