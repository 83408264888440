.HeaderWrapper {
  display: flex;
  justify-content: space-between;
  // padding: 0px 25px;
}

.Header {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-blue);
}

.CloseImg {
  cursor: pointer;
  margin-bottom: 30px;
}

.VideoWrapper {
  position: relative; 
  margin: 0px -20%;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 600px) {
    position: absolute; 
    left: -20px; 
    right: -20px; 
    top: 0; 
    bottom: 0 
  }
}

.VideoWrapper2 {
  border-radius: 12px;
  background: url('../../../../assets/images/webcam_background.svg');
  height: 245px;
  width: 100%;

  @media screen and (max-width: 576px) {
    min-height: 218px;
    height: 100%
  }
}

.MobileVideoWrapper {
  @media screen and (max-width: 415px) {
    overflow-x: hidden;
  }
}

.NoPermissionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 450px;
}

.NoPermissionTitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  margin: 20px auto 15px;
}

.NoPermissionSubtitle {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(35, 56, 98, 0.8);
  width: 317px;
}