.PasswordStyle {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 21px;
  color: rgba(35, 56, 98, 0.8);
  width: '100%';
  margin-left: 6px;
  margin-top: -3px;
}
.FormBoxText {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
  color: rgba(35, 56, 98, 0.8);
}
.FormBoxSubText {
  color: var(--blue) !important;
}
.NameWrapper {
  display: flex;
  justify-content: space-between;
input {
    @media screen and (min-width: 1900px) {
      width: 240px;
    }
  }
}
.ButtonWrapper {
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1900px) {
    margin-top: 38px;
  }
}
.CheckBoxWrapper {
  display: flex;
  justify-content: center;
}
.CheckboxLink {
  color: rgba(35, 56, 98, 1);
  font-family: 'SF-Pro-Display-Bold';
  margin: 0 5px;
  display: inline-block;
}
.LinkWrapper {
  width: 100%;
  height: 26px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  margin: 0px auto;
  margin-top: 10px;
}
