.WEWrapper {
    margin-top: 10px;
    height: 400px;
    overflow-x: auto;

}

.WECardWrapper {
    background: #ffffff;
    border-radius: 6px;
    min-height: 206px;
    padding: 22px 26px 25px 27px;
    margin-bottom: 10px;

    >div {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width: 500px) {
        padding: 12px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        margin: 0 1px 12px 1px;
    }
}

.WECompanyName {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #61708E;
}

.WEJobTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 18px;
    color: #011A3C;
}

.WEDescription {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 21px;
    color: rgba(1, 26, 60, 0.75);
    margin: 21px 0 25px 0;
}

.WELeftItem {
    width: 120px;
    margin-right: 152px;

    @media only screen and (max-width: 500px) {
        margin-right: 76px;
    }
}

.WERightItem {
    width: 220px;

    @media only screen and (max-width: 500px) {
        width: 125px;
        text-align: right;
    }
}

.WEFooterLabel {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);

}

.WEFooterContent {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 25px;
    color: #011A3C;
}

.WEBottomWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: left;
}