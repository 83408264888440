a {
    color: var(--white);
}

a:active {
    font-family: 'SF-Pro-Display-Semibold';
}

a:visited {
    font-family: 'SF-Pro-Display-Semibold';
}

.NavLayout {
    font-family: 'SF-Pro-Display';
    margin: 0px 0%;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.NavHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 46px;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    background-color: #ffffff;
    padding: 25px 59px;
    padding-top: 30px;
    height: 101px;

    &.ConsumerNav {
        @media only screen and (max-width: 900px) {
            padding: 12px 20px 9px 20px;
        }
    }
}

.MenuWrapper {
    border-bottom: 0px;
    align-items: center;
    font-size: 15px;
    font-family: 'SF-Pro-Display';
    justify-content: center;

    & a {
        font-family: 'SF-Pro-Display' !important;

        &.ActiveNavLink {
            font-family: 'SF-Pro-Display-Semibold' !important;
        }
    }
}

.WhiteNavWrapper {
    background-color: var(--white);

    & a {
        color: #4d4d4d !important;

        &.ActiveNavLink {
            color: var(--blue) !important;
        }

        &:hover {
            color: var(--blue) !important;
        }
    }
}

.BlueNavWrappper {
    background-color: var(--blue);

    & a {
        color: var(--white) !important;

        &:hover {
            color: #233862 !important;
        }
    }
}

.NavLogo {
    width: '15%';
}

.NavVector {
    margin-top: 8px;
}

.NavLogoWrapper {
    @media (max-width: 640px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
    }

    a {
        img {
            width: 97.23px;
        }
    }

    &.ConsumerNav {
        @media only screen and (max-width: 500px) {
            margin: 0;

            a {
                img {
                    height: 23.03px;
                    width: 72.13px;
                }
            }
        }
    }
}

.NavItem {
    padding: 0 25px;
    color: var(--white);
    white-space: nowrap;

    > p {
        cursor: pointer;
        font: 15px/24px 'SF-Pro-Display-Medium';
    }
}

.NavBtn {
    padding-left: 5vw;
    padding-right: 1vw;
}

.BarsMenu {
    height: 32px;
    padding: 6px;
    margin-top: 8px;
    display: none;
    background: none;
    margin-left: auto;
    cursor: pointer;
}

.BarsBtn {
    display: block;
    width: 20px;
    height: 2px;
    background: #1890ff;
    position: relative;
}

.BarsBtn:after,
.BarsBtn:before {
    content: attr(x);
    width: 20px;
    position: absolute;
    top: -6px;
    left: 0;
    height: 2px;
    background: #1890ff;
}

.BarsBtn:after {
    top: auto;
    bottom: -6px;
}

.DesktopBtns {
    display: block;

    @media (max-width: 998px) {
        display: none;
    }
}

.signInBtn {
    margin-left: auto;

    @media (max-width: 998px) {
        display: none;
    }
}

.BarsMenu > span {
    display: block;
}

@media (max-width: 998px) {
    .BarsMenu {
        display: inline-block;
        padding: 0;
        margin-top: 6px;
        border: none;
        cursor: pointer;
    }

    BarsSvgWhite > svg > path {
        fill: white;
    }

    .BtnCancel {
        float: right;
        border: none;
        background: white;
        cursor: pointer;
        margin: 3px 18px 0 0;
    }

    .MenuWrapper {
        display: none;
    }

    .ConsumerMenuWrapper {
        display: none;
    }

    .NavText {
        color: #011a3c !important;
        font-size: 25px;
        opacity: 0.57;
        cursor: pointer;

        &:link {
            color: #004aad !important;
        }

        &:visited {
            color: #004aad !important;
        }

        &:hover,
        :focus,
        :focus-visible,
        :focus-within {
            color: #004aad !important;
            font-family: 'SF-Pro-Display-Bold' !important;
            transition: color 0.3s ease-in !important;
            background: none !important;
        }

        &.ConsumerNavText {
            &:link,
            &:hover {
                color: #4d4d4d !important;
            }

            &:visited {
                color: #4d4d4d !important;
            }
        }

        &.ConsumerNavTextActive {
            &:link,
            &:hover {
                color: #004aad !important;
            }

            &:visited {
                color: #004aad !important;
            }
        }

        // &:active {
        //   color: #004aad !important;
        //   background-color: none !important;
        // }
    }

    .TextElement {
        color: #004aad !important;
        font-family: 'SF-Pro-Display-Medium';
        line-height: 18px;
    }

    .NavTextActive {
        color: #004aad !important;
    }

    .BtnPrimary {
        width: 241px;
        border: 1px solid #004aad;
        height: 60px;
        cursor: pointer;
        border-radius: 30px;
        color: white;
        background: #004aad;
        font-family: 'SF-Pro-Display-Semibold';

        &:hover {
            background: #0458c9;
            box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
        }

        &:active,
        :focus {
            background: #004aad;
        }

        &.ConsumerBtn {
            font-family: 'SF-Pro-Display-Semibold';
            width: 160px;
        }
    }

    .BtnSecondary {
        width: 241px;
        border: 2px solid #004aad;
        height: 60px;
        cursor: pointer;
        border-radius: 30px;
        color: #004aad;
        background: white;
        font-family: 'SF-Pro-Display-Semibold';

        &:hover {
            border: 2px solid #0458c9;
            box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
        }

        &:active,
        :focus {
            border: 2px solid #004aad;
        }

        &.ConsumerBtn {
            font-family: 'SF-Pro-Display-Semibold';
            width: 160px;
            border-width: 1px;
        }
    }
}

.MobileDrawer {
    display: inline-block;
    width: 100%;
    margin-top: 14px;
}

.ListWrapper {
    display: block;
    text-align: center;
    list-style: none;
}

.ListStyle {
    margin-bottom: 30px;
}

.ConsumerAppHomeLinks {
    display: flex;

    @media (max-width: 640px) {
        display: none;
    }
}

.ConsumerMenuWrapper {
    border-bottom: 0px;
    align-items: center;
    font-family: 'SF-Pro-Display';
    justify-content: flex-start;

    li {
        font-size: 16px;
        line-height: 24px;
    }

    a,
    p {
        font-family: 'SF-Pro-Display' !important;
        color: #4d4d4d;
    }

    & a {
        &.ActiveNavLink {
            font-family: 'SF-Pro-Display-Semibold' !important;
            color: var(--blue) !important;
        }

        &:hover {
            color: var(--blue) !important;
        }
    }
}

.ConsumerListWrapper {
    display: block;
    text-align: center;
    list-style: none;

    a,
    p {
        font: 24px/24px 'SF-Pro-Display' !important;
        color: #4d4d4d !important;
    }

    a:visited {
        color: #4d4d4d !important;
    }
}

.BtnWrapper {
    color: var(--white);
    border-bottom: 0px;
    align-items: center;
    display: flex;
    font-size: 15px;
    font-family: 'SF-Pro-Display';
    justify-content: flex-end;

    @media only screen and (max-width: 500px) {
        padding: 0;
        width: fit-content;
        justify-content: flex-start;
        margin-right: 24px;
    }

    @media only screen and (max-width: 640px) {
        padding: 0;
        padding-right: 20px;
    }
}

.userBtn,
.userBtn:hover {
    color: transparent;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 40px;
    display: none;

    img {
        width: 50px;
        height: 50px;
    }

    @media screen and (max-width: 480px) {
        display: none;
    }
}
