.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 60px 40px;
    height: 100%;

    & > .TitleWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        & > h2 {
            font-family: 'SF-Pro-Display-Bold';
            font-size: 20px;
            line-height: 23.87px;
            color: #011a3c;
            text-align: center;
        }

        & > p {
            font-family: 'SF-Pro-Display';
            font-size: 15px;
            line-height: 17.9px;
            text-align: center;
            color: #233862b2;
            opacity: 70%;
        }
    }

    & > .InputSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }

    & > .ButtonWrapper {
        display: flex;
        margin-top: auto;
        margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
        padding: 20px;
    }
}
