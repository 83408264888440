.ACVAddContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.ACVInputWrapper {
    display: flex;
    align-items: center;
}
.ACVAddInputMainWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: block;
        width: 100%;
        margin-top: 0px !important;
    }
}
.ACVAddWrapper {
    padding: 48px 48px 15px;

    @media screen and (max-width: 500px) {
        padding: 25px 22px;
    }

    @media screen and (max-width: 375px) {
        padding: 15px 12px;
    }
}

.ACVAddTopWrapper {
    position: relative;

    @media screen and (max-width: 500px) {
        display: flex;
        height: 130px;
        justify-content: center;
    }
}
.ACVAddButtonWrapper{
    display: flex;
    margin-top: 134px;
    justify-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 242px;
    }
}

.ACVAddClusterButtonWrapper{
    display: flex;
    margin-top: 70px;
    justify-content: center;

    @media screen and (max-width: 500px) {
        margin-top: 208px;
    }
}
.ant-tree-select-dropdown {
  padding: 8px 0px;
}
.ant-select-tree .ant-select-tree-treenode {
  padding-left: 10px;
  }
  
  .ant-select-tree .ant-select-tree-treenode:hover {
    background-color: var(--offwhite);
  }
  
  .ant-select-tree .ant-select-tree-node-content-wrapper:hover {
    background-color: transparent;
}
.ant-select-tree-checkbox-disabled {
  display: none;
}

.ant-select-tree .ant-select-tree-treenode-disabled:hover {
  background-color: transparent;

}
.ant-select-tree-checkbox-inner {
  border: 0px;
  border-radius: 50%;
  background: #F2F2F2;
  background-image: url('../../../../assets/images/white-checkmark.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
}


.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #004AAD;
  border-color: #004AAD;
  background-image: none;
}

.ant-select-tree-checkbox-inner::after {
  left: 30%;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  cursor: pointer;
}
.AWCloseIconWrapper {

  @media screen and (max-width: 500px) {
    top: -5px !important;
  }

}
.ant-select-tree-switcher {
  display: none;
}

.ant-select-tree-indent {
  display: none;
}

.ant-select-tree-switcher-noop {
  display: none;
}