.NamesContainer {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    & > div {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 0px;
    }
}

.UploadCV {
    color: #233862cc;
    font-size: 14px;
    line-height: 26px;
}

.ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
