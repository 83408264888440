.Wrapper {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
    background-color: #ffffff;
    display: flex;
    margin-bottom: 101px;
    height: 101px;
    justify-content: space-between;
    padding: 0 56px;
    align-items: center;
    border-bottom: 1px solid #e2eeff;

    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        padding: 32px 20px 29px 20px;
        background: var(--shades-and-tints-gray-scale-ffffff, #fff);
        box-shadow: 0px 0px 18.81818389892578px 0px rgba(0, 0, 0, 0.06);
        height: fit-content;
    }
}

.BackButtonWrapper {
    width: 170px;
    order: 1;

    > div {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 17px;
        color: #004aad;
        display: flex;
        gap: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
        order: 1;
        width: 50%;
    }
}

.BackButtonSpan {
    margin-left: 0px;
    transition: 0.5s ease all;

    :hover {
        margin-left: 4px;
        transition: 0.5s ease all;
    }
}

.SaveButtonWrapper {
    display: flex;
    gap: 32px;
    order: 3;

    > div {
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
        order: 2;
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
}

.StepperWrapper {
    order: 2;

    @media screen and (max-width: 1024px) {
        order: 3;

        margin-top: 32px;
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

.adminNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
    background-color: #ffffff;
    display: flex;
    margin-bottom: 101px;
    height: 101px;
    padding: 0 125px 0 54px;
    border-bottom: 1px solid #e2eeff;

    > div {
        h4 {
            font: 14px/24px 'SF-Pro-Display';
            color: #070c18;
            margin: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
        }
    }

    @media only screen and (max-width: 1650px) {
        padding: 0 54px 0 54px;
    }

    @media only screen and (max-width: 800px) {
        padding: 0 16px;
    }
}

.NextIconSpan {
    display: flex !important;
    justify-content: center;
    align-items: center;

    svg {
        width: 16px;
        height: 16px;
        margin-left: 6px;
    }
}
