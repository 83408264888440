.wrapper {
    display: flex;
    align-items: flex-start;
    gap: 27px;
    margin-top: 27px;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 18px;
    }
}

.left {
    h1 {
        font: 15px/17.9px var(--font-semibold);
        color: #011a3c;
        margin-bottom: 9px;
    }

    p {
        font: 14px/19px var(--font-regular);
        color: #233862cc;
    }
}

.sectionTitle {
    font: 12px/24px var(--font-medium);
    color: #011a3ccc;
    margin-top: -2px;
}

// card
.brandCard {
    width: 465px;
    border-radius: 16px;
    border: 0.3px solid #c4c4c4;
    background-color: #ffffff;
    padding: 16px 21px;

    > span {
        font: 10px/11.93px var(--font-regular);
        letter-spacing: 1px;
        color: #4d4d4d;
        margin-bottom: 10px;
        display: block;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;

        > span {
            margin-bottom: 16px;
        }
    }
}

.brandCard_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h4 {
        font: 18px/24px var(--font-bold);
        color: #011a3c;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 500px) {
        > h4 {
            font: 14px/20px var(--font-bold);
        }
    }
}

.brandButtons {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
        border-width: 0.3px !important;
    }
}

.brandNameList {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    > div {
        border: 0.5px solid #e5e5e5;
        background-color: #fafafa;
        height: 36px;
        border-radius: 21px;
        display: grid;
        place-items: center;
        padding: 0 16px;
    }
}

.noBrands {
    width: 100%;
    display: grid;
    place-items: center;
    img {
        width: 42px;
        height: 42px;
        object-fit: cover;
    }

    p {
        font: 12px/14.32px var(--font-regular);
        color: #b0b0b0;
    }
}

.brandCardLoader_names {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.brandCardError {
    font: 12px/16px var(--font-regular);
    color: #da1e28;
    margin: 8px 0 0 0;
}

.addOrEditBrandWrapper {
    padding: 48px 80px;

    @media only screen and (max-width: 500px) {
        padding: 40px 20px;
    }
}
