div.ant-dropdown.TermsOfServiceDropdown.ant-dropdown-placement-bottomCenter {
    min-width: 272px !important;
}    
.TermsOfServiceDropdown {
    .ant-dropdown-menu-title-content {
        display: flex;
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #233862;
        margin-top: 14px;
    }  
    .ant-dropdown-menu-item {
        padding: 5px 20px;
    }
}
