.DtcReviewListings_Tabs {
    position: relative;
    margin-top: 36px;

    .ant-tabs-top>.ant-tabs-nav::before {
        border: none;
        border-bottom: 1px solid #e2eeff;
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 1px !important;
        background: #004aad;
    }
}

.DtcReviewListings_Tabs_Filter {
    right: 0;
    top: -17px;
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1;
    height: fit-content;

    @media screen and (min-width: 767px) {
        position: absolute;
    }

    &.isFetching {
        top: -22px;
    }

    >span {
        font: 14px/24px SF-Pro-Display;
        color: #4d4d4d;
        margin: 0;
    }

    @media (max-width: 500px) {
        .MultiSelect {
            width: auto !important;
        }
    }

    >div {
        margin-bottom: 0;
        width: fit-content;
    }
}