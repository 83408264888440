.wrapper {
    margin: 26px 42px 0 42px;
    height: fit-content;
}

.section {
    h4 {
        font: 14px/16.8px var(--font-bold);
        color: #011a3c;
        margin: 0 0 8px 0;
    }

    hr {
        border: none;
        border-bottom: 0.3px solid #e5e5e5;
        margin-bottom: 16px;
    }
}

.sectionGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 24px;
    column-gap: 40px;
}

.sectionInfo {
    h5 {
        font: 12px/24px var(--font-medium);
        color: #3d5075;
        margin-bottom: 6px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    p {
        font: 14px/21px var(--font-regular);
        color: #011a3c;
        margin: 0;
    }
}
