.InputBox {
    margin-bottom: 20px;
}
.closeFormText {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #004AAD;
    margin-top: 82px;
    cursor: pointer;
}
.ProfilePageButton {
    margin-top: 82px;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}