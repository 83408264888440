input:focus {
    outline: none;
    border: 1px solid var(--blue);
}

.InputWrapper {
    display: flex;
    align-items: center;
    width: 389px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    position: relative;
    margin-bottom: 20px;

    @media screen and (min-width: 1900px) {
        width: auto;
    }

    input:focus ~ .FloatingInput,
    input:disabled ~ .FloatingInput,
    input:not(:focus):valid ~ .FloatingInput {
        position: absolute;
        pointer-events: none;
        transition: 0.2s ease all;
        height: 26px;
        font-family: 'SF-Pro-Display';
        top: 8px !important;
        margin-bottom: 10px;
        font-size: 11px;
        opacity: 1;
        font-family: 'SF-Pro-Display';
        font-size: 11px !important;
        line-height: 13px;
        color: var(--blue);
    }
}

.SelectCountry::placeholder {
    color: #da1e28;
}

.DropdownInput {
    border: 0px;
    margin-left: 0px;
    padding-top: 10px;
    width: 220px;
    margin-left: -2px;
    background: var(--white);
    display: flex;
}

.NoFloatingLabelInput {
    padding-top: 0px !important;
}

.DropdownInput:focus {
    outline: none;
    border: 0px;
}

.FloatingInput {
    position: absolute;
    pointer-events: none;
    left: 150px;
    top: 17px;
    transition: 0.2s ease all;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.78);

    @media screen and (max-width: 501px) {
        left: 82px;
    }

    &.SCC {
        @media screen and (max-width: 501px) {
            left: 92px;
        }
    }
}

.UnitCode {
    font-family: 'SF-Pro-Display-Semibold';
    color: #233862;
}

.UnitCodeBox {
    position: relative;
    // top: 6px;
    top: 14px;
    padding-left: 10px;
}

.ErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-left: 20px;
    margin-bottom: 20px;
}

input:focus ~ .FloatingInput,
input:disabled ~ .FloatingInput,
input:not(:focus):valid ~ .FloatingInput {
    top: 8px;
    margin-bottom: 10px;
    font-size: 11px;
    opacity: 1;
    font-family: 'SF-Pro-Display';
    font-size: 11px;
    line-height: 13px;
    color: var(--blue);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 55px;
    border: 0px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-left: 0px;
}

.ant-select-arrow {
    top: 55% !important;
    right: 21px;

    .anticon:not(.ant-select-suffix) {
        pointer-events: none;
        color: black;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
    outline: none;
    border: 0px;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    cursor: not-allowed;
}

.lock-icon {
    position: absolute;
    top: 15px;
    cursor: pointer;
    right: 21px;

    @media screen and (max-width: 500px) {
        left: 90% !important;
    }
}

.ant-select-dropdown.countrySelect.ant-select-dropdown-placement-bottomLeft {
    left: 457px;
    top: 296px !important;
    border-radius: 23px;
}

.nairaInputContainer {
    margin-bottom: 20px;
}

.NairaInputWrapper.errorBorder {
    border: 1px solid #da1e28 !important;
    border-radius: 15px;
}

.NairaInputWrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    .NairaInputImgWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50.5%;
        left: 1px;
        transform: translateY(-50%);
        width: 60px;
        height: 57px;
        background: #f9fcff;
        border-right: 1px solid #e2eeff;
        border-radius: 15px 0px 0px 15px;
    }

    input {
        padding-left: 70px;
        color: #000000;
        padding-top: 16px;
        // margin-bottom: 20px;
    }

    input::placeholder {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 26px;
        color: #233862;
        opacity: 0.78;
        padding-top: 0px;
    }

    .FloatingLabel {
        left: 70px;
    }
}

.ConsumerNairaInput::-webkit-inner-spin-button,
.ConsumerNairaInput::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.optionalTag {
    color: #233862;
    margin-left: 21px;
    font-size: 12px;
    font-style: normal;
    font-family: 'SF-Pro-Display';
    line-height: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 0;
    margin-top: 8px;
}

.IconInput,
.IconInput:focus {
    color: var(--shades-and-tints-primary-000-f-23, #000f23) !important;
    font-family: 'SF-Pro-Display' !important;
    font-size: 14px;
}
