.rightLayoutWrapper {
    height: 100%;
    overflow: auto !important;
}

.rightLayoutContent {
    @media only screen and (max-width: 500px) {
        width: calc(100% - 40px);
        margin: 0 20px;
    }
}
