.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    border-radius: 15px;
    margin-top: 11px;
    padding: 10px 0px;
}
.creditDetailsWrapper {
    height: 425px;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.CreditVariableTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    margin-bottom: 32px;
    @media screen and (max-width: 500px) {
        margin-top: 50px;
    }
}

.ViewTenureTableNoHeader {
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 20px 0 26px;
    transition: ease 0.5s;

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        margin-left: 3px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.ViewMoreTenureDiv {
    height: 106px;
    transition: ease 0.5s;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 20px 0 26px;
}

.ViewTenureRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    transition: ease 0.5s;
}

.HideMoreTenure {
    visibility: hidden;
    height: 0;
    transition: ease-out 0.3s;
}

.ViewMoreTenure {
    padding-top: 10px;
    visibility: visible;
    height: auto;
    transition: ease 0.2s;
}

.HideSmallTenure {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.5s;
    padding-left: 5%;
    width: 145px;
    text-align: center;
    padding-top: 5px;
}

.ViewSmallTenure {
    opacity: 1;
    visibility: visible;
    padding-left: 0%;
    transition: ease 0.5s;
    width: 131px;
    text-align: center;
    padding-top: 5px;
}

.ViewDeclined {
    padding-left: 100px;
    position: relative;
    top: -40px;

    @media screen and (max-width: 500px) {
        padding-left: 0;
        top: 0;
    }
}

.ViewDeclinedViewWrapper {
    margin-top: 30px;

    @media screen and (max-width: 1187px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 0px;
    }
}

.ViewDeclinedLeftSection {
    padding-right: 45px;
    border-right: 0.5px solid #e5e5e5;
    width: 58%;

    @media screen and (max-width: 1187px) {
        border-right: none;
        margin-top: 50px;
        width: auto;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.CVViewDeclinedTitleWrapper {
    display: flex;
    align-items: center;

    &.Desktop {
        margin: 50px 0;
        @media screen and (max-width: 1187px) {
            display: none;
        }
    }

    &.Mobile {
        margin: 34px 0 45px;
        @media screen and (min-width: 1188px) {
            display: none;
        }
    }
}

.ViewDeclinedMainTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    margin: -20px 0px !important;
}

.ViewDeclinedLabel {
    height: 23px;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #004aad;
    background: #e2eeff;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -18px 0px -20px 20px !important;
    padding: 0px 15px;

    @media screen and (max-width: 700px) {
        margin: -2px 0px 0px 10px !important;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
        margin: -2px 0px -20px 20px !important;
    }
}

.ViewDeclinedTableNoHeaderName {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
}

.ViewDeclinedTableNoHeaderContent {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: right;
}

.ViewDeclinedTableNoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 60px 0 26px;

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        margin-left: 3px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}
.ViewDeclinedTableNoHeader:nth-of-type(1) {
    margin-top: 20px;
}

.ViewDeclinedRightSection {
    width: 38%;
    padding-left: 20px;

    @media screen and (max-width: 1187px) {
        margin-left: 0;
        padding-left: 0;
        width: auto;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.ViewDeclinedDetailsTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 17px;
    line-height: 20px;
    color: #011a3c;
    margin: 50px 0;

    @media screen and (max-width: 800px) {
        margin: 40px 0;
    }
}

.ViewDeclinedFlexrow {
    display: flex;
    grid-column-gap: 53px;
}

.ViewDeclinedSubTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);

    @media screen and (max-width: 500px) {
        font-family: 'SF-Pro-Display-Medium';
    }
}

.ViewDeclinedDetails {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 13px;
    line-height: 32px;
    color: #011a3c;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 125px;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        font-family: 'SF-Pro-Display';
    }
}
