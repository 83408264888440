.OTPContainer {
    & h2 {
        margin-top: 24px;
        line-height: normal;

        & + p {
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
        }
    }

    & > div {
        border-radius: 30px;
    }

    .PhoneEmail {
        font-family: var(--font-bold);
        color: #233862;
    }

    @media screen and (min-width: 990px) {
    }
}

.OTPGroup {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 57px;

    input {
        width: 60px;
        height: 60px;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center;

        &:focus {
            border-color: #004aad;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        appearance: none;
        -moz-appearance: textfield;
    }

    @media only screen and (max-width: 500px) {
        input {
            width: 100%;
        }
    }
}

.OTPErrorMsg {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    color: #da1e28;
    margin-top: 5px;
}

.ResendOTPGroup {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    align-items: center;
    margin: 39px 0 87px 0;
    font-size: 14px;
    line-height: 26px;

    p:first-of-type {
        color: #233862cc;
    }

    p:last-of-type {
        color: #004aad;
        font-family: var(--font-semibold);
        letter-spacing: 0.5px;
        margin-top: 3px;
    }
}

.VerifyGroup {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    align-items: center;
    margin-bottom: 26px;

    button {
        font-size: 14px !important;
        font-family: var(--font-semibold);
        line-height: normal;
    }

    .ChangeNumber {
        font-size: 15px;
        line-height: 26px;
        color: #233862cc;

        span {
            font-family: var(--font-semibold);
            color: #004aad;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
