.APLPageTitleWrapper {
    margin: 0px 60px 20px;

    @media screen and (max-width: 990px) and (min-width: 768px) {
        margin: 0 0 0 20px;
    }

    @media screen and (max-width: 600px) {
        margin: 30px 0 0 0;
    }
}

.AdminPLWrapper {
    padding: 44px 136px 101px 112px;

    @media screen and (max-width: 1280px) {
        padding: 44px 20px 101px 60px;
    }

    @media screen and (max-width: 990px) and (min-width: 768px) {
        padding: 44px 20px 101px 20px;
    }

    @media screen and (max-width: 600px) {
        padding: 44px 0px 101px 0px;
    }
}

.AdminPLSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.AdminPLFilterBox {
    display: flex;
    gap: 23px;
    height: 45px !important;
    width: 130px;
    margin-left: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 10px !important;
    padding: 11px 20px;
    cursor: pointer;
}

.AdminPLFilterText {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: rgba(1, 26, 60, 0.8);
}

.APLDropDownButtonWrapper {
    @media screen and (max-width: 500px) {
        display: none;
    }
}

.APLFilterButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.AdminPLFilterResultTitle {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-top: 35px;
}

.AdminPLTabWrapper {
    margin-top: 40px;
}

.AdminPLTablewWrapper {
    margin-top: 38px;
}

.AdminPLTableHeader {
    display: grid;
    padding: 0 60px 0 49px;
    grid-template-columns: 23% 22% 21% 21% 13%;
    padding-bottom: 6px;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
    width: 100%;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.AdminPLTableContent {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 23% 22% 21% 21% 13%;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011A3C;
    background: #FFFFFF;
    width: 100%;

    >span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.AdminPLTableHeaderTwo {
    display: grid;
    padding: 0 60px 0 49px;
    grid-template-columns: 30% 30% 28% 12%;
    padding-bottom: 6px;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.AdminPLTableContentTwo {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 30% 30% 28% 12%;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011A3C;
    background: #FFFFFF;

    >span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.AdminPLPaginationWrapper {
    text-align: center;
    margin-top: 42px;
}

#four-column {
    grid-template-columns: 3fr 2fr minmax(130px, 3fr) 2fr;
    padding: 0 0px 0 49px;
}

.AdminPLBtnWrapper {
    display: flex;
    justify-content: space-between;
}

// mobile classnames
.APLMobileCard {
    display: none;

    @media screen and (max-width: 600px) {
        display: grid;
        padding: 20px;
        height: 178px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        border-radius: 7px;
        margin-bottom: 12px;

        >div {
            display: flex;
            justify-content: space-between;
        }

        >div:last-child {
            align-items: end;
        }

        >div>div>h2 {
            font-family: 'SF-Pro-Display-Medium';
            font-style: normal;
            font-size: 8px;
            line-height: 10px;
            letter-spacing: 0.055em;
            color: rgba(1, 26, 60, 0.85);
            text-transform: uppercase;
            ;
        }

        >div>div>p {
            font-family: 'SF-Pro-Display-Semibold';
            font-style: normal;
            font-size: 15px;
            line-height: 18px;
            color: #011A3C;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}