.Wrapper {
  margin: 59px 69px 0 69px;

  @media only screen and (max-width: 500px) {
    margin: 59px 20px 0 20px;
  }
}

.Title {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 9px;
  color: #61708E;
  margin-bottom: 6px;
}

.Name {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  color: #011A3C;
  margin-bottom: 49px;
}

.Schedules {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  color: #011A3C;
  margin-bottom: 55px;
}

.TableHead {
  width: 100%;
  display: grid;
  grid-template-columns: 66% 33%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  p {
    font-size: 13px;
    color: #011A3CCC;
    text-align: left;

  }

  :last-child {
    text-align: right;
  }
}

.TableBody {
  margin-bottom: 39px;
}

//TODO: reset this to a grid when third item - date of replacement - is ready
.RowItem {
  height: 63px;
  border-bottom: 0.5px solid #E5E5E5;
  //display: grid;
  display: flex;
  //grid-template-columns: 33% 33% 33%;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    color: #011A3C;
  }

  :first-child {
    font-family: 'SF-Pro-Display-Medium';
  }


  :last-child {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    text-align: right;
  }
}