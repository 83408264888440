.pageWrapper {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.LoadingContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex: 1;

    h1 {
        margin-top: 32px;
        font: 18px/24px 'SF-Pro-Display';
        color: #4d4d4d;
    }

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;
        max-width: 348px;
        text-align: center;
    }

    @media only screen and (max-width: 500px) {
        p {
            max-width: 100%;
            margin: 0 38px;
            font-size: 12px;
        }
    }
}

.pageContent {
    height: 100%;
    width: 100%;
    flex: 1;
}

.IsNoWrapper {
    width: 501px;
    height: 621px;
    border: 1px solid #e2eeff;
    background-color: #f9fcff;
    border-radius: 13px;
    margin: 205px auto 67px auto;

    @media only screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 26px 20px 200px 20px;
        border-radius: 0;
        border: none;
    }
}

.isNoLogo {
    width: fit-content;
    margin: 26px auto 24px auto;

    img {
        width: 98px;
        height: 31px;
        object-fit: cover;
    }
}

.peopleImg {
    width: fit-content;
    margin: 0 auto;

    img {
        width: 292px;
        height: 260px;
        margin: 0 auto;
        object-fit: cover;
    }
}

.isNoContent {
    text-align: center;
    margin: 34px 39px 0 39px;

    h1 {
        font: 18px/24px 'SF-Pro-Display-Bold';
        color: #233862;
        margin-bottom: 5px;
    }

    >p {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;
        margin-bottom: 19px;
    }

    @media only screen and (max-width: 500px) {
        margin: 50px 0 0 0;

        h1 {
            font: 14px/20px 'SF-Pro-Display-Semibold';
            margin-bottom: 16px;
        }

        >p {
            font: 12px/20px 'SF-Pro-Display';
            margin-bottom: 16px;
        }
    }
}

.maybe1,
.maybe2,
.maybe3 {
    min-height: 55vh;
}

.maybe1 {
    padding: 60px 50px;

    @media only screen and (max-width: 500px) {
        padding: 30px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: 'SF-Pro-Display';

        h2 {
            color: #011a3c;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin-top: 20px;
            margin-bottom: 5px;
        }

        p {
            color: var(--shades-and-tints-gray-scale-4-d-4-d-4-d, #4d4d4d);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            line-height: 20px;
        }
    }
}

.maybe2 {
    padding: 40px;
    padding-bottom: 140px;
    font-family: 'SF-Pro-Display';

    @media only screen and (max-width: 500px) {
        padding: 20px;
        padding-bottom: 70px;
    }

    &__active {
        border: 0.5px solid #004aad !important;
        background: var(--accent-blue-light, #e2eeff);

        span {
            background: #004aad !important;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__item {
            border: 0.5px solid #e5e5e5;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            gap: 15px;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;

            &:hover {
                border: 0.5px solid #004aad;
                cursor: pointer;
            }

            span {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                background: #fff;
                border: 0.5px solid #e5e5e5;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            div {
                display: flex;
                flex-direction: column;
                width: 85%;

                h4 {
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                    margin-bottom: 0px;
                }

                p {
                    color: rgba(35, 56, 98, 0.7);
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                    width: 100%;
                }
            }
        }
    }
}

.maybe3 {
    padding: 40px;
    font-family: 'SF-Pro-Display';

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__image {
            background-color: #f9fcff;
            width: 85%;
            height: 158px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-bottom: 15px;

            >img {
                width: auto;
                height: 100%;
            }

            @media only screen and (max-width: 500px) {
                width: 95%;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h4 {
                color: #011a3c;
                text-align: center;
                font-size: 16px;
                font-style: normal;
                line-height: 24px;
            }

            p {
                color: #4d4d4d;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                line-height: 20px;
            }
        }

        &__question {
            color: #004aad;
            margin-top: 15px;
            cursor: pointer;
            position: relative;
            font-size: 14px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Semibold';
            line-height: normal;
            text-align: center;

            span {
                margin-right: 8px;
            }
        }
    }
}