.Wrapper {
    flex-direction: column;
    display: flex;
    width: 100%;
    align-items: center;
    overflow: auto;

    @media screen and (min-width: 1023px) {
        padding-bottom: 42px;
    }

    @media screen and (max-width: 1024px) {
        padding: 0 16px;
    }
    @media screen and (max-width: 480px) {
        padding: 0 20px;
    }
}

.ChildrenWrapper {
    background: #f9fcff;
    max-width: 586px;
    width: 100%;
    padding: 35px 24px 24px 24px;
    border-radius: 14px;
    @media screen and (max-width: 480px) {
        padding: 17px;
    }
}

.CtaWrapper {
    display: none;

    @media screen and (max-width: 1024px) {
        display: grid;
        justify-content: center;
        margin-top: 100px;
    }
}
