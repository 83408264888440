.LayoutWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.MainWrapper {
    display: grid;
    grid-template-columns: minmax(auto, 574px) 1fr;
    height: calc(100vh - 101px);
    overflow: hidden;
    margin-top: 101px;

    > div:nth-child(2) {
        display: flex;
        justify-content: center;
        padding: 56px 0 42px 0;
        width: 100%;
        overflow: hidden;
    }
}

@media screen and (max-width: 1024px) {
    .LayoutWrapper {
        height: 100%;
    }

    .MainWrapper {
        display: flex;
        justify-content: center;
        height: 100%;
        margin-top: 160px;

        > div:first-child {
            display: none;
        }
    }
}
