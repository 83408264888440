@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin reset {
    margin: 0;
    padding: 0;
}

body {
    overflow-x: hidden;
}

.AIPWrapper {
    font-family: 'SF-Pro-Display';
    color: #011a3c;

    @media screen and (min-width: 500px) {
        margin: 0 32px 0 32px;
    }

    @media screen and (min-width: 769px) {
        margin-left: 64px;
    }

    @media screen and (min-width: 1024px) {
        margin: -24px 0 0 64px;
    }
}

.AIPTopWrapper {
    @include flex-column;
    gap: 34px;
    margin-bottom: 45px;

    @media screen and (min-width: 1280px) {
        gap: 73px;
        margin-bottom: 47px;
    }
}

.AIPNavWrapper {
    @include flex-row;
    align-items: center;
    justify-content: space-between;
}

.AIPCloseIcon {
    width: 14.32px;
    height: 14.32px;
    cursor: pointer;

    @media screen and (min-width: 769px) {
        display: none;
    }
}

.AIPTitleSectionWrapper {
    font-family: 'SF-Pro-Display';
    @include flex-row;
    align-items: center;
    gap: 11px;
}

.AIPMainHeader {
    font-family: 'SF-Pro-Display-Bold';
    @include reset;
    font-size: 23px;
    color: #011a3c;
}

.AIPInProgressLabel {
    @include reset;
    @include flex-row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100px;
    height: 23px;
    border-radius: 3px;
    background-color: #e2eeff;

    h4 {
        @include reset;
        font-family: 'SF-Pro-Display-Semibold';
        color: #004aad;
        text-align: center;
        font-size: 10px;
    }
}

.AIPPageContentWrapper {
    width: 100%;
    @include flex-column;
    gap: 12px;

    @media screen and (min-width: 1280px) {
        flex-direction: row-reverse;
        justify-content: left;
        align-items: flex-start;
    }
}

.AIPRightSection {
    @include flex-column;
    gap: 38px;

    @media screen and (min-width: 1280px) {
        margin: -78px 0 0 16px;
        width: 350px;
    }

    @media screen and (min-width: 1400px) {
        width: 400px;
    }

    @media screen and (max-width: 1279px) {
        //height: 40vh;
        margin-bottom: 52px;
    }

    h4 {
        @include reset;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 17px;
        color: #011a3c;
    }
}

.AIPDetailsHeader {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
}

.AIPDetailsText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    margin-top: 5px;
}

.AIPMainSection {
    height: 100%;
    border-right: 0.5px solid #e5e5e5;
    padding-right: 41px;
    margin-top: -130px;
    padding-top: 115px;
    @include flex-column;
    gap: 92px;

    @media screen and (max-width: 1279px) {
        border: none;
        padding: 0;
        margin: 0;
    }

    @media screen and (min-width: 1024px) {
        width: 605px;
        gap: 9px;
    }

    @media screen and (max-width: 1023px) {
        gap: 0;
    }

    @media screen and (min-width: 1400px) {
        width: 700px;
    }

    @media screen and (min-width: 1680px) {
        width: 800px;
    }
}

.AIPSolutionDetails {
    @include flex-column;
    gap: 15px;
}

.AIPSolutionDetailsTop {
    font-family: 'SF-Pro-Display';
    @include flex-row;
    align-items: center;
    justify-content: space-between;

    h4 {
        @include reset;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 12px;
        color: #011a3ccc;
    }

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        color: #004aad;
        cursor: pointer;
    }

    @media screen and (min-width: 1024px) {
        margin-right: 68px;
    }
}

.AIPSolutionSummaryText {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 25px;
    color: #011a3ccc;

    @media screen and (min-width: 1280px) {
        margin-right: 54px;
    }
}

.AIPTabsWrapper {
    margin-top: 40px;

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--blue);
        font-family: 'SF-Pro-Display-Bold';
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 3px;
    }
}

.AIPTabPaneOneContentWrapper {
    height: 425px;
    margin-top: 12px;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media only screen and (max-width: 500px) {
        padding-left: 3px;
    }
}

.AIPTableNoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 60px 0 26px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.AIPTableNoHeaderName {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
}

.AIPTableNoHeaderContent {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    text-align: right;
}

// modal

.AIPModalContentWrapper {
    padding: 59px 69px 68px 80px;
}

.AIPModalHeaderWrapper {
    font-family: 'SF-Pro-Display';
    @include flex-row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 49px;
}

.AIPModalHeaderWrapperTwo {
    @include flex-row;
    align-items: center;
    gap: 9.25px;
    cursor: pointer;
}

.AIPModalTextOne {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 9px;
    color: #61708e;
}

.AIPModalTextTwo {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
}

.AIPModalTextLink {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    color: #004aad;
}

.AIPModalTextThree {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    margin-bottom: 37px;
}

.AIPModalButtonWrapperTwo {
    text-align: center;
    margin-top: 67px;
}

// skeletons

.AIPTWSTabs {
    @include flex-row;
    align-items: center;
    gap: 19px;
}

.AIPTWSBorder {
    @include flex-row;
    align-items: center;
    gap: 0px;
    margin: 19px 0 28px 0;

    .AIPTWSBorder1 {
        width: 98px;
        height: 3px;
        background-color: #004aad;
    }

    .AIPTWSBorder2 {
        width: 578px;
        height: 2px;
        background-color: #e5e5e5;
    }
}
