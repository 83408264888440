.editProduct {
  padding: 0 100px;
  position: relative;
  top: -40px;

  @media screen and (max-width: 800px) {
    // padding: 0px;
  }

  @media screen and (max-width: 500px) {
    background: #fff;
    padding: 0px;
  }
}

.menu {
  border-radius: 15px;
  padding: 12px;
}

.link {
  margin-bottom: 50px;
  // margin-top: -54px;
}

a {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
}

a:hover {
  color: var(--hover-text-blue) !important;
}

.MainTitleComponent {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 23px;
  line-height: 27px;
  color: var(--primary-blue);
  margin: 20px 0px 50px 0px !important;

  @media screen and (max-width: 500px) {
    margin: 50px 0 !important;
  }

}

.SubTitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-blue);
  margin-bottom: 28px;
}

.InputTitle {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: var(--primary-blue);
}

.Details {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    // display: block;
  }

  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }

  @media screen and (min-width:1500px) {
    justify-content: start;
  }

}

.AddNewButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px !important;
}

.btn {
  display: flex;
  justify-content: center;
  margin-top: 35px;

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
    margin-right: auto !important;
  }

  @media screen and (max-width: 768px) {
    margin-right: 3rem;
  }

  @media screen and (max-width: 1040px) {
    margin-right: 10rem;
  }

  @media screen and (min-width: 1500px) {
    max-width: 47vw;
    margin-left: 29rem;
    justify-content: start;
  }

}

.ant-select-dropdown.componentType.ant-select-dropdown-placement-bottomLeft {
  top: 352px !important;
}

.ant-select-dropdown.units.ant-select-dropdown-placement-bottomLeft {
  top: 208px !important;
}

.ant-select.SelectBox.ant-select-single.ant-select-show-arrow {
  background-color: var(--white);
}

.img-wrapper {
  width: 409px;
  height: 45%;
  background: var(--white);
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 51px;

  @media screen and (max-width: 800px) {
    // width: 335px;
  }

  @media screen and (max-width: 500px) {
    width: 100% !important;
    height: 226px;
  }
}

.replaceDoc {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--blue);
  cursor: pointer;
}

.removeAttributeWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 1px;
  position: relative;
  top: -13px;
  margin-bottom: 15px;
}

.removeAttributeIcon {
  margin-top: 3px;
  color: var(--blue);
  font-size: 11px;
  margin-right: 3px;
}

.ant-upload-list-item-name {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 17px;
  color: rgba(35, 56, 98, 0.78) !important;
}

.ant-upload-text-icon {
  display: none;
}

.filename {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(35, 56, 98, 0.78);
  padding: 10px 20px;
  text-align: center;
}

.mobileLink {
  @media screen and (max-width: 500px) {
    display: none !important;
  }
}

.mobileAttributeInfo {
  @media screen and (max-width: 500px) {
    margin-top: 70px;
  }
}

.ComponentImg {
  height: 100%;

  @media screen and (max-width: 800px) {
    margin-left: 10px;
  }

  @media screen and (min-width:1500px) {
    margin-left: 15rem;
  }
}

.floatBox {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.ImageListingEdit {
  z-index: 1000000;
  position: absolute;
  bottom: 300px;
  right: 105px;

  .ImageListingWrapper {
    max-width: 500px !important;
  }
}

.AttributeInformationWrapper {
  @media screen and (min-width: 700px) and (max-width: 799px) {
    width: 82%;
  }
}