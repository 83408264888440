.page {
    justify-content: center;
    max-width: 700px;
    margin:0 auto 0;
    padding-bottom: 146px;
    @media screen and (max-width:992px) {
        width:90%;
    }
    @media screen and (max-width:576px) {
        background: #F9FCFF;
    }
}
.backNavContainer{
    width:100%;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width:992px) {
        padding-left: 32px;
    }
    @media screen and (max-width:576px) {
        padding-left: 16px;
    }
}

.pageContent {
    background: #f9fcff;
    border: 1px solid #e2eeff;
    border-radius: 12px;
    width: 700px;
    margin: 0 auto;
    padding: 48px;
    @media screen and (max-width:992px) {
        width:90%;
    }
    @media screen and (max-width:576px) {
        padding: 0px;
        background: none;
        border: none;
    }
    h1 {
        font: 28px/35px 'SF-Pro-Display-Bold';
        color: #233862;
        margin-bottom: 8px;
        text-align: center;
        @media screen and (max-width:576px) {
           font-size: 18px;
        }
    }

    >p {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 44px;
        text-align: center;
        @media screen and (max-width:576px) {
           margin-bottom: 18px;
        }
    }

    .button {
        text-align: center;
        width: 100%;
        margin: 36px auto 24px auto;
    }
}

.actionOptionsWrapper {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: flex-start;
    gap: 16px;
    @media screen and (max-width:992px) {
        grid-template-columns: repeat(2,1fr);      
    }
      @media screen and (max-width:576px) {
        grid-template-columns: repeat(1,1fr);      
    }
}

.actionOption {
    width: 190px;
    border: 1px solid #e2eeff;
    border-radius: 4px;
    cursor: pointer;
    padding-bottom: 28px;
      @media screen and (max-width:576px) {
        display: flex;
        align-items: center;
        gap:20px;
        width:100%;
    padding: 16px;

    }
    div {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e2eeff;
        border-radius: 10px;
        margin: 49px auto 41px auto;
        @media screen and (max-width:576px) {
            margin:0;
        }
        svg {
            stroke: #004aad;
        }
    }

    .descWrapper {
            h4 {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin: 0;
        text-align: center;
        @media screen and (max-width:576px) {
            font-size: 14px;
            line-height: 17.5px;
            font-family: 'SF-Pro-Display-Semibold';
            text-align: left;
            margin-bottom: 6px;
        }
    }

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #070c18;
        margin: 0 auto;
        text-align: center;
        max-width: 173px;
        @media screen and (max-width:576px) {
            color: #233862B2;
            text-align: left;
        max-width: 200px;

        }
    }
    }

    &:hover {
        border: 1px solid #004aad;
        transition: all .3s ease-in;
    }

    &.actionOptionActive {
        background: #E2EEFF;
        border: 1px solid #004aad;

        div {
            background: #004aad;
            border-color: #004aad;

            svg {
                stroke: #ffffff;
            }

            path {
                stroke: #ffffff;
            }
        }
    }
}