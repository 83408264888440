.NavBox {
    display: flex;
    margin-top: 30px;
    margin-right: 60px;

    @media screen and (max-width: 992px) {
        margin-right: 0px;
        position: relative;
    }
}

.NavSectionOne {
    width: 100%;
    display: flex;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-top: 17px;

    @media screen and (min-width: 2372px) {
        margin-left: 60px;
    }

    @media screen and (min-width: 2500px) {
        margin-left: 20%;
    }

    @media screen and (min-width: 1900px) and (max-width: 2500px) {
        font-size: 24px;
        margin-left: 13%;
    }

    @media screen and (min-width: 1500px) and (max-width: 1900px) {
        margin-left: 8%;
    }

    @media screen and (max-width: 991px) {
        margin-left: 25px;
    }

    @media screen and (max-width: 768px) {
        position: absolute;
        top: 65px;
        margin-left: 42px;
    }

    @media screen and (max-width: 575px) {
        margin-left: 3px;
    }
}

.NavSectionTwo {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.NotificationBox {
    border-radius: 50px;
    background-color: #fff;
    height: 57px;
    width: 57px;
    display: flex;
    margin-right: 15px;

    img {
        position: relative;
        top: -3px;
        left: 13px;
        height: 27px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.MobileMenu {
    padding: 0;
    border: none;
    cursor: pointer;
    background: transparent;
    display: none;

    @media screen and (max-width: 768px) {
        display: inline-block;
    }

    @media screen and (max-width: 500px) {
        display: inline-block;
    }
}

.MobileMenuCancelIcon {
    border: none;
    background: #011a3c;
    cursor: pointer;
}

.MobileLogoBox {
    padding: 0px 25px;
    cursor: pointer;
    margin-bottom: 50px;
}

.BtnCancel {
    border: none;
    background: white;
    cursor: pointer;
    margin: 3px 18px 0 0;
}

.NotificationNumberBox {
    position: relative;
    top: -13px;
    left: -1px;
}

.ProfilePhoto {
    border-radius: 50px;
    height: 47px;
    width: 47px;
    margin-right: 10px;
    position: relative;
    top: -5px;
}

.dropdownLinks {
    margin-left: 10px;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #233862;
    padding-bottom: 15px;
}

.MobileDrawer {
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }

    @media screen and (max-width: 500px) {
        display: block;
    }
}

.MobileMenuItemBox {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    background: #011a3c;
    line-height: 17px;

    .ant-menu-item {
        padding: 30px 20px !important;
    }
}

.ant-drawer-body {
    @media screen and (max-width: 375px) {
        padding: 24px;
    }
}

.ant-menu-item.ant-menu-item-disabled {
    @media screen and (max-width: 768px) {
        color: rgba(255, 255, 255, 0.8) !important;
    }

    @media screen and (max-width: 500px) {
        color: rgba(255, 255, 255, 0.8) !important;
    }
}

.ant-menu-inline {
    @media screen and (max-width: 768px) {
        border-right: 0px;
    }

    @media screen and (max-width: 500px) {
        border-right: 0px;
    }
}

.ant-menu-inline .ant-menu-item::after {
    @media screen and (max-width: 768px) {
        border-right: 0px;
    }

    @media screen and (max-width: 500px) {
        border-right: 0px;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    @media screen and (max-width: 768px) {
        color: white;
    }

    @media screen and (max-width: 500px) {
        color: white;
    }
}

.ant-dropdown-menu-item {
    border-radius: 15px;
    padding: 12px 20px;
    @media screen and (max-width: 500px) {
        padding: 8px 10px;
    }
}

.ant-dropdown-menu-item:hover {
    background: #fafafa;
}

.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    border-radius: 15px;
    min-width: 150px;
    padding: 10px 0px;

    @media screen and (max-width: 768px) {
        margin-right: 5px;
    }

    @media screen and (max-width: 500px) {
        margin-right: 5px;
    }
}

.Navbar {
    .ant-dropdown.ant-dropdown-placement-bottomCenter {
        top: 77px !important;
    }
}

.AuthNavCursor {
    cursor: pointer;
}

.AuthNavCancelContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 14px;
}

.AuthNavMenuItemMarginLeft {
    margin-left: 15px;
}

.UserNameStyle {
    @media screen and (min-width: 1300px) {
        margin: 0px auto;
    }

    @media screen and (min-width: 700px) and (max-width: 1280px) {
        margin-left: 7%;
    }
}

.NavProfileWrapper {
    display: flex;
    grid-column-gap: 12px;
}

.NavPageTitle {
    display: flex;
    align-items: center;
    padding-left: 62px;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;

    @media screen and (max-width: 1280px) {
        padding-left: 0px;
    }
}

.NavBar_Menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 22px 0 26px 19px !important;
    width: 150px !important;

    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
        padding: 0 !important;
    }
}
