.Estimation_stepone-container {
    padding: 0 80px;

    @media (max-width: 1040px) {
        padding: 0 40px;
    }

    @media (max-width: 800px) {
        padding: 0;
    }
}

// estimatioStepOnen classes
.PrevButton {
    position: relative;
    bottom: 55px;
    color: #004aad;
    stroke: #004aad;
    background: none;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Medium';
    line-height: 17px;
    border: none;
    cursor: pointer;
}

.PrevButton img {
    margin-right: 13.35px;
}

.EstimationFormLayout {
    display: block;
    width: 657px;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.EstimationStepOneH1 {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;

    @media screen and (max-width: 500px) {
        margin-top: 20px;
    }
}

.EstimationStepOneContainer {
    margin-top: 62px;
}

.StepsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.StepsChildContainer > p {
    position: absolute;
    font-family: 'SF-Pro-Display-Light';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-top: 10px;
}

.StepsButton {
    font-family: 'SF-Pro-Display';
    width: 36px;
    height: 36px;
    border-radius: 50px;
    border: 1px solid #e5e5e5;
    cursor: pointer;
}

.EstimationStepOneH2 {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    text-align: left;

    @media screen and (max-width: 500px) {
        margin-bottom: 10px;
    }
}

.EstimationStepOneSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #011a3c;
    text-align: left;
    margin-bottom: 11px;
}

.EstimationStepOneBtnPrimary {
    font-family: 'SF-Pro-Display';
    background: #004aad;
    border-radius: 30px;
    height: 46px;
    border: 1px solid #004aad;
    color: #ffffff;
    padding: 11px 34px 14px 34px;
    margin-top: 37px;
    cursor: pointer;
    width: 132px;
}

.EstimationStepOneBtnPrimary:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.EstimationStepOneBtnSecondary {
    font-family: 'SF-Pro-Display';
    background: #e2eeff;
    border-radius: 30px;
    height: 46px;
    border: none;
    color: #004aad;
    padding: 11px 34px 14px 34px;
    margin: 37px 15px 0 0;
    cursor: pointer;
}

.InputsContainer {
    display: flex;
    justify-content: space-between;
    height: 88px;

    @media (max-width: 600px) {
        height: auto;
        display: block;

        > div {
            height: 78px;
        }
    }
}

.no_height_input {
    height: auto;
}

.InputsContainer__package {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media (max-width: 600px) {
        height: auto !important;
        display: block;

        > div:first-child {
            margin-bottom: 10px;
        }
    }
}

.cluster__info {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-left: 25px;
    background: #e9f2ff;
    padding: 9px;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;

    p {
        color: #233862;
        font-family: 'SF Pro Display';
        font-size: 12px;
        font-style: normal;
        line-height: 20.5px;
    }

    svg {
        margin-top: -5px;
        width: 26px;
        height: 26px;
    }

    @media (max-width: 600px) {
        margin-left: 0;
    }
}

.RadioLabel {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 15px;
    line-height: 28px;
    color: #011a3c;
}

.RadioContainer {
    background: #ffffff;
    cursor: pointer;
    height: 54px;
    width: 155px;
    border-radius: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15.5px 25px 19.5px 16px;
    font-family: 'SF-Pro-Display';
    color: #011a3c;

    > img {
        position: relative;
        bottom: 0px;
        right: 11px;
    }

    &:disabled {
        cursor: default;
    }
}

.RadioContainerWide {
    background: #ffffff;
    cursor: pointer;
    height: 54px;
    width: 178px;
    border-radius: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15.5px 25px 19.5px 16px;
    font-family: 'SF-Pro-Display';
    color: #011a3c;

    > img {
        position: relative;
        bottom: 0px;
        right: 11px;
    }
}

.RadioContainerDisabled {
    background: #efefef !important;
    cursor: not-allowed;
}

.DragDropContainer {
    width: 321px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 15px;
}

.DragDrop_mobile-responsiveness {
    @media (max-width: 600px) {
        margin-top: 15px;
    }
}

.Estimation_stepone-SaveBtn {
    text-align: left;

    @media (max-width: 600px) {
        text-align: center;
    }
}

.DragNDropContainer_Responsiveness {
    @media (max-width: 600px) {
        height: 270px;
    }
}

.CreditCheckErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-left: 20px;
    margin-top: -25px;
}

.ApplianceErrorText {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin-top: 10px;
}

.ant-select-dropdown.countryCodeDropdown.ant-select-dropdown-placement-bottomLeft {
    left: 457px;
    top: 493px !important;
    border-radius: 23px;

    @media screen and (max-width: 500px) {
        top: 635px !important;
    }
}

//dragdrop error style
.DraggerError {
    background: #ffffff !important;
    border: 1px solid #da1e28 !important;
    border-radius: 15px !important;
    cursor: inherit !important;
    height: 154px !important;
}

.ActiveStepsButton {
    color: #ffffff;
    background: #011a3c;
    border: none !important;
    pointer-events: none;
}

.PointerStyle {
    pointer-events: none;
}

.EstimationContainer {
    margin-top: 73px;
    display: flex;
    justify-content: center;
}

.UploadError {
    padding-left: 18px;
    font-size: 12px;
    margin-top: 4px;
    color: #da1e28;
}

.ExistingCustomersWrapper {
    width: 726px;
    height: 89px;
    background: #ffffff;
    border-radius: 7px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 800px) {
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.ExistingCustomersHeader {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    text-transform: uppercase;
    margin-bottom: 10px;
}

.ExistingCustomersSubheader {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    color: #011a3c;
    text-transform: capitalize;
}

.MobileSectionWrapper {
    max-width: 100%;
    height: 180px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 28px 15px;
}

.MobileTopSection {
    display: flex;
    justify-content: space-between;
    font-family: 'SF-Pro-Display';
}

.MobileExistingTableHeader {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: rgba(1, 26, 60, 0.8);
}

.MobileExistingText {
    font-size: 13px;
    line-height: 16px;
    color: #011a3c;
    max-width: 150px;
    word-wrap: break-word;
}

.Right {
    text-align: right;
}

.SummaryWrapper {
    width: 466px;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 25px 20px;

    @media screen and (max-width: 500px) {
        width: 100%;
        margin-top: 20px;

        &.EnergyNeeds_SummaryWrapper {
            height: fit-content;
        }
    }

    &.EnergyNeeds_SummaryWrapper {
        width: 100%;
    }

    &.borderWithNoShadow {
        box-shadow: none;
        border: 0.3px solid #c4c4c4;
    }

    &.EnergyNeeds_SummaryWrapper {
        width: 100%;
    }
}

.SectionOneWrapper {
    display: flex;
    justify-content: space-between;
}

.ConsumerEnergyNeedsStyle {
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #000000;
}

.ApplianceSpanStyle {
    width: 19px;
    height: 19px;
    background: #004aad;
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fafafa;
    border: 0;
    border-radius: 50%;
}

.ApplianceAddedStyle {
    width: 110px;
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: rgba(35, 56, 98, 0.78);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.SectionTwoWrapper {
    margin: 22px auto;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        &.EnergyNeeds_SectionTwoWrapper {
            flex-direction: column;
            gap: 20px;
        }
    }
}

.TotalAddedStyle {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;

    sup {
        top: -1em;
    }
}

.SectionTwoSpanStyle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 24px;
    line-height: 29px;
}

.TotalUsageStyle {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    color: rgba(35, 56, 98, 0.78);
    width: 186px;
    margin-bottom: 10px;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.TotalUsageSpanOne {
    display: inline-block;
    width: 92px;
    margin-left: 5px;
}

.TotalUsageSpan {
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    float: right;
}

.HRStyle {
    border: 0.3px solid rgba(35, 56, 98, 0.28);
    // width: 426px;
    width: 100%;
}

.SectionThreeWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.AverageUsageStyle {
    width: 190px;
    text-align: right;
}

.AverageUsageSpanOne {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    color: rgba(35, 56, 98, 0.78);
}

.AverageUsageSpanTwo {
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #000000;
}

.EnergyNeedsEditor {
    @media screen and (max-width: 500px) {
        margin-top: 20px;
    }
}

.InstallationIconWrapper {
    display: flex;
    margin-right: 7px;

    @media screen and (max-width: 500px) {
        margin-right: 7px;
    }
}

.InstallationHelpIconStyle {
    margin-left: 3px;
    margin-top: 20px;
}

.clusterMinimumDeposit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    @media only screen and (max-width: 600px) {
        height: 95vh;
    }

    &__content {
        @media only screen and (max-width: 600px) {
            margin-top: 40px;
        }

        > h2 {
            color: #011a3c;
            text-align: center;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 22px;
            font-style: normal;
            line-height: normal;
        }

        > p {
            color: rgba(35, 56, 98, 0.8);
            text-align: center;
            font-family: 'SF-Pro-Display';
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
            margin-bottom: 28px;
        }
    }

    &__input {
        max-width: 317px;
        margin: 0 auto;
    }
}
