.Wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 94px 39px 0px 55px;
    width: 574px;
    background: #001837;
    height: 100%;
    color: #ffffff;

    img {
        margin: 41px 0 23px 0;
    }

    > div:first-child {
        > h2 {
            font-family: 'SF-Pro-Display-Medium';
            font-style: normal;
            font-size: 12px;
            line-height: 24px;
            text-align: left;
            letter-spacing: 0.065em;
            text-transform: uppercase;
            color: #ffffff;
            margin: 0;
        }

        > p {
            font-family: 'SF-Pro-Display';
            font-style: normal;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #ffffff;
            margin: 0;
        }
    }

    > :nth-child(3) {
        > h2 {
            font-family: 'SF-Pro-Display-Bold';
            font-style: normal;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;
            margin: 0;
        }

        > p {
            font-family: 'SF-Pro-Display-Light';
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            margin: 8px 0 0 0;
        }
    }

    > :nth-child(4) {
        display: flex;
        gap: 8px;
        margin-top: auto;
        margin-bottom: 47px;

        > span:last-child {
            font-family: 'SF-Pro-Display-Semibold';
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
            cursor: pointer;
            border-bottom: 1px solid #ffffff;
        }
    }
}
