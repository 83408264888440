.Wrapper {
  margin: 20px 140px;

  @media screen and (min-width: 1366px) {
    margin: 20px 10%;
  }


  @media screen and (min-width: 600px) and (max-width: 800px) {
    margin: 20px 0px;
    padding: 30px 100px;

  }

  @media screen and (max-width: 500px) {
    margin: 20px 0px;
  }
}

.Heading {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 23px;
  line-height: 27px;
  color: #011A3C;
  margin: 0;

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
}

.SubHeading {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  line-height: 28px;
  text-align: right;
  color: #004AAD;
  cursor: pointer;
  margin-bottom: 12px;
}

.DashboardWrapper {
  width: 100%;
  height: 139px;
  background: #011A3C;
  border-radius: 10px;
  display: flex;

  @media screen and (min-width: 1366px) {
    width: 100%;
  }


  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.MobileDashboardWrapper {
  width: 100%;
  height: 154px;
  background: #011A3C;
  border-radius: 10px;
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.Dashboard {
  font-family: 'SF-Pro-Display';
  text-transform: uppercase;
  color: #FFFFFF;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.29);
  margin: 35px 0px;
  margin-left: 80px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 600px) {
    border: none;
    width: 100%;
    align-items: center;
    display: flex !important;
    margin: 57px 0px;

  }

}

.DashboardTitle {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.14em;
}

.DashboardDateWrapper {
  display: flex;
}

.DashboardDate {
  width: 83px;
  height: 24px;
  background: #FFF4D2;
  border-radius: 3px;
  color: #201265;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 10px;

  @media screen and (max-width: 600px) {
    height: 20px;
  }
}

.DashboardSubtitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0px;
}

.PaymentBreakdownWrapper {
  font-family: 'SF-Pro-Display';
  margin: 40px 0px;
  width: 968px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.PaymentBreakdownTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 18px;
  color: #011A3C;
}

.StepWrapper {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
}

.DefaultVerticalLine {
  width: 1px;
  height: 75px;
  border-right: 1px solid #f7f7f7;
  margin: 5px 12px;
}

.GreenVerticalLine {
  border-right: 1px solid #61C9A8;
}


.DefaultStepper {
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #040403;
  padding: 4px;
}

.ActiveStepper {
  background: #011A3C;
  font-family: 'SF-Pro-Display-Bold';
  color: #FFFFFF;
}

.checkedStepperWrapper {
  background: #61C9A8;
  border: 1px solid #61C9A8;
}

.ContentWrapper {
  display: flex;
}

.TableWrapper {
  margin-left: 30px;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-left: 10px;
  }
}

.RowWrapper {
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  font-family: 'SF-Pro-Display';
  align-items: center;
  margin-bottom: 36px;

  @media screen and (min-width: 1366px) {
    width: 75vw;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-evenly;
  }

  @media screen and (max-width: 600px) {
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
  }
}

.RowColumnOne {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  width: 20%;

  @media screen and (max-width: 768px) {
    width: 10%;
    margin-left: 0px;
  }
}

.TableData {
  width: 21.5%;

  @media screen and (max-width: 1024px) {
    width: 20%;
  }
}

.TableBtn {
  width: 103px;
  height: 37px;
  background: #004AAD;
  border-radius: 25px;
  font-family: 'SF-Pro-Display-Medium';
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
}

.TableBtnSecondary {
  width: 103px;
  height: 37px;
  background: #e2eeff;
  border-radius: 25px;
  font-family: 'SF-Pro-Display-Medium';
  font-size: 12px;
  line-height: 14px;
  color: #004AAD;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}

.RowColumnOneFirst {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: rgba(1, 26, 60, 0.7);
}

.RowColumnOneSecond {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 18px;
  line-height: 21px;
  color: #011A3C;
}

.PaidStatus {
  width: 54px;
  height: 23px;
  background: #EDFFF5;
  border-radius: 3px;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.055em;
  color: #32CC79;
  text-align: center;
  padding: 5px;
}

.DueStatus {
  width: 82px;
  height: 23px;
  background: #FFF9E7;
  border-radius: 3px;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.055em;
  color: #FFC222;
  text-align: center;
  padding: 5px;
}

.TableDate {
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
}

.NoActivityWrapper {
  height: 300px;
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px auto;

  @media screen and (max-width: 1024px) {
    width: 450px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  }
}

.EmptyHeading {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #011A3C;
  margin-top: 45px;
  margin-bottom: 5px;
}

.EmptyText {
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.8);
}

.SkeletonWrapper {
  margin-bottom: 100px;
}

.TopSkeletonWrapper {
  display: flex;
  justify-content: space-between;

}

.TopLeft {
  width: 209px !important;
  height: 25px !important;
  border-radius: 6px;

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}

.TopRight {
  width: 209px !important;
  height: 20px !important;
  border-radius: 2px;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    width: 100% !important;
    margin-top: 15px;
  }
}

.MiddleSkeletonWrapper {
  width: 968px;
  height: 139px;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (min-width: 1366px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

}

.MiddleRow {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.MiddleTopColumn {
  width: 133px;
  height: 26px;
  border-radius: 4px;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}

.MiddleBottomColumn {
  width: 175px;
  height: 33px;
  border-radius: 7px;
}

.SkeletonMiddle {
  width: 187px;
  height: 25px !important;
  border-radius: 6px;
}

.BottomSkeletonWrapper {
  width: 968px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (min-width: 1366px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.BottomRow {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-left: 30px;
}

.BottomSmallColumn {
  width: 58px;
  height: 16px !important;
  border-radius: 2px;
  margin-bottom: 5px;
}

.BottomColumn {
  width: 35px;
  height: 23px !important;
  border-radius: 4px;
}

.BottomColumn1 {
  width: 58px;
  height: 16px !important;
  border-radius: 2px;
}

.BottomColumn2 {
  width: 72px;
  height: 16px !important;
  border-radius: 2px;
}

.BottomColumn3 {
  width: 109px;
  height: 16px !important;
  border-radius: 2px;
}

.BottomColumn4 {
  width: 109px;
  height: 37px !important;
  border-radius: 6px;
}

.MobileHide {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.DesktopHide {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.ConsumerFloatBox {
  position: absolute;
  right: 0;
  right: 58px;
  bottom: 31px;

  @media screen and (max-width: 600px) {
    display: none;
  }
}