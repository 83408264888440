.ParagraphTitle {
    font-family: 'SF-Pro-Display-Bold';
    color: var(--deepblue);
}

.ParagraphText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    color: rgba(1, 26, 60, 0.7);
    margin-bottom: 30px;
}

.ParagraphWrapper a {
    color: #004aad;
    font-family: 'SF-Pro-Display-Bold';
}

b {
    color: rgba(1, 26, 60, 1);
}