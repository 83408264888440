
.image-Wrapper {
  height: 163px;
  width: 444px;
  background: var(--white);
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 51px;
  @media screen and (max-width: 500px) {
    width: 340px;
  }
  @media screen and (max-width: 321px) {
    padding: 20px;
    text-align: center;
    width: 249px;
  }
}
.replaceDoc {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--blue);
  cursor: pointer;
}

.listingWrapper {
  width: 444px;
  @media screen and (max-width: 500px) {
    width: 343px;
  }
}
.CreateComponentModal {
  div.ant-select.SelectCountry.ant-select-single.ant-select-show-arrow.ant-select-disabled {
    width: 64px !important;
  }
}


.ImageBox {

  @media screen and (max-width: 500px) {
    display: block;
    right: 0px !important;
    top: 0px;
  }
}


.mobileImageWrapper {
  @media screen and (max-width: 500px) {
    height: 154px;
  }
}

.ActionButtonBox {
  margin-top: 27%;
}

.ImageListingBtns {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background: white;
  position: relative;
  top: -20px;
  height: 60px;
  padding: 15px 0px;
}