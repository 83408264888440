.PageTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #011A3C;

  @media screen and (max-width: 500px) {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;

  }
}

.CreateComponentSubHeader {
  display: block;
    width: 100%;
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 18px;
    color: #011A3C;
    margin-bottom: 15px;
}

.ComponentActionHeader {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
}


.PageForm {
  margin-top: 40px;
  width: 409px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }

}

.InputText {
  width: 200px;
}


.SelectBox {
  width: 390px !important;

  svg:nth(1) {
    display: none;
  }

}




.unitContainer {
  width: 200px !important;
  margin-bottom: 22px;

  @media screen and (max-width:500px) {
    width: 100% !important;
  }
}

.ImageBox {
  position: relative !important;
  right: 30px !important;
  top: -80px;
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    right: 0px !important;
    top: 0px;
  }
}

.ShowSampleImage {
  display: block;
}


.MobileBtnWrapper {

  margin-top: 30px;

  @media screen and (max-width: 500px) {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;

  }

  @media screen and (max-width: 375px) {
    position: absolute;
    bottom: 0px;
    margin-left: 50px;
  }
}

.ImgWrapperMobile {

  @media screen and (max-width: 500px) {
    display: block !important;
  }
}

.mobileSampleImageBtn {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.ContinueBtn {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.MobileCreateComponentBtn {
  display: none;

  @media screen and (max-width: 500px) {
    text-align: center;
    display: flex;
    margin-bottom: 20px;
    margin: 20px 10%;
  }

  @media screen and (max-width: 375px) {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    margin: 30px 10%;
  }
}


.UseImageSampleText {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 18px;
    color: #011A3C;
  }
    
}


.HidePrevContinueBtns {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.MobileContinueBtn {
  display: none;

  @media screen and (max-width: 500px) {
    text-align: center;
    display: flex;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 375px) {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    margin: 20px 10%;
  }

}

.mobileImgWrapper {

  @media screen and (max-width: 500px) {
    height: 154px;
  }
}

.ButtonWrapper {
  @media screen and (max-width: 500px) {
    text-align: center;
    margin: 30px 0px;
  }
}

.image-Wrapper {
  width: 397px;
  height: 163px;
  background: var(--white);
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 51px;
  @media screen and (max-width: 500px) {
    width: 340px;
  }
  @media screen and (max-width: 321px) {
    padding: 20px;
    text-align: center;
    width: 249px;
  }
}

.modalButtonWrapper {
  display: flex;
  justify-content: center;
}

.NotFoundWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.NotFoundIcon {
  margin-bottom: 25px;
  margin-top: 20px;
}

.NotFoundTitle {
  font-family: 'SF-Pro-Display-Bold';
font-size: 15px;
line-height: 18px;
color: #011A3C;
margin-bottom: 10px;
}

.NotFoundSubtitle {
  font-family: 'SF-Pro-Display';
font-size: 13px;
line-height: 17px;
text-align: center;
color: rgba(1, 26, 60, 0.6);
width: 216px;
}
.GlobalComponentTitle {
  width: 248px;
  height: 24px;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  margin: 20px 0px 30px;
}

.AddBrandIconWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  div {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  span {
    color: #004AAD;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: normal;
margin-left: 5px;
  }
}

.AddBrandIcon {
  fill: #004aad;
  stroke: #004aad;
}

.CreateBrandWrapper {
  padding: 30px;

  .BrandHeader {
    color: #011A3C;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    text-align: center;
    margin-top: 12px;

  }

  .BrandText {
    color: rgba(35, 56, 98, 0.70);
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-size: 15px;
  }

  .CloseIconWrapper {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -10px;


    @media screen and (max-width: 500px) {
      bottom: 40px;
    }
  }

  .BrandForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
}