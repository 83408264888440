.CountdownTimer {
    display: grid;
    place-items: center;
    width: 100%;
    position: relative;
}

.CountdownTimer_Dotted {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;

    .CountdownTimer_YellowDot {
        position: absolute;
        width: 2px;
        height: 2px;
        background-color: #ffda7a;
        opacity: 0.5;
        border-radius: 50%;
    }
}

@for $i from 0 through 27 {
    .CountdownTimer_Dotted .CountdownTimer_YellowDot:nth-child(#{$i + 1}) {
        transform: rotate(#{$i * 12.86deg})
            translate(54px)
            rotate(-#{$i * 12.86deg});
    }
}

.CountdownTimer_Progress {
    position: absolute;

    .ant-progress-inner {
        width: 115px !important;
        height: 115px !important;
    }

    .ant-progress-circle-path {
        width: 110px;
        height: 110px;
        stroke-width: 2px;
    }

    .ant-progress-text {
        font: 32px/46px 'SF-Pro-Display-Bold';
        color: #004aad !important;
        width: 110px;
    }
}
