.ScheduleWrapper {
  padding: 50px;

  @media screen and (max-width: 500px) {
      padding: 20px;
    }
  h2 {
    margin-bottom: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #011A3C;
  }
}

.MobileWrapper {
  @media screen and (max-width: 500px) {
    margin-top: 50px;
  }
}
.DSSection {
  display: flex;
  justify-content: space-between;
}

.DSdiv {
  width: 158px;
  margin-bottom: 40px;

  a {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: #004AAD;
    margin-right: 6px;
    align-items: center;
  }
}

.InterestStyle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.1em;
  color: #61708E;
}

.MonthStyle {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  margin-top: 5px;
}

.TopItemStyle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.055em;
  color: rgba(1, 26, 60, 0.85);
  text-transform: uppercase;
}

.BottomItemStyle {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 13px;
  line-height: 25px;
  color: #011A3C;
}

.DSBtnWrapper {
  display: flex;
  justify-content: center;
@media screen and (max-width: 500px) {
    margin-top: 100px;
  }
}

.DSButton {
  font-family: 'SF-Pro-Display-Semibold';
  width: 160px;
  height: 56px;
  background: #004AAD;
  border: 0px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.ViewDeclinedViewMobileBackLink {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
  }
}