.APPModal {
    padding: 50px;
}
.APPModalBtn {
    position: absolute;
    bottom: 50px;
    left: 35%;

    @media screen and (max-width: 500px) {
        margin-top: 250px;
        position: relative;
        display: flex;
        left: 0;
        justify-content: center;
        align-items: center;
    }
}
.APPModalInputs {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 500px) {
        display: block;
    }
}

.APPModalTitle {
    position: relative;

    @media screen and (max-width: 500px) {
        margin-top: 30px;
    }
}

.AWCloseIconWrapper {
    @media screen and (max-width: 500px) {
        top: -30px;
    }
}