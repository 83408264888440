.Wrapper {
    background-color: var(--white);
    padding: 35px;
}

.NotFoundContainer {
    display: block;
    text-align: center;
    margin-top: 50px;

    .Illustrator {
        max-width: 100%;
        height: auto;
    }

    h2 {
        font-style: normal;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 40px;
        line-height: 66px;
        color: #011a3c;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 18px;
        line-height: 32px;
        /* or 178% */
        color: rgba(1, 26, 60, 0.7);
        padding: 0 10px;
        margin-bottom: 40px;
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.NavHome {
    display: flex;
    background: #004aad;
    border-radius: 30px;
    width: 181px;
    height: 54px;
    border: none;
    margin-bottom: 25px;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #0458c9;
        box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
        color: #ffffff !important;
    }

    &:active,
    :focus {
        background: #004aad;
    }

    svg {
        position: relative;
        right: 25px;
    }
}

@media (max-width: 390px) {
    .NotFoundContainer {
        .Illustrator {
            width: 100%;
            height: 100%;
        }

        h2 {
            font-size: 36px;
        }
    }
}
