.SimulationStepsLine_two {
    width: 60%;
    border-top: 1px solid #61C9A8;
    margin: 0 25px;
    height: 1px;
}

.SimulationPaymentPlanCard {
    height: 193px;
    background: #F7F7F7;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
    padding: 24px 37px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media only screen and (max-width: 500px) {
        background: #FFFFFF;
    }
}

.SimulationPlanNumber {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: #011A3C;
    margin-bottom: 6px;
}

.SimulationPlanCost {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #011A3C;
}

.SimulatinCardSubtitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
}

.SimulationCardSubtitleContent {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 13px;
    line-height: 25px;
    color: #011A3C;
}

.SimulationPlanWrapper {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    grid-gap: 19px;
    margin-top: 15px;

    @media screen and (max-width: 991px) {
        grid-template-columns: auto;
    }
}

.SimulationSkeletalBox {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    grid-gap: 19px;
    margin-top: 40px;

    @media screen and (max-width: 1123px) {
    grid-template-columns: auto auto;
}

    @media screen and (max-width: 500px) {
    grid-template-columns: auto;
}
}

.SimulationSkeletalBoxStyle {
  display: flex;
}

.SimulationCard {
    display: inline-grid;
    grid-row-gap: 40px;
    padding: 20px 22px 22px 21px;
    width: 100%;
    height: 178px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    font-family: 'SF-Pro-Display';
    margin: 0 13px 14px 0;
    position: relative;
  }