.HelpCenterTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  margin: 20px 0;
}
.header {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 16px;
  color: #011A3C;
  padding: 14px 0;
}
.line {
  cursor: pointer;
  border-bottom: 0.6px solid rgba(229, 229, 229, 0.6);
}
.questions {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  color: #004AAD;
  cursor: pointer;
  margin-bottom: 14px;
}
.AnswerTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 18px !important;
  line-height: 32px;
  color: #011A3C;
  margin: 20px 0;
}
.textCountStyle {
  font-size: 15px !important;
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  color: rgba(35, 56, 98, 0.8);
}
.AnswerText {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px !important;
  line-height: 25px;
  color: rgba(35, 56, 98, 0.8);
  padding-bottom: 10px;
}
.categoryNameWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.HelpCenterBadge {
  margin-bottom: 15px;

  .ant-badge-status-text {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    color: rgba(1, 26, 60, 0.7);
  }

  .ant-badge-status-dot {
    height: 7px;
    width: 7px;
    color: rgba(1, 26, 60, 0.8) !important;
  }
}
.floatBoxClass {
  position: fixed;
  bottom: 1%;
  right: 2%;
}