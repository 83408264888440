.Wrapper {
  margin: 20px 0px;
}

.VerificationTitleWrapper p {
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  text-align: center;
  margin-bottom: 40px;
  color: rgba(35, 56, 98, 0.8);
}

.OtpWrapper {
  display: flex;
  justify-content: space-between;
}

.OtpResend {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 60px;
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.LinkWrapper {
  height: 26px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  margin: 0px auto;
  margin-top: 10px;
  text-align: center;
}
.LinkResend {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.LoadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.BottomWrapper {
  position: absolute;
  bottom: 30px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: rgba(35, 56, 98, 0.7);
  width: 90%;
@media screen and (max-width: 500px) {
    width: 64%;
  }
}

.BottomBtnWrapper {
  position: absolute;
  bottom: 80px;
  width: 90%;
  text-align: center;
@media screen and (max-width: 500px) {
    width: 64%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}
.BtnLoading {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--white);
  box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
    10014px 0 0 0 var(--white);
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px -10px 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px -10px 0 0 var(--white);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
}
