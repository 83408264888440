.SavingsCalculatorWrapper {
    background: #f9fcff;
    background-image: url('../../../../../assets/images/back-ground.svg');
    width: 100%;
    position: relative;
    height: 994px;
    display: flex;
    justify-content: center;
    padding: 0 20px;

    @media only screen and (max-width: 998px) {
        height: auto;
        background-image: none;
    }

    @media only screen and (max-width: 800px) {
        margin-bottom: 64px;
    }

    > section {
        max-width: 1244px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 998px) {
            height: auto;
            flex-direction: column;
            align-items: center;
            background-image: none;
        }

        > section:first-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}

.SavingsCalculatorLeftDiv {
    padding: 150px 0px;
    position: sticky;
    top: 0;

    @media only screen and (max-width: 998px) {
        padding: 50px 0px 10px 0px;
    }

    @media only screen and (max-width: 800px) {
        padding: 50px 0px 10px 0px;
        width: 100%;
    }

    h3 {
        color: #001837;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 35px;
        line-height: 45px;
        max-width: 367px;
        width: 100%;
        margin-bottom: 5px;

        @media only screen and (max-width: 998px) {
            width: 100%;
            max-width: 542px;
        }

        @media only screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 38.5px;
            max-width: 700px;
        }
    }

    p {
        color: #4d4d4d;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        max-width: 367px;
        width: 100%;

        @media only screen and (max-width: 998px) {
            width: 100%;
            max-width: 542px;
        }

        @media only screen and (max-width: 800px) {
            width: 100%;
            font-size: 14px;
            line-height: 23px;
        }
    }
}

.SavingsCalculatorSection {
    width: 628px;
    padding: 24px;
    border-radius: 48px;
    border: 1px solid #e2eeff;
    background: #fff;
    height: 950px;
    margin-top: 20px;

    @media only screen and (max-width: 800px) {
        border-radius: 24px;
    }
}

.SavingsCalculatorHelper {
    margin-bottom: 20px;

    @media only screen and (max-width: 800px) {
        max-width: 100%;
        margin: 0 auto;
        margin-top: 10px;
        height: auto;
        padding: 14px;
        border: 0.5px solid #e2eeff;

        border-radius: 24px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        -ms-border-radius: 24px;
        -o-border-radius: 24px;
    }
}

.SavingsHouseStyle {
    // margin-top: -6px;
    // height: 638px;
    position: absolute;
    bottom: 0;
    left: 0;

    max-width: 444px;
    max-height: 339px;
    height: auto;
    width: 100%;

    @media only screen and (min-width: 1120px) {
        max-width: 494px;
        max-height: 389px;
    }

    @media only screen and (min-width: 1200px) {
        max-width: 594px;
        max-height: 489px;
    }

    @media only screen and (min-width: 1300px) {
        max-width: 694px;
        max-height: 589px;
    }

    @media only screen and (max-width: 998px) {
        display: none;
    }
}

.SavingsCalculatorForm {
    width: 580px;
    padding: 40px 30px;
    border-radius: 24px;
    border: 0.5px solid #e2eeff;
    background: #f9fcff;

    @media only screen and (max-width: 800px) {
        width: 100%;
        padding: 20px 10px;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
    }

    .SavingsCalculatorFormP {
        color: #233862;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;

        @media only screen and (max-width: 800px) {
            line-height: 20px;
        }
    }
}

.SavingsInputFieldsWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.SavingsCalculatorTabsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.SavingsCalculatorTabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 460px;
    border-bottom: 1px solid #e2eeff;

    @media only screen and (max-width: 800px) {
        width: 100%;
    }

    p {
        color: #3d5075;
        cursor: pointer;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        height: 40px;
        line-height: 24px;
        letter-spacing: 0.78px;
        text-transform: uppercase;
        margin: 0px 20px;

        @media only screen and (max-width: 800px) {
            line-height: 20px;
            font-family: 'SF-Pro-Display';
            margin: 0px;
        }
    }
}

.SavingsCalculatorAmount {
    text-align: center;
    margin: 50px auto;

    @media only screen and (max-width: 800px) {
        margin: 30px auto;
        max-width: 100%;
    }

    p {
        color: #3d5075;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;

        @media only screen and (max-width: 800px) {
            font-size: 14px;
            line-height: 23px;
        }
    }

    h3 {
        color: #002c68;
        text-align: center;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 56px;
        line-height: 60px;
        margin: 10px auto 4px;

        @media only screen and (max-width: 800px) {
            font-size: 32px;
            line-height: 38px;
        }
    }

    .SavingsCalculatorMonthsDropdown {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 800px) {
            margin-top: 10px;
        }

        p {
            color: #004aad;
            font-family: 'SF-Pro-Display';
            font-size: 16px;
            line-height: 24px;
            margin-right: 2px;
        }
    }
}

.SavingsCalculatorBreakdownWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
    flex-wrap: wrap;

    @media only screen and (max-width: 800px) {
        justify-content: flex-start;
        gap: 20px;
    }

    p {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
        margin-right: 3px;
        margin-top: -3px;

        @media only screen and (max-width: 800px) {
            margin-bottom: 0px;
        }
    }

    .SavingsCalculatorBreakdownSpan {
        color: #004aad;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        margin-top: 2px;
    }
}

.SavingsCalculatorBreakdown {
    display: flex;
    margin-right: 25px;
}

.SavingsCalculatorHelpCTAWrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e2eeff;
    padding-top: 18px;

    @media only screen and (max-width: 800px) {
        padding-top: 25px;
        justify-content: center;
        align-items: center;
        gap: 30px;
        flex-direction: column-reverse;
    }

    div {
        @media only screen and (max-width: 800px) {
            justify-content: space-between;
            max-width: 230px;
        }
    }
}

.optionalField {
    @media only screen and (max-width: 800px) {
        margin-bottom: 15px;
    }
}

.OptionalSpanStyle {
    color: #233862;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin-left: 20px;
}

.SolarSavingsDrawerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .SolarSavingsDrawerHeader {
        color: #233862;
        text-align: center;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
    }

    .SolarSavingsDrawerText {
        color: #5f5b5b;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        width: 474px;
        margin: 5px auto 25px;

        @media only screen and (max-width: 800px) {
            width: 100%;
            font-size: 14px;
            line-height: 23px;
        }
    }
}

.SavingsCalculatorSpansWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 460px;

    @media only screen and (max-width: 800px) {
        width: 100%;
        flex-direction: column;

        display: none;
    }

    span {
        color: #3d5075;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.78px;
        text-transform: uppercase;
    }
}
