.Wrapper {
    margin: 60px 100px;
    margin-right: 0px;

    @media screen and (max-width: 500px) {
        margin: 0px;
        padding-top: 40px;
    }
}

.Row {
    display: flex;

    @media screen and (max-width: 500px) {
        display: block;
    }
}

.BtnWrapper {
    margin-top: 10px;

    @media screen and (max-width: 500px) {
        margin-top: 40px;
    }
}

.MobileBackLink {
    @media screen and (max-width: 500px) {
        display: none;
    }
}

.LeftComponent {
    margin-right: 50px;

    @media screen and (max-width: 500px) {
        margin-right: 0px;
    }
}

.GlobalPackageTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin: 40px 0 27px 0;
}

.PowerCurveButton {
    margin-top: -30px;

    > p {
        font-family: 'SF-Pro-Display-Semibold';
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: #011a3c;
        margin: 40px 0 27px 0;
    }
}
