.Container {
  display: flex;
  text-align: center;
  margin: 0px 37%;
}

.InActiveLine {
  width: 20px;
  height: 2px;
  background: #E2EEFF;
  margin-right: 5px;
  border-radius: 2px;

}

.ActiveLine {
  background: #004AAD;
  width: 40px;
  height: 3px;
  border-radius: 2px;
  margin-right: 5px;
}