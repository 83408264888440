.Qview_Footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: 0.5px solid rgba(209, 209, 209, 1);

    @media (max-width: 500px) {
        padding: 16px 12px;
        gap: 8px;
    }
}

.QViewSystemWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;

    @media (max-width: 500px) {
        padding: 24px 12px;
    }

    h2,
    p {
        margin: 0;
    }


    *.first_row {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        border-radius: 8px;
        border: 0.5px solid var(--shades-and-tints-gray-scale-fafafa, #FAFAFA);
        background: #FCFCFC;
        padding: 8px;

        >div:first-child {
            h2 {
                color: var(--gray-scale-000000, #070C18);
                font-size: 12px;
                line-height: 150%;
                margin-bottom: 8px;
            }

            p {
                color: var(--shades-and-tints-gray-scale-4-d-4-d-4-d, #4D4D4D);
                font-family: "SF-Pro-Display-Medium";
                font-size: 12px;
                line-height: 150%;
                padding: 4px 8px;
                width: fit-content;
                border-radius: 3px;
                background: var(--shades-and-tints-gray-scale-fbc-948, linear-gradient(90deg, #F1F1F1 0%, #F3F3F3 51.7%, #F3F3F3 100%, #F1F1F1 100%));
            }
        }

        >div:last-child {
            h2 {
                color: var(--shades-and-tints-primary-000-f-23, #000F23);
                font-family: "SF-Pro-Display-Bold";
                font-size: 14px;
                line-height: 120%;
                text-align: right;
                margin-bottom: 15px;
            }

            >div {
                display: flex;
                gap: 4px;
                align-items: center;
                color: var(--Accent-Blue-Primary, #004AAD);
                text-align: center;

                span:first-of-type {
                    font-family: "SF-Pro-Display-Semibold";
                    font-size: 12px;
                    line-height: normal;
                }

                img {
                    width: 18px;
                }
            }
        }
    }

    *.second_row {
        border-radius: 7px;
        background: #ECFFF0;
        padding: 8px;

        >div:first-child {
            display: flex;
            justify-content: space-between;
            gap: 8px;

            >div:first-child {
                display: flex;
                gap: 4px;
                flex-wrap: wrap;

                span:first-of-type {
                    color: var(--gray-scale-000000, #070C18);
                    font-size: 12px;
                    line-height: 150%;
                }

                img {
                    align-self: center;
                    width: 10px;
                    height: 10px;
                }
            }

            >div:last-child {
                display: flex;
                border-radius: 12px;
                background: #BDF6C9;
                gap: 3px;
                padding-right: 8px;
                align-items: center;

                >span:first-child {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                    background: #92E2A3;
                }

                >span:last-child {
                    overflow: hidden;
                    color: #060606;
                    text-overflow: ellipsis;
                    font-size: 12px;
                    line-height: 150%;
                    font-family: "SF-Pro-Display-Light";
                }
            }
        }

        >div:nth-child(2) {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-top: 12px;

            span {
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 1px;
                cursor: pointer;

                p {
                    color: var(--primary-004-aad, #004AAD);
                    text-align: center;
                    font-family: "SF-Pro-Display-Semibold";
                    font-size: 14px;
                    line-height: normal;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            span:hover {
                background: white;
            }

            >div {
                border-top: 1px solid #b6dcbe;
                height: 1px;
                width: 100%;
            }
        }


    }

    .second_row_arrow {
        transform: rotate(0);
        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .second_row_arrow_animate {
        transform: rotate(180deg);
        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .second_row_details {
        height: 0px;
        visibility: hidden;
        opacity: 0;
        transition-property: height, visibilty, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .second_row_details_animate {
        margin-top: 12px;
        height: 65px;
        visibility: visible;
        opacity: 1;
        transition-property: height, visibilty, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .second_row_appliance_title {
        overflow: hidden;
        color: #2B8C40;
        text-overflow: ellipsis;
        font-family: "SF-Pro-Display";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%;
        margin-bottom: 16px;
    }

    *.third_row {
        display: flex;
        justify-content: space-between;
        border-radius: 7px;
        background: var(--Accent-Yellow-Light, #FFFAEC);
        padding: 8px;
        gap: 8px;


        >div:first-child {
            display: flex;
            gap: 4px;

            span:first-of-type {
                color: var(--gray-scale-000000, #070C18);
                font-size: 12px;
                line-height: 150%;
            }

            img {
                align-self: center;
                width: 10px;
                height: 10px;
            }
        }

        >div:last-child {
            display: flex;
            border-radius: 12px;
            background: var(--shades-and-tints-secondary-ffecbc, #FFECBC);
            gap: 3px;
            padding-right: 8px;
            align-items: center;

            >span:first-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                border-radius: 12px;
                background: var(--shades-and-tints-secondary-ffda-7-a, #FFDA7A);

                svg,
                path {
                    stroke: #B28717
                }

            }

            >span:last-child {
                font-family: "SF-Pro-Display-Light";
                overflow: hidden;
                color: #060606;
                text-overflow: ellipsis;
                font-size: 12px;
                line-height: 150%;
            }
        }

    }

    *.fourth_row {
        display: block;
        border-radius: 8px;
        border: 0.5px solid var(--shades-and-tints-primary-e-2-eeff, #E2EEFF);
        background: var(--shades-and-tints-primary-f-9-fcff, #F9FCFF);
        padding: 8px;

        >div:first-child {
            display: flex;
            justify-content: space-between;
            gap: 8px;

            >div:first-child {
                display: flex;
                align-items: center;
                gap: 2px;

                h2 {
                    color: var(--primary-004-aad, #004AAD);
                    font-size: 14px;
                    line-height: 150%;
                }

                svg {
                    width: 12px;
                    height: 12px;
                    cursor: pointer;
                }
            }

            >div:last-child {
                p {
                    color: var(--shades-and-tints-primary-233862, #233862);
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }


        >div:nth-child(2) {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-top: 12px;

            span {
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 1px;
                cursor: pointer;

                p {
                    color: var(--primary-004-aad, #004AAD);
                    text-align: center;
                    font-family: "SF-Pro-Display-Semibold";
                    font-size: 14px;
                    line-height: normal;
                }

                svg {
                    width: 16px;
                    height: 16px;
                }
            }

            >div {
                border-top: 1px solid #E2EEFF;
                height: 1px;
                width: 100%;
            }
        }
    }

    *.fourth_row_amount {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        h2 {
            color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
            font-size: 12px;
            line-height: 150%;
        }


        p {
            color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
        }


        >div:last-child p {
            text-align: right;
        }
    }

    *.fourth_row_info {
        border-radius: 8px;
        background: var(--shades-and-tints-primary-e-2-eeff, #E2EEFF);
        padding: 8px;

        >div {
            display: flex;
            gap: 8px;
            align-items: flex-start;

            svg {
                height: 16px;
                width: 23px;
            }

            >span:first-of-type {
                color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
                font-size: 12px;
                line-height: 150%;
            }
        }
    }

    .fourth_row_dropdown {
        cursor: pointer;
    }

    *.fourth_row_outright {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        border: 0.5px solid var(--shades-and-tints-primary-e-2-eeff, #E2EEFF);
        background: var(--shades-and-tints-primary-f-9-fcff, #F9FCFF);
        padding: 8px;

        h2 {
            color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
            font-size: 12px;
            line-height: 150%;
        }


        p {
            color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
        }

        >div:last-child p {
            text-align: right;
        }
    }


    .fourth_row_arrow {
        transform: rotate(0);
        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .fourth_row_arrow_animate {
        transform: rotate(180deg);
        transition-property: transform;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .fourth_row_details {
        height: 0px;
        visibility: hidden;
        opacity: 0;
        transition-property: height, visibilty, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;
    }

    .fourth_row_details_animate_min {
        margin-top: 12px;
        height: 50px;
        visibility: visible;
        opacity: 1;
        transition-property: height, visibilty, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;

        @media (max-width: 576px) {
            height: 72px;
        }
    }

    .fourth_row_details_animate_max {
        margin-top: 12px;
        height: 100px;
        visibility: visible;
        opacity: 1;
        transition-property: height, visibilty, opacity;
        transition-timing-function: ease-in-out;
        transition-duration: 0.3s;

        @media (max-width: 576px) {
            height: 118px;
        }
    }
}

.appliance_combination_wrapper {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
}

.appliance_combination {
    display: flex;
    padding: 3.2px;
    justify-content: center;
    align-items: center;
    gap: 6.4px;
    width: 100%;
    border-radius: 12.8px;
    border: 0.3px solid #BDF6C9;
    background: #FFF;
    max-width: 131px;
}