.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
        width: 162px;
        height: 160px;
        margin-bottom: 20px;
    }

    h4 {
        font: 24px/28.64px var(--font-bold);
        color: #011a3c;
        margin-bottom: 12px;
        max-width: 358px;
    }

    p {
        font: 16px/26px var(--font-regular);
        color: #233862cc;
        margin-bottom: 35px;
        max-width: 385px;
    }
}
