.Container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > .CardSection {
        display: flex;
        flex-direction: column;

        & > h2 {
            font-family: 'SF-Pro-Display-Bold';
            font-size: 14px;
            line-height: 16.8px;
            color: #011a3ccc;
            opacity: 80%;
        }

        & .CardGallery {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}
