.ImageWrapper {
  display: flex;
  justify-content: center;
}

.Header {
  height: 27px;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 22px;
  line-height: 27px;
  color: var(--primary-blue);
  text-align: center;
  margin-top: 15px;
}

.Subheader {
  width: 100%;
  height: 52px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: rgba(35, 56, 98, 0.8);
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.LinkWrapper {
  display: flex;
  justify-content: center;
  max-width: 257px;
  height: 26px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  margin: 0px auto;
  margin-top: 10px;
}

.SlideContainer {
  width: 161px;
  height: 4px;
  background: #e2eeff;
  margin: 0px auto;
}

.Slider {
  height: 5px;
  width: 50%;
  background: var(--blue);
  border-radius: 5px;
}

.SliderText {
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #233862;
  margin-top: 20px;
}

.SlideWrapper {
  text-align: center;
   padding-top: 20px
}
