.wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 70px 20px;

    > div {
        max-width: 1244px;
        width: 100%;
        display: grid;
        column-gap: 14px;
        grid-template-columns: 50% 1fr;

        @media only screen and (max-width: 980px) {
            grid-template-columns: 100%;
            column-gap: 0;

            > div:nth-child(1) {
                order: 2;
            }
        }

        @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column-reverse;

            > div:nth-child(1) {
                order: 2;
            }
        }

        > div {
            border-radius: 12px;
            background: #004AAD;
            padding: 50px 40px;

            @media only screen and (max-width: 980px) {
                width: 100%;

                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }

        > div:first-child {
            @media only screen and (max-width: 768px) {
                width: 100%;

                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        > div:nth-child(2) {
            @media only screen and (max-width: 768px) {
                img {
                    width: 100%;

                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }

        > div:nth-child(2) {
            display: flex;
            border-radius: 12px;
            background: #fff;
            padding: 0;
        }

        .section_title {
            color: var(--secondary-ffc-222, #ffc222);
            font-family: 'SF-Pro-Display-Bold';
            font-size: 18px;
            font-style: normal;
            line-height: 24px;
            padding-bottom: 46px;
        }
    }
}

.data_container {
    border: none;
    padding: 17px 0;

    h2 {
        color: #fff;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        font-style: normal;
        line-height: 35px;
    }

    > div {
        height: 130px;

        @media only screen and (max-width: 980px) {
            height: auto;
        }
    }

    > div > p {
        color: var(--shades-and-tints-gray-scale-ffffff, #fff);
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 136.7%;
        height: auto;
        transition: all 1s ease-in;
    }

    > div > p:nth-child(1) {
        margin: 0;
        margin-bottom: 12px;
    }
}

.data_container_inactive {
    border-top: 0.3px solid var(--shades-and-tints-primary-3-d-5075, #3d5075);
    padding: 17px 0;

    h2 {
        color: var(--shades-and-tints-primary-3-d-5075, rgba(255, 255, 255, 0.35));
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        font-style: normal;
        line-height: 35px;
    }

    > div > p {
        color: var(--shades-and-tints-gray-scale-ffffff, #fff);
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 136.7%;
        height: 0px;
        opacity: 0;
        visibility: hidden;
    }

    > div > p:nth-child(1) {
        margin: 0;
        margin-bottom: 12px;
    }
}

.active_img {
    display: block;
    object-fit: cover;
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    // height: 570px;
    // transition: all ease-in .3s;
    // visibility: visible;
    animation: fadeIn 1s;

    @media only screen and (max-width: 980px) {
        height: 450px;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        
    }

    @media only screen and (max-width: 500px) {
        height: 300px;
    }
}

.inactive_img {
    display: none;
    object-fit: cover;
    border-radius: 12px;
    width: auto;
    // height: 0px;
    // opacity: 0;
    // visibility: hidden;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
