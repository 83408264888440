.ACVContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ACVInputWrapper {
    display: flex;
}

.ACVInputMainWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: block;
        margin: auto 20px;
    }
}

.ACVButtonWrapper {
    display: flex;
    margin-top: 52px;
    justify-content: center;
}

.ACVCloseIconWrapper {
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 39px;
}