.CardContainer {
    height: 268px !important;
}

.PullEnergyNeedsSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    & > button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        gap: 8px;
        cursor: pointer;

        & > p {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            line-height: 14.32px;
            text-align: center;
            color: #004aad;
        }
    }
}
