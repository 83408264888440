.Heading {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  color: #011A3C;
  margin: 48px 0 28px 0;
}

.Table {
  height: 500px;
  overflow-x: auto;
}

.Row {
  background-color: #FFFFFF;
  height: 67px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 12px;

  @media only screen and (max-width: 500px) {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
  }
}

.Activity {
  font-size: 14px;
  color: #011A3C;

  span {
    font-family: 'SF-Pro-Display-Semibold';
  }

  @media only screen and (max-width: 500px) {
    width: 202px;
  }
}

.DateOf {
  font-size: 14px;
  color: #233862C7;
}