.LogoBox {
    padding: 30px 60px;
    margin-top: 1px;
    cursor: pointer;
    margin-bottom: 40px;
}

.ant-layout-sider-children {
    position: fixed;
    width: 262px;
    background-color: #fff;
    border: none !important;
}

.ant-layout-sider-trigger {
    background-color: #fff;
    border: none;
}

.site-layout {
    min-height: 100vh;
    height: 100%;
    width: 100% !important;
}

.ant-menu-item {
    padding: 20px 20px 20px 60px !important;
    border: none !important;
    margin-bottom: 30px !important;
}

.ContentBox {
    background-color: #f6f6f6;
    height: fit-content;
    min-height: 100%;
    padding: 24px 0px;

    @media screen and (max-width: 500px) {
        background-color: #fff;
        padding: 24px;
    }

    @media screen and (max-width: 374px) {
        padding: 24px 20px 24px 24px;
    }
}

.HeaderBox {
    background-color: #f6f6f6;
    height: 85px;

    @media screen and (max-width: 992px) {
        padding: 0 30px;
    }

    @media screen and (max-width: 500px) {
        padding: 0 30px;
        background-color: #fff;
    }
}

.HeaderBoxV2 {
    &.ant-layout-header {
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03) !important;
        z-index: 10;
        padding: 0 50px;
    }

    @media only screen and (max-width: 500px) {
        padding: 0 20px !important;
    }
}

.MenuItemBox {
    color: rgba(35, 56, 98, 0.8) !important;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    height: 100%;
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .ant-menu-item::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-right: 3px solid #004aad;
        box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
    }
}

.MenuItemBox_Logo {
    width: 100%;
    margin: 53px 0 72px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100px;
        height: 32px;
        object-fit: cover;
    }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover {
    background-color: #fff;
    font-style: normal;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 14px;
    line-height: 17px;
    color: #004aad;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 0px);
}

.ant-menu-item.ant-menu-item-disabled {
    color: rgba(35, 56, 98, 0.8) !important;
}

.FloatBox {
    position: fixed;
    bottom: 8%;
    right: 9%;

    a {
        background-color: #004aad;
    }
}

.VerticalSider {
    @media screen and (max-width: 768px) {
        display: none !important;
    }

    @media screen and (max-width: 500px) {
        display: none !important;
    }
}

.LayoutWrapper {
    display: grid;
    grid-template-columns: 262px 1fr;
    min-height: 100vh;

    @media screen and (max-width: 768px) {
        display: flex;
    }
}

.ConsumerLayoutWrapper {
    min-height: 100vh;
}
.MenuItem {
    background: #fff;
}

.MenuTitle {
    margin-left: 15px;
}

.HelpCenterWrapper {
    position: fixed;
    bottom: 35px;
    right: 31px;
}

.hideNav {
    background-color: #f6f6f6;
    height: 85px;

    @media screen and (max-width: 768px) {
        padding: 0 30px;
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.ContentBox_NotFoundWrapper {
    display: flex;
    padding: 0 !important;
    min-height: fit-content !important;
    height: calc(100vh - 85px) !important;

    > div {
        flex: 1;
        display: flex;
    }
}
