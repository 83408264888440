.AddPackageContainer {
    padding-inline: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    min-height: calc(100vh - 110px);
    position: relative;

    @media screen and (max-width: 768px) {
        padding-inline: 30px;
    }

    @media screen and (max-width: 500px) {
        padding-inline: 5px;
    }
}

.AddPackageTitleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.AddPackageTitle {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 17.9px;
    color: var(--primary-blue);

    span {
        font-family: 'SF-Pro-Display-Semibold';
    }
}

.AddPackageEmptyStateWrapper {
    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 16px;
        line-height: 19.09px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 13px;
        line-height: 15.51px;
        opacity: 80%;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        margin: auto;
    }
}

.AddPackageCardGridWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding-block: 20px;
}

.AddPackageCardGrid {
    width: 100%;
    display: grid;
    grid-gap: 32px 20px;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
}

.AddPackageButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    max-width: 539px;
    height: 64px;
    gap: 20px;
    padding: 16px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0px 5px 32px 0px #0000001a;
    position: absolute;
    bottom: 20px;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 15px;
        line-height: 17.9px;
        color: var(--primary-blue);
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        height: fit-content;
    }
}
