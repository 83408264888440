.StatusIndicator_Wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 6px !important;
    width: 100% !important;

    @media only screen and (max-width: 350px) {
        align-items: flex-start !important;
    }

    @media only screen and (min-width: 1124px) and (max-width: 1350px) {
        align-items: flex-start !important;
    }
}

.StatusIndicator_Title {
    font: 12px/20px 'SF-Pro-Display' !important;
    color: #4d4d4d !important;
    word-break: normal !important;

    @media only screen and (max-width: 350px) {
        max-width: 65px !important;
    }

    @media only screen and (min-width: 1124px) and (max-width: 1350px) {
        max-width: 60px !important;
    }
}

.StatusIndicator_Tooltip {
    display: grid !important;
    place-items: center !important;
    width: 15px !important;
    height: 15px !important;
    flex-shrink: 0 !important;
    background-color: #e2eeff !important;
    border-radius: 50% !important;
    cursor: pointer !important;

    svg {
        width: 8px !important;
        height: 8px !important;
    }
}
