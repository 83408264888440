.technicalReview {
    &__top {
        border: 1px solid #e2eeff;
        background: #fff;
        padding: 22px;

        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;

        @media only screen and (max-width: 550px) {
            padding: 0px;
        }

        &__first {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            h4 {
                color: #000;
                font-size: 12px;
                line-height: 24px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Medium';
                letter-spacing: 0.78px;
                text-transform: uppercase;
            }

            p {
                color: rgba(35, 56, 98, 0.78);
                text-align: right;
                font-size: 10px;
                font-style: normal;
                font-family: 'SF-Pro-Display';
                line-height: normal;

                display: flex;
                align-items: center;
                gap: 5px;

                span {
                    width: 19px;
                    height: 19px;
                    background-color: #004aad;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    color: #fafafa;
                    font-size: 10px;
                    line-height: normal;
                }
            }
        }

        &__second {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            margin-top: 20px;
            border-bottom: 0.3px solid rgba(35, 56, 98, 0.78);
            padding-bottom: 25px;

            &_left {
                color: #011a3c;
                h4 {
                    font-size: 14px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';
                    line-height: normal;
                }

                p {
                    font-size: 24px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display-Bold';
                    line-height: normal;
                    position: relative;

                    sup {
                        font-size: 10px;
                        font-style: normal;
                        font-family: 'SF-Pro-Display-Medium';
                        line-height: normal;
                        margin-left: 3px;
                        position: absolute;
                        top: 2px;
                    }
                }
            }

            &_right {
                display: flex;
                flex-direction: column;
                gap: 5px;

                div {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    small {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        min-width: 100px;

                        img {
                            width: 11.5px;
                            height: 11.5px;
                        }

                        p {
                            color: rgba(35, 56, 98, 0.78);
                            font-size: 12px;
                            font-style: normal;
                            font-family: 'SF-Pro-Display';
                            line-height: normal;
                        }
                    }

                    p {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-family: 'SF-Pro-Display';
                        line-height: normal;
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 10px;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }

        &__third {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            text-align: right;

            &_right {
                p {
                    color: rgba(35, 56, 98, 0.78);
                    font-size: 12px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';
                    line-height: normal;
                }

                span {
                    color: #000;
                    font-size: 14px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';
                    line-height: normal;

                    span {
                        font-size: 10px;
                    }
                }
            }
        }
    }

    &__bottom {
        margin-top: 16px;

        h3 {
            color: #000;
            font-size: 12px;
            line-height: 24px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Medium';
            letter-spacing: 0.78px;
            text-transform: uppercase;
        }

        &__body {
            padding-left: 0;
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            li {
                list-style: none;
                padding: 21px 24px;
                gap: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #e2eeff;
                background: #fff;
                border-radius: 12px;
                -webkit-border-radius: 12px;
                -moz-border-radius: 12px;
                -ms-border-radius: 12px;
                -o-border-radius: 12px;

                @media only screen and (max-width: 550px) {
                    align-items: flex-end;
                    padding: 10px 15px;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                    width: 80%;

                    @media only screen and (max-width: 550px) {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 20px;
                    }
                }

                p {
                    color: #3d5075;
                    font-size: 14px;
                    line-height: 24px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display-Bold';
                    width: 220px;
                }

                small {
                    color: #233862;
                    font-size: 14px;
                    line-height: 24px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';
                    width: 130px;
                }
            }
        }

        &__pagination {
            display: flex;
            justify-content: center;
        }
    }
}

.selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    span {
        max-width: 155px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select {
        background: #e2eeff;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;

        padding: 3px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;

        color: #004aad;
        font-size: 10px !important;
        font-style: normal !important;
        font-family: 'SF-Pro-Display-Semibold' !important;
        line-height: 20px !important;
        letter-spacing: 1.02px !important;
        text-transform: uppercase !important;
    }
}

.planModal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    width: 100% !important;
    padding-top: 15px;
    border-bottom: 3px solid red;
}
