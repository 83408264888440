.cssePromoHeaderWrapper {
    background: #f9fcff;
    display: flex;
    justify-content: center;
    padding: 10px 16px;

    >div {

        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1244px;
    }

    @media only screen and (max-width: 1000px) {
        padding: 10px 40px;
    }

    @media only screen and (max-width: 500px) {
        padding: 10px 20px;
    }
}

.cssePromoCarouselDiv {
    margin-top: 50px;
    width: 620px;
}

.cssePromoListingsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 16px 100px 16px;

    @media only screen and (max-width: 1000px) {
        padding: 50px 40px;
    }

    @media only screen and (max-width: 500px) {
        padding: 50px 20px;
    }
}

.csseSearchInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1244px;
}

.csseFilterWrapperStyle {
    cursor: pointer;
    display: flex;
    padding: 20px 32px 19px 32px;
    justify-content: center;
    gap: 8px;

    @media only screen and (max-width: 500px) {
        padding: 0;
        padding-left: 20px;
    }

    span {
        color: #004aad;
        text-align: center;
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 14px;
        line-height: normal;

        @media only screen and (max-width: 500px) {
            font-size: 12px;
            font-family: 'SF-Pro-Display-SemiBold';
            white-space: nowrap;
        }
    }
}

.cssePromoListingsStyle {
    display: grid;
    flex-wrap: wrap;
    margin-top: 24px;
    grid-gap: 16px 20px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: 1244px;

    @media screen and (max-width: 500px) {
        margin-top: 50px;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
}

.cssepromoListingsImg {
    width: 100%;
    cursor: pointer;
    height: 175px;
    object-fit: cover;
    border-radius: 28px;

    @media screen and (min-width: 1440px) {
        width: 100%;
    }
}

.cssePromoBackBtnStyle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-left: -5px;

    div {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
        padding: 5px;

        img {
            width: 16px;
            height: 16px;
            object-fit: cover;
        }

        span {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            line-height: 17px;
            color: #004aad;
        }
    }
}

.csseMultiplePackagePromoListingsStyle {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px 20px;
    margin-top: 30px;

    @media screen and (min-width: 1900px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media screen and (max-width: 500px) {
        margin-top: 50px;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        padding: 0 30px;
    }

    @media screen and (max-width: 350px) {
        padding: 0;
    }
}

.MultiplePromoPackagesDivWrapper {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 18.81818px 0px rgba(0, 0, 0, 0.06);
    height: 375px;

    .MultiplePromoPackagesImageWrapper {
        height: 147px;
        border: 1px solid #e2eeff;
    }

    img {
        height: 147px;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
}

.SolarRoofImgStyle {
    height: 240px;
    max-height: 377.046px;
}

.PaginationWrapper {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}