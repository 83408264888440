.Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.FormSection {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 409px;

    h4 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 16px;
        line-height: 19.09px;
        color: var(--primary-blue);
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.FormSectionContentCol {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    >div {
        width: 100% !important;
    }
}

.FormSectionContentRow {
    display: flex;
    gap: 10px;
    width: 100%;

    >div {
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.OptionalLabel {
    color: var(--shades-and-tints-primary-233862, #233862);
    font-family: "SF-Pro-Display";
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin: 4px 0 0 21px;
}

.ProfileGroupWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    width: 100%;

    div {
        width: 100%;
    }

    button {
        all: unset;
        color: var(--Accent-Blue-Primary, #004AAD);
        font-family: "SF-Pro-Display-Semibold";
        font-size: 12px;
        line-height: normal;
        cursor: pointer;
    }
}

.UploadContainer {
    background: #ffffff !important;
    border: 1px solid #e5e5e5 !important;
    border-radius: 15px !important;
    cursor: inherit !important;
    height: 204px !important;
}

.fileSize {
    color: #233862;
    text-align: center;
    margin: 0 0 8px 0 !important;
    font-family: "SF-Pro-Display";
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
}

.switch_wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        color: var(--shades-and-tints-gray-scale-c-4-c-4-c-4, #C4C4C4);
        text-align: right;
        font-family: "SF-Pro-Display";
        font-size: 18px;
        font-style: normal;
        line-height: 150%;
    }
}