.ASIProfileWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 112px;

    @media screen and (max-width: 1024px) {
        margin-left: 20px;
    }

    @media screen and (max-width: 500px) {
        margin-left: 0;
    }

    @media screen and (max-width: 400px) {
        overflow-x: hidden;
    }
}

.ASIProfileTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    width: 100%;

    @media screen and (min-width: 1300px) and (max-width: 1366px) {
        align-self: flex-start;
        margin-left: 18%;
    }
}

.ASIProfileTab {
    .ant-tabs-tab {
        justify-content: center;
        width: 123px;
        margin-bottom: -5px !important;
    }

    .ant-tabs-nav {
        width: 68%;

        @media screen and (min-width: 1300px) and (max-width: 1600px) {
            width: 95%;
        }
        @media screen and (min-width: 1601px) {
            margin-left: 0 !important;
        }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        @media screen and (min-width: 2500px) {
            width: 95% !important;
        }
    }
    & {
        width: 100% !important;
        margin-left: 0 !important;
    }
}
.ant-tabs {
    overflow: inherit;
}
div.ant-tabs-nav {
    @media screen and (max-width: 800px) {
        width: 100% !important;
    }
}
div.ant-tabs-nav-operations {
    @media screen and (max-width: 800px) {
        width: 100% !important;
    }
}
