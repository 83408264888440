.NPSRatingModal {
    @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
    }
}

.NPSRatingModal_Subtitle {
    width: 100%;
    text-align: center;
    margin: 49px 0 21px 0;
    font: 12px/20px 'SF-Pro-Display';
    color: #233862b2;
}

.NPSRatingModal_Mid_Text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    > span {
        font: 10px/14px SF-Pro-Display;
        color: #233862;
    }
}

.NPSRatingModal_Mid_Btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 52px;

    .tooltiptext {
        padding: 5px 12px !important;
        font-size: 12px !important;
    }

    .tooltiptext::after {
        margin-left: -6px !important;
    }

    .NPSRatingModal_Mid_Btn {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 0.5px solid #e5e5e5;
        background-color: #ffffff;
        display: grid;
        place-items: center;
        font: 12px/18px 'SF-Pro-Display';
        color: #011a3c;
        cursor: pointer;

        &.active {
            background-color: #004aad;
            color: #ffffff;
        }
    }

    @media only screen and (max-width: 500px) {
        .NPSRatingModal_Mid_Btn {
            width: 24px;
            height: 24px;
            font-size: 10px;
        }
    }
}

.NPSRatingModal_Middle {
    position: relative;
    margin: 0 45px 53px 45px;

    @media only screen and (max-width: 500px) {
        position: initial;
        margin: 0 16px 40px 16px;
        flex: 1;
        height: 100%;
    }
}

.NPSRatingModal_TextArea {
    width: 100%;
}
