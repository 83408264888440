.AddressBlock {
  display: flex;
  margin-bottom: 30px;

  div {
    width: 100% !important;
  }

  >span {
    display: inline-block;
    width: 12%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.TextAreaMsg {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 12px;
  line-height: 1px;
  color: rgba(35, 56, 98, 0.78);
  position: relative;
  top: 2px;
  display: inline-block;
  margin-left: 25px;
}

.FormBox {
  height: 450px;
  width: 545px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    // max-height: 100%;
    height: auto;
    width: 100%;
  }
}

.SelectBox {
  :global {
    .ant-select-selection-item {
      padding-left: 13px !important;
    }
  }
}