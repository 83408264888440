.DISIProfileWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 112px;

    @media screen and (max-width: 1024px) {
        margin-left: 20px;
    }

    @media screen and (max-width: 500px) {
        margin-left: 0;
    }

    @media screen and (max-width: 400px) {
        overflow-x: hidden;
    }

    .DISIProfileTitle {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 23px;
        line-height: 27px;
        color: #011a3c;

        @media screen and (min-width: 2500px) {
            width: 70%;
        }

        @media screen and (min-width: 1300px) and (max-width: 1366px) {
            align-self: flex-start;
            margin-left: 16%;
        }

        @media screen and (min-width: 1440px) {
            width: 58%;
        }

        @media screen and (min-width: 2560px) {
            width: 69%;
        }
    }

    .ProfileTab {
        .ant-tabs-tab {
            justify-content: center;
            width: 123px;
            @media screen and (max-width: 800px) {
                width: 106px;
            }
        }

        .ant-tabs-nav {
            @media screen and (min-width: 1600px) {
                width: 46vw !important;
            }
        }

        .ant-tabs-nav {
            @media screen and (min-width: 2560px) {
                width: 58vw !important;
            }
        }
    }

    .ant-tabs {
        overflow: inherit;
    }

    div.ant-tabs-nav {
        @media screen and (max-width: 800px) {
            width: 100% !important;
        }
    }

    div.ant-tabs-nav-operations {
        @media screen and (max-width: 800px) {
            width: 100% !important;
        }
    }

    div.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
        @media screen and (max-width: 500px) {
            overflow: inherit;
            width: 100%;
        }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
        display: none;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        @media screen and (min-width: 2500px) {
            width: 55% !important;
        }
    }
}
