.wrapper {
    padding: 42px 33px 33px 33px;
}

.methodText {
    h4 {
        font: 15px/26px var(--font-medium);
        color: #233862;
        margin-bottom: 3px;
    }

    p {
        font: 12px/20px var(--font-regular);
        color: #233862b2;
        margin: 0;
    }
}

.btn {
    width: 100%;
    display: flex;
    justify-content: center;
}
