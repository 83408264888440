.Card {
    position: relative;
    width: 100%;
    min-height: 492px;
    background: #e2eeff;
    border-radius: 16px;
    font-family: 'SF-Pro-Display';
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .SecondaryModalV2ContentWrapper {
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        min-height: 449px;
    }
}

.CardImageWrapper {
    width: 100%;
    padding: 20px;
}

.CardImage {
    width: 182px;

    @media screen and (max-width: 768px) {
        width: 144.44px;
    }
}

.CardBody {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    gap: 8px;

    h4 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
        color: #001837;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #233862;
    }

    @media screen and (max-width: 800px) {
        h4 {
            font-size: 24px;
            line-height: 38.5px;
        }

        p {
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.CardButtonWrapper {
    width: 100%;
    margin-top: auto;
    margin-bottom: 35px;

    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
    }
}

.ModalContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px 20px;
    text-align: center;

    h3 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #011a3c;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 18px;
        color: #4d4d4d;
    }
}
