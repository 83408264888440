.EmptyStateContainer {
    text-align: center;
    margin-top: 30px;
}

.EmptyStateTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #011a3c;
    margin-top: 32px;
}

.EmptyStateSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.8);
    margin-top: 7px;
}

.EmptyStateButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.EmptyStateButton {
    height: 46px;
    background: #004aad;
    border-radius: 30px;
    border: none;
    color: #ffffff;
    margin-top: 15px;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
}

.EmptyStateSecondaryTitle {
    color: #3d5075;
    text-align: center;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 14px;
    font-style: normal;
    line-height: 24px;
    margin-top: 5px;
}
