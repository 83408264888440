.asidePlanContainer {
    h3 {
        font-size: 12px;
        line-height: 24px;
        font-style: normal;
        font-family: 'SF-Pro-Display-Medium';
        letter-spacing: 0.78px;
        text-transform: uppercase;
        display: block;

        @media only screen and (max-width: 1180px) {
            display: none;
        }
    }

    &__details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        justify-content: space-between;

        @media only screen and (max-width: 1180px) {
            display: none;
        }

        div {
            h6 {
                color: rgba(1, 26, 60, 0.85);
                font-size: 12px;
                line-height: 20px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Medium';
            }
            p {
                color: #011a3c;
                font-size: 13px;
                line-height: 25px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Semibold';
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 40px;

        @media only screen and (max-width: 700px) {
            gap: 10px;
            margin-top: 20px;
        }
    }

    &__checkReport {
        margin-top: 50px;

        @media only screen and (max-width: 500px) {
            margin-top: 0;
        }

        h3 {
            color: #001837;
            font-size: 12px;
            line-height: 24px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Medium';
            letter-spacing: 0.78px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        &__wrapper {
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
        }

        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 7px 12px;

            border-radius: 4px;
            border: 1px solid #e2eeff;
            background: #f9fcff;
            color: #002c68;

            p {
                font-size: 12px;
                line-height: 20px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Medium';
            }
        }
    }
}

.paymentPlanDetails__collapse {
    width: 100% !important;
    color: #3d5075;
    font-size: 12px;
    line-height: 24px;
    font-style: normal;
    font-family: 'SF-Pro-Display-Medium';
    letter-spacing: 0.78px;
    text-transform: uppercase;

    svg {
        right: 1px !important;
    }
}

.paymentPlanDetails__panel {
    div {
        padding: 0px !important;
        color: #002c68 !important;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        margin: 8px 0px;

        text-transform: capitalize;
    }
}

.repaymentMethodModal {
    width: 100%;
    padding: 48px 80px;
    display: flex;
    flex-direction: column;

    > h2,
    > p,
    > button {
        align-self: center;
        text-align: center;
    }

    > h2 {
        font: 20px/23.87px 'SF-Pro-Display-Semibold';
        margin-bottom: 9px;
    }

    > p {
        font: 15px/17.9px 'SF-Pro-Display';
        color: #233862b2;
    }

    @media only screen and (max-width: 500px) {
        padding: 48px 20px;

        > h2 {
            font-size: 15px;
        }

        > p {
            font-size: 14px;
        }
    }
}

.disabledLink {
    cursor: not-allowed;
    color: grey;
}
