.ConsumerFooterWrapper {
    background-color: #001837;
    background-image: url('../../assets/images/consumer-footer-img.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 450px;
    height: 100%;
    position: relative;
    width: 100%;
    @media screen and (max-width: 900px) {
        background-image: url('../../assets/images/consumer-footer-img-mobile.svg');
    }
}

.FooterSectionWrapper {
    padding: 50px 125px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 1400px) {
        width: 100%;
        padding: 50px 150px 20px;
    }

    @media only screen and (max-width: 800px) {
        padding: 50px 20px;
    }
}
.FooterHonours {
    padding: 0px 0px 80px 0px;
    @media only screen and (max-width: 800px) {
        img {
            padding: 0px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.FooterTopSectionWrapper {
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin: 10px auto 0px;
    }

    a,
    p {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #f9fcff;
        margin-bottom: 8px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }
    }

    .FooterNewsletterTextStyle {
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        width: 90%;
        text-transform: uppercase;
        font-family: 'SF-Pro-Display-SemiBold';

        @media only screen and (max-width: 500px) {
            width: 100%;
            font-family: 'SF-Pro-Display';
        }
    }

    @media only screen and (max-width: 900px) {
        flex-direction: column;
    }
}

.InputWithIconStyle {
    position: relative;
    display: inline-block;
}

.InputWithIconStyle .SendIconStyle {
    position: absolute;
    top: 58%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
}
.SendIconStyle {
    .verifiedIcon {
        width: 36px;
        height: 36px;
        margin-right: 10px;
    }
}
.ConsumerFooterInput {
    padding: 0px 18px 0px 22px;
    width: 275px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    color: #000000;

    @media only screen and (min-width: 1500px) {
        width: 330px;
    }
    @media only screen and (max-width: 500px) {
        width: 85vw;
    }
}

.ConsumerFooterInput.errText {
    font-family: 'SF-Pro-Display';
    color: #da1e28 !important;
}

.SocialIconsWrapper {
    margin-top: 60px;
    @media only screen and (max-width: 500px) {
        hr {
            width: 234px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media screen and (min-width: 1400px) {
        width: 93%;
        margin: 60px 54px auto;
    }
    hr {
        border: none;
        border-top: 0.3px solid #f4f4f4;
        background: none;
    }
}

.SocialIconsStyle {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    @media only screen and (max-width: 500px) {
        justify-content: center;
        gap: 20px;
    }
}

.FooterAddressStyle {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #f9fcff;
    }

    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.FooterLogoAndNewsletter {
    width: 370px;
    margin-top: -15px;

    .FooterNewsletterTextStyle {
        text-transform: none;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 21px;

        @media only screen and (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
            font-family: 'SF-Pro-Display-Light';
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        margin-top: 0px;
    }
}

.FooterPageLinks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;

    @media only screen and (max-width: 1200px) {
        margin-top: 60px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 68px 6px;
    }
    @media only screen and (max-width: 768px) {
        grid-gap: 35px 6px;
        column-gap: 20px;
    }
    @media only screen and (max-width: 375px) {
        margin-top: 60px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 48px 6px;
    }
}

.FooterCopyrightTextMobile {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: 38px 0 20px 0;
    text-align: center;
    @media only screen and (min-width: 501px) {
        display: none;
    }
}

.errMessage {
    font-size: 12px;
    font-family: 'SF-Pro-Display';
    color: #da1e28;
}

.whiteText {
    font-size: 12px;
    font-family: 'SF-Pro-Display';
    color: #ffffff;
}
