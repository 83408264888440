@mixin flex-row {
  display: flex;
  flex-direction: row;
}


.AGAPWrapper {
  font-family: 'SF-Pro-Display';
  color: #011a3c;

  @media screen and (min-width: 500px) {
    margin: 0 32px 0 32px;
  }

  @media screen and (min-width: 769px) {
    margin-left: 64px;
  }

  @media screen and (min-width: 1150px) {
    margin: -24px 0 0 64px;
  }

  @media screen and (min-width: 1151px) {
    margin: -24px 0 0 115px;
  }
}

.AGAPNavWrapper {
  margin-bottom: 73px;

  @media only screen and (max-width: 500px) {
    margin-bottom: 50px;
  }
}

.AGAPContentWrapper {
  @include flex-row;
  gap: 64px;
  align-items: flex-start;

  @media only screen and (max-width: 1150px) {
    flex-direction: column;
    gap: 0;
  }
}

.AGAPLeftSection {
  width: 32vw;

  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 15px;
    color: #011A3C;
    margin-bottom: 22px;
  }

  h2 {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    color: #011A3C;
    margin-bottom: 34px;
  }

  @media only screen and (max-width: 1150px) {
    width: 100%;

    h2 {
      margin-bottom: 21px;
    }
  }
}

.AGAPCountMobile {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  color: #011A3C;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 39px;

  @media only screen and (min-width: 1151px) {
    display: none;
  }
}

.AGAPRightSection {
  @media only screen and (max-width: 1150px) {
    display: none;
  }
}

.CreateAdminGroupWrapper {
  display: flex;
  flex-direction: column;
}

.AGAPInfoWrapper {
  background-color: #DAE8FE;
  border-radius: 15px;
  @include flex-row;
  width: 384px;
  height: 110px;

  p {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    margin: 29px 0 0 15.67px;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.AGAPCount {
  width: 384px;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  height: 63px;
  border-bottom: 0.5px solid #E5E5E5;

  @media screen and (max-width: 500px) {
    display: none;
  }

  p {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #011A3C;
  }
}

.AGAPCount-Mobile {
  width: 358px;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  margin-top: -20px;
  margin-bottom: 45px;

  @media screen and (min-width: 500px) {
    display: none;
  }

  p {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #011A3C;
  }
}

//AddAdminGroupPermissions
.AGAPAddPermissionsWrapper {
  display: flex;
  flex-direction: column;
}

.AGAPMenuAndRemoveWrapper {
  width: 407px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1600px) {
    width: 450px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
}

.ant-dropdown-menu {
  width: fit-content;
  background: #FFFFFF;
  border-radius: 15px;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-popup.ant-dropdown-menu.ant-dropdown-menu-light.AGAPSubMenu {
  background: transparent;

  //  second step
  .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-vertical {
    margin: 12px 12px 0 12px;
    min-width: 180px;

    &:last-of-type {
      margin-bottom: 12px;
    }
  }
}

.AGAPCloseTextButton {
  align-self: flex-end;
  cursor: pointer;
  @include flex-row;
  align-items: center;
  gap: 4.29px;
  margin-top: 8px;
  width: 70px;

  p {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 11px;
    color: #004AAD;
  }
}

.AGAPSelectedWrapper {
  margin-top: 40px;
  width: 430px;
  max-height: 325px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (min-width: 1600px) {
    width: 500px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
}

.AGAPSelectedItem {
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 63px;
  min-width: 462px;
  width: 100%;
  border-bottom: 0.5px solid #E5E5E5;

  p {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    color: #011A3CCC;

    span:first-of-type {
      font-family: 'SF-Pro-Display-Medium';
      color: #011A3C;
    }
  }

  @media screen and (max-width: 500px) {
    padding-left: 10px;
    min-width: auto;
  }
}

.AGAPButtonsWrapper {
  @include flex-row;
  align-items: center;
  gap: 15px;
  margin: 59px auto 30px auto;

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
  }
}

//menu component
.AGAPDropdownWrapper {
  width: 407px;
  height: 60px;
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  @include flex-row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 27.33px 0 30px;

  p {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    color: #233862C7;
  }

  @media only screen and (min-width: 1600px) {
    width: 450px;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
  }

}

.ant-dropdown.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    margin-top: 0 !important;
  }
}

.AGAPSubMenu {
  .ant-menu.ant-menu-sub.ant-menu-vertical {
    padding-top: 25px;
    min-width: 198px;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
  }

  .ant-menu-title-content {
    font-family: 'SF-Pro-Display';
    color: #233862;
  }



  //third level dropdown
  .ant-menu-item {
    font-family: 'SF-Pro-Display';
    height: 50px;
    margin-top: -32px;

    .AGAPCheckboxes {
      @include flex-row;
      align-items: center;
      gap: 22.83px;
      font-family: 'SF-Pro-Display';
      font-size: 14px;
      color: #233862;
    }

  }
}

.AGAPSelectAllSubChild {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  color: #004AAD;
  cursor: pointer;
  margin-left: -40px;
  margin-bottom: -16px;
}

.AGAPLastChildWrapper {
  @include flex-row;
  align-items: center;
  gap: 20px;
  margin-left: -40px;
}

.AGAPSelectAllButton {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  color: #004AAD;
  margin: 7px 0 0 14px;
  cursor: pointer;
}

.AGAPMenuItemSpan {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  color: #233862;
  display: flex;
  align-items: center;
  gap: 22px;

  img {
    width: 18.33px;
    height: 18.33px;
  }
}

.AGAPSubMenuItem {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &.TopLevel {
    margin-bottom: 6px;
  }

}

.AGAPCreateGroupButton {
  margin-top: 50px;

  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: center;
    margin-top: 150px;
  }

}

.ant-dropdown-menu-submenu-popup ul:has(> p) {
  margin-left: 16px;
}