.CheckboxWrapper {
  padding: 1px 17px 12px;
  .CheckboxContent {
    display: flex;
    justify-content: flex-start;
  }
}
.ButtonBox {
  text-align: center;
  margin-top: 50px;
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.CheckboxText {
  margin-left: 10px;
  font-family: "SF-Pro-Display";
  font-size: 14px;
  line-height: 17px;
  margin-top: 3px;
  color: var(--lightblue);
}
.CreateAccount {
  font-family: "SF-Pro-Display";
  text-align: center;
  margin-top: 15px;
  color: rgba(35, 56, 98, 0.8);

  a {
    color: var(--blue);
    font-family: 'SF-Pro-Display-Medium' !important;
  }
}
