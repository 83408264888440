.Container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.ImageUploadDraggerWrapper {
    position: relative;
}

.ImageUploadWrapper {
    .ant-upload-list-item.ant-upload-list-item-uploading.ant-upload-list-item-list-type-text {
        display: none !important;
    }
}

.ImageUploadDragger {
    width: 409px !important;
    padding: 0;
    border: none !important;
    background: #ffffff !important;
    border-radius: 15px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 183px !important;
}

.CloseButton {
    position: absolute;
    top: 9px;
    right: 8px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & svg {
        & path {
            stroke: #070c18;
        }
    }
}
