.NewProfileContainer {
    padding-inline: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (max-width: 768px) {
        padding-inline: 30px;
    }

    @media screen and (max-width: 500px) {
        padding-inline: 5px;
    }
}

.NewProfileTitle {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 16.72px;
    color: var(--primary-blue);
}

.NewProfileContentWrapper {
    display: flex;
    gap: 30px;
    justify-content: space-between;

    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
    }
}

.NewProfileDescriptionWrapper {
    >div {
        width: 420px;

        @media screen and (max-width: 1200px) {
            display: flex;
            width: 409px;
            height: fit-content;
            padding-inline: 0;
            border-radius: 15px;
            overflow: hidden;
            margin: 0;
            transform: scale(1);
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding: 10px 0;
        }
    }
}

.NewProfileFormWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.KYCFormWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;

    >div {
        display: flex;
        flex-direction: column;
        gap: 24px;

        >div {
            display: flex;
            flex-direction: column;
            gap: 2px;


            button {
                svg {
                    fill: transparent !important;
                }

                rect {
                    fill: transparent !important;
                    stroke: transparent !important;
                }

            }

            button:hover,
            button:active,
            button:focus-within,
            button:focus {
                rect {
                    fill: transparent !important;
                    stroke: transparent !important;
                }

                path {
                    stroke: #fff !important;
                }
            }
        }
    }


}

.ant-switch-checked {
    background-color: #004aad;
}

.kyc_module_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    >div {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        border-radius: 16px;
        border: 1px solid #e5e5e5;
        background: #FFF;

        >div:first-of-type {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                color: #011A3C;
                font-family: "SF-Pro-Display-Semibold";
                font-size: 14px;
                line-height: 120%;
            }
        }

        >div:last-child {
            h3 {
                color: var(--shades-and-tints-gray-scale-4-d-4-d-4-d, #4D4D4D);
                font-size: 10px;
                letter-spacing: 1.1px;
                text-transform: uppercase;
            }

            >div {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;

                >div {
                    display: flex;
                    height: 36px;
                    padding: 0px 16px;
                    align-items: center;
                    gap: 8px;
                    width: fit-content;
                    border-radius: 21px;
                    border: 0.5px solid var(--shades-and-tints-gray-scale-e-5-e-5-e-5, #E5E5E5);
                    background: var(--shades-and-tints-gray-scale-fafafa, #FAFAFA);

                    p {
                        color: var(--shades-and-tints-gray-scale-070-c-18, #070C18);
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

.kyc__module_modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0 41px 0;

    >div:first-of-type {
        h2 {
            color: #011A3C;
            text-align: center;
            font-family: "SF-Pro-Display-Semibold";
            font-size: 20px;
            margin-bottom: 4px;
        }

        h3 {
            color: rgba(35, 56, 98, 0.70);
            text-align: center;
            font-size: 15px;
        }
    }

    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 41px 0;
        max-width: 464px;
        width: 100%;


        h5 {
            color: #011A3C;
            font-family: "SF-Pro-Display-Medium";
            font-size: 14px;
            margin-bottom: 2px;
        }

        h6 {
            color: rgba(35, 56, 98, 0.70);
            font-size: 12px;
            margin-bottom: 8px;
        }

        .kyc_modal_list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;

            >p {
                color: #011A3C;
                font-size: 14px;
                line-height: 150%;
            }

            >div {
                display: flex;
                align-items: center;
                gap: 8px;

                >span {
                    color: var(--shades-and-tints-gray-scale-c-4-c-4-c-4, #C4C4C4);
                    font-size: 18px;
                    line-height: 150%;
                }
            }
        }

    }

    >div:last-child {
        display: flex;
        gap: 12px;
    }
}