.Wrapper {
  margin: 20px 0px;
}

.HeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Title {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 22px;
  line-height: 27px;
  color: var(--primary-blue);
  margin-bottom: 5px;
}

.SubTitle {
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
}

.NameFieldWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (min-width: 1900px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.CheckboxLink {
  color: rgba(35, 56, 98, 0.8);
  font-family: 'SF-Pro-Display-Semibold';
  margin: 0 5px;
}

.ButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.LinkWrapper {
  width: 208px;
  height: 26px;
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  margin: 0px auto;
  margin-top: 10px;
}

.CheckBoxWrapper {
  display: flex;
  justify-content: center;
}
.LinkResend {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}
.FormBoxText {
  font-family: 'SF-Pro-Display-Medium';
  color: var(--blue);
  font-size: 15px;
  line-height: 26px;
  width: 450px;
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
}

.PasswordStyle {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 21px;
  color: rgba(35, 56, 98, 0.8);
  width: "100%";
  margin-left: 6px;
  margin-top: -3px;
}

.LoadingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.BtnLoading {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--white);
  box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
    10014px 0 0 0 var(--white);
  animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  50% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px -10px 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px -10px 0 0 var(--white);
  }
  100% {
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
      10014px 0 0 0 var(--white);
  }
}
