@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin reset {
    margin: 0;
    padding: 0;
}

.DetailsPageWrapper {
    margin-left: 56px;

    @media screen and (min-width: 1600px) {
        @include flex-column;
        align-items: center;

        margin-left: 0;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 1300px) {
        margin-left: 56px;
    }

    @media screen and (max-width: 800px) {
        margin-left: 0;
    }
}

.DetailsPageMain {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media screen and (max-width: 1179px) {
        flex-direction: column-reverse;
    }

    @media screen and (min-width: 600px) and (max-width: 800px) {
        padding: 50px;
    }
}

.DetailsPageLeftSection {
    width: 550px;

    @media screen and (min-width: 1600px) {
        width: 800px;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.DetailsPageNameAndStatus {
    @include flex-row;
    align-items: center;
    gap: 17px;

    h2 {
        @include reset;
        font-family: 'SF-Pro-Display-Bold';
        color: #011a3c;
        font-size: 23px;
    }

    .StatusIndicator_Wrapper {
        width: fit-content !important;
    }

    @media screen and (max-width: 500px) {
        align-items: flex-start;
        gap: 4px;
    }

    .DetailsPageStatusTag {
        @include reset;
        @include flex-row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4px 6px;
        border-radius: 3px;
        background-color: #e2eeff;

        p {
            @include reset;
            font-family: 'SF-Pro-Display-Semibold';
            color: #004aad;
            text-align: center;
            font-size: 10px;
            letter-spacing: 0.5px;
        }

        @media screen and (max-width: 500px) {
            margin-top: 6px;
        }
    }

    .DetailsPageStatusSecTag {
        @include reset;
        @include flex-row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 4px 6px;
        border-radius: 3px;
        background: var(--Accent-Yellow-Light, #FFFAEC);

        p {
            @include reset;
            font-family: 'SF-Pro-Display-Semibold';
            color: #E4AA0F;
            text-align: center;
            font-size: 10px;
            letter-spacing: 0.5px;
        }

        @media screen and (max-width: 500px) {
            margin-top: 6px;
        }
    }

    &.Desktop {
        @media screen and (max-width: 1188px) {
            display: none;
        }
    }

    &.Desktop+.APDShareParentWrapper {
        @media screen and (max-width: 1188px) {
            display: none;
        }
    }

    &.Mobile+.APDShareParentWrapper {
        margin-bottom: 33px;

        @media screen and (min-width: 1189px) {
            display: none;
        }
    }

    &.Mobile {
        margin-bottom: 21px;

        h2 {
            max-width: 284px;
            word-break: break-word;
        }

        @media screen and (min-width: 1189px) {
            display: none;
        }
    }

    &.ProviderCustomers__nameAndStatus {
        @media screen and (max-width: 1188px) {
            flex-direction: column-reverse;
            gap: 8px;
        }
    }
}

.DetailsPageInfoSubheadAndButton {
    margin-top: 40px;
    @include flex-row;
    align-items: center;
    justify-content: space-between;

    &.Desktop {
        @media screen and (max-width: 1188px) {
            display: none;
        }
    }

    &.Mobile {
        margin-top: 12px;
        width: 95%;

        @media screen and (min-width: 1189px) {
            display: none;
        }
    }
}

.DetailsPageInfoButton {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    color: #004aad;
    cursor: pointer;
}

.DetailsPageInfoSubhead {
    @include reset;
    font-family: 'SF-Pro-Display-Semibold';
    color: #011a3cd9;
    font-size: 12px;
}

.DetailsPageInfoParagraphWrapper {
    &.Desktop {
        @media screen and (max-width: 1188px) {
            display: none;
        }
    }

    &.Mobile {
        margin-right: 24px;

        @media screen and (min-width: 1189px) {
            display: none;
        }
    }
}

.DetailsPageInfoParagraph {
    @include reset;
    font-family: 'SF-Pro-Display';
    max-width: 544px;
    font-size: 15px;
    line-height: 25px;
    color: #011a3cbf;
    margin: 15px 0 36px 0;
    word-break: break-all;
}

.DetailsPageDivider {
    width: 1px;
    height: 80vh;
    border-left: 0.5px solid #e5e5e5;
    margin: -30px 32px 0 41px;

    @media only screen and (max-width: 1179px) {
        display: none;
    }
}

.DetailsPageRightSection {
    @media only screen and (max-width: 500px) {
        width: 100%;
        overflow-y: hidden;
    }
}

.DetailsPageRightSectionHeading {
    @include reset;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 17px;
    color: #011a3c;
    margin-bottom: 48px;

    @media screen and (max-width: 1188px) {
        margin-bottom: 38px;
    }

    &.RemoveBottomHeading {
        display: none;
    }

    &.Heading {
        @media screen and (max-width: 1188px) {
            display: none;
        }
    }

    &.BottomHeading {
        @media screen and (min-width: 1189px) {
            display: none;
        }
    }
}

.DetailsPageRightSectionGrid {
    @include flex-row;
    align-items: flex-start;
    gap: 67px;

    div {
        margin-bottom: 56px;
        width: 150px;

        h4 {
            @include reset;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 10px;
            color: #011a3cd9;
            margin-bottom: 8px;
            word-wrap: break-word;
        }

        p {
            @include reset;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 13px;
            line-height: 25px;
            color: #011a3c;
            word-wrap: break-word;
        }
    }

    @media screen and (min-width: 1100px) and (max-width: 1400px) {
        div {
            width: 120px;
        }
    }
}

.DetailsPageButtonsWrapper {
    width: 100%;
    @include flex-row;
    align-items: center;
    gap: 8px;
}

.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px !important;
}



.APDShareWrapper {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--Accent-Blue-Primary, #004aad);
    text-align: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    padding: 0;
}