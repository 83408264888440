.Wrapper {
  margin-bottom: 35px;

  @media screen and (max-width: 500px) {
    margin-bottom: 50px;
  }
}

.Title {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 18px;
  color: var(--primary-blue);
}

.Subtitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  line-height: 44px;
  color: var(--primary-blue);
}

.RightWrapper {
  width: 390px;
  height: 110px;
  background: #dae8fe;
  border-radius: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  transform: scale(0.9);

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.Header {
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 25px;
  color: #233862;
  width: 300px;
  margin-bottom: 0px;
}

.InfoIcon {
  width: 20px;
  margin: 0px 16px;
  margin-top: -20px;
}
