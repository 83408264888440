.GettingStartedWrapper {
    margin: 50px 60px 0 60px;
    min-height: 324px;
    overflow: hidden;

    &.UseType {
        margin-top: 38px;
        max-height: 600px;
        margin: 32px 42px 0 42px;
    }

    @media only screen and (max-width: 500px) {
        &.UseType {
            max-height: 500px;
            margin: 48px 20px;
        }

        & {
            margin: 36px 24px;
            min-height: 0;
            overflow: auto;
            overflow-y: auto;
        }
    }
}

.GettingStartedHeadingAndText {
    h3 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 21px;
        color: #011a3c;
        margin-bottom: 3px;
    }

    > p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 21px;
        color: #4d4d4d;
        margin-bottom: 14px;
        font-family: var(--font-light);

        span {
            font-family: 'SF-Pro-Display-Medium';
        }
    }

    &:first-child {
        margin-bottom: 33px;
    }

    &.noBottomMargin {
        margin-bottom: 0;
    }

    &.UseTypeContact {
        margin-bottom: 42px;
    }

    @media only screen and (max-width: 500px) {
        &.UseTypeContact {
            margin-bottom: 0;
        }
    }
}

.GettingStartedOTPGroup {
    display: flex;
    align-items: center;
    gap: 12px;

    input {
        width: 47px;
        height: 47px;
        border: 0.8px solid #e5e5e5;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        color: #000000;
        text-align: center;

        &:focus {
            border-color: #004aad;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        appearance: none;
        -moz-appearance: textfield;
    }

    @media only screen and (max-width: 500px) {
        input {
            width: 43px;
        }
    }
}

.GettingStartedResendOTPText {
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 14px;
    margin-top: 18px;

    span {
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 14px;
        color: #004aad;
        cursor: pointer;

        &:hover {
            color: #013d8c;
        }
    }
}

.GettingStartedResentMsg {
    background: #000f23;
    box-shadow: 0px 47.7922px 167.273px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 10px 14px;
    margin-bottom: 12px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        margin: 0 3px 0 12px;
    }
}

.GettingStartedRequiredInfo {
    margin: 50px 0 0 60px;
    padding-right: 60px;
    min-height: 324px;
    overflow: auto;

    @media (max-width: 500px) {
        margin: 36px 0 36px 24px;
        padding-right: 24px;
        min-height: 0;
    }
}

.GettingStartedRequiredInfoList {
    background: #f9fcff;
    border: 1px solid #e2eeff;
    border-radius: 3px;
    margin: 15px 16px 11px 0;
    padding: 12px 17px;

    h3 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        color: #004aad;
        margin-bottom: 9.26px;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        padding: 0;

        li {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 20px;
            color: #233862;
        }
    }
}

.GettingStartedRequiredInfoDisclaimer {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin: 0 16px 16px 0;

    img {
        width: 11px;
        height: 11px;
        margin-top: 2px;
    }

    p {
        font-family: 'SF-Pro-Display';
        margin: 0;
        font-size: 10px;
        line-height: 14px;
        color: #000000;
    }
}

.GettingStartedOTPErrorMsg {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 16px;
    color: #da1e28;
    margin-top: 5px;
}

.GettingStartedEmailSentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 50px 60px 0 60px;
    min-height: 324px;
    overflow: hidden;

    h2 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #011a3c;
        text-align: center;
        margin-top: -54px;
        margin-bottom: 7px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #4d4d4d;
        margin: 0;
        text-align: center;
    }

    &.ForDealApproved {
        h2 {
            margin-top: 22px;
        }
    }

    @media (max-width: 500px) {
        margin: 36px 24px;
        min-height: 0;
    }
}

.GettingStartedEmailSentImg {
    width: 100%;
}

.GettingStartedDealApprovedImg {
    width: 120px;
    height: 118.52px;
    object-fit: cover;
}

.VerifyWithoutOTPStyle {
    border-radius: 12px;
    background: #f9fcff;
    display: flex;
    height: 44px;
    padding: 0px 8px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    p {
        color: #233862;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
    }
}

.GettingStartedUseTypes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 21px;
    position: relative;

    @media only screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        gap: 26px;
    }

    &.RequestNewDealTypes {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.GettingStartedUseType {
    padding: 17px 13px;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    position: relative;
    cursor: pointer;

    &.RequestNewDealType {
        flex-direction: row;
    }

    &:hover {
        background-color: #ebf3ff !important;
    }

    .GettingStartedUseType_Img {
        width: 44.1px;
        height: 42px;
        object-fit: contain;
        flex-shrink: 0;
    }

    > div {
        h4 {
            font: 14px/24px 'SF-Pro-Display-Semibold';
            color: #233862;
            margin-bottom: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
            margin-bottom: 0;
        }
    }

    .GettingStartedUseType_CheckedIcon {
        width: 16px;
        height: 16px;
        object-fit: cover;
        position: absolute;
        right: 14px;
    }

    @media only screen and (max-width: 500px) {
        padding: 14px 8px 15px 19px;
        flex-direction: row;
        align-items: center;
        gap: 19px;

        .GettingStartedUseType_Img {
            width: 66px;
            height: 66px;
        }

        > div {
            h4 {
                max-width: 156px;
                line-height: 17.5px;
                margin-bottom: 6px;
            }

            p {
                line-height: 16px;
                color: #233862b2;
            }
        }

        .GettingStartedUseType_CheckedIcon {
            top: 10px;
            right: 10px;
        }
    }
}

.GettingStartedUseTypes_Err {
    position: absolute;
    font: 12px/16px 'SF-Pro-Display';
    color: #da1e28;
    bottom: -20px;
}

.RequestNewDealType_CheckIcon {
    width: 18.44px;
    height: 18.44px;
    flex-shrink: 0;
}

.ChangePaymentModel {
    min-height: 314px;
    margin: 50px 60px 0 60px;
    & .Heading {
        font-size: 12px;
        line-height: 18px;
        color: #011a3c;
        font-family: 'SF-Pro-Display-Medium';
    }
    & .SubHeading {
        color: #4d4d4d;
        font-size: 12px;
        line-height: 18px;
        margin-top: 8px;
    }

    & .GettingStartedRequiredInfoList {
        h3 {
            margin-bottom: 9px;
        }
        ul {
            gap: 0;

            li {
                line-height: 18px;
            }
        }
    }
    .PaymentModel {
        text-transform: lowercase;
    }

    @media only screen and (max-width: 500px) {
        margin: 36px 24px;
        min-height: 0;
        overflow: auto;
        overflow-y: auto;
    }
}

.SystemSelected {
    margin-top: 0px;
}

.DisputePackageModal {
    margin: 24px 42px !important;
}
