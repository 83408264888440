.ASMWrapper {
    padding: 20px 75px 0;
    height: 366px;
    overflow-y: auto;
    @media screen and (max-width: 800px) {
        padding: 10px 25px 0;
    }

    @media screen and (max-width: 500px) {
        padding: 10px 20px 0;
    }
}

.ASMLabel {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #61708e;
    margin-top: 16px;

    @media screen and (max-width: 600px) {
        margin-top: 30px;
    }
}

.ASMEnergyCapacity {
    font-family: 'SF-Pro-Display-Thin';
    color: #011a3c;
    margin-left: 5px;
}

.ASMTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
}

.ASMSubTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    margin-top: 40px;
}

.ASMParagraph {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 25px;
    color: rgba(1, 26, 60, 0.75);
    margin: 25px 0;
    text-align: justify;
}

.ASMComponentsTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin: 45px 0 15px 0;
}

table {
    margin-top: 0;
}

td {
    padding: 0;
}

.ASMSelectedComponentsWrapper {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
}
.ASMComponentsRow {
    display: grid;
    grid-template-columns: 40% 25% 30% 5%;
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px 0;
}

.ASMSpaceBetween {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e5e5e5;
    padding: 15px 0;
}

.ASMComponentName {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.ASMComponentType {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}

.ASMComponentAmount {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}

.ASMComponentQuantity {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    color: #011a3c;
}

.ASMSubtotal {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.ASMMargin {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.ASMTotal {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.ASMButtonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
}

.ASMButton {
    display: flex;
    background: #004aad;
    border-radius: 30px;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    border: none;
    padding: 20px 45px;
    cursor: pointer;

    &:hover {
        background: #003781;
    }
}

.ModalWrapper {
    @media only screen and (max-width: 500px) {
        margin-top: 40px;
    }
}

.MainWrapper {
    font-family: 'SF-Pro-Display';
    padding: 30px 0;

    @media screen and (max-width: 600px) {
        padding: 43px 20px;
    }
}
.DepositTitle {
    font-style: normal;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
}

.ASMPaymentPlanTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin: 20px 0;
    margin-left: 60px;

    @media screen and (max-width: 600px) {
        margin-left: 5px;
    }
}

.PaymentPlanBox {
    width: 445px;
    height: 92px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }
}
.PaymentPlanWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}
.ContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 23px;
}
.ButtonWrapper {
    display: flex;
    justify-content: center;

    @media screen and (max-width: 600px) {
        margin-top: 24px;
    }
}
.ASMDepositAmount {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #011a3c;
    margin-bottom: 38px;

    @media screen and (max-width: 600px) {
        margin-bottom: 60px;
    }
}
.Plan {
    font-size: 13px;
    line-height: 16px;
    color: #011a3c;
}
.FigureWrapper {
    font-size: 12px;
    line-height: 14px;
    color: rgba(1, 26, 60, 0.72);
}
.ASMFigure {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
}
.MonthTitle {
    font-size: 10px;
    line-height: 16px;
    color: #011a3c;
}
.ASMMonth {
    font-family: 'SF-Pro-Display-Semibold';
    line-height: 25px;
    text-align: right;
    color: #011a3c;
}
.Selected {
    background: #e2eeff;
    border-radius: 3px;
    margin-left: 6px;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.055em;
    color: #004aad;
    padding: 6px 10px;
    position: relative;
    top: -5px;
}
.ASMPaymentPlanScrollableHeight {
    height: 340px;
    overflow-y: auto;
    margin-bottom: 30px;
    padding: 0 30px;

    @media screen and (max-width: 500px) {
        height: 360px;
    }
}
.ASMPlanSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 34px 0 24px;
    width: 445px;
    height: 92px;
    left: 89px;
    top: 194px;
    background: #011a3c;
    border: 1px solid #e5e5e5;
    border-radius: 12px;

    h2 {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.055em;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.85);
    }

    p {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 13px;
        line-height: 25px;
        color: #ffffff;
    }
}
