.wrapper {
    height: 80vh;
    width: 100%;
    overflow-y: auto !important;
}

.content {
    margin-right: 80px;

    > .btn {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 13px;
    }

    @media screen and (max-width: 500px) {
        margin-right: 20px;

        > .btn {
            flex-direction: column;
            align-items: center;
        }
    }
}
