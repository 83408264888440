.wrapper {
    margin-bottom: 74.5px;

    @media only screen and (max-width: 500px) {
        padding: 0 10px;
    }

    > h2 {
        color: var(--shades-and-tints-primary-001837, #001837);
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        font-style: normal;
        line-height: 35px;
        text-align: center;
        margin-bottom: 24px;

        @media only screen and (max-width: 500px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    .center {
        gap: 50px;
    }

    > div:nth-child(2) {
        height: auto;
    }

    > div:nth-child(2) > div > div {
        margin: auto;
        width: max-content !important;
    }

    > div:nth-child(2) > div > div > div {
        width: auto;
    }

    .card {
        display: grid !important;
        grid-template-rows: auto 1fr auto;
        padding: 30px 35px;
        min-height: 350px;
        width: 100% !important;
        max-width: 370px;
        min-width: 330px;
        margin: 0 8px;
        border-radius: 12px;
        border: 1px solid var(--shades-and-tints-gray-scale-fbc-948, #f1f1f1);
        background: var(--shades-and-tints-gray-scale-fafafa, #fafafa);

        @media only screen and (max-width: 500px) {
            padding: 30px 20px;
            margin: 0;
            height: 250px;
            width: 94% !important;
            margin: 0 auto;
        }

        > span {
            display: flex;
            height: 23px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            background: #e2eeff;
            width: fit-content;
            // typography
            color: var(--accent-blue-primary, #004aad);
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 12px;
            font-style: normal;
            line-height: 20px;
            letter-spacing: 1.02px;
            text-transform: uppercase;
            margin-bottom: 8px;
        }

        > p {
            color: var(--shades-and-tints-primary-233862, #233862);
            font-family: 'SF-Pro-Display';
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin-bottom: 24px;
            opacity: 1 !important;
            transform: scale(1) !important;
        }

        > div {
            display: flex;
            column-gap: 24px;
            align-items: center;

            > img {
                width: 46px;
                height: 46px;
                flex-shrink: 0;
                border-radius: 46px;
                background: lightgray 50% / cover no-repeat;
            }

            > div {
                flex-direction: column;

                h3 {
                    color: var(--shades-and-tints-primary-001837, #001837);
                    font-family: 'SF-Pro-Display';
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                    margin-bottom: 0;
                }

                p {
                    color: var(--shades-and-tints-primary-233862, #233862);
                    font-family: 'SF-Pro-Display';
                    font-size: 12px;
                    font-style: normal;
                    line-height: 20px;
                    opacity: 1 !important;
                    transform: scale(1) !important;
                }

                a {
                    color: #0458c9;
                    font-size: 12px;
                }

                a:hover {
                    color: #1890ff;
                }
            }
        }
    }
}
