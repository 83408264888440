.IdentityWrapper {
  height: 450px;
  width: 545px;

  @media screen and (max-width: 600px) {
    height: 100%;
    width: 100%
  }
}

.pictureWrapper {
  margin: 0px 5%;

  @media screen and (max-width: 600px) {
    margin: 0;
  }

  .ErrorText {
    margin-bottom: 10px;
  }
}

.Passport {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  line-height: 17px;
  color: var(--primary-blue);
}

.ProfileWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.ProfileImg {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 22px;
}


.SelfieWrapper {
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  line-height: 16px;
  color: rgba(35, 56, 98, 0.54);
  margin-left: 13px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Selfie {
  text-align: center;
  font-family: 'SF-Pro-Display-Medium';
  color: var(--blue);
  margin-left: 9px;
  cursor: pointer;
}

.Identification {
  margin-top: 20px;
}

.UploadLogo {
  margin: 20px auto 0px;
}

.DraggerArea {
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 15px !important;
  cursor: inherit !important;
  height: 154px !important;
}

.SlideContainer {
  width: 161px;
  height: 4px;
  background: #e2eeff;
  margin: 0px auto;
}

.Slider {
  height: 5px;
  width: 50%;
  background: var(--blue);
  border-radius: 5px;
}

.UploadText {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 17px;
  color: rgba(35, 56, 98, 0.78);
}

.Upload {
  margin-top: 18px !important;
}

.Browse {
  color: var(--blue);
  font-family: 'SF-Pro-Display-Semibold';
  cursor: pointer;
  margin-left: 2px;
  line-height: 45px;
}

p.ant-upload-drag-icon {
  margin-bottom: 0px !important;
}

.ant-upload-list {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 17px;
  color: rgba(35, 56, 98, 0.78);
}

.ant-upload-list-item {
  margin-top: -72px;
  text-align: center;
}

.ant-upload-text-icon {
  display: none;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 0;
}

.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  padding: 0px;
  margin-left: 16px;
}

.OIDSupportedFormatText {
  color: #233862B2;
  font-family: 'SF-Pro-Display';
  font-size: 13px;
}