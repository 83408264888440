.Content {
    background: #ffffff;
    padding: 8px;
    height: 42px;
    border-radius: 37px;
    color: var(--blue);
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
    cursor: pointer;

    span {
        font-family: 'SF-Pro-Display-Medium';
        display: flex;
        align-items: center;
        height: 26px;
        padding: 4px 10px;
        gap: 10px;
        border-radius: 28px;
        background-color: #ffecbc;
        font-size: 12px;
        line-height: 18px;
        color: #4c3a0a;
        margin: 0;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 21px;
        margin: 0;
    }

    img {
        display: block;
    }

    @media screen and (max-width: 500px) {
        gap: 4px;
        padding: 4px 8px 4px 4px;
        height: 34px;

        img {
            display: none;
        }
    }
}

.ModalContent {
    width: 475px;
    height: 391px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    gap: 20px;

    img {
        width: 100%;
    }

    h2 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #011a3c;
        margin: 0;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #4d4d4d;
        max-width: 345px;
        margin: 0;

        span {
            font-family: 'SF-Pro-Display-Medium';
        }
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        height: calc(80vh - 120px);
    }
}
