.Container {
    width: 100%;
    border-radius: 6px;
    border: 0.3px solid #c4c4c4;
    display: flex;
    flex-direction: column;
    padding-inline: 19px;

    & > .TopSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: 0.3px solid rgba(35, 56, 98, 0.6);
        padding-block: 32px;

        & > .TopSectionTitleWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 14px;
                text-align: left;
                color: #070c18;
            }

            & > .TopRightSection {
                display: flex;
                align-items: center;
                gap: 4px;

                & > .Badge {
                    min-width: 19px;
                    height: 19px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'SF-Pro-Display';
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #fff;
                    background-color: #004aad;
                }

                & > p {
                    font-family: 'SF-Pro-Display';
                    font-size: 10px;
                    line-height: 11.93px;
                    text-align: right;
                    color: #233862c7;
                    opacity: 78%;
                }

                & > .IconBox {
                    cursor: pointer;
                    margin-left: 10px;
                }
            }
        }

        & > .TopSectionSummaryVisible {
            opacity: 1;
            visibility: visible;
            height: 50px;
            margin-top: 22px;
            transition: ease 0.5s;
        }

        & > .TopSectionSummaryHidden {
            opacity: 0;
            visibility: hidden;
            height: 0;
            transition: ease 0.5s;
        }
    }

    & .BottomSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 19px;
    }
}
