.GetStartedRowDiv {
    margin-bottom: 70px;
    color: var(--white);

    @media screen and (max-width: 780px) {
        margin-left: 0%;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 140px;
    }
}

.GetStartedRowChild {
    padding-top: 50px;

    @media screen and (max-width: 994px) {
        justify-content: center;
        margin-bottom: 50px;
    }

    @media (max-width: 676px) {
        margin-left: 10px;
    }

    @media screen and (max-width: 500px) {
        padding-top: 30px;
        display: flex;
    }

    &.HomeGetStartedRowChild {
        @media screen and (max-width: 500px) {
            padding-top: 0;
        }

        @media screen and (min-height: 679px) and (max-height: 750px) {
            padding-top: 0;
        }
    }
}

.GetStartedWrapper {
    max-width: 47%;
    padding: 70px 0 200px calc(110px + 1%);

    @media (max-width: 992px) {
        margin-bottom: 30px;
        padding: 0;
        padding-left: 10px;
    }

    @media screen and (min-width: 992px) and (max-width: 998px) {
        padding: 0 10px 40px 10px;
    }

    @media screen and (min-width: 998px) and (max-width: 1190px) {
        padding: 0 10px 50px 30px;
    }

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        max-width: 45%;
        padding: 70px calc(100px + 5%) 300px calc(100px + 5%);
    }

    @media screen and (min-width: 1922px) {
        padding: 100px calc(200px + 3%) 300px calc(200px + 3%);
    }

    @media screen and (min-width: 2700px) {
        font-size: calc(30px + 5px);
        width: 70%;
        line-height: calc(106px + 20px);
    }

    @media (max-width: 990px) {
        max-width: 75%;
    }

    @media (max-width: 675px) {
        max-width: 90%;
    }

    &.HomePage {
        position: relative;
        padding-top: 220px;

        @media screen and (max-width: 500px) {
            overflow: hidden;

            .SecondParagraphHome,
            .ThirdParagraph {
                margin-bottom: 12px;
            }

            .SecondParagraphHome {
                font-size: 40px;
                line-height: 44px;
            }

            .ThirdParagraph {
                width: 100% !important;
            }
        }
    }
}

.CarouselWrapper {
    position: absolute;
    top: 0;
}

.GetStartedWrapper2 {
    margin-top: 40px;
    width: calc(100% + 1%);
    padding: 0 5px 0 50px;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        max-width: 45%;
        // padding: 0 calc(100px + 5%);
    }

    @media screen and (min-width: 1922px) {
        // padding: 0 calc(200px + 3%);
    }

    @media screen and (min-width: 2700px) {
        font-size: calc(30px + 5px);
        width: 70%;
        line-height: calc(106px + 20px);
    }
}

.GetStartedSpan {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.07em;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        font-size: calc(8px + 5px);
    }

    @media screen and (min-width: 1922px) {
        line-height: calc(23px + 20px);
        font-size: calc(15px + 15%);
    }

    @media screen and (min-width: 2700px) {
        width: 70%;
        line-height: calc(106px + 20px);
        font-size: calc(25px + 15%);
    }
}

.HRule {
    width: 60px;
    height: 2px;
    background-color: var(--yellow);
    margin: 10px;
    border: 0px;
}

.FirstParagraph {
    display: flex;
    color: var(--yellow);
    align-items: center;
}

.SecondParagraph {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 48px;
    line-height: 66px;
    width: 465px;
    margin-bottom: 25px;
    margin-top: 15px;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        width: calc(100% + 30%);
        font-size: calc(65px + 15%);
        line-height: calc(66px + 5px);
    }

    @media screen and (min-width: 1922px) {
        width: calc(100% + 30%);
        line-height: calc(66px + 20px);
        font-size: calc(68px + 15%);
    }

    @media screen and (min-width: 2700px) {
        font-size: calc(65px + 5px);
        width: 70%;
        line-height: calc(106px + 20px);
    }
}

.SecondParagraphHome {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 56px;
    line-height: 60px;
    width: 481px;
    margin-bottom: 20px;
    margin-top: 5px;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        width: calc(100% + 50%);
        font-size: calc(65px + 15%);
        line-height: calc(66px + 5px);
    }

    @media screen and (min-width: 1922px) {
        width: calc(100% + 30%);
        line-height: calc(66px + 20px);
        font-size: calc(68px + 15%);
    }

    @media screen and (min-width: 2700px) {
        font-size: calc(65px + 5px);
        width: 70%;
        line-height: calc(106px + 20px);
    }

    @media (max-width: 998px) {
        width: 100%;
    }

    @media screen and (min-width: 300px) and (max-width: 400px) {
        font-size: 47px;
        line-height: 50px;
    }

    @media screen and (min-width: 380px) and (max-width: 500px) {
        width: 333px !important;
        font-size: 47px;
        line-height: 50px;
    }
}

.ThirdParagraph {
    color: rgba(255, 255, 255, 0.75);
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    width: 434px;
    margin-bottom: 30px;

    @media screen and (min-width: 3000px) {
        margin-bottom: 10%;
    }

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        width: calc(100% + 30%);
        font-size: calc(15px + 15%);
        line-height: calc(20px + 7px);
        margin-bottom: 30px;
    }

    @media screen and (min-width: 3000px) {
        margin-bottom: 10%;
    }

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        width: calc(100% + 30%);
        font-size: calc(15px + 15%);
        line-height: calc(20px + 7px);
        margin-bottom: 30px;
    }

    @media screen and (min-width: 1922px) and (max-width: 2300px) {
        width: calc(80% + 30%);
        line-height: calc(20px + 20px);
        font-size: calc(20px + 15%);
        margin-bottom: 30%;
    }

    @media screen and (min-width: 2700px) {
        font-size: calc(30px + 5px);
        width: calc(80% + 30%) !important;
        line-height: calc(40px + 20px);
    }

    @media (max-width: 998px) {
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        width: 294px !important;
        height: auto;
    }

    @media screen and (max-width: 1025px) {
        width: 100%;
    }
}

.GetStartedBtns {
    margin-top: 10px;

    @media screen and (min-width: 2700px) {
        padding-bottom: 50px;
    }

    @media screen and (min-width: 1922px) {
        margin-bottom: 40px;
    }

    @media (max-width: 500px) {
        margin-top: 0px;
    }
}

.PartnerCtaBtns {
    display: flex;

    @media screen and (max-width: 380px) {
        flex-direction: column;
    }
}

.ImgDiv {
    margin-top: 100px;

    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: center;
    }
}

.ImgWrapper {
    width: 90%;
    text-align: left;
    // margin-bottom: 40px;
    max-height: 722px;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
        width: 100%;
    }

    @media screen and (min-width: 2700px) {
        width: calc(40% + 20%);
    }

    @media (max-width: 600px) {
        max-width: 100%;
        position: relative;
        top: 69px;
        height: auto;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 6px;
        top: 10px;
        margin-left: -64px;
        max-width: 400px;
    }
}

.MobileImgWrapper {
    @media screen and (max-width: 500px) {
        height: 400px;
        margin-bottom: -140px;
        margin-left: 0px;
    }
}

.ConsumerSignInBtn {
    display: inline;

    @media screen and (min-width: 1680px) {
        margin-left: 7px;
    }
}
