@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ADPWrapper {
    margin-left: 112px;
}
.ADPTitleSectionWrapper {
    font-family: 'SF-Pro-Display';
    @include flex-row;
    align-items: center;
    gap: 11px;
    margin-bottom: 48px;
}

.ADPMainTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011A3C;
    margin: -20px 0px !important;
}

.ADPLeftSection {
    padding-right: 45px;
    border-right: 0.5px solid #E5E5E5;
    width: 58%;

    @media screen and (max-width: 1187px) {
        border-right: none;
        margin-top: 50px;
        width: auto;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.ADPLabel {
    text-transform: uppercase;
    height: 23px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: #004AAD;
    background: #E2EEFF;
    border-radius: 3px;
    @include flex-center;
    // margin: -18px 0px -20px 20px !important;
    padding: 0px 15px;

    @media screen and (max-width: 700px) {
        margin: -2px 0px 0px 10px !important;
    }

    @media screen and (min-width: 700px) and (max-width: 800px) {
        margin: -2px 0px -20px 20px !important;
    }
}

.ADPBusinessInfoWrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 17px;
}

.ADPBusinessInfoTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
}

.ADPBusinessInfoText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    line-height: 25px;
    color: rgba(1, 26, 60, 0.75);
    text-align: justify;
    display: flex;
}

.ADPRightSection {
    width: 38%;
    padding-left: 20px;

    @media screen and (max-width: 1187px) {
        margin-left: 0;
        padding-left: 0;
        width: auto;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.ADPDetailsTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 17px;
    line-height: 20px;
    color: #011A3C;
    margin: 50px 0;

    @media screen and (max-width: 800px) {
        margin: 40px 0;
    }
}

.ADPViewWrapper {
    margin-top: 30px;
    display: flex;

    @media screen and (max-width: 1187px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 0px;
    }
}

.ADPFlexrow {
    display: flex;
    grid-column-gap: 53px;
}

.ADPSubTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    margin-bottom: 6px;

    @media screen and (max-width: 500px) {
        font-family: 'SF-Pro-Display-Medium';
    }
}

.ADPDetails {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 13px;
    line-height: 21px;
    color: #011A3C;
    width: 125px;
    overflow: hidden;

    @media screen and (max-width: 500px) {
        font-family: 'SF-Pro-Display';
    }
}