.Table {
  height: 400px;
  padding-right: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.TableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 69px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 60px 0 26px;

  @media screen and (max-width: 500px) {
    padding: 0 15px 0 15px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  }

  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    text-align: right;

    @media screen and (max-width: 500px) {
      margin-left: 12px;
    }
  }
}