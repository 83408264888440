.wrapper {
    padding: 16px;
    border: 1px solid #e2eeff;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    background-color: #f9fcff;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.title {
    font: 14px/21px var(--font-regular) !important;
    color: #001837 !important;
    margin: 0 !important;
}

.iconDown,
.iconUp {
    transition: transform 0.3s ease;
}

.iconDown {
    transform: rotate(0deg);
}

.iconUp {
    transform: rotate(180deg);
}

.content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    font: 14px/21px var(--font-light);
    margin: 0;
}

.open {
    max-height: fit-content;
    opacity: 1;
}

.closed {
    max-height: 0;
    opacity: 0;
}

hr {
    border: none;
    border-bottom: 0.5px solid #e2eeff;
    margin: 8px 0;
}
