.consumerHeader {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
    background-color: #ffffff;
    display: flex;
    margin-bottom: 101px;
    height: 101px;
    justify-content: space-between;
    padding: 0 56px;
    align-items: center;
    border-bottom: 1px solid #e2eeff;

    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        padding: 32px 20px 29px 20px;
        background: #fff;
        box-shadow: 0px 0px 18.81818389892578px 0px rgba(0, 0, 0, 0.06);
        height: fit-content;
    }
}

.right {
    display: flex;
    align-items: center;
    gap: 19px;
    margin-left: auto;

    > div {
        &:first-child {
            width: 50px;
            height: 50px;
            cursor: pointer;
        }
    }
}

.divider {
    border-right: 0.5px solid #e2eeff;
    height: 29px;
}
