.ActionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 374px;
}

.ActionHeader {
    font: 16px/24px 'SF-Pro-Display';
    color: #011a3c;
    margin-bottom: 7px;
    text-align: center;
}

.ActionText {
    font: 12px/20px 'SF-Pro-Display';
    color: #4d4d4d;
    text-align: center;
    max-width: 294px;
}
