@mixin flex-col {
  display: flex;
  flex-direction: column;
}

.Wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}

.Content {
  margin: 48px 90px;

  @media only screen and (max-width: 500px) {
    margin: 100px 22px;
  }
}

.PlansWrapper {
  @include flex-col;
  gap: 32px;
  height: 450px;
  width: 100%;
  -ms-overflow-y: auto;
  overflow-y: auto;
}

.Main {
  width: 445px;
  height: 193px;
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 24px 32px 32px 32px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    height: 100%;
  }
}

.Left {
  height: 100%;
  @include flex-col;
  justify-content: space-between;
  text-align: left;
}

.Right {
  height: 100%;
  @include flex-col;
  justify-content: space-between;
  text-align: right;
}

.SmallHeading {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 8px;
  color: #011A3CD9;
}

.SmallText {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  color: #011A3C;
}

.TopLeft {
  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 8px;
  }

  h3 {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    color: #011A3C;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (max-width: 500px) {
    max-width: 150px;
  }
}