.Content {
    background: #e2eeff;
    padding: 6px 12px;
    height: 36px;
    border-radius: 37px;
    color: var(--blue);
    width: fit-content;
    display: flex;
    gap: 6px;
    margin-bottom: 30px;

    h4 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        line-height: 16.8px;
        color: var(--blue);
        margin-block: auto;
    }

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.065em;
        margin-block: auto;
    }

    .DesktopTimer {
        display: block;
    }

    .MobileTimer {
        display: none;
    }

    @media screen and (max-width: 500px) {
        h4 {
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 6.5%;
        }

        .DesktopTimer {
            display: none;
        }

        .MobileTimer {
            display: block;
        }
    }
}
