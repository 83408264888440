.Wrapper {
  margin: 50px 80px 0 80px;

  @media only screen and (max-width: 500px) {
    margin: 50px 20px 0 20px;
  }
}

.SmallInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 500px) {
    width: 100%;
    display: block;
  }
}

.EvidenceHeader {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  color: #011A3C;
  margin: 40px 0 10px 0;
}

.Evidence {
  width: 100%;
  height: 154px;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  h6 {
    font-size: 14px;
    color: #233862C7;
    margin: 0 0 8px 0;
  }

  a {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    color: #004AAD;
    margin: 0;
    cursor: pointer;
    text-decoration: underline;
  }

}