.component {
    background: #f9fcff;
    border: 1px solid #e2eeff;
    border-radius: 12px;
    width: 700px;
    margin: 0 auto;
    padding: 48px;
    @media screen and (max-width: 992px) {
        width: 90%;
        padding: 24px;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
        background: none;
        border: none;
    }

    h1 {
        font: 28px/35px 'SF-Pro-Display-Bold';
        color: #233862;
        margin-bottom: 8px;
        text-align: center;

        @media screen and (max-width: 576px) {
            font-size: 14px;
            line-height: 16.8px;
        }
    }

    > p {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 44px;
        text-align: center;

        @media screen and (max-width: 576px) {
            line-height: 21px;
            margin-bottom: 18px;
        }
    }

    .button {
        width: 100%;
        text-align: center;
        margin: 36px auto 24px auto;

        @media screen and (max-width: 576px) {
            margin: 18px;
        }

        & > button {
            @media screen and (max-width: 576px) {
                max-width: 157px;
            }
        }
    }
}

.inputAndBtn {
    width: 474px;
    margin: 0 auto;
    @media screen and (max-width: 992px) {
        width: 100%;
    }
    .spanBtn {
        width: 100%;
        > span {
            font: 12px/20px 'SF-Pro-Display-Semibold';
            color: #013d8c;
            margin-top: -6px;
            display: block;
            width: fit-content;
            text-align: right;
            margin-left: auto;
            cursor: pointer;
        }
    }
}
