table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;

  @media screen and (max-width: 500px) {
    border-collapse: separate;
  }
}

td:first-child {
  border-top-left-radius: 12px;
}

td:first-child {
  border-bottom-left-radius: 12px;
}

td:last-child {
  border-bottom-right-radius: 12px;
}

td:last-child {
  border-top-right-radius: 12px;
}


thead {
  border-bottom: 10px solid #F6F6F6;

  @media screen and (max-width: 500px) {
    border-bottom: none;
  }
}

th,
td {
  text-align: left;
  // padding: 0px 17px !important;
  font-style: normal;
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.8);
}

.TableRow {
  border-radius: 16px;
  background-color: #fff;
  border-bottom: 10px solid #F6F6F6;
  height: 70px;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  }

  @media screen and (max-width: 500px) {
    border-bottom: 10px solid #FFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06)
  }

}

.ProductName {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  line-height: 17px;
  color: #011a3c;
  padding: 0px 17px !important;
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span:nth-child(2) {
    margin-left: 13px;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
    height: 100%;
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
    padding-left: 15px;

  }
}

.ProductNameSpan {
  max-width: 242px;

  @media screen and (max-width: 500px) {
    max-width: 151px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 400px;
  }
}

.ProductContent {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.ProductContent {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;

  @media screen and (min-width: 600px) {
    white-space: nowrap;
  }
}

.LargeTable {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.TableHeader {
  font-family: 'SF-Pro-Display-Light';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.7);

  th {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    color: #011A3CCC;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.NoTableHeader {
    display: none;
}

.MobileWrapper {
  @media screen and (max-width: 500px) {
    margin-top: 30px;
  }
}

.MobileCardWrapper {
  display: none;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 98%;
    margin-left: 2px;
    min-height: 188px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 30px 15px;
    margin-bottom: 15px;
  }

  @media screen and (max-width: 320px) {
    padding: 30px 10px;
  }
}

.MobileHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.MobileHeader {
  width: fit-content;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  line-height: 27px;
  color: #011A3C;
  cursor: pointer;
  padding-top: 7px;

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
}

.DateWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 70%;
}

.AmountWrapper {
  display: flex;
  flex-direction: column;
}

.MobileTitle {
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 14px;
  color: #011A3CCC;

  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
}

.MobileSubtitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 13px;
  line-height: 16px;
  color: #011A3C;
  margin-top: 5px;

  @media screen and (max-width: 375px) {
    font-size: 11px;
  }

  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
}

.MobileTable {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.unitClass {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.MobileTableListing {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.MobileProductName {
  display: flex;
  align-items: flex-start;
  gap: 0;
}

.ComponentFlag {
  margin: -9px 0 0 -4px;
}

.ProductFlag {
  margin: -9px 0 0 -22px;
}