.Container {
    width: 100%;
    height: 100%;
    padding: 45px 0px 25px;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @media only screen and (max-width: 500px) {
        height: 100vh;
    }
}

.ModalHeader {
    width: 100%;
    text-align: center;
    padding-inline: 70px;
    display: flex;
    flex-direction: column;

    & > h3 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 22px;
        line-height: 26.25px;
        text-align: center;
        color: #011a3c;
    }

    & > p {
        font-family: 'SF-Pro-Display';
        font-size: 15px;
        line-height: 26px;
        text-align: center;
        color: #233862cc;
        opacity: 80%;
    }
}

.ModalBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > .ContentBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 19px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-inline: 70px;
    }

    & > .PaginationWrapper {
        width: 100%;
        display: flex;
        padding-inline: 70px;
    }
}

.ModalFooter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    margin-top: auto;
    padding-inline: 70px;
}
