.TabStyle {
    .tabHeadingStyle {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #004aad;
        display: flex;
        align-items: center;

        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &.TabsStyle_WithUseTypeForm {
        .tabHeadingStyle {
            color: #3d5075;
            gap: 6px;

            svg {
                width: 18px;
                height: 18px;
                stroke: #b0b0b0;
            }

            &.tabHeadingStyle_active {
                color: #004aad;

                svg {
                    stroke: #004aad;
                }
            }
        }
    }

    svg {
        margin-right: 5px;
    }

    .ant-tabs-tab {
        padding: 20px 17px !important;

        @media screen and (max-width: 500px) {
            margin-right: 10px;
        }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
        @media screen and (max-width: 500px) {
            justify-content: center;
        }
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        @media screen and (max-width: 500px) {
            border: none;
            border-bottom: 1px solid #e2eeff;
        }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        @media screen and (max-width: 500px) {
            height: 1px !important;
        }
    }

    &.TabsStyle_WithUseTypeForm {
        margin-top: 18px;

        .ant-tabs-tab {
            padding: 8px !important;
        }

        .ant-tabs-nav {
            margin-bottom: 24px !important;
        }

        .ant-tabs-top > .ant-tabs-nav::before {
            border: none !important;
            border-bottom: 0.5px solid #e5e5e5 !important;
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            height: 1px !important;
            background: #004aad !important;
        }

        @media only screen and (max-width: 500px) {
            .ant-tabs-tab {
                margin: 0 !important;
                width: 100% !important;
            }
        }
    }
}

.ant-tooltip-content {
    left: 0 !important;
}

.SharedFormNewInputsWrapper {
    margin-top: 16px;

    > .NewFormInputsClosedClusterText {
        margin-bottom: 12px;
        > h4 {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            color: #011a3c;
        }

        p {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 18px;
            text-align: left;
            color: #4d4d4d;
        }
    }
}
