.wrapper {
    border: 1px solid #e2eeff;
    border-radius: 12px;
    background-color: #f9fcff;
    padding: 16px 17.5px;
}

.systemInfoContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 35px;
    row-gap: 24px;
}

.rowItem {
    > h4 {
        font: 14px/21px var(--font-regular);
        color: #001837;
        margin-bottom: 8px;
    }

    > p {
        font: 14px/16.8px var(--font-bold);
        color: #070c18;
        margin-bottom: 0;
    }
}

.tooltipIcon {
    width: 14px !important;
    height: 14px !important;
    flex-shrink: 0;
}

.aftercareInfo {
    display: flex;
    align-items: center;
    gap: 4px;

    > span {
        font: 14px/24px var(--font-bold);
        color: #070c18;
        margin: 0;
    }
}

.aftercareInfo_Stars {
    display: flex;
    align-items: center;
    gap: 4px;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media only screen and (min-width: 500px) and (max-width: 1200px) {
        justify-content: flex-start;
    }
}

.plansBtn {
    display: flex;
    align-items: center;
    gap: 4px;
    > span {
        font: 14px/16.8px var(--font-bold);
        color: #004aad;
    }

    > svg {
        width: 12px;
        height: 12px;
        stroke: #004aad;
    }
}
