.SpinDiv {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.SpinDiv div {
    font-size: 16px;
    color: var(--blue);
    font-family: 'SF-Pro-Display-Regular';
}

.SpinDiv span {
    color: var(--blue);
}

.LoadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 0;
    margin: 0 -5%;
    overflow: hidden;
}

.BtnLoading {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--white);
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
        10014px 0 0 0 var(--white);
    animation: dotTyping 1s infinite linear;
    -webkit-animation: dotTyping 1s infinite linear;
}

.BtnLoadingDark {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--white);
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
        10014px 0 0 0 var(--white);
    animation: dotTypingDark 1s infinite linear;
    -webkit-animation: dotTypingDark 1s infinite linear;
}

.BtnLoadingPrimary {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--blue);
    color: var(--blue);
    box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
        10014px 0 0 0 var(--blue);
    animation: dotTypingPrimary 1s infinite linear;
    -webkit-animation: dotTypingPrimary 1s infinite linear;
}

.sseSmallerIcon {
    width: 6px;
    height: 6px;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    33.333% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    50% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px -10px 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    66.667% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    83.333% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px -10px 0 0 var(--white);
    }
    100% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
}

@keyframes dotTypingDark {
    0% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue), 10014px 0 0 0 var(--primary-blue);
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue), 10014px 0 0 0 var(--primary-blue);
    }
    33.333% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue), 10014px 0 0 0 var(--primary-blue);
    }
    50% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px -10px 0 0 var(--primary-blue),
            10014px 0 0 0 var(--primary-blue);
    }
    66.667% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue), 10014px 0 0 0 var(--primary-blue);
    }
    83.333% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue),
            10014px -10px 0 0 var(--primary-blue);
    }
    100% {
        box-shadow: 9984px 0 0 0 var(--primary-blue),
            9999px 0 0 0 var(--primary-blue), 10014px 0 0 0 var(--primary-blue);
    }
}

@keyframes dotTypingPrimary {
    0% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
    33.333% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
    50% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px -10px 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
    66.667% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
    83.333% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px -10px 0 0 var(--blue);
    }
    100% {
        box-shadow: 9984px 0 0 0 var(--blue), 9999px 0 0 0 var(--blue),
            10014px 0 0 0 var(--blue);
    }
}
