.PageNavWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    >div:first-child {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            object-fit: cover;
        }

        span {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            line-height: 17px;
            color: #004aad;
        }
    }

    >div:first-child:hover {
        background: #f9fcff;
        border-radius: 30px;
    }

    >div:last-child {
        width: fit-content;
    }

    @media only screen and (max-width: 800px) {
        margin-bottom: 10px;
    }
}

.switchtext {
    display: none;

    @media (min-width: 768px) {
        display: inline-block;
        font: 14px/24px var(--font-regular);
        color: #001837;
        text-align: center;
        margin-right: 10px;
    }
}