.toast_container {
    border-radius: 12px;
    width: 320px;
    padding: 9px 16px 9px 16px;
    display: flex;
    align-items: center;
    background-color: #000f23;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideInLeft 0.3s ease-in-out; 

    @media screen and (max-width:400px) {
        width:100%;
    }
    p {
        color: #ffffff;
    }
    .close_section {
        cursor: pointer;
    }

}
.toast_container.exit{
    animation: slideOutRight 0.3s ease-in-out; 
    opacity: 0;
}
.warning_container.exit{
     animation: slideOutRight 0.3s ease-in-out; 
    opacity: 0;
}
@keyframes slideInLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
.message_section {
    display: flex;
    align-items: center;
    gap: 8px;
}
.success_container {
    background-color: #000f23;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.warning_container {
    border-radius: 12px;
    width: 320px;
    padding: 9px 16px 9px 16px;
    background-color: #fffbf2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slideInUp 0.3s ease-in-out; /* Your custom animation */

    p {
        color: #ffc222;
    }
}
