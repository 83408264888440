.HeaderContainer {
    background-color: var(--blue-4);
}

.TOSModalHeaderContainer {
    background-color: white;
    font-family: 'SF-Pro-Display';
    padding: 50px 0px 20px;
    border-bottom: 1px solid rgba(229, 229, 229, 0.7);
    text-align: center;
}

.HeaderSubText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.07em;
    color: #004aad;
    padding-top: 150px;
    text-align: center;
}

.TOSMobileHeaderSubText {
    margin-top: 0;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    color: #004aad;
}

.HeaderText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 54px;
    line-height: 60px;
    color: #011a3c;
    text-align: center;
    margin: 0;
}

.TOSModalHeaderText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 30px;
    color: #011a3c;
}

.TOSContentWrapper {
    padding: 80px 120px;

    @media only screen and (max-width: 500px) {
        padding: 80px 20px;
    }
}

.TOSModalContentWrapper {
    padding: 20px;
}

.TOSWrapper {
    overflow-y: scroll;
    height: 429px;
    padding: 10px 38px 38px 38px;
}

.SubParagraph {
    margin-left: 30px;
}

@media (max-width: 768px) {
    .ContentWrapper {
        padding: 87px 80px;
    }

    .SubParagraph {
        margin-left: 30px;
    }
}

@media (max-width: 676px) {
    .ContentWrapper {
        padding: 50px 80px 71px 80px;

        div > h2 {
            line-height: 40px;
        }
    }

    .SubParagraph {
        div > h2 {
            line-height: 28px;
        }
    }
}

@media (max-width: 500px) {
    .ContentWrapper {
        padding: 30px 40px 71px 40px;
    }

    .SubParagraph {
        margin-left: 25px;
    }
}

@media (max-width: 478px) {
    .HeaderText {
        font-size: 40px;
    }
}

@media (max-width: 400px) {
    .HeaderText {
        font-size: 37px;
    }

    .ContentWrapper {
        padding: 30px 20px 71px 20px;
        font-size: 25px;
    }

    .SubParagraph {
        margin-left: 25px;
    }
}

.InformationsContainer {
    margin-bottom: 60px;
}

.TabsContainer {
    .ant-tabs-nav-wrap {
        display: flex;
        justify-content: center;
        background-color: var(--blue-4);
        padding-top: 105px;
    }

    .ant-tabs {
        margin-top: 0 !important;
    }

    .ant-tabs-top .ant-tabs-nav {
        margin: 0 !important;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-family: 'SF-Pro-Display-Bold';
    }
}
