.ProductLibrary {
  padding: 24px 100px;

  .ant-tabs-tab.ant-tabs-tab-active {
    padding: 20px 22px !important;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px !important;
  }

  .ant-tabs-content-holder {
    padding-top: 15px !important;
  }

  @media screen and (min-width: 501px) and (max-width: 1204px) {
    position: relative;
    padding-top: 70px !important;
  }

  @media screen and (max-width: 1023px) {
    padding: 0 20px 0 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 10px;
    padding-top: 7px !important;
  }

  @media screen and (max-width: 500px) {
    padding: 0px;
  }
}

.PackagesDrawerFormWrapper {
  height: 75vh;
}

.FilterBackLink {
  margin-top: -30px;
  margin-bottom: 30px;
}

.FilterText {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011a3c;
  margin: 50px 0px;
}

.ProductListInputWrapper {
  display: flex;
  width: 60%;


  @media screen and (max-width: 500px) {
    width: 100%;
  }

  @media screen and (max-width: 320px) {
    flex-direction: column;
  }

}

.menu {
  padding: 12px;
  border-radius: 15px;
  font-family: 'SF-Pro-Display';
}

.menuItem {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #233862 !important;

  &:hover,
  &:active {
    color: #233862 !important;
  }
}

.Title {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 23px;
  line-height: 27px;
  color: #011a3c;
  margin-bottom: 50px;
  margin-top: -54px;

  @media screen and (max-width: 768px) {
    margin-top: 10px !important;
  }

  @media screen and (max-width: 500px) {
    margin-top: 10px !important;
  }
}

.filterBox {
  display: flex;
  height: 45px !important;
  width: 130px;
  color: green;
  border: 1px solid #e5e5e5;
  border-radius: 10px !important;
  padding: 11px 20px;
  cursor: pointer;



  @media screen and (max-width: 500px) {
    // width: 100% !important;
  }
}

.filter {
  margin-left: 22px;
  margin-top: 2px;
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(1, 26, 60, 0.8);
}

.buttons {
  display: flex;
  width: 40%;
  justify-content: flex-end;

  @media screen and (min-width: 501px) and (max-width: 1204px) {
    position: absolute;
    right: 86px;
    top: 8px;
  }

  @media screen and (max-width: 991px) {
    justify-content: unset !important;
  }

  @media screen and (max-width: 500px) {
    display: none !important;
  }
}

.MobileTitle {
  @media screen and (max-width: 500px) {
    display: flex;
    justify-content: space-between;
  }
}

.MobileButton {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.ant-tabs-tab {
  @media screen and (max-width: 768px) {
    width: 200px;
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    width: 134px;
    justify-content: center;
  }
}

.ant-tabs {
  @media screen and (max-width: 500px) {
    margin-top: 20px !important;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue);
}

.ant-tabs-tab-btn {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  color: rgba(1, 26, 60, 0.8);
  text-align: center;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border: 1px solid rgba(229, 229, 229, 0.8);
}

th,
td {
  text-align: left;
  padding: 8px;
}

.TableHeader {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: rgba(1, 26, 60, 0.8);
}

.pages {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  @media screen and (max-width: 500px) {
    padding-right: 24px;
  }

  .ant-pagination-item {
    background-color: #f6f6f6;
    padding: 8px 0px;

    a {
      color: #4d4d4d !important;
    }

    @media screen and (max-width: 500px) {
      background-color: #fff !important;
    }

    @media screen and (max-width: 400px) {
      margin: 1.5px;
    }

    @media screen and (max-width: 320px) {
      margin: 0px;
    }
  }

  .ant-pagination-item-ellipsis {
    display: inline-block;
    padding: 8px 0px;
  }

  .ant-pagination-next .ant-pagination-item-link {
    background-color: #f6f6f6;

    @media screen and (max-width: 500px) {
      background-color: #fff !important;
      margin-left: 2px !important;
    }

  }

  .ant-pagination-prev .ant-pagination-item-link {
    background-color: #f6f6f6;

    @media screen and (max-width: 500px) {
      background-color: #fff !important;
      margin-left: 0px;
    }
  }

  .ant-pagination-item-active {
    background: #004aad !important;
    border-color: #004aad !important;
    color: #fff !important;

    a {
      color: #fff !important;
      font-family: 'SF-Pro-Display-Bold';
    }
  }
}

::placeholder {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: #233862;
}

input:focus::placeholder {
  color: transparent;
}

.HelpCenterWrapper {
  position: fixed;
  bottom: 80px;
  right: 10px;

  @media screen and (max-width:500px) {
    bottom: 75px !important;
  }

  @media screen and (max-width:768px) {
    bottom: 75px;
  }

}

.floatBox {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.ant-tabs-ink-bar {
  background: var(--blue);
}

.FilterTitle {
  font-family: "SF-Pro-Display-Bold";
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011a3c;
  margin-bottom: 40px;
  margin-top: 30px;
}

.FilterSubTitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
  margin-bottom: 25px;
}

.dateWrapper {
  display: flex;

  div.DateInputRight {
    width: 48.1% !important;

    @media screen and (max-width: 375px) {
      width: 45% !important;
    }
  }

  div.DateInputLeft {
    width: 48.1% !important;

    @media screen and (max-width: 375px) {
      width: 45% !important;
    }
  }

  .Separator {
    display: inline-block;
    width: 6%
  }
}

.ant-slider-track {
  background-color: var(--blue);
}

div.ant-slider-track.ant-slider-track-1::after {
  background-color: red !important;
}

.ant-tooltip-inner {
  background-color: var(--black);
  border-radius: 10px;
  padding: 6px 15px;
  font-family: 'SF-Pro-Display';
  font-style: normal;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: var(--blue);
}

.ant-slider-handle {
  background-color: var(--blue);
  border: solid 2px var(--blue);
}

div.ant-slider-track.ant-slider-track-1 {
  background-color: var(--blue) !important;
}

.link {
  color: var(--blue);
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  display: flex;
  margin: 13px 0 40px;

  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    margin-left: 12%;
  }

  @media screen and (min-width: 1400px) {
    margin-left: 9%;
  }

  @media screen and (min-width: 2500px) {
    margin-left: 1%;
  }
}

.caretStyle {
  position: absolute;
  left: 81%;
  top: 32%;
  cursor: pointer;
}

.PageTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #011A3C;

  @media screen and (max-width: 500px) {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    display: none;

  }
}

.ant-drawer-content-wrapper {
  @media screen and (max-width: 500px) {
    width: 100% !important;

  }
}

.ant-drawer-body {
  @media screen and (max-width: 375px) {
    padding-left: 15px;
  }

}

.FilterBtnWrapper {
  display: flex;
  margin-top: 40px;



  @media screen and (max-width: 500px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 375px) {
    margin-left: 10px;
  }

  @media screen and (max-width: 320px) {
    position: relative;
    margin-left: 0px;
  }
}

.ProductFilterDrawer {

  // display: none;
  .ant-drawer-close {
    display: none;

    @media screen and (max-width: 500px) {
      display: inline;
    }
  }
}

.LoadingTopRowLarge {
  border-radius: 7px;
  height: 40px !important;
  width: 60%;
  margin-right: 5px;

  @media screen and (max-width: 500px) {
    width: 67%;
    margin-right: 5px;
  }
}

.LoadingTopRowSmall {
  border-radius: 7px;
  height: 40px !important;
  width: 20%;

  @media screen and (max-width: 500px) {
    width: 30%;
  }
}

.LoadingTopRow {
  border-radius: 7px;
  height: 40px !important;
}

.LoadingHr {
  border: 1px solid rgba(190, 190, 190, 0.2);
  margin-top: 15px;
}

.LoadingBtnsWrapper {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;

  &.Table {
    margin-top: 16px;
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.LoadingBtns {
  border-radius: 7px !important;
  height: 25px !important;
  width: 96px !important;
}

.LoadingRows {
  width: 100%;
  margin: 5px 0px;
  border-radius: 7px;
  height: 50px !important
}

.MobileLoadingTopRow {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.LoadingRowsWrapper {
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
}

.AdminProductLibraryBackLink {
  margin-top: -67px;
  margin-bottom: 90px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

.ant-skeleton-element .ant-skeleton-input {
  width: 100%;
}

.ant-skeleton-element .ant-skeleton-input-lg {
  min-width: unset;
}