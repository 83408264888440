.HeaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Title {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 22px;
  line-height: 27px;
  color: var(--primary-blue);
  margin-bottom: 5px;

  @media screen and (max-width: 500px) {
    font-size: 15px;
  }
}

.SubTitle {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
}