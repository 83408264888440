.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    position: relative;

    @media screen and (max-width: 600px) {
        gap: 3px;

        &:has(div.filtered) {
            margin-bottom: 55px;
        }
    }
}

.search {
    width: 384px;
    height: 42px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 38px;
    position: relative;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background: none;
        text-indent: 16px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        :focus {
            border: none;
            outline: none;
            appearance: none;
        }

        ::placeholder {
            font: 14px/24px var(--font-regular);
            color: rgba(196, 196, 196, 0.78);
        }

        @media (max-width: 500px) {
            ::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        margin-left: 16px;
        cursor: pointer;
        width: 13.56px;
        height: 13.56px;
    }

    @media screen and (max-width: 600px) {
        border: 1px solid #e5e5e5;
        padding: 5px 0;
        border-radius: 7px;
        width: 80%;
    }
}

.filterAndDownload {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;

    @media screen and (max-width: 600px) {
        gap: 3px;

        & > .tooltiptext {
            margin-left: -85px;
        }
    }
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    p {
        font: 14px/16.71px var(--font-semibold);
        color: #004aad;
        margin: 0;

        @media screen and (max-width: 600px) {
            span {
                display: none;
            }
        }
    }

    @media screen and (max-width: 600px) {
        border: 1px solid #e5e5e5;
        padding: 10px;
        border-radius: 7px;

        & > div {
            height: 20px;
        }
    }
}

.filtered,
.filteredText {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
}

.filtered {
    gap: 8px;
    align-items: flex-start;
    margin-top: 8px;
    justify-content: flex-end;
    margin-left: -150px;
    width: 80%;

    @media screen and (max-width: 600px) {
        position: absolute;
        justify-content: flex-start;
        top: 45px;
        width: 100%;

        &:after {
            position: absolute;
            content: '';
            bottom: -40px;
            height: 40px;
            width: 1px;
        }
    }
}

.filteredText {
    font: 14px/16.71px var(--font-light);
    color: #004aad;
    margin: 0;
    gap: 3px;

    > span {
        font-family: var(--font-semibold);
        margin: 0;
    }

    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}

.download {
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #f9fcff;
    border-radius: 50%;
    display: grid;
    place-items: center;

    svg {
        width: 20px;
        height: 20px;
    }

    @media screen and (max-width: 600px) {
        border: 1px solid #e5e5e5;
        padding: 5px 0;
        border-radius: 7px;
    }
}
