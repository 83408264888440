.CardImage {
    width: 182px;
    height: 196.57px;

    @media screen and (max-width: 768px) {
        width: 144.44px;
        height: 156px;
    }
}

.ModalContentWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 30px 20px;
    text-align: center;

    h3 {
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        color: #011a3c;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 18px;
        color: #4d4d4d;
    }
}