$table-row-padding-left-full-desktop: 24px;

.table {
    border-collapse: separate;
    border-spacing: 0 16px;
    margin-top: -24px;

    th {
        font: 13px/15.51px 'SF-Pro-Display';
        color: #011a3ccc;
        padding: 0 0 2px 0;

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
        }
    }
}

.tableRow {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px #0000000f;

    td {
        font: 14px/16.71px 'SF-Pro-Display';
        padding: 15px 0;

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
            font-family: 'SF-Pro-Display-Semibold';
        }

        &:last-child {
            width: 124px;
        }
    }
}

.editPackage_closeBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    >img {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        cursor: pointer;
    }
}

.editPackage_titleAndBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    h2 {
        font: 18px/21.48px 'SF-Pro-Display-Bold';
        color: #011a3c;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h2 {
            font: 23px/27.45px 'SF-Pro-Display-Semibold';
        }

        svg {
            cursor: pointer;
        }
    }
}

.editPackage_card {
    width: 100%;
    box-shadow: 0px 0px 9px 0px #0000000f;
    padding: 17px 24px;
    border-radius: 6px;

    >h3 {
        font: 14px/16.71px 'SF-Pro-Display-Semibold';
        color: #011a3c;
        margin-bottom: 36.5px;
    }

    >button {
        display: block;
        margin: 28px 0 0 auto;
    }

    @media only screen and (max-width: 500px) {
        padding: 17px 20px;
    }
}

.editPackage_cardStats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    >div {
        h4 {
            font: 10px/11.93px 'SF-Pro-Display-Medium';
            color: #011a3cd9;
            margin-bottom: 2px;
        }

        p {
            font: 16px/25px 'SF-Pro-Display-Semibold';
            color: #011a3c;
        }
    }
}

.editPackageDrawer {
    padding: 0 !important;
}

.cards {
    button {
        background-color: #ffffff !important;
        color: #004aad !important;
        width: 92px !important;
        height: 29px !important;
    }
}

.pagination_wrapper {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.cluster_info_wrapper {
    display: flex;
    padding: 16px 26px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 6px;
    background: #FFF;

    >div:first-of-type {
        >h3 {
            color: #011A3C;
            font-family: "SF-Pro-Display-Medium";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
        }

    }

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 0.5px solid var(--shades-and-tints-primary-e-2-eeff, #E2EEFF);
        padding-bottom: 16px;

        >button {
            all: unset;
            color: var(--primary-004-aad, #004AAD);
            text-align: center;
            font-family: "SF-Pro-Display-Medium";
            font-size: 12px;
            font-style: normal;
            line-height: normal;
            cursor: pointer;
        }


        >h3 {
            color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
            font-family: "SF-Pro-Display";
            font-size: 14px;
            font-style: normal;
            line-height: 150%;
        }

        >a,
        :active,
        :visited {
            color: #006FFF;
        }

        >p {
            color: var(--shades-and-tints-primary-000-f-23, #000F23);
            text-align: right;
            font-family: "SF-Pro-Display";
            font-size: 14px;
            font-style: normal;
            line-height: normal;
        }
    }
}

.cluster_banner_info {
    display: flex;
    align-items: center;
    gap: 9px;
    color: #004aad !important;
    font-size: 12px !important;

    & svg {
        width: 18px;
        height: 18px;
    }
}