.Title {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 16px;
    line-height: 24px;
    color: #011a3c;
    margin: 0;
}

.SubTitle {
    font-family: 'SF-Pro-Display-Light';
    font-size: 14px;
    line-height: 24px;
    color: #4d4d4d;
    margin: 0 0 16px 0;
}

.SecTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #011a3c;
    margin: 0;
}

.SecSubTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 12px;
    line-height: 20px;
    color: #4d4d4d;
    margin: 0 0 16px 0;
}

.titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.helpIcon {
    width: 18px;
    height: 18px;
    margin-top: 6px;
}
