.wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #f9fcff;
    border-radius: 15px;
    height: 555px;
    width: 1152px;

    .mobileCloseIcon {
        display: none;
        position: absolute;
        top: 0;
        right: 15px;
        top: 15px;
        width: 25.44px;
        height: 25.44px;
        cursor: pointer;

        path {
            stroke: #14181f;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 1000px;
    }

    @media screen and (max-width: 1024px) {
        position: relative;
        width: calc(100vw - 50px);

        .mobileCloseIcon {
            display: block;
        }
    }

    @media screen and (max-width: 500px) {
        min-height: 654px;
        height: auto;
        width: 100%;
        background-color: #fff;
    }
}

.exploreImgWrapper {
    width: 522px;
    height: 436px;
    background-color: #004aad;
    border-radius: 0px 12px 12px 0px;
    position: relative;
    overflow: hidden;
    height: 100%;

    .exploreSolarPackagesImgImg {
        width: 100%;
        object-fit: contain;
    }

    .closeIcon {
        position: absolute;
        top: 0;
        right: 23px;
        top: 23px;
        width: 25.44px;
        height: 25.44px;
        cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
        width: 422px;
    }

    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.left {
    padding: 30px;
    width: calc(100% - 522px);
    max-width: 580px;
    margin-inline: auto;

    > img {
        width: 245px;
        height: 43px;
        object-fit: contain;
    }

    > h2 {
        font: 22px/35px var(--font-bold);
        color: #233862;
        margin: 32px 0 6px 0;
    }

    > p {
        font: 16px/24px var(--font-light);
        color: #5f5b5b;
        margin-bottom: 16px;
    }

    @media screen and (max-width: 1200px) {
        width: calc(100% - 422px);
    }

    @media screen and (max-width: 1024px) {
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        > h2 {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 16px;
            line-height: 19.09px;
            text-align: left;
        }

        > p {
            font-family: 'SF-Pro-Display-Light';
            font-size: 14px;
            line-height: 23px;
        }
    }
}

.btnAndCta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 500px) {
        margin-top: 30px;
    }
}

.mobileChatBox {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 15px;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px;
    cursor: pointer;
    position: relative;
    height: 92.95px;
    width: 100%;
    box-shadow: 0px 0px 13.6px 0px #00000005;
    background-color: #ffffff;
    border-bottom: 0.5px solid #ffffff;

    &:hover {
        border-bottom: 0.5px solid #004aad !important;
    }

    &.selected {
        background-color: #e2eeff;
        border-bottom: 0.5px solid #004aad;
    }

    > div {
        h4 {
            font: 14px/24px 'SF-Pro-Display-Semibold';
            color: #233862;
            margin-bottom: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 500px) {
        height: auto;
        border-bottom: 0.5px solid #e2eeff;

        &:nth-child(2) {
            border-bottom: 0.5px solid #3d5075;
        }

        > div {
            h4 {
                font: 14px/20px 'SF-Pro-Display-Bold';
            }

            p {
                font: 12px/20px 'SF-Pro-Display-Light';
            }
        }
    }
}

.optionImg {
    width: 81px;
    object-fit: contain;
    flex-shrink: 0;
}

.checkIcon {
    width: 16px;
    height: 16px;
    object-fit: cover;
    position: absolute;
    right: 9px;
    top: 10px;
}

.divider {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 16px;

    hr,
    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        margin: 0;
    }

    hr {
        width: 100%;
        border: none;
        border-top: 0.3px solid #b0b0b0;
    }

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #3d5075;
    }
}

.error {
    font: 12px/16px 'SF-Pro-Display';
    color: #da1e28;
    margin-top: 6px;
}
