.Container {
    width: 100%;
    min-height: 60px;
    gap: 0px;
    border-radius: 15px;
    border: 0.5px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
    padding: 17px;
    padding: 18px;

    & > .ContentBox {
        & > p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 14px;
            line-height: 16.71px;
            text-align: left;
            color: #233862;
        }
    }
}
