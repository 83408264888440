.Wrapper {
  margin-top: 50px;
  font-family: 'SF-Pro-Display';

  @media screen and (min-width: 2500px) {
    width: 40vw;
  }

  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
}

.Row {
  display: flex;
  margin-top: 35px;
  height: 70px;

  @media screen and (max-width: 900px) {
    height: fit-content;
    flex-direction: column;
    margin-top: 0px;
  }
}

.LeftWrapper {
  width: 270px;
  @media screen and (max-width: 500px) {
    width: fit-content;
 }
}

.Header {
font-family: 'SF-Pro-Display-Semibold';
font-size: 15px;
line-height: 18px;
color: #011A3C;
margin-bottom: 8px;
padding-top: 10px;
}

.SubHeader {
  font-size: 14px;
  line-height: 19px;
  color: rgba(35, 56, 98, 0.8);
}

.ProfileImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
}

.SelfieWrapper {
  font-size: 13px;
  line-height: 16px;
  color: rgba(35, 56, 98, 0.54);
  margin-left: 20px;
  margin-top: 12px;
  @media screen and (max-width: 500px) {
    margin-left: 0;
 }
}

.FullName {
  display: flex;
  width: 389px;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
 }

  &.PIFullName {
    @media screen and (max-width: 500px) {
      margin-top: 18px;
    }
  }
}

.States {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
  @media screen and (max-width: 500px) {
    flex-direction: column;
 }
}
.shortBioWrapper {
  @media screen and (max-width: 500px) {
    margin-top: 0;
 }
}

.SaveBtn {
  margin-top: 100px;
  display: flex;
  justify-content: flex-end;
  width: 657px;
  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin-top: 60px;
 }
}
.editorStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 448px;
  background-color: var(--black);
  margin: 0px -24px;
  width: 100vw;
  @media screen and (max-width: 500px) {
    height: 100%;
 }
}
.videoWrapperStyle {
  margin: 0;
}
.MobileImageWrapper {
  @media screen and (max-width: 500px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;
    margin-top: 15px;
 }
}
.MobileFieldWrapper {
  width: 389px;
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}
.BioWrapper {
   margin-top: 120px;
   @media screen and (max-width: 500px) {
     margin-top: 40px
   }
}