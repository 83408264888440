.consumerModal {
  .ant-modal-content {
    border-radius: 10px;
@media screen and (max-width: 500px) {
    border-radius: 15px 15px 0 0;
    width: 100vw;
    position: fixed;
    top: 13%;
  }
}

  .ant-modal-body {
    padding: 0px;
  }
@media screen and (min-width: 1500px) {
  top: 10%
}
}
.ant-modal-centered {
  @media screen and (max-width: 500px) {
    text-align: inherit;
  }
}
.consumerModalTop {
  .ant-modal-content {
    border-radius: 24px;
    top: 20%
  }
}