.Card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    width: 100%;
    padding: 20px 10px;
    border-radius: 6px;

    & > .CardHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 10px;
        border-bottom: 1px solid #e5e5e566;

        & > h3 {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            line-height: 16.71px;
            color: #011a3c;
            text-transform: capitalize;
        }

        & > .ApprovedBrandsRight {
            display: flex;
            align-items: center;
            gap: 4px;
            & > span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 19px;
                height: 19px;
                background: #004aad;
                border-radius: 50%;
                color: #fafafa;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                line-height: 12px;
                text-align: center;
            }

            & > p {
                font-family: 'SF-Pro-Display';
                font-size: 12px;
                line-height: 14.32px;
                color: #233862c7;
                opacity: 78%;
            }
        }
    }

    & > .ApprovedBrandsCardBody {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 10px;

        & > .BrandTag {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            padding: 0px 16px;
            border-radius: 21px;
            border: 0.5px solid #e5e5e5;
            background-color: #fafafa;

            & > p {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                line-height: 18px;
                color: #070c18;
            }
        }
    }
}
