.page {
    display: grid;
    grid-template-columns: 574px auto;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media screen and (max-width:800px) {
        grid-template-columns: repeat(1,1fr);
    }
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #001837;
    height: 100%;
    padding-top: 100px;
    > h1 {
        font: 18px/24px 'SF-Pro-Display';
        color: #ffffff;
        margin-bottom: 8px;
        text-align: center;
    }

    > p {
        font: 14px/24px 'SF-Pro-Display';
        color: #ffffff;
        max-width: 277px;
        text-align: center;
    }

    > img {
        width: 480px;
        height: 383px;
        object-fit: contain;
        margin: 90px auto;
        @media screen and (max-width: 1024px) {
            width:90%;
        }
    }
    @media screen and (max-width:800px) {
        display: none;
    }
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    h2 {
        font: 28px/35px 'SF-Pro-Display-Bold';
        color: #233862;
        margin: 141px 0 8px 0;
    }

    > p {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 16px;
    }
}

.signInOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 18px;
    background: #f9fcff;
    border: 0.5px solid #e2eeff;
    border-radius: 4px;
    margin-bottom: 24px;
    width: 438px;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        width:90%;
    }

    &:hover {
        border: 0.5px solid #004aad;
    }

    div {
        h4 {
            font: 18px/24px 'SF-Pro-Display';
            color: #233862;
            margin-bottom: 8px;
        }

        p {
            font: 14px/24px 'SF-Pro-Display';
            color: #233862;
            margin: 0;
        }
    }
}
