.card {
    width: 505px;
    padding: 24px;
    background-color: #f9fcff;
    border-radius: 14px;
    height: fit-content;
    margin-bottom: 18px;

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}

.iconBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 9px 12px 8px 12px;
    border: 0.5px solid #004aad;
    border-radius: 30px;

    p {
        font: 12px/14px 'SF-Pro-Display-Medium';
        color: #004aad;
    }

    svg {
        stroke: #004aad;
    }

    &:hover {
        background: #004aad;

        p {
            color: #ffffff;
        }

        svg {
            stroke: #fff;
        }
    }
}

.titleAndBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    > h4 {
        font: 12px/24px var(--font-medium);
        letter-spacing: 1px;
        color: #000000cc;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        margin-bottom: 18.5px;
    }
}

.info {
    display: flex;
    align-items: flex-start;
    gap: 33px;
    width: 100%;

    img {
        width: 197px;
        height: 94px;
        object-fit: contain;
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        gap: 12px;
    }
}

.infoText {
    width: 100%;

    > div:first-child {
        margin-bottom: 23px;
    }

    .row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            h5 {
                font: 12px/24px var(--font-medium);
                color: #233862;
                margin-bottom: 4px;
            }

            p {
                font: 14px/16.8px var(--font-bold);
                color: #070c18;
                margin-bottom: 0;
            }
        }
    }

    .infoText_Right {
        text-align: right;
    }
}

.seePackageBtn {
    font: 12px/14px var(--font-semibold);
    color: #004aad;
    text-decoration: underline;
    margin-top: auto;
    margin-bottom: 0;
    cursor: pointer;
    text-align: right;
    text-underline-offset: 3px;
}
