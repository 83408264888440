.AdminProviderInstallerInfoTableItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 69px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 60px 0 26px;

  @media screen and (max-width: 500px) {
    padding: 0 15px 0 15px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  }

  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    text-align: right;
  }
}

.APIITIImageTextTitle {
  font-family: 'SF-Pro-Display';
  font-size: 16px;
  line-height: 19px;
  color: #011A3C;
  padding: 32px;
}

.APIITIImageStyle {
  height: 100%;
  border-radius: 0 0 10px 10px;
  width: 500px;
  object-fit: cover;

  @media screen and (max-width: 500px) {
    width: 100vw;
    display: grid;
    place-items: center;
    margin: 0 12px;
  }
}

.AdminProviderInstallerImg {
  width: 500px;
  height: 300px;

  @media only screen and (max-width: 500px) {
    width: 328px;
    height: auto;
    margin-top: 80px;
  }
}

.resume_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.resume_text {
  color: var(--Accent-Blue-Primary, #004AAD);
  font-family: "SF-Pro-Display-Semibold";
  font-size: 12px;
  line-height: normal;

  @media screen and (min-width: 1023px) {
    .one {
      display: none;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1560px) {
    .two {
      font-family: "SF-Pro-Display-Semibold";
    }

    .three,
    .one {
      display: none;
    }
  }

  @media screen and (min-width: 1561px) {
    .two,
    .one {
      display: none;
    }
  }

  @media screen and (max-width: 1023px) {
    .one {
      font-family: "SF-Pro-Display-Semibold";
    }

    .three,
    .two {
      display: none;
    }
  }
}