:root {
  box-sizing: border-box;
  --primary-blue: #011a3c;
  --light-sky-blue: #eceff9;
  --primary-blue-two: #013556;
  --blue-1: #070c18;
  --blue-2: rgba(35, 56, 98, 0.88);
  --yellow-1: #fff4d8;
  --black: #000000;
  --blue: #004aad;
  --hover-text-blue: #0458C9;
  --white: #fff;
  --bg1: #004aad;
  --blue-3: #233862;
  --offwhite: #fafafa;
  --lightblue: rgba(135, 142, 156, 0.88);
  --yellow: #ffc222;
  --deepblue: #011a3c;
  --lightblue: rgba(35, 56, 98, 0.88);
  --black-2: #070c18;
  --yellow-2: #ffc222;
  --purple-light: #e9eeff;
  --blue-light-2: #d6e8ff;
  --lemon-green: #f4fff7;
  --green-1: #32cc79;
  --red-1: #f20519;
  --red-light: #ffe7e9;
  --yellow-3: #fffcf5;
  --lightbrown: #fdf7eb;
  --black-3: #4d4d4d;
  --blue-4: #e4f0ff;
  --dark-blue: #001837;
  --grey: #f2f2f2;
  --brown-grey: #e5e5e5;
}
