.Wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    padding: 70px 20px;

    > div {
        max-width: 1244px;
        width: 100%;

        > h2 {
            max-width: 836px;
            width: 100%;
            color: var(--shades-and-tints-primary-233862, #233862);
            font-family: 'SF-Pro-Display-Bold';
            font-size: 35px;
            font-style: normal;
            line-height: 45px;

            @media screen and (max-width: 500px) {
                font-size: 32px;
                line-height: 38px;
            }
        }

        > p {
            max-width: 750px;
            width: 100%;
            color: rgba(1, 26, 60, 0.75);
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            font-style: normal;
            line-height: 24px;

            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 20px;
            }
        }

        > div {
            height: 100%;
            width: 100%;
            max-width: 1244px;
            margin-top: 24px;

            > div > div,
            > div iframe {
                border-radius: 12px;
            }
        }
    }
}
