.UpdateRepaymentContainer {
    padding: 48px 80px;
    position: relative;
    min-height: 100%;

    @media screen and (max-width: 480px) {
        padding: 48px 10px;
    }

    .ModalHeading {
        text-align: center;

        h2 {
            color: #011a3c;
            font-family: 'SF-Pro-Display-Bold';
            font-size: 20px;
            font-style: normal;
            line-height: normal;
        }

        p {
            color: rgba(35, 56, 98, 0.7);
            text-align: center;
            font-family: 'SF-Pro-Display';
            line-height: normal;
        }
    }

    .FormFieldsContainer {
        display: flex;
        flex-direction: column;
        // min-height:100%;
        overflow-x: hidden;
        gap: 20px;
        padding-top: 40px;
    }

    .FormFields {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .standing_order_error {
        color: #da1e28;
        text-align: center;
        font-size: 12px;
        margin-top: 8px;
    }

    .SubmitButton {
        position: absolute;
        z-index: 2;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 480px) {
            width: 100%;
            padding: 0 10px;
        }
    }

    .SubmitButton.upload {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 480px) {
        }
    }
}

.UpdateRepaymentCalenderWrapper {
    display: flex;
    justify-content: space-between;
    z-index: 1000;

    @media screen and (max-width: 500px) {
        display: block;
    }

    .UpdateRepaymentCalenderDiv {
        width: 224px;

        @media screen and (max-width: 500px) {
            width: 100%;
            margin-bottom: 5px;
        }
    }

    .APAMInputAutoFilled {
        display: flex;
        flex-direction: column;
        gap: 0;
        position: absolute;
        top: 2px;

        p {
            font-size: 11px;
            color: #004aad;
            margin: 0;
            height: 20px;
        }

        span {
            margin: 0;
        }
    }
}
