.howSunfiWorks {
    width: 100%;
    max-width: 1244px;
    padding: 88px 22px;

    @media screen and (min-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }

    @media screen and (max-width: 500px) {
        padding: 45px 20px;
    }

    &__wrapper {
        display: flex;
        gap: 45px;

        @media screen and (max-width: 800px) {
            flex-direction: column;
            gap: 32px;
        }

        &__left {
            width: 50%;
            overflow: hidden;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                border-radius: 10px;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -ms-border-radius: 10px;
                -o-border-radius: 10px;

                video {
                    width: 101% !important;
                }
            }

            @media screen and (max-width: 1000px) {
                width: 100%;
            }
        }

        &__right {
            margin-top: -9px;
            flex-grow: 1;

            @media screen and (max-width: 1000px) {
                width: 100%;
            }

            h2 {
                color: #233862;

                font-family: 'SF-Pro-Display-Bold';
                font-size: 35px;
                font-style: normal;
                line-height: 45px;
                margin: 0;
                margin-bottom: 6px;

                @media screen and (max-width: 1000px) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }

            h6 {
                color: #5f5b5b;
                font-family: 'SF-Pro-Display-Light';
                font-size: 18px;
                font-style: normal;
                line-height: 24px;
                margin: 0;
                margin-bottom: 30px;

                @media screen and (max-width: 1000px) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

.stepsWrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media screen and (max-width: 1000px) {
        gap: 30px;
    }

    &__step {
        border-left: 4px solid transparent;
        padding-left: 12px;
        cursor: pointer;
        opacity: 0.5;

        h4 {
            margin: 0;
            color: #3d5075;
            font-family: 'SF-Pro-Display-Bold';
            font-size: 18px;
            font-style: normal;
            line-height: 24px;
            max-width: 428px;

            @media only screen and (max-width: 500px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        p {
            margin: 0;
            color: #3d5075;
            font-family: 'SF-Pro-Display-Light';
            font-size: 14px;
            font-style: normal;
            line-height: 24px;
            max-width: 428px;

            @media only screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 20px;
            }
        }

        &__active {
            border-left: 4px solid #004aad;
            opacity: 1;
            transition: all 2s ease-in-out;
            -webkit-transition: all 2s ease-in-out;
            -moz-transition: all 2s ease-in-out;
            -ms-transition: all 2s ease-in-out;
            -o-transition: all 2s ease-in-out;

            h4 {
                color: #011a3c;
            }

            p {
                color: rgba(1, 26, 60, 0.75);
            }
        }

        &__previousActive {
            border-left: 4px solid transparent;
            h4 {
                color: #3d5075;
            }
            p {
                color: #3d5075;
            }
            
            & {
                transition: all 1.5s ease-in-out;
                -webkit-transition: all 1.5s ease-in-out;
                -moz-transition: all 1.5s ease-in-out;
                -ms-transition: all 1.5s ease-in-out;
                -o-transition: all 1.5s ease-in-out;
            }
        }
    }
}
