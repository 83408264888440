.page {
    justify-content: center;
    max-width: 700px;
    margin: 0 auto 0;
    padding-bottom: 146px;
    @media screen and (max-width: 992px) {
        width: 90%;
    }
    @media screen and (max-width: 576px) {
        background: #f9fcff;
    }
}

.backNavContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 992px) {
        padding-left: 32px;
    }
    @media screen and (max-width: 576px) {
        padding-left: 16px;
    }
}
