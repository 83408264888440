.wrapper {
    position: relative;
    height: 100vh;
    overflow: auto;
}

.notFullPageWrapper {
    flex: 1;
    height: calc(100vh - 85px);
}

.infoSectionWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 94px;
}

.infoSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 490px;

    > h1 {
        font: 28px/35px var(--font-bold);
        color: #070c18;
        margin-bottom: 8px;
        text-align: center;
    }

    > h2 {
        font: 16px/24px var(--font-regular);
        color: #4d4d4d;
        margin-bottom: 16px;
        text-align: center;
    }

    @media only screen and (max-width: 500px) {
        padding: 0 47px;

        > h1 {
            font-size: 18px;
            line-height: 24px;
        }

        > h2 {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
.infoIcon {
    width: 232px;
    height: 142.96px;
    object-fit: cover;
    margin-bottom: 26px;

    @media only screen and (max-width: 500px) {
        width: 232px;
        height: 142.96px;
    }
}

.infoButtonContent {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 8px !important;
    margin: 0 auto;
    font: 14px/16.71px var(--font-semibold);

    > svg {
        width: 17.44px !important;
        height: 17.44px !important;
    }
}

.footer {
    width: 100%;
    height: 171px;
    padding: 24px 52px;
    background-color: #004aad;
    position: absolute;
    bottom: 0;
    background-image: url('../../assets/images/partial-logo.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (max-width: 500px) {
        padding: 14px 12px;
        background-image: url('../../assets/images/partial-logo-mobile.svg');
    }

    @media only screen and (max-width: 1024px) {
        position: unset;
        height: fit-content;
        margin-top: 70px;
    }
}

.footerTitle {
    font: 18px/27px var(--font-regular);
    color: #ffffff;
    text-align: center;
    width: 100%;
    margin: 0 auto 24px auto;

    @media only screen and (max-width: 500px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.footerOptionsSection {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.footerOption {
    background-color: #ffffff;
    border-radius: 18px;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.footerOptionText {
    h4 {
        font: 14px/16.8px var(--font-bold);
        color: #070c18;
        margin: 0;
    }

    p {
        font: 14px/21px var(--font-regular);
        color: #4d4d4d;
        margin: 0;
    }
}

.footerOptionBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    background-color: #004aad;
}
