.ProductsContainer {
  padding: 0 120px;
  background: #f6f6f6;

  @media screen and (min-width: 1300px) {
    padding: 0px 3%;
  }

  @media screen and (max-width: 650px) {
    padding: 0px 50px;
    height: 100vh;
  }

  @media screen and (max-width: 500px) {
    padding: 0px;
    background: #ffff;
    background: #ffff;
    height: 100vh;
  }



}

.ProductsPageBox {
  display: flex;
  margin-top: 50px;

  @media screen and (min-width: 1300px) {
    justify-content: center;
  }

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.PageBoxSectionOne {
  width: 60%;

  @media screen and (min-width: 1300px) {
    width: fit-content;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.ProductsRightWrapper {
  width: 380px;
  height: 110px;
  background: #dae8fe;
  border-radius: 15px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-left: 60px;

  @media screen and (max-width: 1200px) {
    display: none;
  }

}

.ProductsHeader {
  font-family: 'SF-Pro-Display';
  font-size: 12.5px;
  line-height: 25px;
  color: #233862;
  width: 300px;
  margin-bottom: 0px;
}

.ProductsInfoIcon {
  width: 20px;
  margin: 0px 16px;
  margin-top: -20px;
}

.MobileBackLink {
  margin-top: -20px;

  @media screen and (min-width: 2000px) {
    padding-left: 26vw;
  }

  @media screen and (min-width: 1900px) and (max-width: 2000px) {
    padding-left: 14vw;
  }

  @media screen and (min-width: 1500px) and (max-width: 1900px) {
    padding-left: 9.5vw;
  }

  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    padding-left: 5%;
  }

  @media screen and (max-width: 500px) {
    margin-top: 35px;
  }
}