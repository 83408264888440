.ModalWrapper {
    font-family: 'SF-Pro-Display';
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .HeaderWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .HeaderStyle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 22px;
    line-height: 26px;
    color: #011A3C;
    margin-bottom: 20px;
  }
  
  .SubheaderStyle {
    width: 232px;
    font-size: 15px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
    margin-bottom: 20px;
  }
  
  .SectionWrapper {
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    width: 388px;
    height: 86px;
    padding: 18px;
    cursor: pointer;
@media screen and (max-width: 500px) {
    width: 100%;
  }
  }
  
  .UserImgStyle {
    border: 0.5px solid #E5E5E5;
    border-radius: 10px;
    height: 45px;
    width: 45px;
    text-align: center;
    padding-top: 10px;
  }
  
  .SectionTextWrapper {
    margin-left: 15px;
  }
  
  .SectionHeader {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 15px;
    line-height: 26px;
    color: #233862;
    margin-bottom: 2px;
  }
  
  .SectionText {
    font-size: 12px;
    line-height: 14px;
    color: rgba(35, 56, 98, 0.7);
  }