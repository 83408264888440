.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 52px;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 7px;
  width: 100%;
  padding: 24px 21px;
}

.top, .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text {
  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 8px;
    line-height: 16px;
    color: #011A3CD9;
    margin-bottom: 5px;
  }

  p {
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 12px;
    line-height: 18px;
    color: #011A3C;
    max-width: 200px;
  }
}

.paragraphAndFlag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 180px;


  p {
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 12px;
    line-height: 18px;
    color: #011A3C;
  }

  span {
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 10px;
    padding: 5px 8px;
    letter-spacing: 0.055em;
    border-radius: 3px;
  }
}