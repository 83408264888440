.modal_container {
    position: fixed;
    display: flex;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    overflow-y: hidden;
    background-color: rgba($color: #000000, $alpha: 0.4);
    .modal_content {
        width: 475px;
        height: 525px;
        background-color: #ffffff;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0px;
    }
    .modal_header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 63px;
        border-bottom: 1px solid #b0b0b0;
        position: relative;
        padding: 0px;
        h3 {
            font-size: 14px;
            font-family: 'SF-Pro-Display-Bold';
            line-height: 24px;
            margin-bottom: 0px;
        }
        .cancel_icon {
            position: absolute;
            right: 14px;
            cursor: pointer;
        }
    }
    .modal_footer {
        margin-top: auto;
        height: 88px;
        border-top: 1px solid #b0b0b0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
        .modal_footer_content {
            display: flex;
            align-items: center;
            p {
                font-size: 14px;
                font-family: 'SF-Pro-Display';
                line-height: 24px;
            }
            a {
                text-decoration: underline;
                color: #004aad;
                padding: 3px 5px;
            }
        }
    }
}
