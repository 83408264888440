.SSEVerificationFormWrapper {
    display: block;
    width: 505px;
    position: relative;

    @media only screen and (max-width: 500px) {
        width: 100%;
        margin: 0 20px !important;
        justify-self: unset !important;
    }
}

.SSEBusinessVerificationWrapper {
    padding: 24px;
    height: fit-content;
    background: #f9fcff;
    border-radius: 14px;
}

.SSEBusinessVerificationText {
    margin-bottom: 16px;

    h4 {
        font: 16px/24px 'SF-Pro-Display';
        color: #011a3c;
        margin-bottom: 0;
    }

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;
    }

    @media only screen and (max-width: 500px) {
        margin-bottom: 25px;

        h4 {
            font: 12px/20px 'SF-Pro-Display';
        }

        p {
            font: 12px/20px 'SF-Pro-Display-Light';
        }
    }
}

.SSEBusinessBVNWrapper {
    position: relative;

    .SSEBusinessBVNSpan {
        color: #233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.32px;
        padding-left: 20px;
        margin-bottom: 10px;
    }
}

.SSEBusinessVerification_BvnBtn {
    width: 100%;
    margin-bottom: 8px;

    button {
        margin-left: auto;
        cursor: pointer;
        color: #004aad;
        position: absolute;
        top: 15px;
        right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        line-height: normal;

        border-radius: 30px;
        border: 0.3px solid #004aad;
        background: #f9fcff;
        font-family: 'SF-Pro-Display-Medium';
        width: 79px;
        height: 29px;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.7;
            border: none;
        }

        @media screen and (max-width: 500px) {
            width: 54px;
        }
    }
}

.SSEBusinessVerificationInfo {
    width: 100%;
    background: #e2eeff;
    padding: 14px 7px 14px 16px;
    border-radius: 14px;

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #011a3c;
    }
}

.SSEBusinessVerification_BVNModal_StepOne {
    padding: 50px 67px 0 70px;
    height: 374px;

    h4 {
        font: 12px/20px 'SF-Pro-Display-Medium';
        color: #011a3c;
        margin: 0;
    }

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #4d4d4d;
        margin-bottom: 14px;
    }

    @media only screen and (max-width: 500px) {
        padding: 20px;
    }
}

.SSEBusinessVerificationFileValidation {
    .fileSize {
        color: #233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
    .fileFormat {
        color: #233862b2;
        font-family: 'SF-Pro-Display';
        font-size: 13px;
    }
}

.SSEBVNFailedWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    text-align: center;
    font-family: 'SF-Pro-Display';
    padding: 0px 60px;

    @media screen and (max-width: 500px) {
        padding: 0;
    }

    svg {
        margin: 70px auto 30px;
    }

    h4 {
        color: #011a3c;
        font-size: 16px;
        line-height: 24px;

        @media screen and (max-width: 500px) {
            font-size: 14px;
            line-height: 23px;
        }
    }

    p {
        color: #4d4d4d;
        font-size: 12px;
        line-height: 20px;
        width: 300px;

        @media screen and (max-width: 500px) {
            width: 256px;
            font-family: 'SF-Pro-Display-Light';
        }
    }

    div {
        border-radius: 12px;
        background: #f9fcff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        padding-right: 12px;
        margin-top: 20px;
        width: 351px;

        @media screen and (max-width: 500px) {
            margin-top: 30px;
        }

        p {
            color: #233862;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            line-height: 20px;
            width: 200px;
        }
    }
}

.SSEBusinessBVNTitleWrapper {
    display: flex;
    justify-content: space-between;

    svg {
        width: 18px;
        height: 18px;
    }
}

.FinancialFormVideoWrapper {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;

    p {
        color: #05c95f;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 17.5px;
        margin-left: 5px;
    }
}
