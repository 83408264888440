.RightWrapper {
    width: 390px;
    height: 110px;
    background: #dae8fe;
    border-radius: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    transform: scale(0.9);

    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.RightWrapperConsumer {
    max-width: 345px;
    min-height: 110px;
    background: #dae8fe;
    border-radius: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
        margin-top: 0px;
    }
}

.Header {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 25px;
    color: #233862;
    width: 300px;
    margin-bottom: 0px;
}

.InfoIcon {
    width: 20px;
    margin: 0px 16px;
    margin-top: -20px;
}
