.Estimation_steptwo--container {
  padding: 0 80px;

  @media (max-width: 1040px) {
    padding: 0 40px;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
}


// estimatioStepOnen classes
.PrevButton {
  position: relative;
  bottom: 55px;
  color: #004AAD;
  stroke: #004AAD;
  background: none;
  font-size: 14px;
  font-family: 'SF-Pro-Display-Medium';
  line-height: 17px;
  border: none;
  cursor: pointer;
}

.PrevButton img {
  margin-right: 13.35px;
}

.EstimationStepOneH1 {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
}

.EstimationStepOneContainer {
  margin-top: 62px;
}

.StepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.StepsChildContainer>p {
  position: absolute;
  font-family: 'SF-Pro-Display-Light';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
}

.StepsButton {
  font-family: 'SF-Pro-Display';
  width: 36px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}

.EstimationStepOneH2 {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
  color: #011A3C;
  text-align: left;
}

.EstimationStepOneSubTitle {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  color: #011A3C;
  text-align: left;
  margin-bottom: 11px;
}

.EstimationStepOneBtnPrimary {
  font-family: 'SF-Pro-Display';
  background: #004AAD;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #004AAD;
  color: #ffffff;
  padding: 11px 34px 14px 34px;
  margin-top: 37px;
  cursor: pointer;
}

.EstimationStepOneBtnSecondary {
  font-family: 'SF-Pro-Display';
  background: #e2eeff;
  border-radius: 30px;
  height: 46px;
  border: none;
  color: #004aad;
  padding: 11px 34px 14px 34px;
  margin: 37px 15px 0 0;
  cursor: pointer;
}

.EnergySolutionSelectContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 19px;

  @media (max-width: 1100px) {
    grid-template-columns: 100%;
  }
}

.EnergySolutionSelectChildContainer {
  height: 67px;
  padding: 15px 25px 15px 29px;
  background: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.EnergySolutionSelectLabel {

    display: flex;
    justify-content: space-between;

  @media (max-width: 600px) {
    grid-template-columns: 70% 30%;
  }
}

.EnergySolutionSelectLabel p {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
  padding-top: 8px;
  }
  
  .EstimationPackageName {
    width: 170px;
    display: flex;
    align-items: center;
    gap: 3px;

    p {
      font-family: 'SF-Pro-Display-Medium';
      font-size: 14px;
      color: #011A3C;
    }
}

.EnergySolutionSelectChildContainer input[type="radio"]:checked+label>span {
  background: #004aad;
  color: #ffffff;
}

.ModalTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
}

.ModalSubTitle1 {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.055em;
  color: rgba(1, 26, 60, 0.85);
  margin-top: 41px;
}

.ModalParagraph {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px;
  line-height: 25px;
  color: rgba(1, 26, 60, 0.75);
  margin-top: 15px;
}

.ModalSubTitle2 {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
  margin-top: 49px;
}

.ModalListContainer {
  display: grid;
  grid-template-columns: 35% 23% 25% 15% 2%;
  padding: 24px 0;
  border-bottom: 0.5px solid #E5E5E5;

  @media (max-width: 600px) {
    grid-template-columns: 45% 32% 20% 2%;
  }
}

.ModalListContainer>span {
  font-family: 'SF-Pro-Display';
}

.ModalButton {
  border: none;
  background: #004AAD;
  font-family: 'SF-Pro-Display';
  border-radius: 30px;
  color: #ffffff;
  padding: 20px 32px 19px 33px;
  width: 160px;
  cursor: pointer;
}

.ArrowButton {
  width: fit-content;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.AlignItemsCenter {
  display: flex;
  align-items: center;
}

.EstimationSolutionSelectChildAmountText {
  width: 120px;
  @media (max-width: 600px) {
    display: none;
  }
}

.EstimationStepTwo_Search--mobile-responsiveness {
  @media (max-width: 950px) {
    display: none;
  }
}

.EstimationStepTwo_ComponentType--mobile-responsiveness {
  @media (max-width: 600px) {
    display: none;
  }
}

.EstimationStepTwoLayout {
  margin: 73px 0 0 20px;
  display: flex;
  justify-content: left;

  @media (max-width: '600px') {
    margin: 73px 0 0 0;
  }

  .lock-icon {
    top: 16px !important;
  }
}

.SolutionSelectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 36px;
  padding: 12px;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 11px;
  line-height: 13px;
  border-radius: 25px;
  cursor: pointer;
  background: #E2EEFF;
  color: #004aad;
}

.SolutionSelectButtonActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 36px;
  padding: 12px;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 11px;
  line-height: 13px;
  background: #004aad;
  border-radius: 25px;
  cursor: pointer;
  color: #ffffff;
}

//skeletal styles

.EstimationStepTwoSkeletalField1 {
  height: 21px !important;
  width: 156px !important;
  margin-top: 4px;

  @media (max-width: 1100px) {
    width: 200px !important;
  }

  @media (max-width: 500px) {
    width: 86px !important;
  }
}

.EstimationStepTwoSkeletalField2 {
  height: 21px !important;
  width: 102px !important;
  margin-top: 4px;

  @media (max-width: 1100px) {
    width: 150px !important;
  }

  @media (max-width: 500px) {
    width: 56px !important;
  }
}

.EstimationStepTwoSkeletalField3 {
  height: 21px !important;
  width: 70px !important;
  margin-top: 4px;


  @media (max-width: 1100px) {
    width: 100px !important;
  }


  @media (max-width: 500px) {
    width: 20px !important;
  }
}

.EstimationSkeletalField {
  width: 100%;
  margin-top: 4px;
}

.EstimationSkeletalShortField {
  width: 40px;
  margin-right: 8px;
}

.EstimationStepTwoModal {
  height: 90vh;
  overflow-y: auto;
}

.ModalComponent {
  font-size: 13px;
  color: #011A3C;
  margin-top: 27px;
  font-family: 'SF-Pro-Display';
  margin-bottom: 5px;
}

.SelectedTypeStyle {
  width: 25%;
  text-align: left;
  padding: 0px !important;
}

.SubTotalDiv {
  padding: 5px;
  padding-left: 9px;
  margin-top: 15px;
  display: flex;
  padding-right: 15px;
  border-bottom: 0.5px solid #E5E5E5;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    margin-top: 0;
    padding-bottom: 18px;
    padding-left: 0;
  }
}

.TotalDiv {
  padding: 5px;
  margin-top: 16px;
  padding-left: 9px;
  justify-content: space-between;
  display: flex;
  border-bottom: 0.5px solid #E5E5E5;

  @media screen and (max-width: 500px) {
    padding-bottom: 18px;
    padding-left: 0;
  }
}

.MarginDiv {
  padding-left: 9px;
  padding-bottom: 5px;
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  border-bottom: 0.5px solid #E5E5E5;

  @media screen and (max-width: 500px) {
    padding-bottom: 18px;
    padding-left: 0;
  }
}

.SelectPackageDiv {
  display: flex;
  justify-content: center;
  margin-top: 62px;
}

.StepsChildContainerDiv {
  background: #61c9a8;
  border-radius: 50px;
  border: none;
}

.StepsButtonStyle {
  color: #ffffff;
  border: none !important;
  background: #011a3c;
  pointer-events: none
}
.CenteredTab {
  .ant-tabs-tab {
    width: 100%;
    justify-content: center;
  }

  .ant-tabs-nav-wrap {
    display: inline-block !important;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: rgba(1, 26, 60, 0.8);
    ;
  }

}

.pages .ant-pagination-next .ant-pagination-item-link {
  background-color: #f6f6f6 !important;
}
.AddMarginBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.EstimationStepOneClusterP {
  font-size: 12px;
  font-family: 'SF-Pro-Display-Semibold';
  color: #004AAD;
  cursor: pointer;
}

.EstimationStepOneClusterSpan {
  color: #004AAD;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  background: #E2EEFF;
  width: 120px;
  height: 23px;
  display: inline-block;
  text-align: center;
  vertical-align: 2px;
  margin-left: 20px;

}