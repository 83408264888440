.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-bottom: 1px solid #e2eeff;
    padding: 24px 140px 24px 140px;
    position: sticky;
    top: 0;
    z-index: 100;
    @media screen and (max-width:800px) {
        padding: 24px 40px 24px 40px;     
    }
    @media screen and (max-width:480px) {
        padding: 20px;     
    }
    svg {
        stroke: #ffffff;
        width: 18px;
        height: 18px;
    }
}

.logo {
    width: 98px;
    height: 31px;
    object-fit: cover;
    cursor: pointer;
}
