.override-container {
  display:flex;
  flex-direction: column;
  padding:71px;
  align-items: center;
  img {
    max-width:120px;
    margin-bottom: 28px;
  }
  h2{
    color: #011A3C;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: normal;
    margin-bottom: 12px;
  }
  p{
    color: rgba(35, 56, 98, 0.80);
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    font-style: normal;
    line-height: 21px; /* 150% */
    margin-bottom: 44px;
  }
  .btn_group{
    display:flex;
    gap:8px;
  }
}
.override-check-result{
    display:flex;
    width: 100%;
    margin:0 auto;
  flex-direction: column;
  padding:48px 80px 73px 80px;
    h2{
    color: #011A3C;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: normal;
    text-align: center;
    margin-bottom: 9px;
  }
  p{
    color: rgba(35, 56, 98, 0.80);
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    font-style: normal;
    line-height: 21px; /* 150% */
    margin-bottom: 40px;
  }
  .btn_group{
    margin-top: 61px;
    display: flex;
    justify-content: center;
  }
}