.Wrapper {
  margin: 60px 100px;
  margin-right: 0px;

  @media screen and (max-width: 500px) {
    margin: 0px;
    padding-top: 40px;
  }
}



.Row {
  display: flex;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.BtnWrapper {
  margin-top: 40px;

  @media screen and (max-width: 500px) {
    margin-top: 80px;
  }
}

.MobileBackLink {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.LeftComponent {
  margin-right: 50px;

  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }

}
