.BackNavLink {
    color: var(--blue);
    margin-top: -1px;
}

.BackNavIcon {
    color: var(--blue);
    margin-right: 12px;
    display: grid;
    place-items: center;
}

.BackNavWrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 6px;
    margin-top: 14px;
}

.BackNavCloseIcon {
    position: relative;
    right: -20px;
}

.BackLink {
    display: flex;
    cursor: pointer;
    align-items: center;
    width: fit-content;
}

.link {
    @media screen and (min-width: 1300px) {
        margin-left: 12%;
    }
}

.v2Style {
    align-items: center;
    gap: 4px;
    height: fit-content;
    svg {
        width: 16px;
        height: 16px;
    }

    a {
        font: 14px/24px 'SF-Pro-Display-Medium';
        margin: 0;
        margin-left: 0px;
        transition: 0.5s ease all;
    }

    &:hover {
        // gap: 8px;

        a {
            margin-left: 4px;
            transition: 0.5s ease all;
        }
    }
}
