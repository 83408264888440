.AddImageContentWrapper {
    margin: 60px 100px;
    margin-right: 0px;

    @media screen and (max-width: 500px) {
        margin: 0px;
        padding-top: 40px;
    }
}

.AddImageRow {
    display: flex;

    @media screen and (max-width: 1200px) {
        display: block;
    }
}

.AddImageLeftContent {
    margin-right: 50px;

    @media screen and (min-width: 1900px) {
        .AddImageLeftContent {
            margin-right: 150px;
        }
    }

    @media screen and (max-width: 500px) {
        margin-right: 0px;
    }
}

.AddImageBtnWrapper {
    display: flex;
    margin-top: 50px;

    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.MobileAddImageBtnWrapper {
    display: none;

    @media screen and (max-width: 1200px) {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 50px;
    }
}

.AddImageParentWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.AddImageWrapper {
    position: relative;

    & > button {
        position: absolute;
        top: 9px;
        right: 8px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        & svg {
            & path {
                stroke: #070c18;
            }
        }
    }
}

.AddNewImageButton {
    width: 121px;
    height: 24px;
    font-family: 'SF-Pro-Display-Semibold';
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    span {
        font-size: 12px;
        line-height: 14.32px;
    }

    & svg {
        margin-top: -2px;
    }

    &.BtnDisabled {
        cursor: not-allowed;
        color: #b0b0b0;

        & svg {
            & path {
                stroke: #b0b0b0;
            }
        }
    }
}
