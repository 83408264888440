.wrapper {
    padding: 47px 44px 140px 44px;
    @media screen and (max-width: 576px) {
        padding: 47px 16px 140px 16px;
    }
}

.methodDiv {
    display: flex;
    align-items: center;
    gap: 17px;
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #e5e5e5;
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 18px;
    cursor: pointer;

    &:hover {
        border: 0.5px solid #004aad;
    }

    &.methodDivActive {
        background: #ebf3ff;
        border: 0.5px solid #004aad;
        .methodIcon {
            background: #004aad;
            border-color: #004aad;
            svg {
                stroke: #ffffff;
            }
        }

        &.customStrokeAndFillStyle {
            .methodIcon {
                svg {
                    stroke-width: 0.5;
                    stroke-linejoin: round;
                    stroke-linecap: round;

                    path {
                        stroke: #ffffff;
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}

.methodIcon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 10px;

    svg {
        stroke: #004aad;
    }

    &.customStrokeAndFillStyleIcon {
        svg {
            stroke-width: 0.5;
            stroke-linejoin: round;
            stroke-linecap: round;

            path {
                stroke: #004aad;
                fill: #004aad;
            }
        }
    }
}

.methodText {
    h4 {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
        margin-bottom: 5px;
    }

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #233862b2;
        margin: 0;
    }
}
