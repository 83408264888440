.CSSECreateAccount_FormSkeleton {
    display: flex;
    flex-direction: column;
    gap: 32px;
    background-color: #f9fcff;

    &.includesAvatar {
        gap: 92px;
    }
}

.CSSECreateAccount_FormSkeleton_Field {
    width: 100%;
    height: fit-content;

    .ant-skeleton-title {
        height: 24px;
    }

    .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
        width: 100% !important;
        margin-top: 16px;
        height: 60px;
        border-radius: 15px;

        &:not(:has(ant-skeleton-image)) {
            margin-top: 0 !important;
        }
    }

    .ant-skeleton-image {
        width: 100%;
        height: 201px;
        margin: 0 0 32px 0;
        border-radius: 10px;
    }
}
