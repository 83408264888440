.parent_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 16px 36px 16px;
}

.loadingContent {
    width: 100%;
    height: 374px;
    display: flex;
    align-items: center;
    justify-content: center;

    >div {
        display: block;
        width: 100%;
    }
}

.packageContent {
    margin: 51px 62px 0 60px;
    max-width: 393px;
    width: 100%;

    >h2 {
        color: #011A3C;
        text-align: center;
        font-family: "SF-Pro-Display-Semibold";
        font-size: 20px;
        font-style: normal;
        line-height: normal;
        margin: 0;
    }

    >p {
        color: rgba(35, 56, 98, 0.70);
        text-align: center;
        font-size: 15px;
        margin: 4px 0 44px 0;
    }

    @media only screen and (max-width: 500px) {
        margin: 51px 20px 0 20px;
    }
}

.details {
    display: flex;
    align-items: center;
    gap: 14px;
}

.detailsText {
    display: grid;
    gap: 4px;

    >div {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    h4 {
        font: 14px/21px 'SF-Pro-Display-Bold';
        color: #000f23;
        margin: 0;
        margin-left: 4px;
    }

    >div:last-child {
        span {
            color: var(--gray-scale-000000, #070C18);
            font-size: 14px;
            line-height: 150%;
        }

        svg {
            width: 16px !important;
            height: 16px !important;
        }

        path {
            stroke: #070C18;
        }
    }
}

.detailsTags {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;
    border-radius: 3px;
    width: fit-content;
    padding: 1.5px 8px;
    font: 12px/20px 'SF-Pro-Display-Semibold';
    color: #4d4d4d;
    letter-spacing: 1.02px;
}

.packageImg {
    width: 61px;
    height: 60.43px;
    border-radius: 6.62px;
    border: 0.41px solid #e2eeff;
    display: grid;
    place-items: center;

    img {
        width: 46.36px;
        height: 44.7px;
        object-fit: cover;
    }
}

.linkEl {
    height: 51px;
    border: 1px solid #e2eeff;
    background-color: #f9fcff;
    border-radius: 15px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    padding-left: 24px;

    p {
        font: 16px/26px 'SF-Pro-Display';
        color: #004aad;
    }

    >div {
        display: block;
        width: 100%;
    }
}