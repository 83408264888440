.SkeletalBox {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: auto auto auto;
    grid-gap: 12px;
    margin-top: 24px;

    @media screen and (max-width: 1123px) {
        grid-template-columns: auto auto;
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: auto;
    }
}

.SkeletalBoxStyle {
    display: flex;
}

.Card {
    display: inline-grid;
    grid-row-gap: 40px;
    padding: 20px 22px 22px 21px;
    width: 100%;
    height: 178px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    font-family: 'SF-Pro-Display';
    margin: 0 13px 14px 0;
    position: relative;
}

.CardStyle {
    width: 458px;
}
.NewSolutionCard {
    padding: 16px;
    width: 100%;
    height: 45px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    margin: 0 13px 14px 0;
    position: relative;
    cursor: pointer;
}

.NewSolutionSkeleton {
    border-radius: 3px;
    height: 15px !important;
    width: 99%;
}

.LargeRows {
    display: block;

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.MobileCards {
    display: none;

    @media screen and (max-width: 500px) {
        display: block;
    }
}

.MobileCard {
    display: none;

    @media (max-width: 620px) {
        display: grid;
        width: 100%;
        height: 188px;
        background: #ffffff;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        margin-top: 20px;
        padding: 24px 18px 32px 18px;

        > div {
            display: flex;
            justify-content: space-between;
        }

        > div:first-child > p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 17px;
            line-height: 27px;
            padding-top: 5px;
        }

        > div:last-child {
            align-items: flex-end;

            :last-child {
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 13px;
                margin-top: 5px;
            }
        }
    }
}

.ComponentBrandsCardsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > .ComponentBrandsCard {
        display: flex;
        flex-direction: column;
        gap: 16px;
        background-color: #fff;
        width: 100%;
        padding: 20px 10px;
        border-radius: 6px;

        & > .CardHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 30px;

            & .CardText {
                width: 30px;
            }
        }

        & .CardButton {
            height: 29px;
            width: 100px;
            border-radius: 30px;
        }

        & .CardText {
            height: 16px;
            width: 100%;
        }
    }
}
