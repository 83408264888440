.tooltip {
    position: relative;
    display: inline-block;
    font-family: 'SF-Pro-Display';
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: fit-content;
    height: fit-content;
    background-color: black;
    color: #fff;
    border-radius: 10px;
    padding: 5px 15px;
    position: absolute;
    z-index: 100;
    top: 110%;
    left: 0%;
    margin-left: -60px;
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: 65px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.arrowPositionLeft .tooltiptext::after {
    margin-left: -65px;
}

.arrowPositionCenter .tooltiptext::after {
    margin-left: 0px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
