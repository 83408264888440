@mixin flex-row {
    display: flex;
    flex-direction: row;
}

.ATPTOSTabs {
    @include flex-row;
    align-items: center;
    gap: 19px;
}

.ATPTOSBorder {
    @include flex-row;
    align-items: center;
    gap: 0px;
    margin: 19px 0 28px 0;

    .ATPTOSBorder1 {
        width: 98px;
        height: 3px;
        background-color: #004aad;
    }

    .ATPTOSBorder2 {
        width: 578px;
        height: 2px;
        background-color: #e5e5e5;
    }
}

.ATPTOSNoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 60px 0 26px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}
