.Wrapper {
    margin-top: 50px;
    font-family: 'SF-Pro-Display';

    @media screen and (min-width: 2500px) {
        width: 40vw;
    }
}

.SecurityRow {
    display: flex;
    gap: 173px;
    margin-top: 35px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 56px;
    }

    @media screen and (max-width: 800px) {
        gap: 20px;
    }
}

.RightWrapper {
    width: fit-content;
}

.Header {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
    margin-bottom: 8px;
    padding-top: 10px;
}
.SubHeader {
    font-size: 14px;
    line-height: 19px;
    color: rgba(35, 56, 98, 0.8);
}
.FullName {
    display: flex;
    width: 389px;
    justify-content: space-between;
}
.SaveBtn {
    margin-top: 60px;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
        width: 100%;
        margin-top: 80px;
        justify-content: center;
    }

    @media screen and (min-width: 1920px) {
        width: 88%;
    }
}
.SecurityPasswordWrapper {
    display: flex;
    width: 389px;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;
    }
}
