.SecondaryModal {
    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-body {
        padding: 0px;
    }

    @media screen and (max-width: 600px) {
        border-radius: 0px !important;
        background: #fff;

        .ant-modal-wrap {
            overflow-x: hidden !important;
        }

        .ant-modal-content {
            position: fixed;
            border-radius: 0px;
            width: 100vw;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.NewSecondaryModal {
    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-body {
        padding: 0px;
    }

    // @media screen and (max-width: 500px) {
    //     .ant-modal {
    //         max-width: 64 !important;
    //         margin: unset;
    //         // top: 0;
    //         left: 0;
    //         right: 0;
    //         bottom: 0 !important;
    //         height: 0px !important;
    //     }

    //     .ant-modal-centered::before {
    //         content: unset;
    //     }

    //     .ant-modal-body {
    //         width: 100vw !important;
    //         min-height: 105vh;
    //     }
    // }
}

.RecommendedEnergy {
    .ant-modal-content {
        border-radius: 10px;
        top: -10px;
    }

    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-wrap {
        overflow-x: hidden !important;
    }

    @media screen and (max-width: 500px) {
        .ant-modal-content {
            width: 100vw;
            border-radius: 0px;
        }
    }
}

.adminModal {
    .ant-modal-content {
        border-radius: 10px;
        top: 40px;
    }

    .ant-modal-body {
        padding: 0px;
    }

    .ant-modal-wrap {
        overflow-x: hidden !important;
    }

    @media screen and (max-width: 500px) {
        .ant-modal-content {
            width: 100vw;
            border-radius: 0px;
            top: 0px;
        }
    }
}

.ant-carousel .slick-dots {
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
}

@media screen and (max-width: 500px) {
    .ant-modal {
        max-width: 64 !important;
        margin: unset;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0px !important;
    }

    .ant-modal-centered::before {
        content: unset;
    }

    .ant-modal-body {
        width: 100vw !important;
        min-height: 105vh;
    }

    .NewSecondaryModal {
        top: unset !important;

        .ant-modal {
            margin: auto 15px;
            top: unset;
            bottom: 0 !important;
            height: inherit !important;
        }

        .ant-modal-body {
            width: calc(100vw - 30px) !important;
        }

        .ant-modal-content {
            width: calc(100vw - 30px) !important;
        }
    }
}

.ant-modal-close {
    top: 6px;

    @media screen and (max-width: 600px) {
        top: 8px;
    }
}

.SecondaryModalV2 {
    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-body {
        padding: 0;
    }

    @media only screen and (max-width: 500px) {
        .ant-modal-content {
            border-radius: 15px 15px 0 0;
            width: 100vw;
            position: fixed;
            top: var(--modal-top);
            bottom: 0;
        }

        .ant-modal-body {
            min-height: 80vh;
            max-height: 82vh;
        }
    }
}

.SecondaryModalV2ContentWrapper {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto fit-content(100%);

    @media only screen and (max-width: 500px) {
        //padding to keep the content passed in the modal from being hidden by the footer

        > div {
            padding-bottom: 80px !important;
        }

        &:has(.payment_plans_style) {
            > div {
                padding-bottom: 180px !important;
            }
        }
    }

    @media only screen and (max-width: 500px) and (max-height: 700px) {
        > div {
            padding-bottom: 250px !important;
        }
    }
}

.SecondaryModalTitleWrapper {
    padding: 18px 0;
    border-bottom: 0.3px solid #b0b0b0;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #000000;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h2 {
            max-width: 300px;
        }
    }
}

.SecondaryModalFooterWrapper {
    border-top: 0.3px solid #b0b0b0;
    padding: 14px 42px 21px 42px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 0 0 15px 15px;

    @media only screen and (max-width: 500px) {
        border-radius: 0;
        align-items: flex-start;
        padding: 9px 20px;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 70px;

        > div {
            margin-top: 11px;
        }
    }
}

.videoModal {
    .ant-modal-body {
        padding: 0px;

        @media screen and (max-width: 600px) {
            padding: 20px;
        }
    }

    .ant-modal-close {
        top: -10px;
        right: -40px;

        @media screen and (max-width: 600px) {
            right: 0;
        }
    }

    @media screen and (max-width: 600px) {
        border-radius: 0px !important;

        .ant-modal-wrap {
            overflow-x: hidden !important;
        }

        .ant-modal-content {
            background: transparent;
            position: fixed;
            border-radius: 0px;
            width: 100vw;
            top: 300px;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.SecondaryModal_CloseIcon {
    width: 14.32px !important;
    height: 14.32px !important;
}
