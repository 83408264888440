.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: block;
    }
}

.OverviewWrapper {
    margin-left: 5%;
    display: flex;

    @media screen and (min-width: 1450px) {
        transform: scale(1.05);
        transform-origin: top;
        justify-content: unset;
    }

    @media screen and (min-width: 1900px) {
        transform: scale(1.1);
        transform-origin: top;
        margin-top: 20px;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media screen and (max-width: 991px) {
        margin-left: 50px;
        margin-right: unset;
    }

    @media screen and (max-width: 768px) {
        margin-top: 62px;
    }

    @media screen and (max-width: 575px) {
        margin-left: 10px;
    }

    @media screen and (max-width: 500px) {
        display: block;
        margin: 0px;
        margin-top: 60px;
    }
}

.TopSection {
    width: 310px;
    height: 175px;
    background: #011a3c;
    border-radius: 10px;
    position: relative;
    margin-right: 15px;

    @media screen and (max-width: 1280px) {
        margin-bottom: 30px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.OverviewImage {
    margin-top: -18px;
    height: 193px;
}

.Overlay {
    position: absolute;
    line-height: 32px;
    color: #ffffff;
    left: 45%;
    top: 55%;
    font-family: 'SF-Pro-Display';
}

.Product {
    font-size: 18px;
    top: 25px;
    font-family: 'SF-Pro-Display-Thin';
}

.Library {
  font-size: 21px;
  top: 50px;
  font-family: 'SF-Pro-Display-Bold';
  letter-spacing: 0.9px;
}

.OverlayBtn {
    bottom: 40px;
}

.OverviewPackages {
    width: 117px;
    height: 176px;
    background: #ffffff;
    border-radius: 10px;
    margin-right: 15px;

    @media screen and (min-width: 1440px) {
        width: 130px;
    }

    @media screen and (max-width: 500px) {
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        min-width: 117px;
        margin: 3px 15px 3px 3px;
    }

    @media screen and (max-width: 350px) {
        width: 80px;
    }
}

.SectionWrapper {
    margin: 0px 15px 15px;
}

.SubHeading {
  font-size: 9px;
  font-family: 'SF-Pro-Display-Semibold';
  line-height: 12px;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: rgba(35, 56, 98, 0.81);
}

.Total {
  font-size: 16px;
  line-height: 21px;
  color: #011a3c;
  font-family: 'SF-Pro-Display-Bold';
}

.ImageWrapper {
    height: 115px;
}

.CardImage {
    height: 90px;
}

.ActivityWrapper {
    display: flex;
    margin: 50px 85px 50px 12%;

    @media screen and (min-width: 1450px) {
        transform: scale(1.05);
        transform-origin: top;
        justify-content: unset;
        margin-left: 133px;
    }

    @media screen and (min-width: 1900px) {
        transform: scale(1.1);
        transform-origin: top;
        margin: 100px 117px 50px 185px;
        width: 55% !important;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media screen and (max-width: 800px) {
        margin: 50px 0px;
    }

    @media screen and (max-width: 575px) {
        margin-left: 10px;
    }

    @media screen and (max-width: 500px) {
        display: block;
        margin: 0px;
        margin-top: 50px;
    }
}

.OverlayEstimation {
  font-size: 15px;
  line-height: 18px;
  color: #011a3c;
  font-family: 'SF-Pro-Display-Medium';
  padding-top: 11px;

  @media screen and (max-width: 500px) {
    margin-bottom: 30px;
    font-family: 'SF-Pro-Display-Medium';
  }
}

.RecentActivities {
    @media screen and (max-width: 500px) {
        margin-top: 50px;
    }
}

.EstimationWrapper {
    width: 520px;
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: block;
    margin-right: 15px;
    padding-top: 10px;

    @media screen and (min-width: 1440px) {
        margin-right: 56px;
    }

    @media screen and (max-width: 1024px) {
        width: 470px;
        height: 270px;
        margin-bottom: 40px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
        padding-bottom: 15px;
    }
}

.NoActivityWrapper {
    width: 310px;
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
        width: 450px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.ActivitiesWrapper {
    width: 310px;
    height: 300px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    margin-top: 35px;

    @media screen and (max-width: 1024px) {
        width: 470px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.EmptyStateWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
}

.EmptyHeading {
  font-style: normal;
  font-family: 'SF-Pro-Display-Bold';
  font-size: 16px;
  line-height: 19px;
  color: #011a3c;
  margin-top: 20px;
  margin-bottom: 5px;
}

.EmptyText {
    font-size: 12px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
}

.ImgWrapper {
    display: flex;
    width: 35px;
    height: 35px;
    background: #f1fff6;
    border-radius: 21px;
    margin-right: 10px;
}

.ImgStyle {
    align-items: center;
    justify-content: center;
    padding: 9px;
}

.RowWrapper {
    border-bottom: 1px solid #f7f7f7;
    padding: 25px 0px;

    @media screen and (min-width: 500px) {
        padding: 13px 0px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    @media screen and (min-width: 500px) and (max-width: 1024px) {
        gap: 16px;
    }
}

.AdminRowWrapper {
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 0px;

    &:last-child {
        border-bottom: none;
    }

    div:first-child {
        align-self: flex-start;
        margin: 1px 10px 0 0;
    }

    @media screen and (max-width: 500px) {
        padding: 18px 0px;

        div:last-child {
            width: 100%;

            p {
                width: 50vw;
            }
        }
    }
}

.ActivityRow {
    display: flex;
    gap: 20px;
}

.ActivityText {
    display: flex;

    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 500px) {
        margin-top: 7px;
        gap: 5px;
    }
}

.Activity {
  width: 245px;
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  line-height: 23px;
  color: #011a3c;

    @media screen and (min-width: 1025px) {
        width: unset;
        max-width: 245px;
    }

    @media screen and (max-width: 1024px) {
        width: 300px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 0;
    }
}

.ActivityDate {
    display: block;

    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;

    letter-spacing: 0.095em;
    text-transform: uppercase;
    color: rgba(35, 56, 98, 0.81);
    text-align: right;

    @media screen and (max-width: 1024px) {
        margin-top: unset;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.AnalyticBtn {
    position: absolute;
    width: 520px;
    text-align: right;
    height: 270px;
    margin-top: 55px;
    margin-right: 40px;

    @media screen and (min-width: 1600px) {
        height: 450px;
    }

    @media screen and (max-width: 1024px) {
        width: 450px;
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.MobilePackageWrapper {
    display: flex;

    @media screen and (min-width: 1400px) {
        padding-right: 15px;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        overflow: scroll;
        scrollbar-width: none !important;
    }
}

.BarChartDataContainer {
    display: block;
}

.MobileAnalyticBtn {
    @media screen and (max-width: 500px) {
        display: block;
        text-align: left;
        margin-bottom: 25px;
        width: 114px;
    }
}

.MobileBtnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 510px;

    @media screen and (max-width: 1024px) {
        width: 450px;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
    }
}

.HelpCenterWrapper_Overview {
    position: inherit;
    bottom: 35px;
    right: 31px;
}
