.MaintenanceSectionWrapper {
  border-radius: 10px;
  border: 1px solid #E2EEFF;
  background: #F9FCFF;
  height: 616px;

  @media screen and (max-width: 500px) {
    height: 100%;
  }

  .MaintenanceSectionDiv {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .TextStyle {
    color: #4D4D4D;
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    width: 423px;

    @media screen and (max-width: 500px) {
      width: 100%;
    }

  }

  .SubTitleText {
    color: #233862;
    font-family: 'SF-Pro-Display-Semibold';
    margin: 25px auto 5px;
  }

  .MaintenanceFooterTextStyle {
    color: #011A3C;
    font-family: 'SF-Pro-Display-Bold';
    margin: 20px auto;

  }
}
