.wrapper {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.32px;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    @media screen and (max-width: 480px) {
        margin: auto;
        z-index: 6;
    }
    .ant-alert {
        max-width: fit-content;
        @media screen and (max-width: 480px) {
            max-width: 90vw;
        }
         @media screen and (max-width: 375px) {
            max-width: 96vw;
        }
    }
}

.error-wrapper {
    .ant-alert-message {
        color: #da1e28;
        display: inline-block;
    }

    .ant-alert-error {
        background-color: #fff1f1;
        border: none;
        border-radius: 10px;
        font-size: 12px;
    }

    .ant-alert-error .ant-alert-icon {
        color: #da1e28;
    }
}

.success-wrapper {
    .ant-alert-message {
        color: #18b661;
    }

    .ant-alert-success .ant-alert-icon {
        color: #18b661;
    }

    .ant-alert-success {
        background-color: #f2fef9;
        border: none;
        border-radius: 10px;
    }
}

.success-secondary-wrapper {
  
    .ant-alert-message {
        color: #ffffff;
    }

    .ant-alert-success .ant-alert-icon {
        color: #18b661;
    }

    .ant-alert-success {
        background-color: #000f23;
        box-shadow: 0px 47.7922px 167.273px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 12px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }

    .ant-alert-close-icon .anticon-close {
        color: #ffffff;
    }
}

.error-secondary-wrapper {

    .ant-alert-message {
        color: #ffffff;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
    }

    .ant-alert-error {
        background-color: #000f23;
        box-shadow: 0px 47.7922px 167.273px rgba(0, 0, 0, 0.1);
        border: none;
        border-radius: 12px;
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }

    .ant-alert-close-icon .anticon-close {
        color: #ffffff;
    }
}
.toast-fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.toast-fade-none {
    display: none;
}
