.UpfrontDepositModalWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 367px;
    position: relative;
}

.InputsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.UpfrontDepositBtn {
    position: absolute;
    bottom: 0;
}