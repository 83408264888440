.StepperWrapper {
    display: flex;

    > div {
        :first-child {
            display: flex;
            align-items: center;
        }
    }

    @media screen and (max-width: 578px) {
        justify-content: center;
    }
}

.StepperTitle {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    color: #004aad;
    position: relative;
    left: -40px;
    margin: 6px 0 0 0;

    @media screen and (max-width: 578px) {
        left: 0;
        width: 50px;
    }
}

.StepperEllipse {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background: #e2eeff;

    @media screen and (max-width: 578px) {
        position: absolute;
        left: 12px;
    }
}

.StepperLine {
    height: 3px;
    width: 250px;
    background: #e2eeff;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        width: 132px;
    }

    @media screen and (max-width: 578px) {
        margin-left: 12px;
        margin-right: -12px;
    }
}

.StepperProgress {
    width: 0px;
    height: 3px;
    background: #004aad;
    border-radius: 2px;
}
