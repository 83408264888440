.TableItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 29.76px 0 20px;

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }

    h4 {
        font-family: 'SF-Pro-Display-Medium';
        font-style: normal;
        font-size: 14px;
        line-height: 25px;
        color: #011a3c;
        text-align: left;
        margin: 0;
    }
}

.RightSection {
    display: flex;
    align-items: center;
    p {
        font-family: 'SF-Pro-Display';
        font-style: normal;
        font-size: 14px;
        line-height: 25px;
        color: #011a3c;
        text-align: right;
        margin-right: 42.76px;

        @media screen and (max-width: 401px) {
            margin-right: 14.76px;
        }
    }
}

.CloseButton {
    cursor: pointer;
}

.CloseBtnDisabled {
    cursor: not-allowed;
}

.RemoveButton {
    text-align: center;
    margin-top: 61px;
}
