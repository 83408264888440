.DrawerContainer {
    width: 100%;

    @media screen and (max-width: 600px) {
        .ant-drawer-close {
            display: none;
        }

        .ant-drawer-body {
            width: 100%;
        }
    }
}

.SelectEnergyCloseAndBackNav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 0;

        img {
            width: 15px;
            height: 15px;
        }
    }
}

.SelectEnergyContentWrapper {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 50px);
}

.SelectEnergyTitleAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 50px;
}

.SelectEnergyTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #011a3c;
    margin: 0;
}

.SearchProductWrapper {
    margin-bottom: 40px;
}

.EmptySearchWrapper {
    display: flex;
    margin: auto;
}

.EnergyProductPaginationWrapper {
    display: flex;
    justify-content: center;
    margin-top: auto;

    a {
        font-size: 12px;
    }

    .ant-pagination-item {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        width: 35px;
    }

    .ant-pagination-item a {
        margin-top: 6px;
    }

    .ant-pagination-jump-next {
        margin-right: 5px;
    }

    .ant-pagination-next .ant-pagination-item-link {
        background-color: transparent;
    }

    .ant-pagination-prev {
        margin-right: 0px;
    }
}