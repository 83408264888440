@media screen and (min-width: 1900px) {
  .DropdownInput {
    width: 0;
  }
}


.FormTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 22px;
  line-height: 27px;
  color: #011A3C;
  text-align: center;
}

.FormSubTitle {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  text-align: center;
  margin-top: 5px;
}

.ButtonBox {
  text-align: center;
  margin-top: 30px;
}

.CreateAccount {
  font-family: 'SF-Pro-Display';
  text-align: center;
  margin-top: 10px;
  color: rgba(35, 56, 98, 0.8);

  a {
    color: #004AAD;
    font-family: 'SF-Pro-Display-Semibold' !important;
  }
}

.ForgotPasswordText {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 26px;
  color: #233862;
  display: inline-block;
  margin: 0px 0px 30px 10px !important;
  cursor: pointer;
  position: relative;
}

.ForgotPasswordText:hover {
  color: var(--hover-text-blue);
}

.SwitchToOptionWrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
}

.FormBoxText {
  font-family: 'SF-Pro-Display';
  color: var(--blue);
  font-size: 15px;
  line-height: 26px;
  font-family: 'SF-Pro-Display-Medium';
  margin-top: 18px;
  cursor: pointer;
  width: fit-content;
}

.FormBoxText:hover {
  color: var(--hover-text-blue);
}