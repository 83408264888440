.AULSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.AULHeaderWrapper {
    margin: 0px 60px 20px;

    .AULMobileDropdown {
        display: none;
    }

    @media (max-width: 992px) {
        margin: 24px 12px;

        .AULMobileDropdown {
            display: block;
        }

        .AULMobileDropdown path {
            fill: #fff;
        }
    }
}

.AULWrapper {
    padding: 44px 136px 101px 112px;

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        padding: 44px 60px 101px 60px;
    }

    @media (max-width: 992px) {
        padding: 24px 12px;
    }
}

.AULFilterBox {
    display: flex;
    gap: 23px;
    height: 45px !important;
    width: 130px;
    margin-left: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 10px !important;
    padding: 11px 20px;
    cursor: pointer;

    @media (max-width: 576px) {
        width: auto;
    }
}

.AULFilterText {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: rgba(1, 26, 60, 0.8);

    @media (max-width: 576px) {
        display: none;
    }
}

.AULBtnWrapper {
    display: flex;
    grid-column-gap: 9px;

    @media (max-width: 992px) {
        display: none;
    }
}

.AULTabWrapper {
    margin-top: 30px;
}

.HideUserDesktop {
    @media (max-width: 801px) {
        display: none !important;
    }
}

.AULTableWrapperUsers {
    margin-top: 52px;
}

.AULTableWrapperGroups {
    margin-top: 52px;

    @media (max-width: 992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-column-gap: 12px;
    }
}

.AULTableHeader {
    display: grid;
    padding: 0 60px 0 49px;
    grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
    padding-bottom: 6px;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);

    @media (max-width: 992px) {
        display: none;
    }
}

.AULTableContentUsers {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011a3c;
    background: #ffffff;

    > span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    > div > p {
        display: none;
    }
}

.AULTableContentGroups {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 2fr 2fr 1fr 2fr 1fr;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011a3c;
    background: #ffffff;

    > span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    > div > p {
        display: none;
    }

    @media (max-width: 992px) {
        display: grid;
        flex-basis: 330px;
        width: 100%;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        height: 178px;
        padding: 0px 20px;
        justify-content: space-between;
        gap: 12px;
        grid-template-areas: 'a b' 'c d';

        & > div:nth-child(1) {
            grid-area: a;
        }

        & > div:nth-child(3) {
            grid-area: d;
            text-align: right;
        }

        & > div:nth-child(4) {
            grid-area: c;
        }

        & > span:nth-child(5) {
            grid-area: b;
            text-align: right;
        }

        > :nth-child(2) {
            display: none;
        }

        > div > p {
            display: block;
            text-transform: capitalize;
            font-family: 'SF-Pro-Display-Medium';
            font-style: normal;
            font-size: 8px;
            line-height: 10px;
            letter-spacing: 0.055em;
            color: rgba(1, 26, 60, 0.85);
            margin: 0 0 5px 0;
        }
    }
}

.AULTableContentGroupsSecondary {
    @media (max-width: 992px) {
        & > div:nth-child(4) {
            grid-area: d;
            text-align: right;
        }

        & > div:nth-child(3) {
            grid-area: c;
            text-align: left;
        }
    }
}

.AULPaginationWrapper {
    text-align: center;
    margin-top: 42px;
}

.AULInviteWrapper {
    padding: 48px 0px 0 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 500px) {
        padding: 65px 23px 10px 23px;
    }
}

.AULInviteTitle {
    text-align: center;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin-bottom: 0px;

    @media screen and (max-width: 500px) {
        text-align: left;
    }
}

.AULInviteSubTitle {
    text-align: center;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
    margin-top: 9px;

    @media screen and (max-width: 500px) {
        text-align: left;
    }
}

.AULInviterUserScrollWrapper {
    position: relative;
    height: 500px;
    max-width: 460px;
    width: 100%;

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        height: 405px;
    }
}

.AULInviteUserWrapper {
    margin-top: 40px;
    max-height: 370px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: left;

    > div:first-child {
        margin-top: 0;
    }

    > div {
        margin-top: 38px;
    }

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        margin-top: 30px;
        max-height: 300px;
    }

    @media screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.AULInviteUserTitle {
    text-align: left;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
}

.AULAddUserWrapper {
    margin: 30px 0 52px 0;
    text-align: right;

    @media only screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.AULRemoveUserWrapper {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 7px;
}

.AULInviteUserBtnWrapper {
    @media only screen and (max-width: 500px) {
        padding-right: 0;
    }
}

.AULRemoveUserText {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 11px;
    line-height: 13px;
    color: #004aad;
    padding-left: 5px;
}

.AULPlusIcon {
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.AULEndDateFilter {
    position: absolute;
    right: 170px;
}

@media screen and (min-width: 501px) {
    .ant-modal {
        top: 0px;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
    }
}

.AULFilterButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.CustomWrapperStyle {
    width: 72px;
    height: 23px;
    background: #e2eeff;
    border-radius: 3px;

    @media screen and (max-width: 500px) {
        width: 58px;
    }
    p {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        display: flex;
        justify-content: center;
        padding: 3px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #004aad;
        @media screen and (max-width: 500px) {
            font-size: 10px;
        }
    }
}

.ACLMobileDiv {
    display: none;

    @media screen and (max-width: 501px) {
        display: block;
        margin-top: 20px;
    }
}

.GroupNameWrapper {
    p {
        display: none;

        @media screen and (max-width: 992px) {
            display: block;
            text-transform: capitalize;
            font-family: 'SF-Pro-Display-Medium';
            font-style: normal;
            font-size: 8px;
            line-height: 10px;
            letter-spacing: 0.055em;
            color: rgba(1, 26, 60, 0.85);
            margin: 0 0 5px 0;
        }
    }

    @media screen and (max-width: 500px) {
        max-width: 70px;
    }
}
