.OfferOverviewWrapper {
    width: 807px;
    height: 206px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 20px;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.OfferTopCardWrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.ProfileWrapper {
    display: flex;

    @media screen and (max-width: 500px) {
        justify-content: space-around;
    }
}

.ProfileInfo {
    margin-top: 20px;
    margin-left: 20px;

    @media screen and (max-width: 500px) {
        margin-top: 7px;
        margin-left: 47px;
    }
}

.ProfileName {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    margin-bottom: 6px;
}

.ProfileEmail {
    font-size: 14px;
    line-height: 17px;
    color: rgba(35, 56, 98, 0.8);

    max-width: 100%;
    word-break: break-word;
}

.StatusWrapper {
    margin-right: 40px;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.StatusWrapperMobile {
    display: none;

    @media screen and (max-width: 900px) {
        margin-right: 40px;
        display: block;
    }
}

.OfferStatus {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 20px;

    @media only screen and (max-width: 500px) {
        margin-bottom: 12px;
    }
}

.StatusTag {
    width: 129px;
    height: 23px;
    background: #e2eeff;
    border-radius: 3px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #004aad;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HorizontalLine {
    border: 1px solid #f7f7f7;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.OfferBottomCardWrapper {
    display: flex;
    justify-content: space-around;
    padding: 14px;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.OfferBottomText {
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.PersonalIdentity {
    margin-left: -50px;
}

.CardTextStyle {
    margin-bottom: 0px;
    margin-left: 8px;
}

.RecentUpdateWrapper {
    margin: 70px auto;
}

.RecentUpdateText {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
}

.RowWrapper {
    display: flex;
}

.RecentActivityColumn {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    @media screen and (max-width: 900px) {
        margin-bottom: 10px;
        width: 100%;
    }
}

.RecentActivityWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
}

.ImgStyle {
    width: 40px;
    height: 40px;
    background: #f1fff6;
    border-radius: 21px;
    align-items: center;
    justify-content: center;
    padding: 9px;
    margin-right: 20px;
}

.RecentActivity {
    font-size: 14px;
    line-height: 23px;
    color: #011a3c;
    margin-bottom: 14px;

    @media screen and (max-width: 900px) {
        max-width: 70%;
    }
}

.RecentActivityV2 {
    font: 12px/23px 'SF-Pro-Display';
    color: #011a3c;
    margin-bottom: 8px;

    @media screen and (max-width: 900px) {
        font: 14px/24px 'SF-Pro-Display';
        max-width: 70%;
    }
}

.RecentActivityDate {
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    letter-spacing: 0.095em;
    text-transform: uppercase;
    color: rgba(35, 56, 98, 0.81);
}

.VerticalLine {
    width: 1px;
    height: 100px;
    border-right: 1px solid #f7f7f7;
    margin: 0px 20px;
}

.SelectSolution {
    width: 746px;
    height: 78px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media screen and (max-width: 900px) {
        width: 100%;
        display: block;
        height: fit-content;
        padding: 5px;
        margin-bottom: 20px;
    }
}

.BtnStyle {
    width: 155px;
    height: 37px;
    background: #004aad;
    border-radius: 25px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    border: none;
    margin-right: 20px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.BtnStyleV2 {
    width: auto;
    min-width: 103px;
    padding: 0 18px;
    height: 37px;
    background-color: #e2eeff;
    color: #004aad;
    font-family: 'SF-Pro-Display';

    &:hover {
        background-color: #004aad;
        color: #ffffff;
    }

    @media screen and (max-width: 900px) {
        display: block;
        padding: 0;
        margin: 0px 0 0 20px;
    }
}

.SelectEnergy {
    display: flex;
    align-items: center;
    margin-left: 20px;
    min-height: 60px;

    @media screen and (max-width: 900px) {
        max-width: 100%;
        padding-bottom: 13px;
    }
}

.SelectEnergyImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #f8f8f8;
    border-radius: 20px;
}

.SelectEnergyTextWrapper {
    margin-left: 20px;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.SelectEnergyTextWrapperMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: block;
        margin-top: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
}

.SelectEnergyBoldText {
    margin-bottom: 2px;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin-bottom: 8px;
}

.SelectEnergyText {
    font-size: 12px;
    line-height: 14px;
    color: #011a3c;
    font-family: 'SF-Pro-Display-Thin';
}

.StatusImageWrapper {
    margin: 30px 0px;
}

.StatusImageWrapperV2 {
    width: 100%;

    > div {
        width: 100%;
    }

    .RecentActivityColumn {
        width: 100%;
    }
}

.CommentWrapper {
    margin-top: 10px;

    @media screen and (max-width: 900px) {
        display: none;
    }
}

.CommentWrapperMobile {
    display: none;

    @media screen and (max-width: 900px) {
        display: block;
        margin-top: 10px;
        cursor: pointer;
    }
}

.CommentText {
    font-size: 14px;
    line-height: 22px;
    color: #011a3c;
    width: 554px;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
}

.requestDetailsOfferWrapper {
    background: transparent;
    box-shadow: none;
    height: 100%;
}

.requestCardWrapper {
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.NoActivityWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    font-family: 'SF-Pro-Display';
}

.NoActivityHeading {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
}

.NoActivitySubheading {
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);
    text-align: center;
}

.ProfileImageStyle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
}

.ant-layout * {
    box-sizing: border-box;
    width: 100%;
}

.MobileRequestUpdateBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;

    @media screen and (min-width: 500px) {
        display: none;
    }
}

.RequestUpdateDesktopBtn {
    margin-top: 20px;

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.SelectSolutionV2 {
    width: 100%;

    .SelectEnergy {
        margin-left: 0px;
    }

    .SelectEnergyBoldText {
        font: 14px/17px 'SF-Pro-Display-Medium';
    }

    .SelectEnergyText {
        font-family: 'SF-Pro-Display';
    }
}
