.CtaBox {
    margin-top: 5%;
    background-color: var(--black-2);
    padding: 20px 0px;
    background: url(../../assets/images/bg-blue.png), #011A3C;
    background-attachment: fixed;

    .TextBoxContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .TextBox {
        display: inline-block;
        padding: 70px;

        @media screen and (max-width: 1230px) and (min-width: 500px) {
            width: 700px;
            margin-left: 50px;
        }

        @media screen and (min-width: 500px) and (max-width: 800px) {
            margin-left: 0px;
        }

        @media screen and (max-width: 800px) {
            width: 70%;
        }

        @media screen and (max-width: 500px) {
            padding: 20px;
            padding-bottom: 50px;
            width: 100%;
        }

        @media screen and (max-width: 350px) {
            margin-left: 10px;
            padding: 20px;
        }
    }

    .CircleBox {
        position: relative;
        left: -6px;
        top: -40px;
        width: 100px;
        height: 50px;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }

    .WaveBox {
        text-align: right;
        margin-top: -27%;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }

    .Title {
        color: var(--white);
        font-family: 'SF-Pro-Display-Bold';
        font-style: normal;
        font-size: 45px;
        line-height: 47px;
        width: 438px;

        @media screen and (max-width: 1921px) and (min-width: 1580px) {
            font-size: calc(60px + 5px);
            width: 700px;
            line-height: calc(66px + 10px);
        }

        @media screen and (min-width: 1922px) {
            font-size: calc(80px + 5px);
            width: 80%;
            line-height: calc(66px + 20px);
        }

        @media screen and (min-width: 2700px) {
            font-size: calc(100px + 5px);
            width: 70%;
            line-height: calc(106px + 20px);
        }

        @media screen and (max-width: 800px) {
            width: 330px;
        }

        @media screen and (max-width: 500px) {
            font-size: 42px;
            line-height: 47px;
            width: 329px;
        }
    }

    .PartnersTitle {
        color: var(--white);
        font-family: 'SF-Pro-Display-Bold';
        font-style: normal;
        font-size: 45px;
        line-height: 47px;
        width: 523px;

        @media screen and (max-width: 1921px) and (min-width: 1580px) {
            font-size: calc(60px + 5px);
            width: 750px;
            line-height: calc(66px + 10px);
        }

        @media screen and (min-width: 1922px) {
            font-size: calc(80px + 5px);
            width: 80%;
            line-height: calc(66px + 20px);
        }

        @media screen and (min-width: 2700px) {
            font-size: calc(100px + 5px);
            width: 70%;
            line-height: calc(106px + 20px);
        }

        @media screen and (max-width: 800px) {
            width: 400px;
        }

        @media screen and (max-width: 500px) {
            font-size: 42px;
            line-height: 47px;
            width: 329px;
        }
    }

    .SubTitle {
        color: var(--yellow-2);
        font-family: 'SF-Pro-Display-Semibold';
        font-style: normal;
        font-size: 12px;
        line-height: 23px;
        letter-spacing: 0.07em;
        text-transform: uppercase;

        @media screen and (max-width: 1921px) and (min-width: 1580px) {
            font-size: calc(10px + 5px);
            width: calc(100% + 10px);
            line-height: calc(66px + 10px);
        }

        @media screen and (min-width: 1922px) {
            font-size: calc(20px + 5px);
            width: 80%;
            line-height: calc(66px + 20px);
        }

        @media screen and (min-width: 2700px) {
            font-size: calc(30px + 5px);
            width: 70%;
            line-height: calc(106px + 20px);
        }

        @media screen and (max-width: 500px) {
            margin-top: 50px;
        }
    }

    .ActionBox {
        display: flex;
        margin-top: 35px;

        @media screen and (max-width: 500px) {
            display: inline-block;

            .ActionBoxBtn2 {
                margin: 17px 0 50px 0;
            }
        }

        .ActionBoxBtn2 {
            @media screen and (max-width: 500px) {
                margin: 17px 0 94px 0;
            }
        }
    }
}