.AdminProvidersActiveDropdownLinks {
    margin-right: 10px;
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #233862;
    padding-bottom: 15px;

    &.ComponentTypes {
        @media only screen and (max-width: 500px) {
            margin-right: 0;

            span {
                width: 250px;
            }
        }
    }
}
