.CustomersListingsWrapper {
  margin-left: 72px;
  @media screen and (max-width: 500px) {
      margin-left: 0px;
    }
}

.SubTitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  line-height: 19px;
  color: #011A3C;
  margin: 100px auto 30px;
}
.MobileCLWrapper {
  display: none;

  @media screen and (min-width: 600px) and (max-width: 800px) {
    width: 90%;
  }

  @media screen and (max-width: 800px) {
    display: block;
    max-width: 100%;
    height: 245px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 28px 15px;
  }
}

.MobileTopSection {
  display: flex;
  justify-content: space-between;
}

.CLMobileTableHeader {
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  line-height: 14px;
  color: rgba(1, 26, 60, 0.8);
}

.CLMobileText {
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  line-height: 16px;
  color: #011A3C;
  max-width: 148px;
  word-wrap: break-word;
}

.CLBtnWrapper {
  margin-top: 22px;
  text-align: center;

}
