.wrapper {
    border: 1px solid #f3f3f3;
    border-radius: 24px;
    width: 100%;
    height: 502px;
    grid-column: 1 / 3;
    position: relative;
    background-color: transparent;

    > span {
        width: 100%;
        height: 100%;
        border-radius: 24px;
        position: relative;
        z-index: 1;

        > img {
            width: 100%;
            border-radius: 24px;
            object-fit: contain;
        }
    }

    @media only screen and (max-width: 500px) {
        height: 274px;
        > span {
            border-radius: 12px;

            > img {
                border-radius: 12px;
                width: 100%;
                height: 274px !important;
            }
        }
    }
}

.bottomPanel {
    height: 78px;
    width: 100%;
    background: rgba(255, 255, 255, 0.66);
    position: absolute;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;

    > h2 {
        font: 18px/27px var(--font-regular);
        color: #070c18;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        height: fit-content;
        padding: 8px;
        justify-content: flex-start;

        > h2 {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px;
        }
    }
}

.imageSelection {
    display: flex;
    align-items: center;
    gap: 13px;

    @media only screen and (max-width: 500px) {
        gap: 8px;
    }
}

.imageOption {
    width: 59px;
    height: 59px;
    border-radius: 6px;
    cursor: pointer;
    padding: 0;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px !important;
    }

    &.imageOptionActive {
        border: 1px solid #004aad;
    }

    @media only screen and (max-width: 500px) {
        border-radius: 6px !important;
        width: 48px;
        height: 48px;
    }
}

.overlayInactive {
    display: none;
}

.overlayActive {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    pointer-events: none;
    border-radius: 6px;
}
