.button {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
}

.left {
    background: #f9fcff;
    border: 1px solid #e2eeff;
    border-radius: 15px 0px 0px 15px;
    width: 73px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right {
    display: flex;
    align-items: center;
    padding-left: 12px;
    border: 1px solid #e5e5e5;
    border-radius: 0 15px 15px 0;
    border-left: none;
    width: 100%;
    height: 60px;
    background: #ffffff;

    p {
        font: 14px/26px 'SF-Pro-Display-Light';
        color: #233862;
    }
}

.uploadedBtn {
    width: 100%;
    height: 60px;
    background: #e2eeff;
    border: 0.5px dashed rgba(0, 74, 173, 0.79);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 19px;

    @media screen and (max-width: 480px) {
        width: 100%;
    }

    svg:last-child {
        margin-left: auto;
        cursor: pointer;
    }

    p {
        font: 14px/22px 'SF-Pro-Display';
        color: #004aad;
        margin-left: 8px;
        max-width: 70%;
        word-break: break-word;
    }
}

.errorMsg {
    color: #da1e28;
    font: 12px/20px 'SF-Pro-Display';
    margin-top: 11px;
    margin-left: 20px;
}

.downloadBtn {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 14px 16px;
    border: 1px solid #e2eeff;
    background: #ffffff;
    border-radius: 14px;

    svg {
        width: 16px !important;
        height: 16px !important;
        flex-shrink: 0;
    }

    p {
        font: 14px/24px 'SF-Pro-Display';
        color: #004aad;
        margin: 0 0 0 8px;
    }

    a {
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        background-color: #004aad;
        margin-left: auto;
        width: 77px;
        height: 29px;
        font: 12px/14px 'SF-Pro-Display-Medium';
        border-radius: 30px;
        display: grid;
        place-items: center;
        flex-shrink: 0;
    }

    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        outline: none;
        color: #ffffff !important;
        background-color: #004aad;
    }
}
