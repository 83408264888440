.no_cluster_wrapper {
    display: flex;
    padding: 18px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 14px;
    border: 1px solid var(--shades-and-tints-gray-scale-e-5-e-5-e-5, #E5E5E5);
    background: var(--shades-and-tints-gray-scale-ffffff, #FFF);

    >p {
        color: #011A3C;
        // TODO: replace with light font
        font-family: "SF-Pro-Display";
        font-size: 14px;
        font-style: normal;
        line-height: 150%;
    }

    >button {
        all: unset;
        cursor: pointer;
        display: flex;
        gap: 6px;
        justify-self: right;
        color: var(--primary-004-aad, #004AAD);
        text-align: right;
        align-items: center;
        font-family: "SF-Pro-Display-Semibold";
        font-size: 13px;
        font-style: normal;
        line-height: normal;
    }
}

.representative_card_parent_wrapper {
    display: grid;
    gap: 8px;
}

.representative_card {
    display: flex;
    width: 100%;
    min-height: 73px;
    padding: 13px;
    gap: 8px;
    align-items: flex-start;
    border-radius: 15px;
    border: 1px solid var(--shades-and-tints-gray-scale-e-5-e-5-e-5, #E5E5E5);
    background: var(--shades-and-tints-gray-scale-ffffff, #FFF);

    >div:first-child {
        align-self: center;
    }

    >div:last-child {
        width: 100%;

        >div:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;

            >h3 {
                color: var(--shades-and-tints-primary-000-f-23, #000F23);
                font-family: "SF-Pro-Display";
                font-size: 16px;
                font-style: normal;
                line-height: 150%;
                margin: 0;
            }

            svg {
                height: 24px;
            }
        }

        >div:last-child {
            >div {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                align-items: center;

                span {
                    color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
                    font-family: "SF-Pro-Display";
                    font-size: 14px;
                    font-style: normal;
                    line-height: 150%;
                }
            }

            p {
                color: var(--shades-and-tints-primary-3-d-5075, #3D5075);
                font-family: "SF-Pro-Display";
                font-size: 14px;
                font-style: normal;
                line-height: 150%;
                margin: 4px 0 0 0;
            }
        }
    }
}


.representative_card_add_more_btn {
    display: flex;
    justify-content: flex-end;

    button {
        all: unset;
        cursor: pointer;
        display: flex;
        gap: 6px;
        justify-self: right;
        color: var(--primary-004-aad, #004AAD);
        text-align: right;
        align-items: center;
        font-family: "SF-Pro-Display-Semibold";
        font-size: 13px;
        font-style: normal;
        line-height: normal;
        margin-top: 7px;
    }
}

.rep_modal_content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 50px 24px;

    >div:first-of-type {
        h2 {
            color: #011A3C;
            text-align: center;
            font-family: "SF-Pro-Display-Bold";
            font-size: 20px;
            font-style: normal;
            line-height: normal;
            margin: 0 0 9px 0;
        }

        h3 {
            color: rgba(35, 56, 98, 0.70);
            text-align: center;
            font-family: "SF-Pro-Display";
            font-size: 15px;
            font-style: normal;
            line-height: normal;
            margin: 0;
        }
    }

    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        max-width: 464px;
        width: 100%;
        margin-top: 33px;
    }
}