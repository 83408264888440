.BankDetailsMainWrapper {
    margin-top: 50px;
    font-family: 'SF-Pro-Display';
    width: 80%;

    @media screen and (min-width: 2500px) {
        width: 52%;
    }
    @media screen and (min-width: 1300px) and (max-width: 1680px) {
        width: 100%;
    }
    @media screen and (max-width: 500px) {
        margin-top: 20px;
    }
}

.LeftWrapper {
    width: 270px;
    @media screen and (max-width: 500px) {
        width: fit-content;
    }
}

.Header {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
    margin-bottom: 8px;
    padding-top: 10px;
}

.SubHeader {
    font-size: 14px;
    line-height: 19px;
    color: rgba(35, 56, 98, 0.8);
}

.BankDetailsInfo {
    display: flex;
    margin-top: 35px;
    gap: 65px;

    @media screen and (max-width: 599px) {
        flex-direction: column;
        gap: 20px;
    }
}
.BankDetailsWrapper {
    width: 341px;
    position: relative;
    height: 165px;
    background: #ffffff;
    border: 1px solid #e2eeff;
    border-radius: 15px;
    display: flex;
    padding: 25px;
}
.BankName {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #011a3c;
}
.BankAccountNumber {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
}
.ImageWrapper {
    position: absolute;
    top: -2px;
    right: 0px;
}
.SaveBankDetails {
    margin-top: 80px;
    display: flex;
    justify-content: flex-end;
}
.ReplaceBank {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    color: #004aad;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    @media screen and (min-width: 599px) and (max-width: 801px) {
        width: 341px;
    }
}
.BankLogo {
    position: absolute;
    right: 23px;
}
.AccountName {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.135em;

    color: rgba(35, 56, 98, 0.8);
}
