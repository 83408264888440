.promoCarouselWrapper {
  margin-bottom: 27px;
  width: 560px;
    @media screen and (max-width: 500px) {
      margin-bottom: 50px;
      max-width: 320px;
    }
}

.promoCarouselBtns {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 5px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    width: fit-content;
    padding: 0;

    img {
      width: 23px;
      height: 23px;
      object-fit: cover;
    }
  }
}

.promoCarousel {
  position: relative;

  &.contentRight {
    .slick-list {
      .slick-track {
        .slick-slide.slick-active.slick-current {
          margin-right: -5px !important;
        }
      }
    }
  }
}

.promoCarouselDots {
  position: absolute !important;
  top: -10% !important;
  left: 43% !important;
  li {
    background: #FFFFFF54 !important;
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    margin: 0 2px !important;
  }
  .slick-active {
    button {
      background: white !important;
      width: 6px !important;
      height: 6px !important;
      border-radius: 50% !important;
    }
  }
}

.promoCarouselImg {
  width: 541px;
  height: 169px;
  object-fit: cover;
  cursor: pointer;

    @media screen and (max-width: 500px) {
      width: 300px;
      height: 160px;
    }
}