.AddressBlock {
  display: flex;

  div.TextField {
    width: 100% !important;
  }

  .Separator {
    display: inline-block;
    width: 12%;
  }

  @media screen and (max-width: 600px) {
 flex-direction: column;
    }
}

.DateBlock {
  display: flex;

  div.DateInputRight {
    width: 48.1% !important;

    .CalenderBox .react-datepicker {
      position: relative;
      left: -20px !important;
    }

    @media screen and (max-width: 600px) {
      width: 100% !important;
      }
  }

  div.DateInputLeft {
    width: 48.1% !important;

    .CalenderBox .react-datepicker {
      position: relative !important;
      left: -200px !important;
    }

    @media screen and (max-width: 600px) {
      width: 100% !important;
      }
  }

  .Separator {
    display: inline-block;
    width: 6%;
    @media screen and (max-width: 600px) {
      display: none;
      }
  }
}

.TextAreaMsg {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 12px;
  line-height: 1px;
  color: rgba(35, 56, 98, 0.78);
  position: relative;
  top: -12px;
  display: inline-block;
  margin-left: 25px;
}

.FormBox {
  height: 450px;
  width: 545px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    max-height: 100%;
    height: auto;
    width: 100%
  }
}

.CheckBoxWrapper {
  display: flex;
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(35, 56, 98, 0.8);
  margin: 10px;
}

.SaveButton {
  text-align: right;
  margin-bottom: 30px;
  margin-right: 10px;
  margin-top: 15px;  
  svg {
    fill: var(--blue);
  }
}

.AddNewButton {
  text-align: right;
  margin-bottom: 30px;
  margin-top: 10px;
  margin-right: 10px;

  svg {
    fill: var(--blue);
    font-family: 'SF-Pro-Display-Semibold';
  }
}

.CloseCard {
  background: #ffffff;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  padding: 20px;
  margin: 10px 0;
  font-family: 'SF-Pro-Display';
  justify-content: center;

  @media screen and (max-width: 600px) {
    align-items: center;
    }

  .Role {
    font-style: normal;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 30px;
    color: #011a3c;
    width: 35%;
    margin-left: 10px;
  }

  .Period {
    font-style: normal;
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 30px;
    color: rgba(35, 56, 98, 0.8);
    width: 40%;
  }

  .Action {
    display: flex;
    width: 20%;

    .ActionButton {
      display: inline-block;
      cursor: pointer;
      padding: 6px;
      text-align: center;
      border-radius: 50px;
      height: 30px;
      width: 30px;
      margin-left: 15px;
    }

    .EditActionButton {
      background: #e2eeff !important;
    }

    .DeleteActionButton {
      position: relative;
      top: -6px;
      left: -5px;
      svg {
        height: 32px;
        width: 32px;
      }
    }

    @media screen and (max-width: 600px) {
      width: auto;
      }
  }
}

.TopActionBox {
  display: flex;
  text-align: center;
  justify-content: center;
  position: relative;
  top: -15px;
  margin-bottom: 20px;

  div.LinkedInBox {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: flex;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #233862;
    padding: 15px;
    cursor: pointer;
    margin-right: 20px;

    span {
      margin-left: 10px;
    }
  }

  label.ResumeBox {
    background: #004AAD;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: block;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    padding: 17px;
    cursor: pointer;
    width: 200px;

    span {
      margin-left: 10px;
      position: relative;
      top: -4px;
    }

  }

  input[type=file] {
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
