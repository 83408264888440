.ASLWrapper {
    padding: 44px 136px 101px 112px;

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        padding: 44px 60px 101px 60px;
    }

    @media screen and (max-width: 800px) {
        padding: 30px 23px;
    }
}

.ASLSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.ASLFilterBox {
    display: flex;
    gap: 23px;
    height: 45px !important;
    width: 130px;
    margin-left: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 10px !important;
    padding: 11px 20px;
    cursor: pointer;
}

.ASLFilterText {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: rgba(1, 26, 60, 0.8);
}

.ASLTabWrapper {
    margin-top: 40px;
}
.ASLTablewWrapper {
    margin-top: 38px;
}
.ASLTableHeader {
    display: grid;
    padding: 0 60px 0 49px;
    grid-template-columns: 2fr 2fr minmax(130px, 1fr) 2fr 1fr;
    padding-bottom: 6px;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);

    &.WithExtraButton {
        :nth-child(5) {
            width: 150px;
        }
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ASLTableContent {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 2fr 2fr minmax(130px, 1fr) 2fr 1fr;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011a3c;
    background: #ffffff;

    > span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}
.ASLPaginationWrapper {
    text-align: center;
    margin-top: 42px;
}
#four-column {
    grid-template-columns: 3fr 2fr minmax(130px, 3fr) 2fr;
    padding: 0 0px 0 49px;
}
.ASLBtnWrapper {
    display: flex;
    justify-content: space-between;

    &.WithExtraButton {
        width: 150px;
    }
}

.ASLEditModalInputWrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 18px;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}
.ASLMobileWidth {
    @media screen and (max-width: 500px) {
        width: 329px;
    }
}
.ASLEditModalButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 50px;

    @media screen and (max-width: 500px) {
        margin-top: 150px;
    }
}

.ASLPageTitle {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    padding-left: 112px;

    @media screen and (max-width: 1280px) {
        padding-left: 60px;
    }

    @media screen and (max-width: 800px) {
        padding-left: 20px;
    }
}

.ASLResourceHeader {
    display: grid;
    grid-template-columns: 3fr minmax(224px, 1fr) 1fr;
    width: 691px;
    padding: 0 1px 0 49px;

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.ASLTableMobile {
    @media screen and (min-width: 501px) {
        display: none;
    }
}

.ApplianceCount {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    margin-bottom: 10px;
}

.AddApplianceModalContent {
    max-height: 448px;
    overflow-y: scroll;
    padding: 23px 80px 0px;
}

.ApplianceCloseTextButton {
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4.29px;
    margin-top: 8px;
    margin-bottom: 8px;

    p {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 11px;
        color: #004aad;
        cursor: pointer;
    }
}

.ASLRangeWrapper {
    width: calc(100% - 50px);
    display: flex;
    align-items: flex-start;
    gap: 14px;

    > div {
        width: 100%;
        max-width: 152px;
    }

    .InputFieldWrapper {
        margin: 0;
    }
}

.ASLTitleWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .ASLMobileDropdown {
        display: none;
    }

    @media (max-width: 1024px) {
        margin: 24px 12px;

        .ASLMobileDropdown {
            display: block;
        }

        .ASLMobileDropdown path {
            fill: #fff;
        }
    }
}

.ASLCreateNew_Btn {
    display: block;

    @media (max-width: 1024px) {
        display: none;
    }
}
