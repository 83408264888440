.wrapper {
    padding: 26px 43px;
}

.title {
    color: #011a3c;
    font-size: 14px;
    line-height: 21px;
    margin-top: 25px;
}

.subtitle {
    color: #3d5075;
    font-size: 12px;
    line-height: 18px;
    margin-top: 1.5px;
}

.howToContainer {
    margin-top: 22px;

    .howToTitle {
        font-size: 12px;
        font-family: var(--font-medium);
        line-height: 24px;
        letter-spacing: 0.78px;
        text-transform: uppercase;
        color: #4d4d4d;
        margin-bottom: 8px;
    }

    ul {
        list-style: none;
        padding-inline-start: 0;
    }

    li {
        display: flex;
        gap: 9px;
        color: #3d5075;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;

        span {
            width: 24px;
            height: 24px;
            background-color: #e2eeff;
            color: #004aad;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--font-semibold);
        }
    }
}

.downloadFileContainer {
    display: flex;
    justify-content: space-between;
    border: 1px solid#e2eeff;
    background-color: #f9fcff;
    padding: 8px;
    margin-bottom: 40px;
    align-items: center;
    border-radius: 8px;

    .icon {
        height: 50px;
        width: 50px;
        border-radius: 100%;
        background-color: #e2eeff;
    }

    .file {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .fileName {
        font-size: 12px;
        line-height: 18px;
        color: #3d5075;
        word-break: break-word;
        max-width: 90%;
    }

    .fileSize {
        font-size: 10px;
        line-height: 15px;
        color: #b0b0b0;
    }
}

.downloadFile {
    border-radius: 30px;
    border: 0.5px solid #004aad;
    background: #f9fcff;
    font-size: 12px;
    padding: 8px 12px;
    color: #004aad;
    font-family: var(--font-medium);
    cursor: pointer;
}
