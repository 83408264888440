.emptyState {
    border: 1px solid #e2eeff;
    border-radius: 14px;
    background-color: #f9fcff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 121px 0;

    img {
        width: 70px;
        height: 70px;
        object-fit: contain;
        margin-bottom: 10px;
    }

    h4 {
        font: 14px/21px var(--font-regular);
        color: #011a3c;
        margin-bottom: 8px;
        text-align: center;
    }

    p {
        font: 12px/18px var(--font-regular);
        color: #4d4d4d;
        max-width: 292px;
        text-align: center;
    }
}

.table {
    border-collapse: separate;
    border-spacing: 0px;
    border-top: 0.5px solid #e5e5e5;
    border-left: 0.5px solid #e5e5e5;
    border-right: 0.5px solid #e5e5e5;
    border-radius: 8px 8px 0 0;
    background-color: #ffffff;
    padding: 0;
    margin: 0 0 8px 0;
    width: 100%;

    .tableHead {
        background-color: #fcfcfd;
    }

    th {
        font: 12px/24px var(--font-medium);
        color: #4d4d4d;
        text-transform: uppercase;
        border-bottom: 0.5px solid #e5e5e5;
        padding-left: 0;

        &:first-child {
            padding-left: 24px;
            border-top-left-radius: 8px;
        }
        &:first-child {
            border-top-right-radius: 8px;
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.overflowTable {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-align: center;
    min-height: 300px;
    width: 100%;

    table {
        overflow-x: hidden;
    }
}

.overflowTable::-webkit-scrollbar {
    display: none;
}

.row {
    td {
        font: 14px/21px var(--font-regular);
        padding: 12px 0;
        color: #3d5075;
        border-bottom: 0.5px solid #e5e5e5;

        &:first-child {
            padding-left: 24px;
            color: #070c18;
            border-bottom-left-radius: 0px;
        }

        &:last-child {
            width: 42px;
            border-bottom-right-radius: 0px;
        }
    }
}

.dashboardRow {
    @extend .row;
}

.paymentRow {
    @extend .row;
    td {
        &:last-child {
            width: initial;
        }
    }
}

.status {
    padding: 1.5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    text-transform: uppercase;
    font: 12px/20px var(--font-semibold);
    letter-spacing: 1px;
}

.statusActive {
    @extend .status;
    background-color: #32cc7926;
    color: #32cc79;
}

.statusInactive {
    @extend .status;
    background-color: #e5e5e580;
    color: #233862b2;
}

.dueTag,
.noCreditReportTag,
.completedTag,
.uploadVerificationFailed {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5px 8px;
    font: 12px/20px var(--font-semibold);
    letter-spacing: 1px;
    width: fit-content;
    text-transform: uppercase;
}

.noCreditReportTag {
    color: #e4aa0f;
    background-color: #fffaec;
}

.dueTag,
.uploadVerificationFailed {
    color: #da1e28;
    background-color: #fff1f1;
}

.completedTag {
    background-color: #32cc7926;
    color: #32cc79;
}

.dotsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
}

.paginationContainer {
    text-align: center;
    margin-top: 42px;
}
.mobileTable {
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
}

.mobileCard {
    border-radius: 7px;
    background: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    padding: 36px 21px 20px 21px;
    margin: 24px 0;

    .nameAndOptions,
    .emailAndStatus {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .emailAndStatus {
        p.title {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 1.02px;
            text-transform: uppercase;
            font-family: var(--font-semibold);

            &:last-of-type {
                text-align: right;
                &:last-child {
                    text-align: right;
                }
            }
        }

        p.emailAddress {
            color: #011a3c;
            font-family: var(--font-medium);
            font-size: 12px;
            line-height: 18px;
        }
    }

    .nameAndOptions > p {
        color: #011a3c;
        font-size: 14px;
        line-height: 21px;
    }

    .groupMembers {
        display: flex;
        justify-content: space-between;

        & > div {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        & > div:nth-of-type(2) {
            align-items: end;
            gap: 30px;
        }
    }
}

.noDocumentFound {
    color: #da1e28;
    border-color: #f6dbdb;
    background-color: #fff1f1;
    border-radius: 3px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    font-family: var(--font-semibold);
    font-size: 12px;
    justify-content: space-between;
    max-width: 200px;

    span {
        background-color: #f6dbdb;
        border-radius: 100%;
        height: 16px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        width: 9px;
        height: 9px;
    }

    @media screen and (max-width: 500px) {
        gap: 4px;
    }
}

.docName {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-right: 25px;

    svg {
        transform: rotate(80deg);
        path {
            stroke: #004aad;
        }
    }

    span {
        color: #004aad;
        font-size: 12px;
    }
}
