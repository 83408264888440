.HeaderText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 54px;
    line-height: 66px;
    color: var(--primary-blue);
}

.ContentWrapper {
    padding: 80px 120px;
}

.SubParagraph {
    margin-left: 30px;
}

.HeaderSubText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #004AAD;

}

.TextWrapper {
    text-align: center;
    background-color: var(--blue-4);
    padding: 152px 0 162px 0;
}

.AlphabetList {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    color: rgba(1, 26, 60, 0.7);
}


@media (max-width: 768px) {
    .ContentWrapper {
        padding: 87px 80px;
    }

    .SubParagraph {
        margin-left: 30px;
    }
}

@media (max-width: 676px) {
    .ContentWrapper {
        padding: 50px 80px 71px 80px;

        div>h2 {
            line-height: 40px;
        }
    }

    .SubParagraph {
        div>h2 {
            line-height: 28px;
        }
    }
}

@media (max-width: 500px) {
    .ContentWrapper {
        padding: 87px 40px;
    }

    .SubParagraph {
        margin-left: 25px;
    }
}



@media (max-width: 400px) {
    .HeaderText {
        font-size: 40px;
    }

    .ContentWrapper {
        padding: 87px 20px;
    }

    .SubParagraph {
        margin-left: 25px;
    }
}
.ModalTextWrapper {
  font-family: 'SF-Pro-Display';
  padding: 50px 0px 20px;
  border-bottom: 1px solid rgba(229, 229, 229, 0.7);
  text-align: center;
}

.ModalHeaderText {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 30px;
  line-height: 36px;
  color: #011A3C;
  text-align: center;
}

.ModalContentWrapper {
  padding: 20px 50px 50px;
  height: 435px;
  overflow-y: auto;

  @media screen and (max-width: 500px) {
    padding: 20px 20px 50px;
    height: 80vh;
  }
}