.RecommendedSolutionDrawer {
    width: 100%;
    .ant-drawer-close {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
}

.SelectEnergyCloseAndBackNav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        cursor: pointer;
        img {
            width: 15px;
            height: 15px;
        }
    }
}

.SelectEnergyTitleAndButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 50px;
}

.SelectEnergyTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #011a3c;
    margin: 0;
}

.CRESSearchProductWrapper {
    margin-bottom: 40px;
}

.emptyContainer {
    font-family: 'SF-Pro-Display';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 150px;
}

.emptyTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
    margin: 30px auto 10px;
}

.emptySubtitle {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: rgba(1, 26, 60, 0.6);
}

.CustomRecommendationEnergySolutionWrapper {
    background: #ffffff;
    max-width: 417px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
}

.CustomRecommendationEnergySolutionTop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 30px;

    h4 {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        line-height: 16.71px;
        color: #011a3c;
        margin: 0 25px 0 0;
        width: 140px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        color: #011a3c;
        margin: 0;
    }

    @media only screen and (max-width: 500px) {
        h4 {
            margin-right: 10px;
        }
    }
}

.CRESAmountAndFlag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 250px;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        gap: 3px;
        align-items: flex-start;
        width: 150px;
    }
}

.CRESTopSelectedFlag {
    width: 64px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2eeff;
    border-radius: 3px;

    p {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 9px;
        letter-spacing: 0.055em;
        color: #004aad;
        margin: 0;
    }
}

.CRESTopArrowWrapper {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CRESTopArrowOpen {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.CRESTopArrowClosed {
    transition: transform 0.3s ease;
}

.CustomRecommendationEnergySolutionBottomClosed {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.3s;
    margin: 0;
}

.CustomRecommendationEnergySolutionBottomOpen {
    opacity: 1;
    visibility: visible;
    transition: ease 0.3s;
    margin: 7px 30px 16px 30px;
}

.CRESBottomTextWrapper {
    display: flex;
    justify-content: flex-start;
    gap: 80px;
    align-items: center;
    margin-bottom: 24px;

    &.ALTRecommendations {
        gap: 0;
        justify-content: space-between;
    }

    div {
        h5 {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 10px;
            color: #011a3cd9;
            margin: 0;
        }

        p {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 16px;
            color: #011a3c;
            margin: 0;
        }
    }

    div:has(svg) {
        gap: 9px;
        align-items: center;

        p {
            font: 14px/24px 'SF-Pro-Display';
            color: #233862;
            text-wrap: nowrap;
            text-align: right;
        }

        svg {
            flex-shrink: 0;
        }

        @media only screen and (max-width: 500px) {
            p {
                text-wrap: wrap;
            }
        }
    }
}

.EnergyProductPaginationWrapper {
    display: flex;
    justify-content: center;
    margin-top: 45px;

    a {
        font-size: 12px;
    }

    .ant-pagination-item {
        height: 35px;
        width: 35px;
    }

    .ant-pagination-item a {
        margin-top: 6px;
    }

    .ant-pagination-jump-next {
        margin-right: 5px;
    }
}

.ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
}

.ant-pagination-prev {
    margin-right: 0px;
}
