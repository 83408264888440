.SolarSystemsWrapper {
    overflow: hidden;
    padding: 82px 135px;
    @media only screen and (min-width: 1441px) {
        max-width: 1440px;
        margin: 0 auto;
    }
    @media only screen and (max-width: 1440px) {
        padding: 82px 80px;
    }
    @media only screen and (max-width: 1024px) {
        padding: 82px 40px;
    }
    @media screen and (max-width: 500px) {
        padding: 22px 20px 82px 20px;
        overflow-x: hidden;
        width: 100%;
        margin: 0;
    }
}
.EnergyNeedsSummaryWrapper {
    background: #f9fcff;
    border-radius: 4px;
    min-height: 171px;
    padding: 30px 50px;
    width: 100%;

    @media screen and (max-width: 500px) {
        padding: 20px;
        height: 243px;
    }
    .ant-select-item {
        padding: 14px 10px;
    }
}

.EnergyNeedsSummaryTopStyle {
    display: flex;
    justify-content: space-between;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.065em;
        text-transform: uppercase;

        @media screen and (max-width: 500px) {
            line-height: 20px;
            width: 135px;
        }
    }

    span {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #ffffff;
        margin-bottom: 2px;
        vertical-align: middle;

        @media screen and (max-width: 500px) {
            font-family: 'SF-Pro-Display';
        }
    }

    svg {
        fill: #ffffff;
        stroke: #ffffff;
        width: 12px;

        @media screen and (max-width: 500px) {
            margin-left: -10px;
        }
    }
}

.EnergyNeedsSummaryBottomStyle {
    display: flex;
    flex-wrap: wrap;

    margin-top: 20px;
    @media only screen and (min-width: 1440px) {
        justify-content: space-between;
    }
    @media only screen and (max-width: 1440px) {
        justify-content: center;
    }

    @media screen and (max-width: 600px) {
        display: block;
        margin-top: 10px;
    }
    div {
        width: auto;
        @media screen and (max-width: 600px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 9px;
            width: 100%;
        }
        p:first-child {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 24px;
            color: #3d5075;
            @media only screen and (min-width: 1440px) {
                font-size: 16px;
            }
            @media screen and (max-width: 500px) {
                line-height: 20px;
            }
        }

        span {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 20px;
        }

        p:nth-child(2) {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 28px;
            line-height: 35px;
            color: #001837;
            margin-top: 5px;
            @media screen and (max-width: 500px) {
                font-size: 18px;
                line-height: 24px;
                margin-top: 0px;
            }
        }

        sup {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 24px;
            color: #001837;
            vertical-align: text-top;
            margin-left: 5px;
            @media screen and (max-width: 500px) {
                top: 0;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
}

.SSESummaryVerticalLine {
    height: 30px;
    border: 0.1px solid #bcbcbc;
    margin: 15px 45px 0px;
    @media screen and (max-width: 1200px) {
        margin: 15px 20px 0px;
    }
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.SystemsListWraaper {
    margin: 40px auto;

    @media screen and (max-width: 500px) {
        margin: 30px auto;
    }
}

.SystemsListTopStyle {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    flex-wrap: wrap;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    align-items: center;

    @media screen and (max-width: 500px) {
        display: block;
    }
    > p:first-child {
        color: #070c18;
    }

    p {
        color: #233862;
    }
    .SystemsSortByWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 225px;

        @media screen and (max-width: 500px) {
            margin-top: 30px;
            width: 100%;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            padding: 12px;
        }

        .ant-select.SelectBox.ant-select-single.ant-select-show-arrow {
            @media screen and (max-width: 500px) {
                width: 180px !important;
                height: 48px;
            }

            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                @media screen and (max-width: 500px) {
                    width: 171px !important;
                    height: 48px;
                }
            }
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            @media screen and (max-width: 500px) {
                line-height: normal !important;
            }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            @media screen and (max-width: 500px) {
                min-height: auto;
                padding-left: 0px;
            }
        }
    }

    span {
        color: #b0b0b0;
    }

    &__btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;

        @media screen and (max-width: 500px) {
            justify-content: space-between;
        }

        span {
            color: #004aad;
            text-align: center;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }
    }
}

.SystemsListBottomStyle {
    display: grid;
    margin-top: 20px;
    column-gap: 16px;
    row-gap: 38px;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 1280px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 1024px) and (min-width: 769px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        margin-top: 50px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }

    @media screen and (max-width: 700px) {
        margin-top: 50px;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
    }

    .BestFitStyle {
        padding: 0px;
    }
}

.SystemsListBottomStyle > div:first-child {
    border: 1px solid #e2eeff;
}

.SolarSystemListWrapper {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 18.8182px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 20px;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
    @media screen and (max-width: 375px) {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }

    @media only screen and (min-width: 701px) {
        > span:has(img) {
            width: 100%;
            display: block !important;
            padding: 0;
        }

        &.SolarSystemListWrapper_BestFit {
            > span:has(img) {
                padding: 20px 20px 0 20px;
            }
        }

        > span:has(img) img {
            height: 147px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        margin: 0px auto;
        display: block;
    }
}

.SolarSystemDescriptionWrapper {
    padding: 1px 20px 20px;
    background: #004aad;
    color: #ffffff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .SolarSystemNameStyle {
        color: #ffffff;
    }

    .SolarSystemDescriptionStyle {
        color: #ffffff;
    }

    .SolarSystemPlanWrapper p {
        background: #002c68;
        color: #ffffff;
    }
}

.SolarSystemNameWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    margin: 10px 0px 3px;
}

.SolarSystemNameStyle {
    font-family: 'SF-Pro-Display';
    color: #070c18;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
    font-size: 14px;
    line-height: 24px;
}

.SolarSystemFitStyle {
    background: #f9fcff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    height: 18px;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        color: #004aad;
        white-space: nowrap;
    }

    @media screen and (max-width: 500px) {
        padding: 5px !important;
    }
}

.SolarSystemDescriptionStyle {
    font-family: 'SF-Pro-Display-Bold';
    color: #070c18;
}

.SolarSystemPlanWrapper {
    display: flex;
    margin: 10px 0px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        background: linear-gradient(
            90deg,
            #f1f1f1 0%,
            #f3f3f3 51.7%,
            #f3f3f3 100%,
            #f1f1f1 100%
        );
        border-radius: 1.488px;
        padding: 0px 5px;
        margin-right: 5px;
        color: #4d4d4d;
    }
}

.SolarSystemPriceWrapper {
    display: flex;
    justify-content: space-between;

    div {
        p:first-child {
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            line-height: 20px;
            color: #b0b0b0;
        }
    }
}

.SolarSytemViewBtn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.SSEEditModalWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 380px;
    font-family: 'SF-Pro-Display';

    @media screen and (max-width: 500px) {
        height: 500px;
    }

    svg {
        @media screen and (max-width: 500px) {
            margin-bottom: 40px;
        }
    }

    h3 {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #011a3c;
    }

    p {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #4d4d4d;
        width: 294px;
    }
}

.SSEEditApplianceWrapper {
    padding: 0px 20px;

    @media screen and (max-width: 500px) {
        padding: 0;
    }

    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
        color: #004aad;
    }

    .SSEEditApplianceSubHeading {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #5f5b5b;
        width: 324px;
    }

    .SelectFloatingLabel {
        top: 15%;
    }
}
.FilterRecommendatationModalFooter {
    margin-top: 0px;
}

.SSEFilterRecommendationsWrapper {
    overflow: auto;
    max-height: 580px;
    margin: 40px 22px;

    @media screen and (max-height: 800px) {
        height: 520px;
        margin: 20px 22px;
    }
    @media screen and (max-width: 1400px) {
        height: 100%;
        margin: 20px 22px;
    }

    @media screen and (max-width: 500px) {
        height: 500px;
        overflow-y: auto;
        padding: 20px 29px;
        margin: 0px 0px 60px;
    }

    h3 {
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        color: #3d5075;

        @media screen and (max-width: 500px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.SSEFilterCheckboxWrapper {
    display: flex;
    font-family: 'SF-ProDisplay';
    margin: 20px 0px;
    width: 97%;
    justify-content: space-between;
    @media screen and (max-width: 1280px) {
        margin: 10px 0px;
    }
    @media screen and (max-width: 500px) {
        flex-direction: column;
        justify-content: space-between;
        height: 160px;
    }

    .SSEFilterCheckboxHeaderStyle {
        font-size: 14px;
        line-height: 24px;
        color: #011a3c;

        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    .SSEFilterCheckboxText {
        font-size: 12px;
        line-height: 20px;
        color: #4d4d4d;
        width: 247px;
    }
}

.SSEInitialDepositWrapper {
    margin: 60px 0px 45px;

    @media screen and (max-width: 1400px) {
        margin: 30px 0px 20px;
    }
    @media screen and (max-height: 1240px) {
        margin: 20px 0px 10px;
    }
    @media screen and (max-height: 800px) {
        margin: 30px 0px 20px;
    }
}

.SSEAmountInputsWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @media screen and (max-width: 500px) {
        flex-direction: column;
    }
}

.SSEMonthlyRepaymentWrapper {
    margin-top: 40px;
    @media screen and (max-width: 1280px) {
        margin-top: 20px;
    }

    @media screen and (max-width: 500px) {
        margin-bottom: 50px;
    }
}

.SSEFilterButtonsWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;

    @media screen and (max-width: 500px) {
        justify-content: space-between;
    }

    button {
        width: 177px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 28px;
        color: #011a3c;
        cursor: pointer;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 24px;
        transition: 0.3s ease all;
        margin-right: 10px;

        @media screen and (max-width: 500px) {
            width: 101px !important;
            height: 42px;
            margin-right: 0;
        }
    }

    button:hover {
        border: 1px solid #004aad;
        background: #f9fcff;
    }
}

.SSEFilterButtonsFocus {
    background: #011a3c !important;
    color: #ffffff !important;
    transition: 0.3s ease all;
}

.ShowFilteredPackagesWrapper {
    background: #001837;
    border-radius: 4px;
    color: #ffffff;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transition: 0.3 ease-in;
    padding: 14px 10px;

    @media only screen and (min-width: 1500px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 500px) {
        height: 130px;
        padding: 0px 15px;
        font-size: 16px;
    }
}
@media screen and (min-width: 501px) {
    .ant-modal {
        top: 10px !important;
    }
}

.SSEEnergySummaryArrowRotateHidden {
    rotate: 0deg;
    transition: ease 0.5s;
}

.SSEEnergySummaryArrowRotateVisible {
    rotate: 180deg;
    transition: all 0.5s ease 0s;
}

.EditYourInput {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    @media screen and (max-width: 480px) {
        gap: 2px;
    }
    span {
        text-transform: none;
        margin: 0px;
    }
}

.emptyStateWrapper {
    max-width: 516px;
    margin: 50px auto 0 auto;
    display: flex;
    gap: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
        display: inline-block;
        max-width: 435px;
    }
}

.emailModalContent {
    height: 100%;
    max-width: 338px;
    margin: 36px auto;
    display: flex;
    flex-direction: column;
    gap: 14px;

    &__head {
        color: #011a3c;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
    }

    &__text {
        color: #4d4d4d;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
        margin-bottom: 14px;
    }

    &__name {
        display: flex;
        align-items: flex-start;
        gap: 14px;
        max-width: 100%;
    }

    &__optional {
        color: #233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.32px;
        padding-left: 21px;
    }
}

.SolarSystemListingsCardHR {
    border: none;
    border-top: 0.3px solid #b0b0b0;
    margin: 16px 0;
}

.DemandInfoWrapper {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
        font: 14px/21px 'SF-Pro-Display' !important;
    }
}
