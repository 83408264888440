del {
    text-decoration: none;
    position: relative;
}
del:after {
    content: ' ';
    font-size: inherit;
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    top: 40%;
    bottom: 40%;
    border-top-color: inherit;
    border-bottom-color:inherit;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style:solid;
    border-bottom-style: solid;
}