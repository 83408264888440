.Card {
    display: inline-grid;
    grid-row-gap: 40px;
    padding: 25px 22px 28px 21px;
    width: 100%;
    height: 178px;
    background: #ffffff;
    border-radius: 7px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    font-family: 'SF-Pro-Display';
    margin: 0 13px 14px 0;
}

.CardChildLayout {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CardChildLayout p {
    margin: 0;
    font-style: normal;
}

.CardStatusTitle {
    font-size: 9px;
    font-family: 'SF-Pro-Display-Medium';
    line-height: 11px;
    letter-spacing: 0.1em;
}

.CardNameTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 18px;
    color: #011a3c;
    padding-top: 4px;
}

.CardButton {
    width: 70px;
    height: 37px;
    background: #e2eeff;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    color: #004aad;

    &:hover,
    &:focus,
    &:active {
        background: #004aad;
        color: #ffffff;
        transition-delay: 0.1s;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }
}

.CardDateAmountHeader {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
}

.CardDateAmountValue {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    color: #011a3c;
    padding-top: 8px;
}

.FlexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FlexContainer p {
    font-family: 'SF-Pro-Display';
    margin: 0;
}

.Divider {
    border-top: 0.7px solid rgba(229, 229, 229, 0.8);
    margin: 27px 0;
}

.TextHeader {
    font-family: 'SF-Pro-Display';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.055em;
    text-transform: uppercase;
    color: rgba(50, 63, 82, 0.85);
}

.TextContent {
    font-family: 'SF-Pro-Display-Medium' !important;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #011a3c;
}

.SubHeader {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 19px;
    color: #011a3c;
    margin-top: 47px;
    margin-bottom: 51px;
}

.DrawerAmountText {
    font-family: 'SF-Pro-Display-Medium' !important;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #233862;
}

.DrawerNameText {
    font-family: 'SF-Pro-Display-Bold' !important;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;
    margin-top: 12px;
}

.DrawerStatusText {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
}

.DrawerBtnPrimary {
    font-family: 'SF-Pro-Display-Semibold';
    background: #004aad;
    border-radius: 30px;
    font-style: normal;
    font-size: 14px;
    width: auto;
    height: 50px;
    padding: 17px 51px 16px 50px;
    line-height: 17px;
    color: #ffffff;
    border: none;
    cursor: pointer;
}

.DrawerBtnTertiary {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    line-height: 17px;
    font-size: 14px;
    text-align: center;
    width: 160px;
    height: 50px;
    background: #e2eeff;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    color: #004aad;

    &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }
}

.TextContentBold {
    font-family: 'SF-Pro-Display-Semibold';
}

.ProductStatus {
    display: flex;
    justify-content: center;
    margin-top: 67px;
}

.TextContentLeft {
    font-size: 14px;
    line-height: 22.5px;
    text-align: left;
    font-family: 'SF-Pro-Display';
}

.reEstimationCard {
    border: 0.5px solid #e2eeff;
    background-color: #f9fcff;
    border-radius: 8px;
    margin-top: 39px;
    padding: 16px;
}

.reEstimationCardTitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10.47px;

    > div {
        width: 24px;
        height: 24px;
        background-color: #e2eeff;
        border-radius: 50%;
        display: grid;
        place-items: center;

        > svg {
            width: 12.75px;
            height: 12.75px;
        }
    }

    > h4 {
        font: 14px/16.8px var(--font-semibold);
        color: #000000;
        margin: 0;
    }
}

.reEstimationCardReason {
    font: 12px/18px var(--font-regular);
    color: #000000;
}
