.ComponentWrapper {
  margin: 60px 100px;
  margin-right: 0px;

  @media screen and (max-width: 500px) {
    margin: 0px;
    padding-top: 40px;
  }
}

.AddComponentRow {
  display: flex;

  @media screen and (max-width: 1200px) {
    display: block;
  }
}

.LeftComponent {
  margin-right: 50px;


  @media screen and (min-width: 1900px) {
    .LeftComponent {
      margin-right: 150px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-right: 0px;
  }

}

.ComponentBtnWrapper {
  display: flex;
  margin-top: 50px;

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.MobileComponentBtnWrapper {
  display: none;

  @media screen and (max-width: 1200px) {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
}

.PDParentWrapper {
  >div:not(:first-child) {
    border-top: 1px solid #E4E4E4;
    padding-top: 34px;
  }
}

.PDWrapper {
  padding-bottom: 26px;

  >div {
    display: flex;
    justify-content: space-between;
    gap: 8px;


    @media (max-width: 576px) {
      display: block;
    }
  }
}

.PDRemoveDeviceWrapper {
  display: flex;
  justify-content: flex-end !important;
  align-items: baseline;
  cursor: pointer;
  margin-top: 0px;
  width: 100%;

  >p {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 11px;
    line-height: 13px;
    color: #004AAD;
    margin: 0 4px 0 6px;
  }
}