.TableHead {
  display: grid;
  grid-template-columns: 55% 30% 15%;
  padding: 0 29px 0 20px;
  margin-bottom: 18px;

  &.Users {
    grid-template-columns: 40% 30% 40%;
  }

  p {
    margin: 0;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    color: #011A3CCC;
  }
}

.TableRow {
  display: grid;
  grid-template-columns: 55% 30% 15%;
  align-items: center;
  height: 67px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 0 29px 0 20px;

  &.Users {
    grid-template-columns: 40% 30% 40%;
  }

  h4 {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    margin: 0;
  }
}

.BtnWrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  &.MobileBtnWrapper {
    gap: 3px;
  }
}

.PermissionsList {
  height: 370px;
  overflow-y: auto;
  display: grid;
  justify-content: center;
}

.ModalContent {
  margin: 40px auto 0 auto;
  max-width: 462px;

  &.PermissionsList {
    height: 370px;
    overflow-y: auto;
  }

  @media only screen and (max-width: 500px) {
    margin: 28px 23px;
  }

  @media only screen and (max-width: 500px) {
    &.MoreActions {
      margin: 35px 23px;
    }
  }
}

.ModalBtn {
  text-align: center;
  margin: 52px 0 54px 0;

  &.MoreActions {
    margin-top: 104px;

    @media only screen and (max-width: 500px) {
      margin-top: 259px;
    }
  }
}

.DesktopTable {
  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.MobileCards {
  @media only screen and (min-width: 501px) {
    display: none;
  }
}

.Card {
  height: 188px;
  margin: 12px 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.Top, .Bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Top {
  margin: 30px 21px 0 16px;
}

.Bottom {
  margin: 0 0 30px 16px;
}

.TitleAndText {
  font-size: 13px;
  color: #011A3CCC;
  max-width: 180px;

  span {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 27px;
    color: #011A3C;
  }
}

