.OtpInputBox {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 15px;
  width: 60px;
  height: 60px;
  color: #233862;
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 23px;
  text-align: center;
  margin-right: 5px;

  @media screen and (max-width: 640px) { 
    margin: 0px 1px;
    width: 100%;
  }
}

.OtpInputBoxFilled {
  border: 1px solid #004aad;
}

.FourOtpInputBox {
  margin-right: 6px;

  @media screen and (max-width: 500px) {
    margin-right: 5px;
  }
}