.field_container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 50%;
    width: 100%;

    button {
        position: absolute;
        top: 15px;
        right: 24px;
        margin-left: auto;
        display: flex;
        padding: 3px 8px;
        margin-bottom: 8px;
        justify-content: center;
        align-items: center;

        color: #004aad;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-family: 'SF-Pro-Display-Semibold';
        line-height: normal;
        cursor: pointer;

        border-radius: 30px;
        border: 0.3px solid #004aad;
        background: #f9fcff;
        font-family: 'SF-Pro-Display-Medium';
        width: max-content;
        height: 29px;

        span {
            padding-bottom: 1px;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.7;
            border: none;
        }

        @media screen and (max-width: 500px) {
            width: 54px;
        }
    }

    @media screen and (max-width: 600px) {
        max-width: none;
    }
}

.SelfieWrapper {
    div:first-of-type {
        min-height: 0 !important;
    }
}

.inProgress {
    color: #233862;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding-left: 20px;
}
