.Wrapper {
    display: flex;
    justify-content: space-between;
    font-family: 'SF-Pro-Display';
    margin-top: 40px;
    padding: 0px 100px;
    gap: 20px;

    @media screen and (max-width: 1040px) {
        flex-direction: column;
    }

    @media screen and (max-width: 500px) {
        // flex-direction: column;
        padding: 0px;
    }

    @media screen and (min-width: 1500px) {
        justify-content: start;
    }
}

.MobileLink {
    margin-left: 90px;
    margin-top: -40px;

    @media screen and (max-width: 500px) {
        margin: 12px 0 0 0;
    }
}

.LeftWrapper {
    margin: -10px 5%;

    @media screen and (max-width: 500px) {
        margin: 0;
    }
}

.RightWrapper {
    margin-top: 80px;

    @media screen and (max-width: 500px) {
        margin-top: 40px;
    }

    @media screen and (min-width: 1500px) {
        margin-left: 15rem;
    }
}

.MainTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 23px;
    line-height: 27px;
    margin-bottom: 33px;
    color: var(--primary-blue);
}

.SubTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 25px;
    color: var(--primary-blue);
}

.InputTitle {
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: var(--primary-blue);
    margin-bottom: 11px;
}

.BtnWrapper {
    // text-align: center;
    margin: 30px auto;
    max-width: 66vw;
    display: flex;
    // border: 1px solid red;
    justify-content: center;
    gap: 16px;

    @media screen and (min-width: 1500px) {
        max-width: 47vw;
        margin-left: 33rem;
        justify-content: start;
    }

    @media screen and (max-width: 1040px) {
        margin-left: -1rem;
    }

    @media screen and (max-width: 768px) {
        margin-left: 3rem;
    }

    @media screen and (max-width: 500px) {
        margin: 30px auto;
        justify-content: center;
    }
}

.floatBox {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.EditSubtotalWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    border-bottom: 0.5px solid #e5e5e5;
    padding-left: 37px;
    width: 407px;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-bottom: 18px;
    }
}

.firstSubTotalWrapper {
    padding: 5px;
    padding-left: 9px;
    padding-right: 15px;
    margin-top: 12px;

    @media screen and (max-width: 500px) {
        width: 100%;
        margin-top: 54px !important;
        padding-bottom: 18px;
    }
}

.NameTableData {
    padding: 4px;
}

.ComponentTypeTableData {
    padding: 0px;
    width: 23%;
    text-align: left;
}

.SelectedCapacityTableData {
    padding: 4px;
}

.QuantityTableData {
    padding: 4px;
}

.HandleCancelTableData {
    padding: 4px;
    width: 5%;
    cursor: pointer;
}

.MarginWrapper {
    padding-left: 9px;
    margin-top: 20px;
    padding-bottom: 5px;

    @media screen and (max-width: 500px) {
        padding-bottom: 18px;
    }
}

.TotalStyle {
    padding: 5px;
    padding-left: 9px;
    margin-top: 16px;
    padding-right: 15px;

    @media screen and (max-width: 500px) {
        padding-bottom: 18px;
    }
}

// .SelectedComponents {
//   display: block;
// }
