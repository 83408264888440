.BanksDetailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.DirectDebitBtnWrapper {
    position: absolute;
    bottom: 80px;

    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.BottomWrapper {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
    margin-top: 24px;
}

.SetupWarning {
    color: #3d5075;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
}

.InsuranceCost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    background: #e2eeff;
    border-radius: 4px;
    margin: 20px 0;

    > p {
        font: 12px/20px 'SF-Pro-Display-Medium';
        color: #3d5075;
    }
}

.InsuranceCostRight {
    display: flex;
    align-items: center;
    gap: 4px;

    > span {
        font: 12px/24px 'SF-Pro-Display-Bold';
        color: #004aad;
    }
}
