.reviewWrapper {
    width: 100%;
    margin-top: 10px;
}

.creditReview {
    display: flex;
    padding: 21px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    border-radius: 12px;
    border: 1px solid #e2eeff;
    background: #fff;

    @media only screen and (max-width: 550px) {
        padding: 10px;
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding-bottom: 18px;
        border-bottom: 0.1px solid #3d5075;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                color: #3d5075;
                font-size: 14px;
                line-height: 24px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Bold';
                margin-bottom: 0px;

                @media only screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }

            p {
                color: #233862;
                text-align: right;
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                font-family: 'SF-Pro-Display';

                @media only screen and (max-width: 500px) {
                    font-size: 12px;
                }
            }
        }
    }

    &__body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        background-color: #f9fcff;
        padding: 16px;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;

        &__head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            font-style: normal;
            padding-bottom: 8px;
            border-bottom: 0.3px solid #3d5075;

            h6 {
                color: #3d5075;
                font-size: 12px;
                line-height: 20px;
                font-family: 'SF-Pro-Display';
            }

            p {
                color: #233862;
                font-size: 14px;
                line-height: 24px;
                font-family: 'SF-Pro-Display-Bold';
            }
        }

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            > div {
                display: flex;
                column-gap: 8px;
            }

            p {
                color: #3d5075;
                font-size: 14px;
                line-height: 20px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Medium';
            }

            span {
                color: #233862;
                text-align: right;
                font-style: normal;
                font-family: 'SF-Pro-Display';
                line-height: 24px;

                display: flex;
                align-items: center;
                gap: 5px;

                svg {
                    width: 15px !important;
                    height: 15px !important;
                }
            }

            .YES {
                color: #32cc79;
            }

            .NO {
                color: #ff4d4f;
            }

            .UNDECIDED {
                color: #ff4d4f;
            }

            .MAYBE {
                color: #faad14;
            }
        }
    }
}

.dropdown {
    margin-top: -12px;
    width: 100%;
    padding: 20px 15px;

    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;

    border: 0.3px solid #3d5075;

    @media only screen and (max-width: 550px) {
        padding: 15px 5px;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 8px;
        border-bottom: 0.1px solid #3d5075;

        h4 {
            color: #3d5075;
            font-size: 12px;
            line-height: 24px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Medium';
            letter-spacing: 0.78px;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        a,
        a:active,
        a:focus,
        a:link {
            color: #004aad;
            font-size: 12px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Semibold';
            line-height: normal;

            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                width: 18px;
                height: 18px;

                @media only screen and (max-width: 500px) {
                    width: 14px !important;
                    height: 14px !important;
                }
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 8px;
        padding-left: 0px;
        margin-bottom: 0;

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            p {
                color: #3d5075;
                font-size: 14px;
                line-height: 20px;
                font-style: normal;
                font-family: 'SF-Pro-Display';
            }

            small {
                color: #001837;
                text-align: right;
                font-size: 14px;
                line-height: 24px;
                font-style: normal;
                font-family: 'SF-Pro-Display';
            }
        }

        &__link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            p {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                font-family: 'SF-Pro-Display';
                color: #3d5075;

                @media only screen and (max-width: 500px) {
                    flex-direction: column-reverse;
                    align-items: flex-start;
                }

                span {
                    background: #e2eeff;
                    padding: 2px 4px;

                    border-radius: 3px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    -ms-border-radius: 3px;
                    -o-border-radius: 3px;

                    color: #004aad;
                    font-size: 10px;
                    line-height: 14px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';
                }
            }

            a {
                color: #004aad;
                font-size: 12px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Semibold';
                line-height: normal;

                display: flex;
                align-items: flex-end;
                text-align: end;
                gap: 5px;
                cursor: pointer;

                @media only screen and (max-width: 500px) {
                    font-size: 10px;
                }

                svg {
                    width: 18px;
                    height: 18px;

                    @media only screen and (max-width: 500px) {
                        width: 14px !important;
                        height: 14px !important;
                    }
                }
            }

            small {
                color: #001837;
                text-align: right;
                font-size: 14px;
                line-height: 24px;
                font-style: normal;
                font-family: 'SF-Pro-Display';

                span {
                    &:first-child {
                        font-family: 'SF-Pro-Display-Semibold' !important;
                    }
                }
            }
        }
    }
}

.caret {
    cursor: pointer;
    gap: 1px !important;

    span {
        font-family: 'SF-Pro-Display-Semibold' !important;
    }
}

.hideScore {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s;
    -webkit-transition: grid-template-rows 0.5s;
    -moz-transition: grid-template-rows 0.5s;
    -ms-transition: grid-template-rows 0.5s;
    -o-transition: grid-template-rows 0.5s;

    animation: coverGap 0.5s forwards;
    -webkit-animation: coverGap 0.5s forwards;

    .dropdown {
        overflow: hidden;
        animation: hideScoreAnimation 0.5s forwards;
        -webkit-animation: hideScoreAnimation 0.5s forwards;
    }
}

.showScore {
    display: grid;
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.5s;
    -webkit-transition: grid-template-rows 0.5s;
    -moz-transition: grid-template-rows 0.5s;
    -ms-transition: grid-template-rows 0.5s;
    -o-transition: grid-template-rows 0.5s;

    .dropdown {
        overflow: auto;
        animation: showScoreAnimation 0.5s forwards;
        -webkit-animation: showScoreAnimation 0.5s forwards;
    }
}

@keyframes coverGap {
    0% {
        margin-top: 0;
    }
    100% {
        margin-top: -20px;
    }
}

@keyframes hideScoreAnimation {
    0% {
        margin-top: -12px;
        padding: 20px 15px;
        border: 0.3px solid #3d5075;
    }
    50% {
        opacity: 0.5;
    }
    80% {
        opacity: 0.2;
        border: none;
    }
    100% {
        margin: 0px;
        padding: 0;
        border: none;
        opacity: 0;
    }
}

@keyframes showScoreAnimation {
    0% {
        margin-top: -20px;
        padding: 0;
        border: none;
        opacity: 0;
    }
    20% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        margin-top: -12px;
        padding: 20px 15px;
        border: 0.3px solid #3d5075;
    }
}

.creditEngineSuccess,
.creditEngineError {
    font-family: 'SF-Pro-Display-Semibold' !important;
    font-size: 12px !important;
    font-style: normal !important;
    line-height: 20px !important;
    letter-spacing: 1.02px !important;
    text-transform: uppercase;
    border-radius: 3px;
    display: flex;
    height: 23px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.creditEngineError {
    color: #da1e28 !important;
    background: #fff1f1 !important;
}

.creditEngineSuccess {
    color: #32cc79 !important;
    background: rgba(50, 204, 121, 0.15) !important;
}

.creditFailureModal {
    padding: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        color: #011a3c;
        text-align: center;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 8px;
    }

    > p {
        color: rgba(35, 56, 98, 0.8);
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
        max-width: 252px;
        margin: 0 auto;
        margin-bottom: 25px;
    }

    &__body {
        display: flex;
        align-items: flex-start;
        align-self: flex-start;
        width: 100%;
        max-height: 18vh;
        overflow: scroll;

        gap: 10px;
        background: #f9fcff;
        padding: 13px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;

        p {
            width: 90%;
            color: #4d4d4d;
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            font-style: normal;
            line-height: 24px;
        }
    }

    &__btns {
        margin-top: auto;
    }
}
.disputeCreditDecisionStyle {
    width: 100%;

    span {
        color: #004aad;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: normal;
        cursor: pointer;
    }
}

.DisputeCreditDecisionWrapper {
    padding: 40px;

    @media screen and (max-width: 500px) {
        padding: 40px 15px;
    }
}

.DCDCategoryWrapperStyle {
    padding: 10px 30px 20px;
    height: 450px;
    overflow: auto;

    @media screen and (max-width: 500px) {
        padding: 10px 0px 20px;
        height: 500px;
        overflow-x: hidden;
    }
}

.DCDCategoryWrapperPlanStyle {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.DCDCategorySectionStyle {
    display: flex;
    width: 476px;
    padding: 18px 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    border: 0.7px solid #e5e5e5;
    background: #fff;
    margin-bottom: 10px;

    @media screen and (max-width: 500px) {
        width: 100%;
    }

    p {
        color: #004aad;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: normal;
        cursor: pointer;
    }

    span {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 10px;
        line-height: 14px;
    }

    div > p {
        color: #011a3c;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
        width: 135px;
    }

    .DCDCurrentScoreStyle {
        @media screen and (max-width: 500px) {
            width: 100px;
        }
    }
}

.DCDButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.DCDBankstatementStyle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 500px) {
        flex-wrap: nowrap;
        flex-direction: column;
    }
}

.DCDUploadBankStatementStyle {
    border-radius: 15px !important;
    border: 1px solid #e5e5e5 !important;
    background: #fff !important;
    height: 196px !important;
}

.DCDUploadModalContent {
    p {
        color: rgba(35, 56, 98, 0.78);
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: normal;
        margin-bottom: 10px;

        @media screen and (max-width: 500px) {
            font-size: 12px !important;
        }
    }

    span {
        color: #004aad;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        text-decoration-line: underline;

        @media screen and (max-width: 500px) {
            font-size: 12px;
        }
    }
}

.ManualDCDButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;

    @media screen and (max-width: 500px) {
        bottom: 34px;
    }
}

.creditReviewDoc {
    padding: 20px 16px;
    border-radius: 5px;
    border: 0.3px solid #3d5075;

    p {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 150%;
    }

    > div {
        margin-bottom: 24px;
    }

    > div:last-child {
        margin-bottom: 0;
    }

    div > div {
        display: flex;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
        }
    }

    a {
        color: #004aad;
        text-align: center;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        line-height: normal;
    }

    .note {
        border-radius: 5px;
        background: #fafafa;
        height: 53px;
        padding: 8px;
        margin-top: 8px;
    }
}
