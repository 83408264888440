.Card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    width: 100%;
    padding: 20px 10px;
    border-radius: 6px;

    & > .CardHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 10px;
        border-bottom: 1px solid #e5e5e566;

        & > h3 {
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            line-height: 16.71px;
            color: #011a3c;
            text-transform: capitalize;
        }
    }

    & .ReviewBrandsCardBody {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px;

        & > .BodyHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            & > h4 {
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                line-height: 18px;
                color: #4d4d4d;
            }

            & > button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2px;
                border: none;
                color: #004aad;
                background-color: #fff;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                line-height: 14.32px;
                height: 29px;
                width: 67px;
                border-radius: 30px;
                cursor: pointer;

                &:hover {
                    background: #f9fcff;
                }

                & > svg {
                    stroke: #004aad;
                }

                @media screen and (max-width: 500px) {
                    width: 40px;
                }
            }
        }

        & > .BrandRow {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;

            & > p {
                font-family: 'SF-Pro-Display-Medium';
                font-size: 14px;
                line-height: 16.71px;
                color: #011a3c;
            }

            & > .ApprovedTag,
            .RejectedTag {
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 10px;
                line-height: 11.93px;
                letter-spacing: 0.055em;
                height: 23px;
                border-radius: 3px;
                padding-inline: 10px;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & > .ApprovedTag {
                background-color: #e5e5e580;
                color: #233862e0;
            }

            & > .RejectedTag {
                background-color: #fff9e7;
                color: #e4aa0f;
            }

            & > .BrandRowActions {
                display: flex;
                align-items: center;
                gap: 16px;

                & > button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    padding: 0;
                    height: 32px;
                    width: 32px;
                }
            }
        }
    }

    & > .ApprovedBrandsCardBody {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: 10px;

        & > .BrandTag {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            padding: 0px 16px;
            border-radius: 21px;
            border: 0.5px solid #e5e5e5;
            background-color: #fafafa;

            & > p {
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                line-height: 18px;
                color: #070c18;
            }
        }
    }

    & > .ReviewBrandsCardFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 10px;
    }
}
