.Container {
    background-image: url('https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/solar-city-bg.svg');
    background-size: 35%;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
}

.NoBgContainer {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.MinHeightContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.MaxWidthContainer {
    display: flex;
    max-width: 1050px;
    margin: auto;
    width: 100%;
    padding: 20px;

    @media screen and (max-width: 600px) {
        padding: 0;
    }
}

.LeftSection {
    display: flex;
    width: 50%;
    flex-direction: column;
}

.RightSection {
    width: 50%;
    padding: 60px;

    @media screen and (min-width: 991px) and (max-width: 1200px) {
        width: auto;
    }
}

.TextSection {
    margin-top: 50px;
    max-width: 385px;
    padding-right: 30px;

    @media screen and (min-width: 990px) {
        padding-right: 0;
    }
}

.PageText {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 35px;
    line-height: 54px;
    color: #011a3c;
}

.SubText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.88);
    margin-top: 12px;

    @media screen and (min-width: 2560px) {
        font-size: 18px;
    }
}

.ImageSection {
    height: 80%;
    margin-top: 40px;

    @media screen and (min-width: 2560px) {
        height: 80%;
    }
}

.ImageSectionNoText {
    height: 60% !important;
    margin-top: 380px;

    @media screen and (min-width: 2560px) {
        height: 60%;
    }
}

.LeftSectionClass {
    flex: 1;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.RightSectionClass {
    max-width: 450px;
    width: 100%;

    @media screen and (max-width: 600px) {
        padding: 0;
        max-width: 100%;
    }

    @media screen and (min-width: 1900px) {
        width: 53%;
        max-width: unset !important;
    }
}

.LeftSectionContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.ConsumerAppNavHeader {
    display: flex;
    justify-content: space-between;
    line-height: 46px;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    padding: 25px;
    padding-top: 30px;
}

.ConsumerFloatBox {
    position: absolute;
    right: 0;
    right: 58px;
    bottom: 31px;

    @media screen and (max-width: 500px) {
        right: 10px;
    }

    @media screen and (max-width: 360px) {
        right: 0;
        bottom: -22px;
    }
}

.ConsumerFooter {
    display: none;

    @media screen and (max-width: 640px) {
        padding: 0;
        max-width: 100%;

        margin: 1.8em 0px;
        text-align: center;
        display: flex;
        justify-content: center;

        div,
        a {
            padding: 1.5em 0.5em;
            font-family: 'SF-Pro-Display';
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            color: rgba(35, 56, 98, 0.88);
        }
    }
}

.FullWidthContainer {
    flex: 1;
    display: flex;

    > * {
        flex: 1 !important;
    }
}
