#ConsumerLoaderOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ConsumerLoaderText {
    width: 200px;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    margin-top: 12px;
    text-align: center;
}

.ConsumerLoaderSpinner {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.ConsumerLoaderIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-top: -16px;
    margin-left: -16px;

    &.MediumIcon {
        width: 74px;
        height: 74px;
        margin-top: -37px;
        margin-left: -37px;
    }
}

.ConsumerLoaderTopIcon {
    margin-top: -2px;
    margin-left: -17px;
    animation: spin2 2s linear infinite;

    &.MediumIcon {
        margin-top: -4px;
        margin-left: -37px;
    }
}

.ConsumerLoaderBottomIcon {
    animation: spin 2s linear infinite;
}

@keyframes spin2 {
    0% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(180deg);
    }
}
