.TableHead {
  display: grid;
  grid-template-columns: 40% 30% 40%;
  padding: 0 29px 0 20px;
  margin-bottom: 18px;

  p {
    margin: 0;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    color: #011A3CCC;
  }

  @media screen and (min-width: 1600px){
    grid-template-columns: 50% 30% 20%;
  }

}

.Rows {
  height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.TableRow {
  display: grid;
  grid-template-columns: 40% 30% 40%;
  align-items: center;
  height: 67px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 0 29px 0 20px;

  h4 {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    margin: 0;
@media screen and (max-width: 500px) {

    max-width: 120px;
    word-break: break-word;
  }
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    margin: 0;
@media screen and (max-width: 500px) {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
  }
  }

  @media screen and (min-width: 1600px){
    grid-template-columns: 50% 30% 20%;
  }
@media screen and (max-width: 500px) {
  width: 98%;
  height: 188px;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-top: 4px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
}

.BtnWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.Heading {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  color: #011A3C;
  margin-bottom: 37px;
@media screen and (max-width: 1100px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.ModalWrapper {
  margin: 48px 79px 63px 80px;
@media screen and (max-width: 500px) {
    margin: 66px 20px;
  }
}
.Desktop {
  @media screen and (max-width: 500px) {
    display: none;
  }
}

.MobileTopRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.MobileBottomRow {
  display: block;
  padding-top: 50px;
  width: 100%;
}