.Table {
  height: 400px;
  padding-right: 10px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.HeaderRow {
  display: grid;
  grid-template-columns: 50% 35% 15%;

  :first-child {
    margin-left: 20px;
  }

  :last-child {
    margin-left: -26px;
  }

  h4 {
    margin: 0;
    font-size: 13px;
    color: #011A3CCC;
    margin-bottom: 18px;
    text-align: left;
  }
}

.ContentRow {
  display: grid;
  grid-template-columns: 50% 35% 15%;
  align-items: center;
  height: 69px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 0 28px 0 20px;

  h4 {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    text-align: left;
  }
}

.cards {
    display: none;
    @media only screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}