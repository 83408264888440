.NavWrapper {
    display: flex;
    padding: 25px;
    justify-content: space-between;

    @media screen and (max-width: 640px) {
        padding: 25px 0px;
    }
}

.NavWrapperForWorkspace {
    align-items: center;
    line-height: 0;

    .NavMenu {
        margin-left: auto;
    }

    .SunfiLogo {
        margin-top: 0;
    }
}

.SunfiLogo {
    margin-top: 12px;
}

.NavMenu {
    align-items: center;
    justify-content: flex-end;
    border: none;
    background-color: #fff;

    @media screen and (max-width: 640px) {
        a {
            display: none;
        }
    }
}

.ListItem {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: rgba(35, 56, 98, 0.88);
    padding: 0px 30px;
    cursor: pointer;
    @media screen and (max-width: 640px) {
        padding: 18px 0px;
        position: relative;
    }
}

.NavLogout {
    font-family: 'SF-Pro-Display-Medium';
    color: #004aad;
}

.MobileNav {
    height: fit-content;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 500px) {
        display: none;
    }
}

.options {
    display: flex;
    align-items: center;
    margin-left: 80px;
}

.AvatarImg {
    width: 37px;
    height: 37px;
    margin-right: 10px;
}

.DropDownLI {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 17px;
    margin-right: 10px;

    span,
    span:hover {
        font: 14px/24px 'SF-Pro-Display';
        color: #233862;
    }
}

.InitialsAvatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0, 74, 173);
    margin-right: 8px;

    span {
        font: 14px/24px 'SF-Pro-Display-Semibold';
        color: white;
    }
}
