.AllComponents {
    margin: 28px 0 20px 0;
    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
    }

    h5 {
        margin: 0;
    }

    ul {
        margin-block: 0;
        padding-inline: 0;
    }

    li {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr 45px 1fr 1fr 20px;
        border-bottom: 0.5px solid #c4c4c4;
        padding: 20px 0 16px 0;
        justify-items: center;
        column-gap: 5px;
    }

    .componentName {
        color: #011a3c;
        font-size: 14px;
        font-family: 'SF-Pro-Display-Semibold';
        line-height: normal;
        justify-self: flex-start;
    }

    .componentCapacity,
    .componentType,
    .componentCost,
    .componentQuantity {
        color: rgba(1, 26, 60, 0.8);
        font-size: 13px;
        line-height: normal;
    }

    .componentQuantity {
        font-family: 'SF-Pro-Display-Medium';
        color: #011a3c;
    }
}

.TopArrowWrapper {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.TopArrowOpen {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.TopArrowClosed {
    transition: transform 0.3s ease;
}
