.Estimation_create-Container {
  padding: 0 80px;

  @media (max-width: 1040px) {
    padding: 0 40px;
  }

  @media (max-width: 800px) {
    padding: 0;
  }
}

//create estimation classes
.PrevButton {
  position: relative;
  bottom: 55px;
  color: #004AAD;
  stroke: #004AAD;
  font-family: 'SF-Pro-Display-Medium';
  background: none;
  font-size: 14px;
  line-height: 17px;
  border: none;
  cursor: pointer;


  @media (max-width: 800px) {
    margin-top: 34px;
  }
    @media (max-width: 500px) {
      margin-top: 0px;
    }
}

.PrevButton img {
  margin-right: 13.35px;
}

.CreateEstimationH1 {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  @media screen and (max-width: 500px) {
      margin-top: 20px;
    }
}

.CreateEstimationContainer {
  margin-top: 62px;
}

.StepsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.StepsChildContainer>p {
  position: absolute;
  font-family: 'SF-Pro-Display-Light';
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
}

.StepsButton {
  font-family: 'SF-Pro-Display';
  width: 36px;
  height: 36px;
  border-radius: 50px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  background: linear-gradient(90deg, #F1F1F1 0%, #F3F3F3 51.7%, #F3F3F3 100%, #F1F1F1 100%);


  @media (max-width: 500px) {
    background: #ffffff;
  }
}

.StepsLine {
  min-width: 250px;
  border-top: 1px solid #e5e5e5;
  margin: 0 25px;
  height: 1px;

  @media (max-width: 800px) {
    min-width: 150px;
  }

  @media (max-width: 500px) {
    min-width: 92px;
    margin: 0 9px;
  }

  @media (max-width: 330px) {
    min-width: 80px;
    margin: 0 8px;
  }
}

.StepsText {
  @media (max-width: 500px) {
    display: none;
  }
}

.CreateEstimationH2 {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #011A3C;
}

.CreateEstimationSubTitle {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: rgba(1, 26, 60, 0.8);
}

.CreateEstimationBtnPrimary {
  font-family: 'SF-Pro-Display';
  background: #004AAD;
  border-radius: 30px;
  height: 46px;
  border: 1px solid #004AAD;
  color: #ffffff;
  padding: 11px 34px 14px 34px;
  margin-top: 17px;
  cursor: pointer;
}
.NewCustomerTypeSubheaderStyle {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 15px;
  line-height: 18px;
  color: #011A3C;
}

.NewCustomerTypeSectionWrapper {
  display: flex;
  margin: 45px auto 40px;
}

.NewCustomerTypeCardStyle {
  background: #FFFFFF;
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  width: 190px;
  height: 233px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 17px;
  margin-right: 30px;
  cursor: pointer;
  @media (max-width: 500px) {
      margin-right: 15px;
    }
}

.UserImgStyle {
  border: 0.5px solid #E5E5E5;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  text-align: center;
  padding-top: 10px;
}

.NewCustomerTypeCardHeader {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #233862;
  margin-top: 40px;
}

.NewCustomerTypeCardText {
  font-family: 'SF-Pro-Display';
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: rgba(35, 56, 98, 0.7);
}