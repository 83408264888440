.form {
    margin-top: 4px;
    background-color: #f9fcff;
    border: 0.5px solid #e5e5e5;
    border-radius: 16px;
    padding: 16px;

    h2 {
        font: 14px/21px 'SF-Pro-Display';
        color: #3d5075;
        margin-bottom: 16px;
    }
}

.newform {
    background-color: #fff;
}

.options {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.option {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 16px;
    border-radius: 14px;
    cursor: pointer;
    position: relative;

    &:hover {
        border: 0.5px solid #004aad !important;
        background-color: #e2eeff !important;
    }

    > div {
        h4 {
            font: 14px/24px 'SF-Pro-Display-Semibold';
            color: #233862;
            margin-bottom: 0;
        }

        p {
            font: 12px/20px 'SF-Pro-Display';
            color: #4d4d4d;
            margin-bottom: 0;
        }
    }
}

.optionImg {
    width: 44.1px;
    height: 42px;
    object-fit: contain;
    flex-shrink: 0;
}

.checkIcon {
    width: 16px;
    height: 16px;
    object-fit: cover;
    position: absolute;
    right: 9px;
    top: 10px;
}

.divider {
    display: flex;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 36px;

    hr,
    p {
        margin: 0;
    }

    hr {
        width: 100%;
        border: none;
        border-top: 0.3px solid #b0b0b0;
    }

    p {
        font: 12px/20px 'SF-Pro-Display';
        color: #3d5075;
    }
}

.disclaimer {
    font: 14px/21px 'SF-Pro-Display';
    color: #4d4d4d;
    margin-top: 12px;
    text-align: center;

    a {
        color: #004aad;
        font-family: 'SF-Pro-Display';
        text-decoration: underline;
    }
}

.error {
    font: 12px/16px 'SF-Pro-Display';
    color: #da1e28;
    margin-top: 6px;
}
