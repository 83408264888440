.MobileTitle {
  font-size: 14px;
}
.AddMorePackagesWrapper {
  font-family: 'SF-Pro-Display-Medium';
  width: 100%;
  height: 68px;
  background: #E2EEFF;
  border: 0.5px dashed rgba(0, 74, 173, 0.79);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  margin-bottom: 20px;
}

.AddMorePackagesLeftWrapper {
  display: flex;
}

.AddMorePackageImageWrapper {
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  border: 0.5px solid rgba(0, 74, 173, 0.79);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddMorePackagetext {
  font-size: 15px;
  line-height: 26px;
  color: #004AAD;
  margin-left: 20px;
  margin-top: 8px;
}
.MobileWrapper {
  @media screen and (max-width: 500px) {
    margin: 20px 15px;
    justify-content: space-between;
  }
}

.MobileWrapper h4 {
  @media screen and (max-width: 500px) {
    font-size: 12px;
    width: 95px;
  }
}

.MobileWrapper p {
  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
}

.MobileAmountWrapper {
  gap: 25px;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    gap: 3px;
    width: 180px;
    align-items: center;
  }
}