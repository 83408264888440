.ConsumerHelpCenterTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  line-height: 24px;
  color: #011A3C;
  margin: 20px 0 40px;
}

.CategoryLineDivide {
  border-bottom: 0.6px solid rgba(229, 229, 229, 0.6);
  width: 315px;
  height: 0px;
  margin-left: -4px;

  @media screen and (max-width: 320px) {
    width: 277px;
  }
}


.ConsumerCategoryheader {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 16px;
  color: #011A3C;
  padding: 7px 0;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  padding-left: 3px;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
  padding-left: 3px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: rgba(1, 26, 60, 0.21);
}

.line {
  cursor: pointer;
  border-bottom: 0.6px solid rgba(229, 229, 229, 0.6);
}

.questions {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 15px;
  line-height: 26px;
  color: #004AAD;
  cursor: pointer;
}

.AnswerTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 18px !important;
  line-height: 32px;
  color: #011A3C;
  margin: 20px 0;
}

.textCountStyle {
  font-size: 15px !important;
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  color: rgba(35, 56, 98, 0.8);
}

.ConsumerAnswerText {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px !important;
  line-height: 25px;
  color: rgba(35, 56, 98, 0.8);
  padding-bottom: 10px;
}

.HelpCenterBadge {
  margin-bottom: 15px;

  .ant-badge-status-text {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15px;
    color: rgba(1, 26, 60, 0.7);
  }

  .ant-badge-status-dot {
    height: 7px;
    width: 7px;
    color: rgba(1, 26, 60, 0.8) !important;
  }
}

.ConsumerDrawer {
  position: relative;
}

.ConsumerFloatBoxClass {
  position: absolute;
  margin-top: 12px;
  right: 6%;
  bottom: 2%;

  @media screen and (max-width: 360px) {
    bottom: 1%;
    right: 6%;
  }

  @media screen and (max-width: 320px) {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 2%;
    right: -8%;
  }
}

.AnswerHelpCenterTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 20px;
  color: #011A3C;
  margin: 20px 0 30px;
}

.SelectedQuestion {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 17px;
  color: #011A3C;
}

.SelectedAnswer {
  padding-top: 20px;
}

.AskQuestionTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: rgba(1, 26, 60, 1);
  margin: 20px 0;
}

.AskQuestionTitleDescription {
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 25px;
  color: rgba(35, 56, 98, 0.8);
  margin-bottom: 20px;
}

.AskQuestionInfoWrapper {
  display: flex;
  padding-bottom: 30px;

  @media screen and (max-width: 320px) {
    padding-bottom: 18px;
  }
}

.AskQuestionEmailWrapper {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;

  a {
    margin-bottom: 6.1px;
    font-size: 13px;
    font-family: 'SF-Pro-Display-Medium';
    color: #004AAD;
  }

  @media screen and (max-width: 320px) {
    margin-right: 4px;
  }
}

.AskQuestionPhoneWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    margin-bottom: 6.1px;
    font-size: 13px;
    font-family: 'SF-Pro-Display-Medium';
    color: #004AAD;
  }
}

.Ask_EmailIconWrapper {

  // height: 10px;
  // width: 10px;
  padding-right: 5px;

  svg {
    filter: brightness(0) saturate(100%) invert(16%) sepia(97%) saturate(661%) hue-rotate(189deg) brightness(90%) contrast(88%);
    opacity: 0.7;
  }
}



.Ask_PhoneIconWrapper {
  // padding-right: 2px;

  svg {
    filter: brightness(0) saturate(100%) invert(16%) sepia(97%) saturate(661%) hue-rotate(189deg) brightness(90%) contrast(88%);
    opacity: 0.7;
  }
}


.sendQuestionBtnWrapper {
  margin: auto;
  text-align: center;
  margin-top: 30px;
}

.SentMessagePageWrapper {
  text-align: center;
}

.MessageSentHeader {
  color: #011A3C;
  font-size: 20px;
  // line-height: 24px;
  font-family: 'SF-Pro-Display-Bold';
  padding-top: 20px;
  margin-bottom: 12px;
}

.MessageSentHeaderDescription {
  font-family: 'SF-Pro-Display';
  font-size: 15px;
  line-height: 26px;
  color: rgba(35, 56, 98, 0.8);
  padding-bottom: 25px;
  width: 312px;
}

.CloseHelpSliderBtnWrapper {
  margin: 0 0 60px;
}

.MessageSentIconWrapper {
  margin-top: 60px;
}

.CaptchaTitle {
  font-family: 'SF-Pro-Display-Medium';
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
  margin: 25px 0 0 0;
}

.CaptchaSubtitle {
  font-family: 'SF-Pro-Display';
  font-size: 14px;
  line-height: 17px;
  color: rgba(35, 56, 98, 0.8);
  margin: 2px 0 9px 0;
}