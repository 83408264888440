.EmptyStateContainer {
  border-radius: 50px;
  height: 47px;
  width: 47px;
  margin-right: 10px;
  position: relative;
  top: 2px;
  display: inline-block;
  text-align: center;
}

.EmptyStateContainerProfilePage {
  border-radius: 50px;
  height: 47px;
  width: 47px;
  margin-right: 10px;
  position: relative;
  top: -8px;
  display: inline-block;
  text-align: center;
}

.TextBox {
  display: inline-block;
  font-family: 'SF-Pro-Display-Bold';
  color: #fff;
  position: relative;
  top: -9px;
  font-size: 17px;
}

.TextBoxProfilePage {
  display: inline-block;
  font-family: 'SF-Pro-Display-Bold';
  color: #fff;
  position: relative;
  top: 10px;
  font-size: 17px;
}