.CSSEVideoPlayerWrapper {
    display: flex;
    padding: 63px 10px 0px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        color: #011a3c;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
    }

    span {
        color: #4d4d4d;
        font-family: 'SF-Pro-Display';
        font-size: 10px;
        line-height: 14px;
        display: inline-block;
    }

    p {
        color: #4d4d4d;
        text-align: center;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        width: 345px;

        @media screen and (max-width: 600px) {
            width: 287px;
        }
    }
    @media screen and (max-width: 500px) {
        padding-top: 19px;
    }
}

.CSSEVideoPlayerImageWrapper {
    margin: 15px;
    text-align: center;
    position: relative;

    svg {
        cursor: pointer;
        position: absolute;
        top: 34%;
        right: 46%;
        width: 49px;
        height: 34px;
    }

    img {
        cursor: pointer;
        max-width: 100%;
        height: auto;
        border-radius: 3.164px;
        width: 265px;
    }
}
