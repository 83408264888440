.card {
    width: 100%;
    height: fit-content;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 0.5px solid #e5e5e5;
}

.cardTitle {
    font: 12px/24px var(--font-regular);
    color: #3d5075;
    margin-bottom: 17px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #e5e5e5;
    margin-bottom: 16px;
    padding-bottom: 6px;

    h5 {
        font: 14px/21px var(--font-regular);
        color: #011a3c;
        margin: 0;
    }

    p {
        font: 14px/16.71px var(--font-semibold);
        color: #011a3c;
        margin: 0;
    }
}

.btnsRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        font: 12px/14.32px var(--font-semibold);
        margin: 0;

        &.removeBtnInactive {
            color: #b0b0b0;
            cursor: not-allowed;
        }

        &.removeBtnActive {
            color: #004aad;
            cursor: pointer;
        }
    }
}
