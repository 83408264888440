.CSSECTAWrapper {
    width: 100%;
    height: 310px;
    background: #004aad;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    >div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1244px;
        width: 100%;
        gap: 40px;
        flex-wrap: wrap-reverse;

        >img {
            width: 100%;
            max-width: 543px;
            min-width: 280px;
        }

        @media only screen and (max-width: 939px) {
            justify-content: center;
            height: auto;
        }

        @media only screen and (max-width: 500px) {

            >img {
                // margin-left: 30px;
                height: 100%;
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 939px) {
        padding: 50px 0 0 0;
        height: auto;

    }

    @media only screen and (max-width: 500px) {
        margin-top: 57px;
        padding: 30px 0 0 0;

    }
}

.CSSECTARightWrapper {
    min-width: 280px;

    h1 {
        color: #fff;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 35px;
        line-height: 45px;
        width: 347px;

        @media only screen and (max-width: 500px) {
            width: auto;
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 32px;
            line-height: 38px;
        }
    }

    @media only screen and (max-width: 500px) {
        padding: 0 20px;
    }
}