.mainPlanDetails {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

    @media only screen and (max-width: 500px) {
        gap: 0px;
    }

    @media only screen and (min-width: 1300px) and (max-width: 1480px) {
        max-width: 600px;
    }

    &__top {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @media only screen and (max-width: 700px) {
            gap: 20px;
        }

        h3 {
            display: flex;
            align-items: center;
            color: #001837;
            font-size: 18px;
            line-height: 24px;
            font-style: normal;
            font-family: 'SF-Pro-Display-Bold';

            span {
                display: flex;
                height: 23px;
                padding: 8px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 3px;
                background: #e2eeff;

                color: #004aad;
                font-size: 12px;
                line-height: 24px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Medium';
                letter-spacing: 0.78px;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            flex-wrap: wrap;

            @media only screen and (max-width: 500px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
            }

            div {
                h6 {
                    color: #233862;
                    font-size: 12px;
                    line-height: 24px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display-Medium';
                    letter-spacing: 0.78px;
                    text-transform: uppercase;
                    margin-bottom: 0;
                }
                p {
                    color: #3d5075;
                    font-size: 16px;
                    line-height: 24px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display';

                    display: flex;
                    align-items: center;

                    svg {
                        cursor: pointer;
                        margin-left: 5px;
                        align-self: baseline;
                    }
                }
            }

            a {
                color: #004aad;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Semibold';
                display: flex;
                align-items: center;

                svg {
                    width: 18px;
                    height: 18px;
                    margin-left: 10px;
                }
            }
        }
    }

    &__mobile {
        display: none;
        margin-bottom: 70px;

        @media only screen and (max-width: 1180px) {
            display: block;
        }

        @media only screen and (max-width: 500px) {
            margin-top: 25px;
        }

        &__items {
            display: flex;
            row-gap: 10px;
            column-gap: 20px;
            flex-wrap: wrap;

            div {
                h6 {
                    color: rgba(1, 26, 60, 0.85);
                    font-size: 12px;
                    line-height: 20px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display-Medium';
                }
                p {
                    color: #011a3c;
                    font-size: 13px;
                    line-height: 25px;
                    font-style: normal;
                    font-family: 'SF-Pro-Display-Semibold';
                }
            }
        }
    }
}

.nameAndStatus {
    display: flex;
    width: fit-content;
    align-items: flex-start;
    gap: 24px;

    h3 {
        width: 100%;
        margin: 0;
    }

    > div {
        min-width: 200px;
    }

    span {
        margin-left: 0px;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column-reverse;
        gap: 8px;
    }
}
.ContentWrapper {
    height: 425px;
    margin-top: 12px;
    padding-right: 10px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media only screen and (max-width: 500px) {
        padding-left: 3px;
    }
}

.TableNoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0 60px 0 26px;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
    }

    @media screen and (max-width: 500px) {
        padding: 0 15px 0 15px;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    }
}

.NoHeaderName {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
}

.NoHeaderContent {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #233862;
    text-align: right;
}

.KYCLink {
    pointer-events: none;
    opacity: 0.7;
}
