// Global Styles

.AdminProfilesDetails_EditDrawer {
    .ant-drawer-body {
        padding: 20px 30px !important;
    }

    @media only screen and (max-width: 500px) {
        .ant-drawer-body {
            padding: 20px !important;
        }
    }
}

.SelectedSolutionCard-InfoImgWrapper {
    @media only screen and (max-width: 500px) {
        display: block !important;
        width: 100% !important;

        img {
            width: 100% !important;
        }
    }
}

.SystemDetailsCardMenu {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 22px 0 !important;
    width: 150px !important;

    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
        padding: 0 16px !important;
    }
}

.ActionModalV2_Footer {
    padding: 16px 24px !important;
}

.SelectVariablesDrawer_Wrapper {
    .ant-drawer-body {
        padding: 30px;
    }
}
