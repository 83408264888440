.dispute_modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 48px 24px;

    > div:first-of-type {
        h2 {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 22px;
            line-height: normal;
            text-align: center;
            margin: 0;
            color: #011a3c;
        }

        h3 {
            color: rgba(35, 56, 98, 0.8);
            text-align: center;
            font-family: 'SF-Pro-Display';
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
            margin: 8px 0 22px 0;
        }
    }

    > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 19px;
        margin-bottom: 38px;

        h3 {
            color: #233862;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 15px;
            font-style: normal;
            line-height: 26px;
        }

        p {
            color: rgba(35, 56, 98, 0.7);
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            font-style: normal;
            line-height: normal;
        }

        input {
            display: none;
        }

        label {
            display: grid;
            grid-template-columns: auto 1fr;
            cursor: pointer;
            padding: 18px;
            gap: 17px;
            height: 86px;
            align-self: stretch;
            border-radius: 10px;
            border: 0.5px solid #e5e5e5;
            background: #fff;

            div:first-of-type {
                width: 50px;
                height: 50px;
                flex-shrink: 0;
                border-radius: 10px;
                border: 0.5px solid #e5e5e5;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @media screen and (max-width: 500px) {
                height: 100%;
            }
        }

        label:hover,
        input[type='radio']:checked + label {
            border-radius: 10px;
            border: 0.5px solid var(--Accent-Blue-Primary, #004aad);
            background: var(--Accent-Blue-Light, #e2eeff);

            div:first-of-type {
                border-radius: 10px;
                background: var(--Accent-Blue-Primary, #004aad);

                path {
                    stroke: #ffffff;
                }
            }
        }
    }

    button {
        @media screen and (max-width: 500px) {
            position: absolute;
            bottom: 34px;
        }
    }
}
