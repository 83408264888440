.NameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.GroupSelect {
  margin: 20px 0 48px 0;
}

.ButtonDiv {
  text-align: center;
}