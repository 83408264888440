.dateWrapper {
    display: flex;
    justify-content: space-between;

    div.DateInputRight {
        width: 48.1% !important;

        @media screen and (max-width: 375px) {
            width: 45% !important;
        }
    }

    div.DateInputLeft {
        width: 48.1% !important;

        @media screen and (max-width: 375px) {
            width: 45% !important;
        }
    }

    .Separator {
        display: inline-block;
        width: 2%;
    }
}
.ApprovedSelectField {
    margin: 16px 0 45px;
}
.ApprovedModalText {
    font-size: 14px;
    line-height: 17px;
    font-style: normal;
    font-family: 'SF-Pro-Display-Bold';
    color: #011a3c;
    margin-top: 40px;
    margin-bottom: 14px;
}

.ApprovedModalDragger {
    width: 468px;
}

.ApprovedBtnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.ant-dropdown.AdminApprovedTimePicker.ant-dropdown-placement-bottomCenter {
    top: 175px !important;
    position: fixed;
    .ant-dropdown-menu-item:hover {
        background: #ffff;
    }
}
.ApprovedModalTimeWrapper {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    width: 99px;
    height: 60px;
    font-size: 22px;
    line-height: 26px;
    font-family: 'SF-Pro-Display-Bold';
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
}

input:focus {
    border: 1px solid #004aad;
}

.ApprovedModalTimePickerText {
    margin-top: 7px;
    margin-bottom: 25px;
    text-align: center;
}
.ApprovedModalTimePickerSeparator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #202124;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding: 12px 15px;
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    font-family: 'SF-Pro-Display';
    color: #4f6487;

    @media screen and (max-width: 401px) {
        line-height: 22px;
    }
}

.MoreActionModalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.APAModalDateTimeInput {
    @media screen and (max-width: 500px) {
        width: 72% !important;
    }
}

.TPInputWrapper {
    display: flex;
    position: relative;
    align-items: center;

    > input {
        padding: 0 24px 0 8px;
    }

    > div {
        position: absolute;
        right: 24px;
    }
}

.APAModalDateTimeWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
    width: 463px;
    position: relative;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        width: 100%;
        gap: 20px;

        .APAModalDateTimeInput {
            width: 100% !important;
        }
    }

    .APAMInputAutoFilled {
        display: flex;
        flex-direction: column;
        gap: 0;
        position: absolute;
        top: 2px;

        p {
            font-size: 11px;
            color: #004aad;
            margin: 0;
            height: 20px;
        }

        span {
            margin: 0;
        }
    }
}

.APAActionText {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    color: #011a3c;
    margin: 20px 0 10px 0;
}

.APAViewUploadedFile {
    width: 464px;
    height: 154px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    @media screen and (max-width: 500px) {
        width: 331px;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        color: #233862c7;
        margin-bottom: 10px;
    }

    a {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        color: #004aad;
        text-decoration: underline;
    }
}

.APADraggerArea {
    margin-bottom: 60px;

    .ant-upload.ant-upload-drag {
        max-width: 464px;
        height: 154px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.APADraggerText {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    color: #233862;
    opacity: 78%;

    span {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 14px;
        color: #004aad;
        opacity: unset;
        text-decoration: underline;
    }
}

.APADraggerUploadingText {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    color: #233862;
    opacity: 78%;
}

.APARSArea {
    width: 464px;
    height: 60px;
    padding: 10.5px 23.33px 10.5px 30px;
    margin: 16px 0 70px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    cursor: not-allowed;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-left: 24px;
    }

    div {
        h4 {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            color: #004aad;
            margin: 0;
        }

        span {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            color: #011a3c;
        }
    }
}

.APAModalTimeInputWrapper {
    position: relative;
}

.APAModalPrefilledTimeWrapper {
    position: absolute;
    bottom: 30px;
    z-index: 100;
}

.ApprovedModalUploadError {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    letter-spacing: 0.32px;
    color: #da1e28;
    margin: 5px 0 0 19px;
}

.AdminPaymentPlanCaretStyle {
    position: absolute;
    left: 81%;
    top: 32%;
    cursor: pointer;

    @media screen and (max-width: 500px) {
        left: 89%;
    }
}

.RecordRepayment__PaymentMethodWrapper {
    display: flex;
    margin-bottom: 46px;

    @media screen and (max-width: 500px) {
        flex-direction: column;
        gap: 20px;
    }
}
