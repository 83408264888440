.Wrapper {
  margin-top: 50px;
  font-family: "SF-Pro-Display";

  @media screen and (min-width: 2500px) {
    width: 40vw;
  }
  

  @media screen and (min-width: 1300px) and (max-width: 1400px) {
    margin-left: -5%;
  }

  @media screen and (max-width: 500px) {
    margin-top: 20px;
  }
}

.Row {
  display: flex;
  margin-top: 35px;
  height: 70px;
  @media screen and (max-width: 800px) {
    height: fit-content;
    flex-direction: column;
    margin-top: 20px;
  }
}

.LeftWrapper {
  width: 270px;
  @media screen and (max-width: 500px) {
    width: fit-content;
 }
}

.Header {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 15px;
  line-height: 18px;
  color: #011a3c;
  margin-bottom: 8px;
  padding-top: 10px;
}
.SubHeader {
  font-size: 14px;
  line-height: 19px;
  color: rgba(35, 56, 98, 0.8);
}
.FullName {
  display: flex;
  width: 389px;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 100%;
 }
}
.SaveBtn {
  margin-top: 60px;
  display: flex;
  justify-content: flex-end;
  width: 657px;
  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin-top: 60px;
 }
}
.MobileFieldWrapper {
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}