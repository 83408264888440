.CheckBoxWrapper {
    display: flex;

    svg {
        flex-shrink: 0;
    }
}

.CheckboxText {
    margin-left: 10px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    margin-top: 2px;
    color: #233862;
    white-space: normal;

    @media screen and (max-width: 500px) {
        font-size: 13px;
    }
}

.CheckboxCursor {
    cursor: pointer;
}

.CheckBoxWrapper_storybook {
    display: flex;
    align-items: center;
}
