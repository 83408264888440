.TextSectionBox {
  padding: 25% calc(12% + 10%);
  color: var(--primary-blue);
  font-size: calc(30px + 20%);
  font-family: 'SF-Pro-Display-Bold';
  font-style: normal;
  line-height: 47px;

  @media screen and (max-width: 1921px) and (min-width: 1580px) {
    width: calc(65% + 30%);
    font-size: calc(40px + 20%);
    padding: calc(25% - 2%) calc(20% + 5%);
  }

  @media screen and (min-width: 1922px) {
    width: calc(80% + 20%);
    font-size: calc(60px + 20%);
    line-height: calc(68px + 20px);
    padding: calc(25% - 2%) calc(20% + 5%);
  }

  @media screen and (min-width: 2700px) {
    width: calc(60% + 30%);
    padding: calc(25% - 2%) calc(20% + 5%);
  }

  @media (max-width: 998px) {
    padding: 20px 40px;
  }

  @media screen and (max-width: 800px) {
    padding: 60px 40px;
  }

  @media (max-width: 500px) {
    padding: 40px 20px;
    font-size: 30px;
    width: 100%;
  }

  .Header {
font-size: 40px;
line-height: 48px;
    @media (max-width: 500px) {
      font-size: 28px !important;
      width: 333px !important;
    }
  }

  .PartnerHeader {
    @media (max-width: 500px) {
      font-size: 30px !important;
      line-height: 47px !important;
      width: 322px;
    }
  }

  .SubHeader {
    font-family: 'SF-Pro-Display-Light';
    font-style: normal;
    font-size: 16px;
    line-height: 23px;
    color: rgba(1, 26, 60, 0.8);
    margin-top: 20px;
    min-width: 103%;
    margin-bottom: 20px;
    text-align: left;

    @media screen and (max-width: 1921px) and (min-width: 1580px) {
      width: calc(65% + 30%);
      font-size: calc(15px + 5%);
      line-height: calc(10px + 20px);
    }

    @media screen and (min-width: 1922px) {
      width: calc(80% + 20%);
      font-size: calc(15px + 5%);
      line-height: calc(10px + 20px);
    }

    @media screen and (min-width: 2700px) {
      width: calc(60% + 30%);
      font-size: calc(15px + 5%);
      line-height: calc(10px + 20px);
    }

    @media screen and (max-width: 800px) {
      width: 318px;
      min-width: 60%;
    }

    @media (max-width: 500px) {
      width: 318px !important;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .ButtonContainer {
    display: flex;
    margin-top: 40px;

    @media (max-width: 500px) {
      display: grid;
      grid-template-columns: auto auto;
      grid-row-gap: 16px;
    }
  }
}

.TextSectionBox2 {
  padding: 12% calc(4% + 10%);

  .SubHeader {
     width: 350px; 
     min-width: 89%;

     @media screen and (max-width: 800px) {
      width: 318px;
      min-width: 60%;
    }
  }

  @media screen and (max-width: 500px){
    padding: 40px 20px;
    font-size: 30px;
    width: 100%;
  }
}

.PictureSectionBox {
  margin-top: calc(10px + 10%);
  display: block;
  // @media (max-width: 998px) {
  //   margin-top: 20px;
  // }

  img {
    @media screen and (max-width: 998px) {
      display: block;
    }
  }

  svg {
    position: relative;
    right: 40px;
    top: 395px;
    
      @media (max-width: 998px) {
        display: none;
      }
  }

 
}

.ImageBox {
  width: calc(70% + 1%);

  @media screen and (max-width: 1921px) and (min-width: 1580px) {
    width: 80%;
  }

  @media screen and (min-width: 1922px) {
    width: calc(35% + 20%);
    height: calc(550px + 1%);
    margin-left: 12%;
  }

  @media screen and (min-width: 2700px) {
    width: calc(65% + 10%);
    height: calc(550px + 10%);
  }

  @media (max-width: 998px) {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 500px) {
    margin-bottom: 70px;
  }
}

.mobileImageBox {
  @media screen and (max-width: 500px) {
    width: 444px;
    margin-bottom: 0px;
  }
}

.PartnerMobileImageBox {
  @media screen and (max-width: 500px) {
    width: 436px;
    margin-bottom: 10px;
  }
}

.ImageBox2 {
  width: calc(70% + 1%);
  margin-top: -50px;
  position: absolute;
  margin-left: 21%;


  @media screen and (max-width: 1920px) and (min-width: 1580px) {
    width: 60%;
    margin-top: -76px;
  }

  @media screen and (min-width: 2560px) {
    margin-left: 0;
  }

  @media screen and (min-width: 1922px) {
    width: calc(75% + 20%);
    height: calc(550px + 1%);
  }

  @media screen and (min-width: 2700px) {
    width: calc(70% + 10%);
    height: calc(550px + 10%);
  }

  @media (max-width: 998px) {
    max-width: 100%;
    height: auto;
    position: unset;
    left: -10 !important;
  }

  @media (max-width: 500px) {
    position: relative !important;
    margin-top: -120px;
    top: 0px !important;
    left: 0;
  }
}

.mobileImageBox2 {
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
    margin-top: -80px;
  }
}

.PartnerQuote {
  font-family: 'SF-Pro-Display-Light';
  font-size: 12px;
  line-height: 19px;
  color: rgba(1, 26, 60, 0.8);
  margin-bottom: 5px;
}

.PartnerName {
  height: 19px;
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12.5px;
  line-height: 19px;
  color: var(--primary-blue);
}

.ImageWrapper {
  width: 250px;
  height: 230px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  margin-left: 100px;

  @media screen and (max-width: 1921px) and (min-width: 1580px) {
    left: 55%;
  }

  @media screen and (min-width: 1922px) {
    bottom: 30%;
  }

  @media screen and (min-width: 2700px) {
    bottom: 4%;
    left: 69%;
  }
}

.LogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 44px;
  background: var(--white);
  border-radius: 5px;
  margin-right: 10px;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.1);
}

.imgLogoWrapper {
  max-height: 30px;
  margin-top: 10px;
}

.Slides {
  display: flex !important;
}

.Slides2 {
  display: flex !important;
  margin-bottom: 30px !important;
}

.HeadingStyle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #004AAD;

  @media (max-width: 500px) {
    width: 100%;
  }
}

.NewTitleStyle {
  font-family: 'SF-Pro-Display-Bold';
  width: 266px;
  font-size: 35px;
  line-height: 42px;
  color: #011A3C;

  @media (max-width: 500px) {
    width: 100%;
    font-size: 32px;
    line-height: 40px;
  }
}

.ant-carousel .slick-dots-bottom {
  bottom: -20px;

  @media screen and (max-width: 500px) {
    bottom: -220px;
  }
}

.ant-carousel .slick-dots {
  z-index: 1;
}

.ant-carousel .slick-dots li button {
  background: var(--blue);
}

.ant-carousel .slick-dots li.slick-active {
  width: 30px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: var(--blue);
}

.ant-carousel .slick-dots {
  justify-content: flex-start;
  margin-left: 0;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  color: rgba(1, 26, 60, 0.8);
}

.SupportersImgStyle {
  width: 98%;
    margin-top: -150px;
    position: absolute;
    left: -10px;

    @media screen and (min-width: 1900px) {
      width: 80%;
      left: 20%;
    }

    @media screen and (min-width: 1600px) {
      margin-top: -180px;
    }
}