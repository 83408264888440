.SectionHeaderWrapper {
  .Icon {
    @media (max-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  .MobileIcon {
    display: none;

    @media screen and (max-width: 500px) {
      display: block;
    }
  }


 

  .HeaderText {
    height: 52px;
    font-family: 'SF-Pro-Display-Light';
    font-style: normal;
    font-size: 15px;
    width: 471px;
    color: rgba(1, 26, 60, 0.88);

    @media (max-width: 500px) {
      width: 88%;
    }

    @media screen and (max-width: 500px) {
      width: 272px !important;
      height: auto;
      line-height: 26px;
      color: rgba(35, 56, 98, 0.88);
    }
  }

  .SectionCol {
    @media screen and (max-width: 450px) {
      margin-left: 40px;
      margin-top: -38px;
    }
  }
}

.SectionHeaderWrapper2 {
  margin-left: 86px !important;


  @media (max-width: 800px) {
    margin-left: 0px !important;
  }
}

.HeaderTitle {
  font-family: 'SF-Pro-Display-Bold';
  font-size: 35px !important;
  line-height: 45px !important;
  color: #011A3C !important;
  width: 295px;


  @media (max-width: 500px) {
    width: 300px;
    font-size: 32px !important;
    font-size: 22px;
    line-height: 32px;
    margin-top: 10px;

  }

}

.HeadingStyle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #004AAD;
  margin-bottom: 4px;

@media screen and (max-width: 500px) {
  display: none;
}
}
