.HeadingText {
    height: auto;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 42px;
    line-height: 66px;
    color: var(--primary-blue);
    text-align: center;
    padding: 65px 8px 0 8px;
    background-color: var(--white);
    max-width: 680px;
    margin: auto;

    @media screen and (max-width: 500px) {
        max-width: 323px;
    }

    @media screen and (max-width: 400px) {
        font-size: 40px;
        line-height: 50px;
    }
}

.WiderHeading {
    max-width: 740px;

    @media screen and (max-width: 500px) {
        max-width: 323px;
    }
}

.HeadingDate {
    text-align: center;
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 15px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.44);
}

.CircleDot {
    width: 6px;
    height: 6px;
    background: var(--blue);
    display: inline-block;
    border-radius: 100%;
    margin: 0px 20px;
    position: relative;
    top: -2px;
}

.SocialCircle {
    border-radius: 100%;
    text-align: center;
    display: inline-block;
    border: 0.8px solid #c4c4c4;
    padding: 7px 0px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    margin: 3px;

    &:hover {
        background-color: #011a3c;
    }
    &:hover > svg > path {
        fill: #ffffff;
    }
}

.SocialBox {
    text-align: center;
    padding: 20px;
}

.ContentImage {
    text-align: center;
    padding: 10px 150px;
    width: 100%;
    margin-top: 3%;

    @media screen and (max-width: 900px) {
        padding: 10px 80px;
    }

    @media screen and (max-width: 768px) {
        padding: 10px 40px;
    }

    @media screen and (max-width: 500px) {
        padding: 10px 20px;
    }

    @media screen and (max-width: 380px) {
        padding: 10px;
    }

    img {
        max-height: 600px;
        width: 100%;
        border-radius: 23px;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            height: 300px;
            width: 100%;
        }
    }
}

.ContentTitle {
    margin-top: 35px;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 28px;
    line-height: 42px;
    color: #011a3c;
    max-width: 90%;
}

.ContentParagraphs {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 16.5px;
    line-height: 32px;
    color: rgba(1, 26, 60, 0.7);
    margin-top: 2.5%;
    max-width: 90%;

    a {
        font-family: 'SF-Pro-Display-Semibold';
        font-style: normal;
        font-size: 15.5px;
        line-height: 26px;
        color: #004aad;
        margin-bottom: 22px;
        text-decoration: underline;
    }

    @media screen and (max-width: 500px) {
        margin-left: 20px;
    }
}

.ContentSocialBox {
    display: inline-flex;
    flex-direction: column;
    margin: 0 3%;

    @media screen and (max-width: 600px) {
        display: none;
    }
}

.ShareText {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    padding: 10px 5px;
}

.ContentBox {
    display: inline-flex;
    flex-direction: row;
    padding: 20px 12%;

    @media screen and (max-width: 600px) {
        padding: 20px 40px;
    }
}

.UsefulBox {
    text-align: center;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 18px;
    line-height: 28px;
    color: var(--primary-blue);
    margin-top: 3%;
}

.RelatedContentBox {
    margin: 70px 0 10px 257px;
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: 900px) {
        margin-left: 200px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 150px;
    }

    @media screen and (max-width: 500px) {
        margin-left: 50px;
    }

    @media screen and (max-width: 380px) {
        margin-left: 10px;
    }
}

.RelatedContentListBox {
    display: inline-flex;
    margin: 20px 0 20px 257px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        margin-left: 200px;
    }

    @media screen and (max-width: 768px) {
        margin-left: 150px;
    }

    @media screen and (max-width: 500px) {
        margin-left: 50px;
    }

    @media screen and (max-width: 380px) {
        margin-left: 10px;
    }

    img {
        height: 117px;
        width: 117px;
        border-radius: 20px;
    }
}

.RelatedContentListInfo {
    margin-left: 25px;
    display: inline-flex;
    padding: 5px;
    flex-direction: column;
}

.RelatedContentHeaderText,
.RelatedContentHeaderDate {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.65);
    cursor: pointer;

    &:hover {
        color: #0458c9;
    }
}

.RelatedContentDateText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.65);

    &:hover {
        color: #0458c9;
    }
}

.RelatedContentHeaderTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 18px;
    margin: 10px 0px;
    cursor: pointer;

    &:hover {
        color: #0458c9;
    }
}

.RelatedImageContainer {
    cursor: pointer;
}

@media screen and (max-width: 479px) {
    .RelatedContentHeaderText {
        display: none;
    }
}

.ContentSubtitle {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 22px;
    line-height: 27px;
    color: var(--primary-blue);
}

.ContentDarkBox {
    background-color: #011a3c;
    width: 90%;
    height: 300px;
    display: flex;
    border-radius: 20px;
}

.ContentDarkBoxTextSection {
    width: 65%;
    padding: 60px 6%;
}

.ContentDarkBoxText {
    color: #fff;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    margin: 10px 0px;
    font-family: 'Poppins';

    @media screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.ContentDarkBoxImageSection {
    width: 35%;
    text-align: right;

    @media screen and (max-width: 768px) {
        position: relative;
        top: -70px;
        height: 100% !important;
    }

    @media screen and (max-width: 600px) {
        position: relative;
        top: -32px;
        height: 100% !important;
    }

    @media screen and (min-width: 780px) and (max-width: 800px) {
        position: relative;
        top: -30px !important;
        height: 100% !important;
    }
}

.ContentDarkBoxLink {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
    margin: 10px 0px;
    font-family: 'Roboto';
}

.MobileImageView {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
}

.DesktopImageView {
    display: block;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.BoldParagraph {
    font-family: 'SF-Pro-Display-Semibold';
    color: var(--primary-blue);
    margin-top: -16px;
}

.InfoTitle {
    background: var(--offwhite);
    padding: 30px 40px;
    width: 90%;
}

.BulletWrapper {
    background: #fafafa;
    width: 833px;
    margin-top: 22px;
    padding: 30px 42px;

    @media screen and (max-width: 500px) {
        width: 90%;
        padding: 30px 13px;
        margin-left: 30px;
    }
}
.BulletTitleWrapper {
    display: flex;
    align-items: center;

    &.ContainsOrdered {
        align-items: flex-start;
    }

    @media screen and (max-width: 500px) {
        align-items: initial;
    }
}

.BulletTitle {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 16px;
    line-height: 34px;
    color: #011a3c;

    &.Number {
        margin-right: 3px;
    }

    @media screen and (max-width: 500px) {
        line-height: 27px;
    }
}
.BulletText {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 15.5px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.7);
    margin-bottom: 22px;

    a {
        font-family: 'SF-Pro-Display-Semibold';
        color: #004aad;
        text-decoration: underline;
    }
}

.subListHeaderName {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--primary-blue);
    margin-top: 40px;
}

.BulletArticleHeader {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 24px;
    line-height: 32px;
    color: #011a3c;
}

.BulletArticleSubText {
    font-family: 'SF-Pro-Display';
    font-size: 16.5px;
    line-height: 32px;
    color: rgba(1, 26, 60, 0.7);
    margin-bottom: 16px;
}

.ArticleListImage {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;

    img {
        width: 100%;
        border-radius: 10.45px;
        object-fit: cover;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
}
