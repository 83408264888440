.MobileWrapper {
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}

.MobileUpload {
  @media screen and (max-width: 500px) {
    height: 272px !important;
  }
}

.MobileUploadBtnWrapper {
  @media screen and (max-width: 500px) {
    width: 100% !important;
  }
}