.PaymentPlansListings_Wrapper {
    @media screen and (min-width: 500px) {
        margin: 0 24px;
    }

    @media screen and (min-width: 1200px) {
        margin: 0 32px;
    }
}

.PaymentPlansListings_Heading {
    font: 22px/35px 'SF-Pro-Display-Bold';
    color: #001837;
    margin-top: -42px;

    @media screen and (max-width: 576px) {
        margin-top: 0px;
    }
}

.PaymentPlansListings_SearchAndFilter {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 95px;

    @media screen and (max-width: 576px) {
        margin-top: 32px;
    }
}

.PaymentPlansListings_Search {
    width: 361px;
    height: 50px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    position: relative;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background: none;
        text-indent: 16px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        :focus {
            border: none;
            outline: none;
            appearance: none;
        }

        ::placeholder {
            font: 14px/24px 'SF-Pro-Display';
            color: #b0b0b0;
        }

        @media (max-width: 500px) {
            ::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        margin-right: 14px;
        cursor: pointer;
    }
}

.PaymentPlansListings_FiltersBtn {
    width: 130px;
    height: 50px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 7px;
    cursor: pointer;
}

.PaymentPlansListings_Tabs {
    position: relative;
    margin-top: 36px;

    .ant-tabs-top > .ant-tabs-nav::before {
        border: none;
        border-bottom: 1px solid #e2eeff;
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        height: 1px !important;
        background: #004aad;
    }
}

.PaymentPlansListings_Tabs_Filter {
    // position: absolute;
    right: 0;
    top: -3px;
    display: flex;
    align-items: center;
    gap: 16px;
    z-index: 1;
    height: fit-content;

    @media screen and (min-width: 767px) {
        position: absolute;
    }

    &.isFetching {
        top: -22px;
    }

    > span {
        font: 14px/24px 'SF-Pro-Display';
        color: #4d4d4d;
        margin: 0;
    }

    @media (max-width: 500px) {
        .MultiSelect {
            width: auto !important;
        }
    }

    > div {
        margin-bottom: 0;
        width: fit-content;
    }
}

.PaymentPlansListings_Table {
    display: none;

    @media (min-width: 1250px) {
        display: block;
    }

    & {
        margin-top: 20px;
    }

    table {
        display: table;
        border-collapse: separate;
        table-layout: fixed;
        border-spacing: 0 16px;
        width: 100%;
    }

    thead {
        display: table;
        table-layout: fixed;
        width: 100%;
        border: none;
        margin-bottom: 0;
    }

    tbody {
        display: table;
        table-layout: fixed;
        width: 100%;
        border-spacing: 0 16px;
    }

    th {
        font: 13px/20px 'SF-Pro-Display';
        color: #011a3ccc;
        padding-left: 0;
        padding-left: 16px;
    }

    th:first-child {
        padding-left: 61px;
    }

    @media (max-width: 1440px) {
        th:first-child {
            padding-left: 20px;
        }
    }
}

.PaymentPlansListings_Table_BodyRow {
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;

    td {
        font: 14px/24px 'SF-Pro-Display';
        color: #011a3c;
        padding: 22px 0;
        padding-left: 16px;
        border: 1px solid #e2eeff;
        border-left: none;
        border-right: none;
        background: #fff;
    }

    td:first-child {
        font: 14px/24px 'SF-Pro-Display-Bold';
        color: #011a3c;
        padding-left: 61px;
        border-left: 1px solid #e2eeff;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    td:last-child {
        border-right: 1px solid #e2eeff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    @media (max-width: 1440px) {
        td:first-child {
            padding-left: 20px;
        }
    }
}

.PaymentPlansListings_Table_BodyRow_Flexbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.PaymentPlansListings_Table_BodyRow_Name {
    font: 14px/16.8px var(--font-bold);
    color: #011a3c;
    height: 24px;
    margin: 0;
    display: flex;
    align-items: center;
}

.PaymentPlansListings_Table_BodyRow_Tag {
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5px 8px;
        width: fit-content;

        span {
            font: 12px/20px 'SF-Pro-Display-Semibold';
            letter-spacing: 1.02px;
        }
    }
}

#PlansTableHiddenLink {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.PaymentPlansListings_Pagination {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PaymentPlansListings_Drawer {
    .PaymentPlansListings_Drawer_Content {
        padding: 37px 20px 0 20px;
        max-height: 80vh;
        overflow: auto;

        h2 {
            font: 20px/23px 'SF-Pro-Display-Bold';
            color: #011a3c;
            margin-bottom: 42px;
        }

        h3 {
            font: 14px/16px 'SF-Pro-Display-Semibold';
            color: #011a3c;
            margin-bottom: 18px;
        }
    }

    .ant-drawer-body {
        padding: 0;
        position: relative;
    }
}

.PaymentPlansListings_Drawer_DateFields {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 38px;

    > div {
        width: 100%;
    }
}

.PaymentPlansListings_Drawer_Buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 0;
    margin: 0 0 48px 20px;
}

// Tablet & Mobile
.PaymentPlansListings_Mobile_Wrapper {
    display: grid;
    gap: 20px;

    @media (min-width: 1250px) {
        display: none;
    }

    .PPLM_Card {
        width: 100%;
        height: 178px;
        border-radius: 7px;
        background: #fff;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.06);
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            column-gap: 20px;
            text-wrap: pretty;

            h4 {
                color: rgba(1, 26, 60, 0.85);
                font-family: 'SF-Pro-Display-Medium';
                font-size: 8px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.44px;
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 15px;
                font-style: normal;
                line-height: normal;

                @media (max-width: 500px) {
                    max-width: 150px;
                }
            }
        }

        &:has(.StatusIndicator_Wrapper) {
            > div:first-child {
                margin-top: 22px;
            }
        }

        > div:last-child {
            align-items: start;
        }
    }

    .PPLM_Card_EmailOrPhone {
        p {
            font-size: 12px !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media only screen and (max-width: 450px) {
                max-width: 160px !important;
            }

            @media only screen and (max-width: 400px) {
                max-width: 120px !important;
            }
        }
    }

    .StatusIndicator_Wrapper {
        position: absolute;
        top: 21px;
    }
}

.Tag_Wrapper {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 3px;
    background: var(--Accent-Yellow-Light, #fffaec);
    color: #e4aa0f;
    font-family: 'SF-Pro-Display-SemiBold';
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 1.02px;
    text-transform: uppercase;
    margin-left: 10px;
    text-align: center;
    text-wrap: nowrap;

    @media screen and (min-width: 1400px) {
        max-height: 23px;
    }
}

.updatedPaymentPlansListingsTr {
    display: flex;
}

.updatedPaymentPlansListingsP {
    max-width: 60px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (min-width: 500px) {
        max-width: 200px !important;
    }

    @media screen and (min-width: 900px) {
        max-width: 300px !important;
    }
}
