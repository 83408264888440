.CapexButton {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    color: #004aad;
    cursor: pointer;
}

.CapexRemoveApplianceWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    cursor: pointer;
    margin-top: -9px;
    margin-bottom: 19px;
    width: 100%;
}

.CapexRemoveApplianceWrapper > p {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 11px;
    line-height: 13px;
    color: #004aad;
    margin: 0 4px 0 6px;
}

.MobileWrapper {
    &.Capex {
        @media screen and (max-width: 500px) {
            flex-direction: column !important;
        }
    }
}

//modal styles
.CCFModalTitle {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    text-align: center;
}

.CCFModalSubTitle {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: rgba(35, 56, 98, 0.7);
    margin-bottom: 30px;
}

.CCFModalBtn {
    height: 56px;
    left: 232px;
    background: #004aad;
    border-radius: 30px;
    border: none;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
    padding: 19px 24px;
    cursor: pointer;
    margin-top: 40px;
    width: 160px;
    opacity: 1;
}

.CCFModalBtn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}
