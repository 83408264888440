.TableRow {
  display: grid;
  grid-template-columns: 39% 30% 15% 15%;
  align-items: center;
  height: 67px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 15px;
  padding: 0 29px 0 20px;

  h4 {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    text-align: left;
    margin: 0;
  }

  p {
    font-family: 'SF-Pro-Display';
    font-style: normal;
    font-size: 14px;
    line-height: 25px;
    color: #011a3c;
    margin: 0;
  }
}

.TableHead {
  display: grid;
  grid-template-columns: 39% 30% 15% 15%;
  padding: 0 29px 0 20px;
  margin-bottom: 18px;

  &.Users {
    grid-template-columns: 39% 30% 15% 15%;
  }

  p {
    margin: 0;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    color: #011A3CCC;
  }
}

.BtnWrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ModalContent {
  max-height: 448px;
  overflow-y: scroll;
  padding: 40px 80px 0px;

  &.PermissionsList {
    height: 370px;
  }

  @media only screen and (max-width: 500px) {
    padding: 35px 23px;
  }
}

.ModalBtn {
  text-align: center;
  margin: 52px 0 54px 0;

  &.MoreActions {
    margin: 57px auto;
    width: 54%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.EditModal {
    width: 100%;
    margin: 103px auto 50px auto;
    display: flex;
    justify-content: center;
    gap: 13px;
  }

  @media only screen and (max-width: 500px) {
    &.MoreActions, &.EditModal {
      flex-direction: column;
      align-items: center;
      gap: 12px;
      width: 100%;
      margin: 36px auto;
    }
  }
}

.CheckBoxText {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(35, 56, 98, 0.8);
}
.CheckBoxWrapper {
  margin: 10px 0 10px 30px;
}
.AttributeCount {
  font-family: 'SF-Pro-Display-Medium';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #011A3C;
  margin-bottom: 10px;
}
.SavedAttributeWrapper {
  width: 455px;
  height: 65px;
  background: #FFFFFF;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  margin-bottom: 27px;

  @media only screen and (max-width: 500px) {
    width: 100%;
    max-width: 333px;
    height: 60px;
  }
}

.SavedAttributeActions {
    display: flex;
    align-items: center;
    gap: 7px;
}

.SavedAttributeName {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #011A3C;
}

.StatusTag {
  width: 69px;
  height: 23px;
  background: #E2EEFF;
  border-radius: 3px;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #004AAD;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'SF-Pro-Display-Semibold';
  margin-left: 6px;

  @media only screen and (max-width: 500px){
    margin-left: 4px;
  }
}
.AttrLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 500px){
    align-items: flex-start;
  }
}

.TableWrapper {
    margin-top: 60px;

}

.TableTitle {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 16px;
  margin-bottom: 35px;
}

.ModalWrapper {
  padding: 48px 80px 0 80px;

  @media only screen and (max-width: 500px) {
    padding: 35px 23px;
  }
}

.Table {
  height: 450px;
  overflow: auto;
}

.DesktopTable {
  @media only screen and (max-width: 500px) {
    display: none;
  }
}

.MobileCards {
  width: 100%;
  height: 450px;
  overflow-y: auto;
  padding: 3px;
  @media only screen and (min-width: 501px) {
        display: none;
    }
}

.Card {
  width: 100%;
  height: fit-content;
  background: #FFFFFF;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;
  overflow: hidden;
  margin-bottom: 12px;
}

.Top, .Bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Top {
  margin: 9px 21px 0 16px;
}

.Bottom {
  margin: 0 0 33px 16px;
}

.CardTitle {
  font-family: 'SF-Pro-Display';
  font-size: 13px;
  color: #011A3CCC;
  margin-bottom: 5px;
}

.CardTitleText {
  font-family: 'SF-Pro-Display-Semibold';
  font-size: 13px;
  line-height: 27px;
  color: #011A3C;
  max-width: 120px;

  &.WithTag {
    max-width: 60px;
  }
}

h4.CardTitleText {
  font-size: 14px;
}

.MobileBtnWrapper {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-top: 21px;
}

.ModalTitleWrapper {
  margin-top: 48px;

  @media only screen and (max-width: 500px) {
    margin-left: 23px;
}
}