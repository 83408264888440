$img-maxHeight: 580px;

.consumerSectionwrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    padding-bottom: 48px;

    @media screen and (max-width: 900px) {
        padding: 0;
    }

    @media screen and (max-width: 800px) {
        justify-content: space-between;
        align-items: center;
        height: 1520px;
    }

    @media screen and (max-width: 650px) {
        height: 1620px;
    }

    @media screen and (max-width: 500px) {
        padding: 0;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
    }

    @media screen and (max-width: 430px) {
        height: 1640px;
    }

    @media screen and (max-width: 340px) {
        height: 1820px;
    }

    &.consumerSectionwrapper_WithUseTypeForm {
        margin-bottom: 240px;

        @media screen and (max-width: 800px) {
            margin-bottom: 0;
        }
    }

    &.consumerSectionwrapper_NewForm {
        margin-bottom: 50px;

        @media screen and (max-width: 800px) {
            margin-bottom: 0px;
            justify-content: space-between;
            align-items: center;
            height: 1270px;
        }

        @media screen and (max-width: 650px) {
            height: 1370px;
        }

        @media screen and (max-width: 500px) {
            padding: 0;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
        }

        @media screen and (max-width: 430px) {
            height: 1370px;
        }

        @media screen and (max-width: 340px) {
            height: 1420px;
        }
    }
}

.HeroFormWrapper {
    display: flex;
    justify-content: center;
    background: linear-gradient(66deg, #010101 1.63%, rgba(0, 0, 0, 0) 66.46%),
        url('https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Hero+section+image.png');
    margin: -3px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    max-height: 860px;
    padding: 0 20px;

    &.WithUseTypeForm {
        max-height: $img-maxHeight;
        overflow: visible;
    }

    @media (min-width: 375px) and (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        max-height: 560px;
        border-radius: 0px;
    }
}

.HeroFormWrapper.GetStarted {
    padding: 0px;
}

.GetStartedContentsWrapper.Reverse {
    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        max-height: 560px;
        border-radius: 0px;
    }
}

.HeroFormWrapper2 {
    display: flex;
    justify-content: center;
    background: linear-gradient(66deg, #010101 1.63%, rgba(0, 0, 0, 0) 66.46%),
        url('https://assets-dagzdegshxbhgqbs.z03.azurefd.net/frontend/Hero+image.png');
    margin: -3px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    max-height: 731px;
    padding: 0 20px;

    @media screen and (max-width: 500px) {
        max-height: 560px;
        border-radius: 0px;
    }
}

.MainImgWrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    @media screen and (min-width: 1500px) {
        width: 100%;
    }
}

.MainImgStyle {
    max-width: 100%;
    height: auto;
    border-radius: 5px;

    @media screen and (min-width: 1500px) {
        height: 574px;
        width: 100%;
    }
}

.ConsumerImgOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    border: 1px solid red;
}

.ImgContentsWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1244px;
    height: 100%;
    gap: 10px;

    &:has(.ConsumerLandingPageFormStyle_WithUseTypeForm) {
        max-width: 1244px;
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
        // margin: auto 20px;
        position: static;
    }
}

.GetStartedContentsWrapper {
    margin: 0px 20px;
    position: static;

    @media screen and (min-width: 1500px) {
        width: 100%;
    }

    @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.LeftContentStyle {
    width: 100%;
    max-width: 614px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 120px;

    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 49px;
        line-height: 60px;
        color: #ffffff;
        margin-bottom: 5px;

        @media screen and (min-width: 1500px) {
            font-size: 54px;
        }
    }

    @media screen and (max-width: 900px) {
        // width: 318px;
        padding-top: 40px;
        padding-bottom: 0;
    }

    @media screen and (max-width: 500px) {
        width: 318px;
        padding-top: 104px;
    }

    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 49px;
        line-height: 60px;
        color: #ffffff;
        margin-bottom: 5px;

        @media screen and (min-width: 1500px) {
            font-size: 54px;
        }

        @media screen and (max-width: 500px) {
            font-size: 38px;
            line-height: 42px;
        }
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;

        @media screen and (max-width: 500px) {
            font-size: 14px;
            line-height: 23px;
            margin-bottom: 8px;
        }
    }

    &.LeftContentStyle_WithUseTypeForm {
        max-height: $img-maxHeight;
        padding-bottom: 0;
    }
}

.ConsumerLandingPageFormStyle {
    padding: 43px;
    position: relative;
    max-width: 430px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e2eeff;
    border-radius: 8px;
    margin-top: 60px;

    @media screen and (max-width: 500px) {
        margin-top: 24px;
        border: 0.77907px solid #e2eeff;
        border-radius: 6.23256px;
        padding: 20px 15px;
    }

    h3 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 17px;
        line-height: 24px;
        color: #004aad;

        @media screen and (max-width: 500px) {
            font-size: 13px;
            line-height: 20px;
        }
    }

    .ConsumerLandingPageSubHeading {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #5f5b5b;

        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &.ConsumerLandingPageFormStyle_WithUseTypeForm {
        padding: 32px;
        max-width: 465px;
        border-radius: 16px;
        border: 0.5px solid #e2eeff;

        @media screen and (max-width: 800px) {
            max-width: 800px;
        }

        @media screen and (max-width: 500px) {
            padding: 20px;
        }
    }
}

.FeaturedIconsStyle {
    margin-top: 51px;
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.26em;
        text-transform: uppercase;
        color: #4d4d4d;
        width: 100%;
        max-width: 1244px;
    }

    div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 35px;
        margin-top: 10px;
        width: 100%;
        max-width: 1244px;

        a {
            img {
                &.TechCrunch {
                    width: 142.636px;
                }
                &.TechCabal {
                    width: 122.127px;
                }
                &.Norrsken {
                    width: 152.891px;
                }
                &.Nasdaq {
                    width: 96px;
                }
            }
        }

        .MobileLogoIconsStyle {
            @media screen and (max-width: 500px) {
                display: flex;
                gap: 35px;
            }
        }

        @media screen and (max-width: 1244px) {
            width: calc(100% - 500px);
            margin-left: 0;
            margin-right: auto;
            gap: 15px;
        }

        @media screen and (max-width: 900px) {
            width: 100%;
            margin-left: auto;
        }

        @media screen and (max-width: 800px) {
            justify-content: center;
        }

        @media screen and (max-width: 500px) {
            a {
                img {
                    &.TechCrunch {
                        width: 96.632px;
                    }
                    &.TechCabal {
                        width: 83.211px;
                    }
                    &.Norrsken {
                        width: 97.326px;
                    }
                    &.Nasdaq {
                        width: 67.672px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 20px;
        width: 50%;
    }

    @media screen and (max-width: 800px) {
        p {
            text-align: center;
        }
        & {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        margin: 0 20px;
    }

    @media screen and (max-width: 500px) {
        width: 90%;
        display: block;
    }
}

.AddAppliancesStyle {
    background: #e2eeff;
    border: 0.5px dashed rgba(0, 74, 173, 0.79);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px 19px;
    margin: 12px auto 20px;
    width: 338px;
    height: 66px;

    @media screen and (max-width: 500px) {
        border: 0.389535px dashed rgba(0, 74, 173, 0.79);
        border-radius: 7.7907px;
        width: 100%;
        height: 51px;
    }

    div {
        background: #ffffff;
        border: 0.5px solid rgba(0, 74, 173, 0.79);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        width: 50px;
        height: 46px;

        @media screen and (max-width: 500px) {
            border: 0.389535px dashed rgba(0, 74, 173, 0.79);
            border-radius: 7.7907px;
            width: 38.95px;
            height: 35.84px;
        }
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #004aad;

        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 20px;
        }
    }
}

.TabStyle {
    .tabHeadingStyle {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #004aad;
        display: flex;
        align-items: center;

        @media screen and (max-width: 500px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &.TabsStyle_WithUseTypeForm {
        .tabHeadingStyle {
            color: #3d5075;
            gap: 6px;
            svg {
                width: 18px;
                height: 18px;
                stroke: #b0b0b0;
            }

            &.tabHeadingStyle_active {
                color: #004aad;
                svg {
                    stroke: #004aad;
                }
            }
        }
    }

    svg {
        margin-right: 5px;
    }

    .ant-tabs-tab {
        padding: 20px 17px !important;

        @media screen and (max-width: 500px) {
            margin-right: 10px;
        }
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center;
    }

    .ant-tabs-top > .ant-tabs-nav::before {
        @media screen and (max-width: 500px) {
            border: none;
            border-bottom: 1px solid #e2eeff;
        }
    }

    .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        @media screen and (max-width: 500px) {
            height: 1px !important;
        }
    }

    &.TabsStyle_WithUseTypeForm {
        margin-top: 18px;
        .ant-tabs-tab {
            padding: 8px !important;
        }
        .ant-tabs-nav {
            margin-bottom: 24px !important;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
            border: none !important;
            border-bottom: 0.5px solid #e5e5e5 !important;
        }

        .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
            height: 1px !important;
            background: #004aad !important;
        }

        @media only screen and (max-width: 500px) {
            .ant-tabs-tab {
                margin: 0 !important;
                width: 100% !important;
            }
        }
    }

    &.TabsStyle_GetStartedPage {
        @media only screen and (max-width: 500px) {
            .ant-tabs-nav-list {
                max-width: 80vw !important;
            }
        }
    }
}

.FormPStyle {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    color: rgba(35, 56, 98, 0.78);

    @media screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.AnswerStyleWrapper {
    display: flex;
    gap: 10px;
    margin-top: 10px;

    button {
        width: 163px;
        height: 46px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 28px;
        color: #011a3c;
        cursor: pointer;
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 24px;
        transition: 0.3s ease all;

        @media screen and (max-width: 500px) {
            font-family: 'SF-Pro-Display';
            height: 46px;
            line-height: 23px;
            margin-right: 10px;
            width: 100%;
        }
    }

    &__active {
        background: #011a3c !important;
        color: #ffffff !important;
        transition: 0.3s ease all !important;
        -webkit-transition: 0.3s ease all !important;
        -moz-transition: 0.3s ease all !important;
        -ms-transition: 0.3s ease all !important;
        -o-transition: 0.3s ease all !important;
    }

    &.OwnOrRentStyleWrapper {
        button {
            width: 100px;
        }
    }

    &.GenCostQuestionStyle {
        gap: 1px;
        button {
            width: 110px;
        }

        @media only screen and (max-width: 500px) {
            justify-content: flex-start;
            gap: 5px;

            button {
                margin: 0;
            }
        }
    }
}

.AnswerStyleWrapperError {
    font: 12px/24px 'SF-Pro-Display';
    color: #da1e28;
    margin-bottom: 20px;
}

.AnswerStyleWrapperBottomTexts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 22px 0;
}

.ConsumerFormSmallP {
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 12px;
    line-height: 14px;
    color: #013d8c;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}

.ConsumerCheckboxWrapper {
    margin-top: 20px;

    p {
        line-height: 24px;
        color: #4d4d4d;

        @media screen and (max-width: 500px) {
            margin-left: 2px;
        }
    }

    svg {
        width: 35px;
        margin-right: 0px;
        margin-top: 5px;

        @media screen and (max-width: 500px) {
            width: 50px;
        }
    }
}

.ConsumerBackButtonStyle {
    margin-top: 10px;

    .ConsumerBackButtonWrapper {
        font-family: 'SF-Pro-Display-Medium';
        font-size: 14px;
        line-height: 17px;
        color: #004aad;
        display: flex;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .ConsumerBackButtonSpan {
        margin-left: 0px;
        transition: 0.5s ease all;
    }

    .ConsumerBackButtonSpan:hover {
        margin-left: 4px;
        transition: 0.5s ease all;
    }

    hr {
        border-radius: 18px;
        height: 7px;
        border: none;
        background: linear-gradient(to right, #004aad 50%, #f9fcff 50%);
    }

    svg {
        margin-right: 4px;
        transition: 0.5s ease all;
    }

    svg:hover {
        margin-right: 8px;
        transition: 0.5s ease all;
    }
}

.ConsumerNextFormWrapper {
    margin: 20px auto;
}

.ConsumerNairaInput {
    padding: 0px 21px 0px 0px;
    width: 334px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 15px;

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.ConsumerFormTOS {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 20px;
    color: #4d4d4d;
    margin: 20px 23px;

    a {
        color: #004aad;
        font-size: 12px;
    }
}

.ConsumerAnimatedTextWrapper {
    display: inline-block;
    height: 67px;
    overflow: hidden;
    vertical-align: middle;

    span {
        @media screen and (max-width: 500px) {
            line-height: 52px;
        }
    }
}

.slide-down {
    display: block;
}

.hidden {
    display: none;
}

.SSEEnergySummaryWrapper {
    display: flex;
    justify-content: center;
    margin: 20px auto 10px;
}

.SSESummaryChild {
    width: 334px;
    height: 100%;
    background: #011a3c;
    border-radius: 14px;
    padding: 16px 15px;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 5px 5px 10px;
    }
}

.SSESlideWrapper {
    border-radius: 8px;
    padding: 8px;
}

.SSEEnergySummaryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e2eeff;
    border-radius: 8px;
    padding: 8px;
    transition: ease 0.5s;
}

.SSEEnergySummaryHeaderLarge {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.SSEEnergySummaryHeaderP {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    color: #001837;

    @media screen and (max-width: 500px) {
        font-size: 12px;
        line-height: 20px;
    }
}

.SSEEnergySummaryTotalWattsLabel {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 20px;
    color: #011a3c;
}

.SSEEnergySummaryBodyUsageLabel {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
        color: #233862;
    }
}

.SSEEnergySummaryBodyUsageValue {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #000f23;
}

.SSEUnitStyle {
    font-family: 'SF-Pro-Display';
    color: #233862;
}

.SSEEnergySummaryTotalWattsValue {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 18px;
    line-height: 24px;
    color: #001837;

    @media screen and (max-width: 500px) {
        font-size: 14px;
        line-height: 20px;
        margin: 5px 0px;
    }

    sup {
        @media screen and (max-width: 500px) {
            font-size: 12px;
            font-family: 'SF-Pro-Display';
        }
    }
}

.SSEEnergySummaryBody {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: ease 0.5s;
    display: none;
}

.SSEEnergySummaryBodyVisible {
    opacity: 1;
    visibility: visible;
    transition: ease 0.5s;
    display: block;
}

.SSEEnergySummaryResponseCardAppliances {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 15px;
    line-height: 25px;
    color: #ffffff;
    margin: 20px auto;
}

.SSEEnergySummaryEnergyCountLabel {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: rgba(35, 56, 98, 0.78);
    margin-right: 5px;
}

.SSEEnergySummaryLargeHeader {
    background-color: #e2eeff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 8px;
    margin-top: -6px;
    transition: ease 0.5s;
}

.genQuestionWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #001837;
    }
}

input {
    color: #233862 !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 10px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    color: #233862 !important;
    line-height: 35px !important;
    opacity: 0.78;

    @media screen and (max-width: 500px) {
        margin-left: 0;
    }
}

.SelectNoFloatingLabelWrapper .InitialOptionTab {
    padding-left: 0px;
    margin-top: -4px;
    display: block;

    @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-top: 0;
    }
}

.SSEConsumerAddIcon {
    fill: #004aad;
    stroke: #004aad;
}

.CSSEPromoGetstartedWrapper.Mobile {
    margin-top: 50px;
    margin-bottom: 40px;

    h2 {
        color: #001837;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 18px;
        line-height: 24px;
    }
}

.CSSEPromoGetstartedWrapper {
    margin-top: 95px;

    h2 {
        color: #001837;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
    }

    .CSSEPromoGetstartedP {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        line-height: 24px;
        width: 572px;

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        @media screen and (max-width: 500px) {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.CSSEPromoGetstartedTitleWrapper {
    margin-bottom: 42px;

    > h2 {
        font: 28px/35px var(--font-bold);
        margin-bottom: 8px;
        color: #001837;
        max-width: 482px;
    }

    > p {
        font: 16px/24px var(--font-regular);
        margin: 0;
        color: #3d5075;
        max-width: 482px;
    }
}

.ConsumerLandingPageVideo {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: 10px;

    p {
        color: #32cc79;
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 17.5px;
        margin-left: 5px;
    }
}
