.Container {
  width: 320px;
  min-height: 320px;
  right: 0px;
  top: -380px;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 5px 32px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 25px;
  overflow-y: scroll;
}

.Title {
  font-family: 'SF-Pro-Display-Semibold';
  font-style: normal;
  font-size: 17px;
  line-height: 20px;
  color: #011A3C;
}

.SubText {
  font-family: 'SF-Pro-Display';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(35, 56, 98, 0.7);
  margin-top: 10px;
}

.ClearFix {
  border-top: 0px solid rgba(242, 242, 242, 0.9);
  margin-top: 10px;
  border-left: 0px solid rgba(242, 242, 242, 0.9);
}

.StageBox {
  display: flex;
  font-size: 13px;
  margin-top: 15px;
  font-family: 'SF-Pro-Display-Regular';
  cursor: pointer;
}

.StageTitle {
  width: 60%;
  font-family: 'SF-Pro-Display-Semibold';
  color: #011A3C;
  margin-left: 10px;
}

.StageCompletion {
  width: 30%;
  font-style: normal;
  font-family: 'SF-Pro-Display';
  font-size: 12px;
  text-align: right;
  color: rgba(1, 26, 60, 0.49);
}