@mixin reset {
    margin: 0;
    padding: 0;
}

.APLEndDateFilter {
    position: absolute;
    right: 170px;
}

.APLWrapper {
    padding: 44px 136px 101px 112px;

    @media screen and (max-width: 1280px) and (max-height: 800px) {
        padding: 44px 60px 101px 60px;
    }

    @media screen and (max-width: 800px) {
        padding: 30px 23px;
    }

    @media screen and (max-width: 576px) {
        .ant-tabs-tab {
            width: fit-content;
        }
    }
}

.APLSpaceBetween {
    display: flex;
    justify-content: space-between;
}

.APLFilterBox {
    display: flex;
    gap: 23px;
    height: 45px !important;
    width: 130px;
    border: 1px solid #e5e5e5;
    border-radius: 10px !important;
    padding: 11px 20px;
    cursor: pointer;

    @media screen and (max-width: 576px) {
        width: fit-content;
        padding: 10px 14px;
    }
}

.APLFilterText {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Medium';
    font-size: 14px;
    line-height: 17px;
    color: rgba(1, 26, 60, 0.8);

    @media screen and (max-width: 576px) {
        display: none;
    }
}

.APLTabWrapper {
    margin-top: 72px;
}

.APLTablewWrapper {
    margin-top: 52px;
}

.APLTableHeader {
    display: grid;
    padding: 0 63px 0 49px;
    grid-template-columns: 3fr 2fr minmax(130px, 1fr) 2fr 1fr;
    padding-bottom: 6px;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 13px;
    line-height: 16px;
    color: rgba(1, 26, 60, 0.8);

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.APLTableContent {
    display: grid;
    align-items: center;
    padding: 0 60px 0 49px;
    height: 67px;
    grid-template-columns: 3fr 2fr minmax(130px, 1fr) 2fr 1fr;
    grid-column-gap: 12px;
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 17px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin-top: 12px;
    color: #011a3c;
    background: #ffffff;

    >span:first-child {
        font-family: 'SF-Pro-Display-Semibold';
    }

    >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media screen and (max-width: 500px) {
        display: none;
    }
}

.APLTableMobile {
    @media screen and (min-width: 501px) {
        display: none;
    }
}

.APLTableMobileContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 330px;
    height: 169px;
    background: #ffffff;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    border-radius: 7px;
    margin-bottom: 13px;
    padding: 30px 21px;

    @media screen and (max-width: 400px) {
        width: 288px;
    }
}

.APLMobileContent {
    display: flex;
    justify-content: space-between;
}

.APLMobileContentTitle {
    font-family: 'SF-Pro-Display-Medium';
    font-style: normal;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 0.055em;
    color: rgba(1, 26, 60, 0.85);
    text-align: right;
    margin-bottom: 8px;
}

.APLMobileContentData {
    font-family: 'SF-Pro-Display-Semibold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #011a3c;
    word-wrap: break-word;
}

.APLMobileTopWrapper {
    max-width: 100px;
}

.APLPaginationWrapper {
    text-align: center;
    margin-top: 42px;
}

.APLEmptyState {
    margin-top: 60px;
}

.APLContainer>div:first-child {
    @media only screen and (min-width: 1281px) {
        padding-left: 112px;
    }
}

.APLPageTitle {
    display: flex;
    align-items: center;
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 23px;
    line-height: 27px;
    color: #011a3c;

    @media screen and (max-width: 1280px) {
        padding-left: 60px;
    }

    @media screen and (max-width: 800px) {
        padding-left: 20px;
    }
}

.ARLFilterHeader {
    @include reset;
    font-size: 20px;
    font-family: 'SF-Pro-Display-Bold';
    margin-bottom: 35px;
    color: #011a3c;
}

.ARLDateFilterHeader {
    @include reset;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Semibold';
    margin-bottom: 19px;
    color: #011a3c;
}

.ARLDateFieldsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;

    @media only screen and (max-width: 500px) {
        gap: 6px;
    }
}

.ARLDateField {
    width: 154px !important;

    @media screen and (max-width: 500px) {
        width: 164px !important;
    }
}

.ARLStatementFilterHeader {
    @include reset;
    font-size: 14px;
    font-family: 'SF-Pro-Display-Semibold';
    margin-bottom: 27px;
    color: #011a3c;
}

.ARLStatementFilterWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
}

.PCListingsStatementFilterWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 40px;
    max-height: 300px;
    overflow: auto;
}

.ARLStatementLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 22.83px;
    cursor: pointer;

    p {
        font-size: 14px;
        color: #233862;
    }
}

.ARLFilterButtonWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    position: absolute;
    bottom: 40px;
}

.AdminPaymentPlanFilterText {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #011a3c;
    margin: 50px 0px;
}