.ButtonBox {
    text-align: center;
    margin-top: 100px;
  }

  .ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
.CreateAccount {
    font-family: 'SF-Pro-Display-Bold';
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    margin-bottom: 30px;
    color: #004AAD;
  
    a, span {
      color: var(--blue);
      cursor: pointer;
    }
  }  

  .CreateAccount span:hover {
      color: var(--hover-text-blue) !important;
    }

  .FormText {
    font-family: 'SF-Pro-Display';
    color: var(--blue);
    font-size: 15px;
    line-height: 26px;
    font-family: 'SF-Pro-Display-Medium';
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
    width: 96%;
  }  

  .FormText:hover {
    color: var(--hover-text-blue);
  }

  .LinkResend {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
  } 