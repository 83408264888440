.AddressBlock {
  display: flex;
  margin-bottom: 30px;

  div {
    width: 100% !important;
  }

  span {
    display: inline-block;
    width: 12%
  }
}

.FormBox {
  height: 450px;
  width: 545px;
  overflow-y: scroll;
  overflow-x: hidden;

  @media screen and (max-width: 600px) {
    max-height: 100%;
    height: auto;
    width: 100%
  }
}

.InputBox {
  margin-bottom: 20px;
}


.NameFieldWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;

  @media screen and (min-width: 1900px) {
    width: 100%;

  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0;
  }
}