.outrightSaleContainer {
    margin-top: 37px;
    padding: 32px;
    width: 100%;
    padding-top: 0px;

    @media only screen and (max-width: 900px) {
        padding: 15px;
    }

    @media only screen and (max-width: 500px) {
        padding: 0px;
    }

    &__content {
        margin-top: 16px;
        display: flex;

        flex-wrap: nowrap;

        @media only screen and (max-width: 1175px) {
            flex-wrap: wrap;
        }

        &__divider {
            flex: 0 0 0.5px;
            background: #e2eeff;
            margin-left: 59px;
            margin-right: 55px;

            @media only screen and (max-width: 1175px) {
                display: none;
            }
        }

        section {
            flex: 1 1 0px;
            @media only screen and (max-width: 1175px) {
                width: 100%;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1480px) {
                max-width: 630px;
            }
        }

        aside {
            min-width: 352px;
            @media only screen and (max-width: 1220px) {
                width: 100%;
                min-width: 100%;
            }
        }
    }

    .ViewSystemTabsPayment_Insurance {
        p {
            font-family: 'SF-Pro-Display';
        }
    }

    .tooltip .tooltiptext p {
        color: white;
    }
}

.aside-details-dropdown {
    .ant-dropdown {
        top: 419px !important;
    }

    .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
        min-width: 300px;
        padding: 18px 10px;
    }

    .ant-dropdown-menu-item {
        padding: 8px 0px;
    }
}
