.Wrapper {
    width: 100%;
    height: auto;
    margin: 0 0 50px 0px;
}

.BlogCategory {
    display: flex;
    font-family: 'SF-Pro-Display-Light';
    font-size: 16px;
    color: var(--black);
}

.BlogPosts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0px 50px;

    @media screen and (min-width: 1279px) and (max-width: 1675px) {
        justify-content: left;
    }
}

.BlogItem {
    margin: 20px;
    cursor: pointer;

    @media screen and (max-width: 500px) {
        margin: 0 0 40px 0;
    }

    @media screen and (max-width: 338px) {
        text-align: center;
        margin-bottom: 40px;
    }
}

.BoldText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 30px;
    color: var(--primary-blue);
    width: 70%;
    margin-top: 40px;
    margin-left: 20px;
}

.BlogTitle {
    width: 310px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 18px;
    line-height: 28px;
    color: var(--primary-blue);
    margin-top: 15px;
    cursor: pointer;

    &:hover {
        color: #0458c9;
    }

    @media screen and (max-width: 338px) {
        text-align: left;
    }
}

.ImgContainer {
    height: 351px;
    width: 350px;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;

    @media screen and (max-width: 400px) {
        width: 300px;
    }

    @media screen and (max-width: 338px) {
        width: 90%;
    }
    @media screen and (min-width: 1100px) and (max-width: 1365px) {
        width: 340px;
    }
}

.BlogDate {
    font-family: 'SF-Pro-Display-Light';
    font-size: 16px;
    line-height: 26px;
    color: #686666;

    @media screen and (max-width: 338px) {
        text-align: left;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
    .BlogTitle {
        width: 370px;
        font-size: 22px;
        margin-top: 15px;
        cursor: pointer;
    }

    .ImgContainer {
        height: 351px;
        width: 350px;
        border-radius: 20px;
        cursor: pointer;
    }
}

.CurrentArticlesWrapper {
    margin: 20px;
}
