$table-row-padding-left-full-desktop: 49px;

.wrapper {
    margin: 0 136px 0 112px;

    @media only screen and (max-width: 1300px) {
        margin: 0 52px;
    }

    @media only screen and (max-width: 1024px) {
        margin: 0 20px;
    }

    @media only screen and (max-width: 500px) {
        margin: 0;
    }
}

.titleAndMobileBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;

    @media only screen and (max-width: 500px) {
        margin: 24px 0 52px 0;
    }
}

.searchAndBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-bottom: 37px;
}

.search {
    width: 361px;
    height: 50px;
    border: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 7px;
    position: relative;

    &:focus-within {
        border-color: #004aad;
    }

    input {
        flex: 1;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        background: none;
        text-indent: 16px;
        position: relative;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        :focus {
            border: none;
            outline: none;
            appearance: none;
        }

        ::placeholder {
            font: 14px/24px 'SF-Pro-Display';
            color: #b0b0b0;
        }

        @media (max-width: 500px) {
            ::placeholder {
                width: 100px;
            }
        }
    }

    svg {
        margin-right: 14px;
        cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
        width: 100%;
    }
}

.createBtnWrapper {
    button {
        display: block;
    }

    @media only screen and (max-width: 500px) {
        display: none;
    }
}

.dropdown {
    display: block;

    @media only screen and (min-width: 501px) {
        display: none;
    }
}

.table {
    border-collapse: separate;
    border-spacing: 0 16px;

    th {
        font: 13px/15.51px 'SF-Pro-Display';
        color: #011a3ccc;
        padding: 24px 0 2px 0;

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
        }
    }

    @media only screen and (max-width: 600px) {
        display: none;
    }
}

.tableRow {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px #0000000f;

    td {
        font: 14px/16.71px 'SF-Pro-Display';
        padding: 15px 0;

        &:first-child {
            padding-left: $table-row-padding-left-full-desktop;
            font-family: 'SF-Pro-Display-Semibold';
        }

        &:nth-child(2) {
            font-family: 'SF-Pro-Display-Semibold';
        }
    }
}

.cards {
    display: none;

    >div {
        h4 {
            font-size: 10px;
        }

        p {
            font-size: 14px;
        }

        button {
            background-color: #004aad !important;
            color: #ffffff !important;
        }
    }

    @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}

.view_modal_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px 48px 24px;


    >div:first-of-type {
        width: 100%;
        max-width: 488px;
        display: flex;
        flex-direction: column;
        gap: 59px;
        margin: 36px 0 76px 0;

        h2 {
            font-family: SF-Pro-Display-Bold;
            font-size: 20px;
            line-height: normal;
        }

        h3 {
            font-family: SF-Pro-Display-Medium;
            color: #61708E;
            font-size: 9px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.9px;
            text-transform: uppercase;
        }

        p {
            font-family: SF-Pro-Display-Semibold;
            font-size: 15px;
            line-height: 17.9px;
            color: #011A3C;
            font-size: 13px;
            font-style: normal;
            line-height: 25px;
        }



        >div {
            display: flex;
            justify-content: space-between;
            gap: 24px;
            margin-bottom: 0;

            >div {
                flex: 1 1 0;
            }


        }
    }

}