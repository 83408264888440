.consumer-modal-base-mobile-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    background-color: rgba(7, 12, 24, 0.6);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1001;
}

.consumer-modal-base-mobile-backdrop.open {
    opacity: 1;
    visibility: visible;
}

.consumer-modal-base-mobile-content {
    width: 100%;
    background-color: white;
    transition: transform 0.3s;
    transform: translateY(100%);
    border-radius: 10px 10px 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.consumer-modal-base-mobile-backdrop.open .consumer-modal-base-mobile-content {
    transform: translateY(0);
}

.consumer-modal-base-header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 61px;
    position: relative;
    border-bottom: 0.3px solid #b0b0b0;
    width: 100%;

    > h4 {
        font: 14px/16.8px var(--font-bold);
        color: #000000;
        margin: 0;
    }

    > svg {
        position: absolute;
        right: 18px;
        width: 28px;
        height: 28px;
        cursor: pointer;
    }
}

.consumer-modal-base-main {
    flex: 1;
    overflow: auto;
}

.consumer-modal-base-footer {
    border-top: 0.5px solid rgba(209, 209, 209, 1);
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
        padding: 9px 20px;
    }
}

.consumer-modal-base-standard {
    .ant-modal-content {
        border-radius: 15px;
    }

    .ant-modal-body {
        padding: 0;
    }
}
