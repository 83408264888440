.wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;

  p {
    font: 12px/24px 'SF-Pro-Display';
    color: #001837;
  }


  span {
    font: 12px/24px 'SF-Pro-Display-Semibold';
    color: #004AAD;
    text-decoration: underline;
    text-underline-offset: 3px;
    cursor: pointer;
  }
}