
.pill_wrapper{
  background: #E2EEFF;
  font-size: 9px;
  padding:5px;
  border-radius: 3px;
  color:#004AAD;
  font-family: 'SF-Pro-Display-Semibold';
  text-transform: uppercase;
  letter-spacing: 0.495px
}