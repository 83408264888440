.FormBox {
  height: auto;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 30px;
  box-shadow: 0px 20px 70px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 1900px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    height: 100%;
    width: 100% !important;
    box-shadow: none;
    border-radius: 0;
    padding: 20px;
  }
}
.Text {
  font-family: 'SF-Pro-Display-Medium';
  color: var(--blue);
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  margin-top: 18px;
}
