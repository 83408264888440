.modal_content_container {
    padding: 66px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;

    .lottie_animation {
        position: absolute;
    }

    h3 {
        color: #011a3c;
        text-align: center;
        /* Website/Body Text/P2 */
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        margin-bottom: 8px;
    }

    p {
        color: #4d4d4d;
        text-align: center;
        /* Website/Body Text/P4-Light */
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        font-style: normal;
        line-height: 20px;
    }
}

.payment_plan_container {
    width: 100%;
    padding: 60px 82px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 600px;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
        padding: 60px 48px;
    }

    @media screen and (max-width: 501px) {
        padding: 60px 16px 200px 16px !important;
    }

    .payment_plan {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px 36px;
        border-radius: 12px;
        background-color: #f9fcff;
        border: 1px solid #e2eeff;

        @media screen and (max-width: 501px) {
            padding: 24px 18px;
        }

        .plan_details {
            display: flex;
            justify-content: space-between;

            div {
                &:nth-child(2) {
                    text-align: right;
                }

                h3 {
                    color: rgba(1, 26, 60, 0.85);
                    font-family: 'SF-Pro-Display-Medium';
                    font-size: 10px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.55px;
                }

                p {
                    color: #011a3c;
                    font-family: 'SF-Pro-Display-Semibold';
                    font-size: 13px;
                    font-style: normal;
                    line-height: 25px;
                }
            }
        }

        .select_plan {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .monthly_payment_section {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                    color: rgba(1, 26, 60, 0.72);
                    font-family: 'SF-Pro-Display';
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                }
            }

            .select_plan_button {
                display: inline-flex;
                padding: 14px 16px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                border-radius: 30px;
                cursor: pointer;
                border: 0px;
                color: #ffffff;
                background-color: #004aad;
                font-size: 13px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Semibold';
                line-height: normal;
                height: 44px;

                @media screen and (max-width: 501px) {
                    padding: 12px 14px;
                }
            }
        }
    }
}

.payment_plan_container_two {
    width: 100%;
    min-height: 550px;
    padding: 60px 82px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-height: 600px;
    overflow-y: auto;

    @media screen and (max-width: 768px) {
        padding: 60px 48px;
    }

    @media screen and (max-width: 501px) {
        padding: 60px 16px 200px 16px !important;
    }

    .payment_plan {
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px 36px;
        border-radius: 12px;
        background-color: #f9fcff;
        border: 1px solid #e2eeff;

        @media screen and (max-width: 501px) {
            padding: 24px 18px;
        }

        .plan_details {
            display: flex;
            justify-content: space-between;

            div {
                &:nth-child(2) {
                    text-align: right;
                }

                h3 {
                    color: rgba(1, 26, 60, 0.85);
                    font-family: 'SF-Pro-Display-Medium';
                    font-size: 10px;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.55px;
                }

                p {
                    color: #011a3c;
                    font-family: 'SF-Pro-Display-Semibold';
                    font-size: 13px;
                    font-style: normal;
                    line-height: 25px;
                }
            }
        }

        .select_plan {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .monthly_payment_section {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                    color: rgba(1, 26, 60, 0.72);
                    font-family: 'SF-Pro-Display';
                    font-size: 12px;
                    font-style: normal;
                    line-height: normal;
                }
            }

            .select_plan_button {
                display: inline-flex;
                padding: 14px 16px;
                justify-content: center;
                align-items: center;
                gap: 6px;
                border-radius: 30px;
                cursor: pointer;
                border: 0px;
                color: #ffffff;
                background-color: #004aad;
                font-size: 13px;
                font-style: normal;
                font-family: 'SF-Pro-Display-Semibold';
                line-height: normal;
                height: 44px;

                @media screen and (max-width: 501px) {
                    padding: 12px 14px;
                }
            }
        }
    }
}

.monthly_payment {
    color: #011a3c;
    font-family: 'SF-Pro-Display-Semibold';
    font-size: 20px;
    font-style: normal;
    line-height: normal;
}

.selected {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    color: #004aad;
    height: 44px;

    p {
        color: #004aad;
        text-align: center;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 13px;
        font-style: normal;
        line-height: normal;
    }
}

.payment_plan_details {
    padding: 75px 82px 125px 82px;

    @media screen and (max-width: 768px) {
        padding: 75px 48px 125px 48px;
    }

    @media screen and (max-width: 501px) {
        padding: 60px 20px;
    }

    .go_back {
        display: flex;
        cursor: pointer;
        align-items: center;
        gap: 4px;
        margin-bottom: 24px;

        p {
            color: #004aad;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            font-style: normal;
            line-height: normal;
        }
    }

    .upfront_deposit_field {
        height: 143px;
        background-color: #f9fcff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        margin-bottom: 34px;

        > div {
            height: 51px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 0px;

            p {
                color: #000;
                text-align: center;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.4px;
                text-transform: uppercase;
            }

            .upfront_amount {
                color: #000;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 24px;
                font-style: normal;
                line-height: normal;
            }
        }
    }

    .payment_breakdown {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 34px;
        justify-content: space-between;
        flex-wrap: wrap;

        section {
            flex-basis: 1;

            &:nth-child(even) {
                text-align: right;
            }

            h3 {
                color: #000;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.78px;

                > svg {
                    top: 1.5px;
                    position: relative;
                }
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }
    }

    .payment_breakdown_sub {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 34px;
        justify-content: space-between;
        flex-wrap: wrap;

        section {
            flex-basis: 1;

            div {
                width: fit-content;
            }

            h3 {
                color: #000;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.78px;

                > svg {
                    top: 1.5px;
                    position: relative;
                }
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }

        section:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.payment_plan_details_two {
    padding: 75px 70px 125px 70px;

    @media screen and (max-width: 768px) {
        padding: 75px 48px 125px 48px;
    }

    @media screen and (max-width: 501px) {
        padding: 60px 20px;
    }

    .go_back {
        display: flex;
        cursor: pointer;
        align-items: center;
        gap: 4px;
        margin-bottom: 24px;

        p {
            color: #004aad;
            font-family: 'SF-Pro-Display-Medium';
            font-size: 14px;
            font-style: normal;
            line-height: normal;
        }
    }

    .upfront_deposit_field {
        height: 143px;
        background-color: #f9fcff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        margin-bottom: 34px;

        > div {
            height: 51px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 0px;

            p {
                color: #000;
                text-align: center;
                font-family: 'SF-Pro-Display';
                font-size: 10px;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1.4px;
                text-transform: uppercase;
            }

            .upfront_amount {
                color: #000;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 24px;
                font-style: normal;
                line-height: normal;
            }
        }
    }

    .payment_breakdown {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 34px;
        justify-content: space-between;
        flex-wrap: wrap;

        section {
            flex-basis: 1;

            &:nth-child(even) {
                text-align: right;
            }

            h3 {
                color: #000;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.78px;

                > svg {
                    top: 1.5px;
                    position: relative;
                }
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }
    }

    .payment_breakdown_sub {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 34px;
        justify-content: space-between;
        flex-wrap: wrap;

        section {
            flex-basis: 1;

            div {
                width: fit-content;
            }

            h3 {
                color: #000;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.78px;

                > svg {
                    top: 1.5px;
                    position: relative;
                }
            }

            p {
                color: #011a3c;
                font-family: 'SF-Pro-Display-Semibold';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }

        section:last-child {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.select_tenure {
    display: flex;
    color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
    text-align: center;
    margin-bottom: 16px;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
}

.flexible_tenure_wrapper {
    display: flex;
    max-width: 464px;
    height: 60px;
    padding: 10px 19px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 0.5px dashed var(--disabled-btn, rgba(0, 74, 173, 0.79));
    background: var(--shades-and-tints-primary-f-9-fcff, #f9fcff);

    > span {
        display: flex;
        align-items: center;
        color: var(--accent-blue-primary, #004aad);

        font-family: 'SF-Pro-Display';
        font-size: 14px;
        font-style: normal;
        line-height: 24px;
    }

    span:first-child {
        svg {
            margin-right: 8px;
        }
    }

    span:last-child {
        svg {
            cursor: pointer;
        }
    }
}

.switch_tenure_btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
        all: unset;
        color: #013d8c;
        text-align: center;
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        margin-top: 11px;
        cursor: pointer;
    }
}

.mandate_wrapper {
    > p {
        color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
        font-family: 'SF-Pro-Display';
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        max-width: 330px;
        margin-bottom: 22px;
    }

    > div {
        display: flex;
        gap: 17px;
        justify-content: flex-start;

        > div:first-child {
            p {
                color: rgba(35, 56, 98, 0.7);
                text-align: left;
                font-family: 'SF-Pro-Display';
                font-size: 15px;
                font-style: normal;
                line-height: normal;
                margin-top: 12px;
            }
        }

        > div:last-child {
            padding-top: 20px;

            > h2 {
                color: #000;
                text-align: center;
                font-family: 'SF-Pro-Display-Medium';
                font-size: 12px;
                font-style: normal;
                line-height: 24px;
                letter-spacing: 0.78px;
                text-transform: uppercase;
                text-align: left;
            }

            > div {
                display: flex;
                align-items: center;
                gap: 9px;
                margin-top: 19px;
            }

            p {
                color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
                font-family: 'SF-Pro-Display';
                font-size: 14px;
                font-style: normal;
                line-height: 24px;
            }
        }
    }
}

.newdeposit_label {
    color: var(--shades-and-tints-primary-3-d-5075, #3d5075);
    text-align: left;
    font-family: 'SF-Pro-Display';
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;

    span {
        font-family: 'SF-Pro-Display-Semibold';
        font-size: 18px;
    }
}

.depositinfo_container {
    display: flex;
    column-gap: 8px;
    align-items: center;

    p {
        color: #3d5075;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
}

.moreoffer_container_one {
    display: flex;
    column-gap: 8px;
    align-items: center;

    p {
        color: #004aad;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
}

.moreoffer_container_two {
    display: flex;
    justify-content: space-between;

    p {
        max-width: 197px;
        color: rgba(35, 56, 98, 0.7);
        font-family: 'SF-Pro-Display-Medium';
        font-size: 12px;
        line-height: 20px;
    }
}

.insurance_cost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    background: #e2eeff;
    border-radius: 4px;

    > p {
        font: 12px/20px 'SF-Pro-Display-Medium';
        color: #3d5075;
    }
}

.insurance_cost_right {
    display: flex;
    align-items: center;
    gap: 4px;

    > span {
        font: 12px/24px 'SF-Pro-Display-Bold';
        color: #004aad;
    }
}

.repayment_title {
    font-family: 'SF-Pro-Display-Medium';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.repayment_sub_title {
    font-family: 'SF-Pro-Display';
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}

.repayment_type_wrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
}

.repayment_type_child {
    input {
        display: none;
    }

    label {
        transition: all ease-in-out 0.25s;
        // all: unset;
        cursor: pointer;
        display: flex;
        padding: 18px 15px;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid
            var(--shades-and-tints-gray-scale-e-5-e-5-e-5, #e5e5e5);
        background: #fff;

        > div:first-child {
            display: flex;
            gap: 16px;
        }

        > div > div:first-child {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            border: 1px solid
                var(--shades-and-tints-gray-scale-e-5-e-5-e-5, #e5e5e5);
            display: flex;
            justify-content: center;
            align-items: center;
            background: transparent;
        }

        > div > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 3px;
        }

        > div:nth-child(2) {
            display: flex;
            align-items: center;
        }

        h2 {
            color: #233862;
            font-family: 'SF-Pro-Display';
            font-size: 16px;
            font-style: normal;
            line-height: 24px;
            margin: 0;
        }

        p {
            color: rgba(35, 56, 98, 0.7);
            font-family: 'SF-Pro-Display';
            font-size: 12px;
            font-style: normal;
            line-height: 20px;
            margin: 0;
        }
    }

    input[type='radio']:hover + label,
    input[type='radio']:checked + label {
        border: 1px solid var(--primary-004-aad, #004aad);
        background: #e2eeff;

        > div > div:first-child {
            background: #fff;
            border-color: transparent;
        }

        > div:nth-child(2) {
            path {
                stroke: #004aad;
            }
        }
    }
}
