.AddMarginForm {
  display: flex;
  margin-top: 20px;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.AddMarginMobileBackLink {
  display: none;

  @media screen and (max-width: 500px) {
    display: block;
  }
}

.MobileTitleBar {
  @media screen and (max-width: 500px) {
    margin-top: 70px;
  }
}

.InputRight {
  margin-right: 20px;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

.BtnWrapper {
  text-align: center;
   margin-top: 20px;

   @media screen and (max-width: 500px) {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 50px;
  }
}

.Wrapper {
  max-width: 400px;
}

