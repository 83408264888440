.dotsLineLoader {
    display: flex;
    gap: 2.27px;
    align-items: center;

    .dotsLineDot {
        width: 3.24px;
        height: 3.24px;
        margin: 0;
        background-color: #004aad;
        border-radius: 50%;
        display: inline-block;
        animation: moveUpDown 1.5s infinite ease-in-out;

        &1 {
            animation-delay: 0s;
        }

        &2 {
            animation-delay: 0.3s;
        }

        &3 {
            animation-delay: 0.6s;
        }
    }
}

@keyframes moveUpDown {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-2px);
    }
}
