.Wrapper {
    width: 100%;
    height: auto;
    padding: 90px;
    padding-left: 150px;

    &.WrapperV2 {
        padding: 0;
        margin: 90px auto;
        width: fit-content;

        @media only screen and (max-width: 800px) {
            width: 100%;
            padding: 30px 20px 0 20px;
            margin: 0 0 100px 0;
        }
    }

    @media (max-width: 768px) {
        padding: 100px;
    }

    @media (max-width: 480px) {
        padding: 43px;
    }

    @media (max-width: 500px) {
        overflow: hidden;
        padding: 20px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
}

.Wrapper3 {
    display: flex;
    justify-content: center;
    padding: 90px 20px;

    @media (max-width: 500px) {
        padding: 0px 20px;
        padding-bottom: 90px;
    }

    > div {
        max-width: 1244px;
        width: 100%;
    }
}

.Faqs {
    margin: 30px 100px;

    @media (max-width: 768px) {
        margin: 0;
        margin-top: 30px;
    }
}

.Faqs2 {
    margin: 30px 0 0 0;

    @media only screen and (max-width: 500px) {
        margin: 0px;
    }

    div div:nth-child(2) {
        div {
            margin-right: 0px;
        }
    }
}

#PanelDropdown {
    max-width: 440px;
    background: var(--white);
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    margin-bottom: 20px;
    padding: 5px 5px 5px 14px;

    @media screen and (min-width: 1600px) {
        max-width: 100%;
    }

    @media (max-width: 800px) {
        max-width: 100%;
    }
}

#PanelDropdown2 {
    border-radius: 12px;
    border: 1px solid var(--shades-and-tints-primary-e-2-eeff, #e2eeff);
    background: #fff;
    margin-bottom: 20px;
    padding: 5px 5px 5px 14px;

    @media screen and (min-width: 1600px) {
        max-width: 100%;
    }

    @media (max-width: 800px) {
        max-width: 100%;
    }
}

.CollapseRow {
    display: flex;
    justify-content: center;
    font-family: 'SF-Pro-Display-Light';
    font-size: 14px;
    line-height: 26px;
    color: rgba(1, 26, 60, 0.8);
}

.FaqButton {
    display: flex;
    justify-content: center;

    @media screen and (min-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 500px) {
        margin-top: 25px;
    }
}

.Faq3 {
    width: 100%;
}

.Title {
    font-size: 16px;
    font-family: 'SF-Pro-Display-Semibold';
    margin-bottom: 0px;
    margin-left: 10px;
}

.MinusIcon {
    color: var(--blue);
    font-size: 16px !important;
    top: 40% !important;
}

.PlusIcon {
    color: var(--blue);
    font-size: 16px !important;
}

.CollapseStyle {
    margin-right: 20px;

    @media screen and (max-width: 800px) {
        margin-right: 0px;
    }
}

.CollapseStyleV2 {
    width: 100%;

    > div {
        width: 464px !important;
    }
}

.SectionV2 {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }

    .Faqs {
        margin: 0;
        width: 100%;

        .CollapseRow {
            display: block;
        }
    }

    @media only screen and (max-width: 800px) {
        flex-direction: column;
    }
}

.HeaderV2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    height: 100%;
}

.HeaderV2Text {
    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
        margin-bottom: 12px;
        color: #000000;
    }

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #011a3cb2;
        width: 466px;
    }

    @media only screen and (max-width: 800px) {
        h2 {
            font-size: 18px;
            line-height: 24px;
        }

        p {
            font-size: 12px;
            line-height: 20px;
            width: 100%;
        }
    }
}

.cta_hide {
    @media only screen and (max-width: 500px) {
        display: none !important;
    }
}

.v2CTA {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -52px;
    cursor: pointer;

    p {
        font-family: 'SF-Pro-Display';
        font-size: 14px;
        line-height: 24px;
        color: #001837;
    }

    span {
        font-family: 'SF-Pro-Display-SemiBold';
        font-size: 12px;
        line-height: 24px;
        color: #004aad;
        text-decoration: underline;
        text-underline-offset: 3px;
    }

    @media only screen and (max-width: 500px) {
        margin-top: 55px;
    }
}

.SSEHeaderWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    div,
    h2 {
        margin: 0;
    }
}

.SSEColTitle {
    color: var(--shades-and-tints-primary-001837, #001837);
    font-family: 'SF-Pro-Display-Bold';
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;

    @media only screen and (max-width: 500px) {
        margin-top: 30px;
    }
}
