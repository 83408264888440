.Container {
    position: relative;
    width: 182px;
    height: 123px;
    border-radius: 10px;
    overflow: hidden;

    .ImageOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(249, 252, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity linear 200ms;
    }

    &:hover {
        border: 0.5px solid #0458c9;
        .ImageOverlay {
            opacity: 1;
        }
    }
}
