.ImageUploadContent {
    position: relative;
    width: 409px;
    min-height: 111px;
    background: #ffffff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        font: 14px/26px 'SF-Pro-Display';
        color: #233862c7;

        span {
            font-family: 'SF-Pro-Display-SemiBold';
            color: #004aad;
            text-decoration: underline;
            text-underline-offset: 3px;
        }
    }
    .fileSize {
        color: #233862;
        font-family: 'SF-Pro-Display';
        font-size: 12px;
        line-height: 20px;
    }
    .fileFormat {
        color: #233862b2;
        font-family: 'SF-Pro-Display';
        font-size: 13px;
    }

    & .uploadIcon {
        width: 24px;
        height: 24px;

        & path {
            stroke: #b0b0b0;
        }
    }
    .viewFileButton {
        font-family: 'SF-Pro-Display-SemiBold';
        color: #004aad;
        font-size: 12px;
        line-height: 14.32px;
        text-align: center;
        background-color: transparent;
        border: none;
        margin-top: 6px;
        cursor: pointer;
    }
}

.UMC__AdditionalLoadingCopy {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 16px !important;
}
