.adminActivitiesWrapper {
  width: 310px;
  height: 300px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin-top: 25px;

  @media screen and (max-width: 1024px) {
    width: 400px;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.06);
    padding: 0 10px;
  }
}

.SelectBtn {
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      padding-top: 4px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      min-height: 40px;
    }

    .ant-select.SelectBox.ant-select-single.ant-select-show-arrow {
      background-color: transparent;
    }

    .ant-select-item {
      height: 40px;
      color: #233862;
    }

    @media screen and (max-width: 500px) {
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        font-size: 11px;
        padding-top: 6px;
      }
    }

}