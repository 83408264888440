.Wrapper {
    margin: 20px 0px;
}

.HeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.Title {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 22px;
    line-height: 27px;
    color: var(--primary-blue);
    margin-bottom: 5px;
}

.SubTitle {
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
}

.NameFieldWrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 1900px) {
        width: 100%;
    }
}

.CheckboxLink {
    color: rgba(35, 56, 98, 1);
    font-family: 'SF-Pro-Display-Bold';
    margin: 0 5px;
    display: inline-block;
    cursor: pointer;
}

.ButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.LinkWrapper {
    width: 100%;
    height: 26px;
    font-family: 'SF-Pro-Display';
    font-size: 15px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
    margin: 0px auto;
    margin-top: 10px;
}

.CheckBoxWrapper {
    display: flex;
    justify-content: center;
}
.LinkResend {
    font-family: 'SF-Pro-Display';
    font-size: 14px;
    line-height: 26px;
    color: rgba(35, 56, 98, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
}

.LinkResendText {
    margin-bottom: 0px;
}

.FormBoxText {
    font-family: 'SF-Pro-Display-Medium';
    color: var(--blue);
    font-size: 15px;
    line-height: 26px;
    width: 96%;
    margin-top: 18px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.FormBoxText:hover {
    color: var(--hover-text-blue);
}

.LoadingWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 0;
    margin: 0 -5%;
    overflow: hidden;
}

.BtnLoading {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--white);
    color: var(--white);
    box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
        10014px 0 0 0 var(--white);
    animation: dotTyping 1s infinite linear;
}

@keyframes dotTyping {
    0% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    16.667% {
        box-shadow: 9984px -10px 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    33.333% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    50% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px -10px 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    66.667% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
    83.333% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px -10px 0 0 var(--white);
    }
    100% {
        box-shadow: 9984px 0 0 0 var(--white), 9999px 0 0 0 var(--white),
            10014px 0 0 0 var(--white);
    }
}

.ResendErrorText {
    text-align: center;
    color: #da1e28;
}

.resendText {
    color: #004aad;
    cursor: pointer;
}

.HeaderText {
    font-family: 'SF-Pro-Display-Bold';
    font-size: 30px;
    line-height: 36px;
    color: #011a3c;
    text-align: center;
}

.HeaderWrapper {
    font-family: 'SF-Pro-Display';
    border-bottom: 1px solid hsla(0, 0%, 89.8%, 0.7);
    padding-bottom: 26px;
}

.PartnerName {
    font-family: 'SF-Pro-Display-Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #004aad;
    margin-right: 5px;
}

.PartnerDropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 35px;
}

.SetPasswordContainer {
    padding-block: 50px;
    .PasswordInstructionBox {
        display: flex;
        gap: 8px;

        svg {
            margin-top: 2px;
        }

        .PasswordInstructionText {
            font-family: 'SF-Pro-Display';
            font-size: 14px;
            line-height: 21px;
            text-align: left;
            color: #233862cc;
        }

        .PasswordInstructionTextError {
            color: #da1e28;
        }
    }
}

.ApplicationSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .TextBox {
        display: flex;
        flex-direction: column;
        align-items: center;

        .TextBoxTitle {
            font-family: 'SF-Pro-Display-Semibold';
            font-size: 22px;
            line-height: 26.25px;
            color: #011a3c;
            text-align: center;
        }
        .TextBoxContent {
            font-family: 'SF-Pro-Display';
            font-size: 15px;
            line-height: 26px;
            text-align: center;
            color: rgba(35, 56, 98, 0.8);
            max-width: 310px;
        }
    }
}
