.radioBtnWrapper{
	display: flex;
    flex-direction: column;
}
.radioLabel{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
	margin-bottom: 30px;
}

.radioLabel:after{
    content: '';
    width: 8px;
    height: 8px;
    background: white;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}



.notchecked:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}



.checked:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #004AAD;
}
.radioBtnText {
	font-family: 'SF-Pro-Display';
	font-style: normal;
	font-size: 14px;
	line-height: 17px;
	color: #233862;
}


