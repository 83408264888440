.Wrapper {
    background-color: var(--white);
    width: 100%;
    padding: 30px;
}

.HeaderText {
    height: 240px;
    font-family: 'SF-Pro-Display-Bold';
    font-size: 54px;
    line-height: 66px;
    color: var(--primary-blue);
    text-align: center;
    padding-top: 65px;
    background-color: var(--white);

    @media screen and (max-width: 400px) {
        font-size: 40px;
        line-height: 50px;
    }
}

.testimonialWrapper {
    margin-top: 70px;
}

.new__testimonial {
    padding-top: 100px;

    @media screen and (max-width: 500px) {
        padding-top: 50px;
    }
}
