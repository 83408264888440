.Row {
  display: flex;
  align-items: center;
  gap: 75px;
  margin-bottom: 56px;

  @media screen and (max-width: 1300px) {
      gap: 55px
    }
  @media screen and (max-width: 500px){
    margin-bottom: 40px;
  }
}

.Item {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.NameAndStatus {
  display: flex;
  gap: 3px;
  margin-bottom: 45px;
}

.InfoHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}