.ProfileListingsWrapper {
    background-color: #f9fcff;

    @media (min-width: 767px) {
        min-height: calc(100vh - 151px);
    }
}

.ProfileListingsContainer {
    overflow: hidden;
    padding: 82px 135px;

    @media only screen and (min-width: 1441px) {
        max-width: 1440px;
        margin: 0 auto;
    }

    @media only screen and (max-width: 1440px) {
        padding: 82px 80px;
    }

    @media only screen and (max-width: 900px) {
        padding: 82px 20px;
    }

    @media screen and (max-width: 500px) {
        padding: 22px 20px 82px 20px;
        overflow-x: hidden;
        width: 100%;
        margin: 0;
    }
}

.ProfileListingsTitle {
    width: 100%;
    font-family: 'SF-Pro-Display';

    h2 {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 28px;
        line-height: 35px;
        color: #001837;
    }

    p {
        font-size: 16px;
        line-height: 26px;
        color: #233862;
    }

    @media screen and (max-width: 768px) {
        h2 {
            font-size: 18px;
            line-height: 24px;
        }

        p {
            font-size: 14px;
            line-height: 23px;
        }
    }
}

.ProfileCardGrid {
    display: grid;
    grid-gap: 32px 20px;
    margin-top: 24px;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1123px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.PLEmptyStateWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    height: 100%;

    > div {
        max-width: 470px;

        button {
            max-width: 340px;
        }
    }
}
