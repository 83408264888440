.AppliancesPreviewContainer {
    max-width: 357px;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
        padding: 0 24px;
    }
}

.ImportantNotice {
    background-color: #f9fcff;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 1rem 0;
    border: 0.5px solid #e2eeff;

    p:first-of-type {
        font-family: 'SF-Pro-Display-Bold';
        font-size: 14px;
        line-height: 20px;
    }

    p:last-of-type {
        font-size: 12px;
        line-height: 18px;
    }

    @media screen and (max-width: 765px) {
        margin-top: 36px;
    }
}

.WarningIcon {
    background-color: #e2eeff;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    height: 24px;
    width: 24px;
    justify-content: center;
}

.ImportantNoticeText {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0px 0px 8px 0px;

    & + p > span {
        font-family: 'SF-Pro-Display-SemiBold';
    }
}

.AppliancesPreview {
    height: 100%;
    h6 {
        text-transform: uppercase;
        color: #4d4d4d;
        font-size: 12px;
        font-family: 'SF-Pro-Display-SemiBold';
        letter-spacing: 1px;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 6px;

        .tooltip {
            margin-top: 5px;

            svg {
                path {
                    stroke: rgb(35, 56, 98, 0.88);
                }
            }
        }

        span,
        .tooltiptext {
            text-transform: none;
            left: 0;
            margin-left: -140px;
            font-family: 'SF-Pro-Display';
            width: 174px;
            line-height: 18px;
            padding: 9px 8px !important;
            font-size: 12px;
            display: inline-block;
        }

        .tooltip .tooltiptext::after {
            bottom: 99.3%;
            left: 49.2%;
        }

        @media screen and (max-midth: 500px) {
            letter-spacing: 0.78px;
        }
    }

    ul {
        padding: 0;
    }
}

.AllAppliances {
    height: 100%;
    max-height: 300px;
    overflow-y: auto;
    scrollbar-width: none;
    margin: 0;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 765px) {
        max-height: 200px;
        margin-bottom: 5px;
    }
}

.ApplianceItem {
    display: grid;
    width: 100%;
    padding: 12px 0px 16px 0px;
    border-bottom: 0.1px solid rgba(176, 176, 176, 0.5);

    &:last-of-type {
        border-bottom: none;
    }
}

.ApplianceTitle {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    gap: 4px;
}

.ApplianceName {
    font-size: 14px;
    color: #060606;
    line-height: 21px;
}

.ApplianceInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.InfoTitle {
    font-size: 12px;
    color: #4d4d4d;
    margin-bottom: 4px;
    line-height: 18px;
}

.InfoContent {
    font-size: 14px;
    color: #060606;
    line-height: 21px;
}

.SecondaryModalV2,
.ant-modal-body {
    min-height: 0 !important;
    max-height: none !important;
}

.SecondaryModalV2ContentWrapper {
    & > div {
        @media screen and (max-width: 500px) {
            padding-bottom: 130px !important ;
        }
    }
}

.PreviewFooter {
    border-top: 0.3px solid #b0b0b0;

    .Agreement {
        padding: 14px 20px 5px 20px;

        p {
            font-size: 14px;
            line-height: 21px;
            margin-top: -2px;
        }
    }

    @media screen and (max-width: 500px) {
        position: absolute;
        bottom: -60px;
    }
}
